import React, { useState, SyntheticEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import './ShowVerification.css';
import Navbar from '../Navbar/index';
import Footer from '../Footer';
import swal from 'sweetalert';

function mapStateToProps (state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

type adminUserProps = {
  action: {
    user: {
      credenciales: {
        rol: number
        id: string
      }
    }
  }
}

function ShowVerificationPetsitter (props: adminUserProps) {
  const location = useLocation();
  const { request } = location.state;
  const [decline, setDecline] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const navigate = useNavigate();

  function aceptVerification (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/petsitters/updateverification/' + request.userId, {
      userId: request.userId,
      adminId: props.action.user.credenciales.id,
      accepted: true,
      petsitterId: request.userId,
      antecedentesPhotoUrl: request.antecedentesPhotoUrl,
      titulocarreraPhotoUrl: request.titulocarreraPhotoUrl
    }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
      swal({
        title: 'Verificación aceptada',
        icon: 'success',
        buttons: { Aceptar: true },
        timer: 2000
      });
      navigate('/adminverification');
    })
      .catch((error) => {
        console.log(error);
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 5000
        });
      });
  };

  function declineVerification (event: SyntheticEvent) {
    event.preventDefault();
    setDecline(!decline);
  };

  function submitResponse (event: SyntheticEvent) {
    event.preventDefault();
    console.log('enviado');
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/petsitters/updateverification/' + request.userId, {
      userId: request.userId,
      adminId: props.action.user.credenciales.id,
      accepted: false,
      deniedReason: reason,
      petsitterId: request.userId,
      antecedentesPhotoUrl: request.antecedentesPhotoUrl,
      titulocarreraPhotoUrl: request.titulocarreraPhotoUrl
    }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
      swal({
        title: 'Verificación rechazada',
        icon: 'success',
        buttons: { Aceptar: true },
        timer: 2000
      });
      navigate('/adminverification');
    })
      .catch((error) => {
        console.log(error);
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      });
  }

  return (
    <div>
      <div style={{ minHeight: '88.6vh' }}>
        <Navbar logged={true} />
        <div className='spacer32'></div>
        <h6 className='navigation-info-post-post ms-3'>Verificaciones / {request.name}</h6>
        <div className='spacer32'></div>
        <div className='container-show-ver'>
          <div className='col-auto' style={{ width: '100%' }}>
            <div className='row' style={{ margin: '20px 0px', padding: '0px' }}>
              <div className='col-auto texto-informacion' style={{ padding: '0px' }}>
                Información
              </div>
            </div>
            <div className='row' style={{ display: 'flex', justifyContent: 'center', margin: '0px 0px', padding: '0px' }}>
              <div className='col-auto' >
                <div className='row' style={{ justifyContent: 'center', gap: '5vw' }}>
                  <div className='col-auto' style={{ justifyContent: 'center' }}>
                    <div className='spacer12'></div>
                    <div className='spacer20'></div>
                    <div className='texto-campo'>
                      Usuario
                    </div>
                    <div className='spacer12'></div>
                    <div className='box-campo'>
                      <div className='texto-form'>
                        {request.name}
                      </div>
                    </div>
                    <div className='spacer20'></div>
                    <div className='texto-campo'>
                      Nombre
                    </div>
                    <div className='spacer12'></div>
                    <div className='box-campo'>
                      <div className='texto-form'>
                        {request.petsitterName}
                      </div>
                    </div>
                    <div className='texto-campo'>
                      Descripción
                    </div>
                    <div className='spacer12'></div>
                    <div className='box-campo'>
                      <div className='texto-form'>
                        {request.petsitterDescription}
                      </div>
                    </div>
                    </div>
                    <div className='col-auto'>
                    <div className='spacer12'></div>
                    <div className='spacer20'></div>
                    <div className='texto-campo'>
                      Antecedentes
                    </div>
                    <div className='spacer12'></div>
                    <a className='download-button' href={request.antecedentesPhotoUrl} style={{ textDecoration: 'none' }}>Descargar antecedentes del usuario</a>
                    <div className='spacer12'></div>
                    <div className='spacer20'></div>
                    <div className='texto-campo'>
                      Título profesional o técnico
                    </div>
                    <div className='spacer12'></div>
                    <a className='download-button' href={request.titulocarreraPhotoUrl} style={{ textDecoration: 'none' }}>Descargar título profesional o técnico</a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0px', padding: '0px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1vh' }}>
                <button className='acccept-button' onClick={aceptVerification}>Aceptar Verificación</button>
                <button className='decline-button' id={'red-button'} onClick={declineVerification}>Rechazar Verificación</button>
              </div>
            </div>
            {decline
              ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <form onSubmit={(e) => submitResponse(e)} style={{ width: '45%' }}>
                  <h3 className='ver-title'>Razón de rechazo</h3>
                  <input type='text' minLength={10} maxLength={300} value={reason} onChange={e => setReason(e.target.value)} className='reason-input' />
                  <div className="button-verif-petsitter">
                    <button className="acccept-button" value="submit">Enviar</button>
                  </div>
                </form>
              </div>
              : undefined
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default connect(mapStateToProps)(ShowVerificationPetsitter);
