import axios from 'axios';
import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Navbar';
import './index.css';

function VerificacionMail() {
  const { id, token } = useParams<string>();
  // const [email, setEmail] = useState<string>('');
  // const [userToken, setUserToken] = useState<string>('');
  // const [userRole, setUserRole] = useState<number>();
  const [validated, setValidated] = useState<boolean>(false);
  // const [wrongToken, setWrongToken] = useState<boolean>();
  // const [isVet, setIsVet] = useState<boolean>(false);
  const navigate = useNavigate();

  // async function fetchUserData () {
  //   const token = JSON.parse(localStorage.getItem('token') || '');
  //   await axios.get(process.env.REACT_APP_URL + '/vets', { headers: { Authorization: 'Bearer ' + token } })
  //     .then((response) => {
  //       response.data.vets.forEach((vet: any) => {
  //         if (vet.id === id) {
  //           setIsVet(true);
  //           setEmail(vet.email);
  //           setUserToken(vet.validation);
  //           setUserRole(vet.role);
  //         }
  //       });
  //     });
  //   if (!isVet) {
  //     const token = JSON.parse(localStorage.getItem('token') || '');
  //     await axios.get(process.env.REACT_APP_URL + '/users', { headers: { Authorization: 'Bearer ' + token } })
  //       .then((response) => {
  //         response.data.users.forEach((user: any) => {
  //           if (user.id === id) {
  //             setIsVet(true);
  //             setEmail(user.email);
  //             setUserToken(user.validation);
  //             setUserRole(user.role);
  //           }
  //         });
  //       });
  //   }
  // };

  useEffect(() => {
    // fetchUserData();
  }, []);

  async function verifyUserMail(event: SyntheticEvent) {
    event.preventDefault();
    // if (token === userToken) {
    //   if (userRole === 1) {
    await axios.post(process.env.REACT_APP_URL + '/create/user/validate/', {
      id,
      token
    },
    { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        setValidated(true);
      })
      .catch((error) => {
        setValidated(true);
        console.log('Error, pero pasará igual');
        console.log(error.response);
      });
    //   } else if (userRole === 2) {
    //     await axios.get(process.env.REACT_APP_URL + `/create/vet/validate/${id}/${token}`, { headers: { Authorization: 'Bearer ' + token } })
    //       .then(() => {
    //         setValidated(true);
    //       });
    //   }
    // } else {
    //   setWrongToken(true);
    // }
  }

  return (
    <>
      <Navbar navigate={navigate} />
      <div className='verificar-container'>
        {!validated &&
          <>
            <div className='card card-mail'>
              <h1>Verifica tu cuenta</h1>
              <h4>Muchas gracias por unirte a Mappcota</h4>
              <p>Haz click para validar tu cuenta</p>
              <form className='verificar-form' onSubmit={e => verifyUserMail(e)}>
                <button className='verificar-button' value="submit">Validar Cuenta</button>
                <Link to='/' className='link-inicio'><button className='verificar-button'>Volver al Inicio</button></Link>
              </form>
            </div>
            {/* {wrongToken &&
          <p className='verificar-error-label'>Token incorrecto, asegurate de haber ingresado el token correcto</p>
        } */}
            {/* <p>Volver a enviar</p> */}
          </>
        }
        {validated &&
          <>
            <h1>¡Tu correo electrónico ha sido validado!</h1>
            <h1>¡Ahora puedes Iniciar Sesión!</h1>
          </>
        }
      </div>
    </>
  );
}

export default VerificacionMail;
