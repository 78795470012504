import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import './IndexVerifications.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Footer from '../Footer';
import { requestServDataType } from '../../Types/RequestServType';
import { requestVetDataType } from '../../Types/VetTypes';
import { requestPetsitterDataType } from '../../Types/PetSitterTypes';

function IndexVerifications() {
  const [requestsVets, setRequestsVets] = useState<Array<requestVetDataType>>([]);
  const [requestServ, setRequestServ] = useState<Array<requestServDataType>>([]);
  const [requestPetsitter, setRequestsPetsitter] = useState<Array<requestPetsitterDataType>>([]);
  const [requestHotel, setRequestsHotel] = useState<Array<any>>([]);
  const [view, setView] = useState<string>('vets');

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/vets/verificationRequests', { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setRequestsVets(response.data.requests);
      })
      .catch((error) => {
        console.log(error.response);
        swal({
          title: 'Error',
          text: String(error.response.data.error),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      }
      );

    axios.get(process.env.REACT_APP_URL + '/servicesRequests', { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        response.data.forEach((req: requestServDataType) => {
          if (req.is_active) {
            setRequestServ((prevRequestServ) => {
              return [...prevRequestServ, req];
            });
          }
        });
      }).catch((error) => {
        console.log(error.response);
      });

    axios.get(process.env.REACT_APP_URL + '/petsitters/verification', { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        console.log(response.data);
        setRequestsPetsitter(response.data.requests);
      })
      .catch((error) => {
        console.log(error.response);
      }
      );

    axios.get(process.env.REACT_APP_URL + '/hotels/verification', { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setRequestsHotel(response.data.requests);
      })
      .catch((error) => {
        console.log(error.response);
      }
      );
  }, []);

  function mapRequeststoGroupsVets() {
    const arrayLength = 5;
    const finalArray = [];
    for (let i = 0; i < requestsVets.length; i += arrayLength) {
      finalArray.push(requestsVets.slice(i, i + arrayLength));
    }
    return finalArray;
  }

  function mapRequeststoGroupsServ() {
    const arrayLength = 5;
    const finalArray = [];
    for (let i = 0; i < requestServ.length; i += arrayLength) {
      finalArray.push(requestServ.slice(i, i + arrayLength));
    }
    return finalArray;
  }

  function mapRequeststoGroupsPetsitter() {
    const arrayLength = 5;
    const finalArray = [];
    for (let i = 0; i < requestPetsitter.length; i += arrayLength) {
      finalArray.push(requestPetsitter.slice(i, i + arrayLength));
    }
    return finalArray;
  }

  function mapRequeststoGroupsHotel() {
    const arrayLength = 5;
    const finalArray = [];
    for (let i = 0; i < requestHotel.length; i += arrayLength) {
      finalArray.push(requestHotel.slice(i, i + arrayLength));
    }
    return finalArray;
  }

  return (
    <div>
      <div className='wrapper'>
        <Navbar logged={true} />
        <div className='verifications-index-body'>
          <h6 className='navigation-info-post-post ms-3'>Verificaciones / Todas</h6>
          <div className='row margin-row'>
            {view === 'vets' &&
              <>
                <button className="ver-index-title-color" onClick={() => setView('vets')}><h5>Verificaciones de Médicos/as Veterinarios/as</h5></button>
                {/* <button className="ver-index-title" onClick={() => setView('services')}><h5>Verificaciones de servicios</h5></button> */}
                <button className="ver-index-title" onClick={() => setView('petsitters')}><h5>Verificaciones de petsitters</h5></button>
                <button className="ver-index-title" onClick={() => setView('hotels')}><h5>Verificaciones de hoteles</h5></button>
              </>
            }
            {view === 'services' &&
              <>
                <button className="ver-index-title" onClick={() => setView('vets')}><h5>Verificaciones de Médicos/as Veterinarios/as</h5></button>
                {/* <button className="ver-index-title-color" onClick={() => setView('services')}><h5>Verificaciones de servicios</h5></button> */}
                <button className="ver-index-title" onClick={() => setView('petsitters')}><h5>Verificaciones de petsitters</h5></button>
                <button className="ver-index-title" onClick={() => setView('hotels')}><h5>Verificaciones de hoteles</h5></button>
              </>
            }
            {view === 'petsitters' &&
              <>
                <button className="ver-index-title" onClick={() => setView('vets')}><h5>Verificaciones de Médicos/as Veterinarios/as</h5></button>
                {/* <button className="ver-index-title" onClick={() => setView('services')}><h5>Verificaciones de servicios</h5></button> */}
                <button className="ver-index-title-color" onClick={() => setView('petsitters')}><h5>Verificaciones de petsitters</h5></button>
                <button className="ver-index-title" onClick={() => setView('hotels')}><h5>Verificaciones de hoteles</h5></button>
              </>
            }
            {view === 'hotels' &&
              <>
                <button className="ver-index-title" onClick={() => setView('vets')}><h5>Verificaciones de Médicos/as Veterinarios/as</h5></button>
                {/* <button className="ver-index-title" onClick={() => setView('services')}><h5>Verificaciones de servicios</h5></button> */}
                <button className="ver-index-title" onClick={() => setView('petsitters')}><h5>Verificaciones de petsitters</h5></button>
                <button className="ver-index-title-color" onClick={() => setView('hotels')}><h5>Verificaciones de hoteles</h5></button>
              </>
            }
          </div>
          <hr />

          {view === 'vets' && (
            mapRequeststoGroupsVets().map((requestGroup, index) => (
              <div key={index} className='card-row card-group'>
                {requestGroup.map((req) => {
                  return (
                    <div key={req.userId} className="card border h-100 mb-3" style={{ width: '20rem', borderRadius: '10px', maxWidth: '18%', padding: '10px', borderStyle: 'inset', borderLeftWidth: '1px', minHeight: '40vh' }}>
                      <div className="card-body">
                        <h5 className="card-title">{req.name}</h5>
                        <p className="card-text">Número Colmevet: {req.colmevet === 0 ? 'No ingresado' : req.colmevet}</p>
                      </div>
                      <Link to={{
                        pathname: '/adminverification/' + req.userId
                      }}
                        state={{ request: req }}>
                        <button className="ver-button">Ver Información</button>
                      </Link>
                    </div>
                  );
                })}
              </div>
            ))
          )}
          {view === 'services' && (
            mapRequeststoGroupsServ().map((requestGroup, index) => (
              <div key={index} className='card-row card-group'>
                {requestGroup.map((req) => {
                  return (
                    <div key={req.id} className="card border h-100 mb-3" style={{ width: '20rem', borderRadius: '10px', maxWidth: '18%', padding: '10px', borderStyle: 'inset', borderLeftWidth: '1px', minHeight: '30vh' }}>
                      <div className="card-body">
                        <h5 className="card-title">{req.service.name}</h5>
                        <p className="card-text">{req.service.platformCat}</p>
                      </div>
                      <Link to={{
                        pathname: '/adminverificationServ/' + req.serviceId
                      }}
                        state={{ request: req }}>
                        <button className="ver-button">Ver Información</button>
                      </Link>
                    </div>
                  );
                })}
              </div>
            )))}
            {view === 'petsitters' && (
              mapRequeststoGroupsPetsitter().map((requestGroup, index) => (
              <div key={index} className='card-row card-group'>
                {requestGroup.map((req) => {
                  return (
                    <div key={req.id} className="card border h-100 mb-3" style={{ width: '20rem', borderRadius: '10px', maxWidth: '18%', padding: '10px', borderStyle: 'inset', borderLeftWidth: '1px', minHeight: '40vh' }}>
                      <div className="card-body">
                        <h5 className="card-title">{req.petsitterName}</h5>
                        <p className="card-text">Usuario: {req.name}</p>
                        <p className="card-text">Descripción de {req.petsitterName}: {req.petsitterDescription}</p>
                      </div>
                      <Link to={{ pathname: '/adminverificationPetsitter/' + req.id }}
                        state={{ request: req }}>
                        <button className="ver-button">Ver Información</button>
                      </Link>
                    </div>
                  );
                })}
              </div>
              ))
            )}
            {view === 'hotels' && (
              mapRequeststoGroupsHotel().map((requestGroup, index) => (
              <div key={index} className='card-row card-group'>
                {requestGroup.map((req) => {
                  return (
                    <div key={req.id} className="card border h-100 mb-3" style={{ width: '20rem', borderRadius: '10px', maxWidth: '18%', padding: '10px', borderStyle: 'inset', borderLeftWidth: '1px', minHeight: '40vh' }}>
                      <div className="card-body">
                        <h5 className="card-title">{req.hotelName}</h5>
                        <p className="card-text">Usuario: {req.name}</p>
                        <p className="card-text">Descripción de {req.hotelName}: {req.hotelDescription}</p>
                      </div>
                    <Link to={{
                      pathname: '/adminverificationHotel/' + req.id
                    }}
                        state={{ request: req }}>
                        <button className="ver-button">Ver Información</button>
                      </Link>
                    </div>
                  );
                })}
              </div>
              ))
            )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default IndexVerifications;
