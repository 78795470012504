import React, { FC, ReactElement, useEffect, useState } from 'react';
import './index.css';
import axios from 'axios';
import Block from './Card';
import perrito from '../../Assets/Imagenes/perrito.svg';
import { connect } from 'react-redux';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { propsType, reduxAction, response } from '../../Types/petType';
import swal from 'sweetalert';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}
const IndexUserPets : FC <propsType> = (props): ReactElement => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [petParams, setPetParams] = useState<Array<response>>([]);
  const fetchUserPets = async () => {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/userPets/' + id, { headers: { Authorization: 'Bearer ' + token } })
      .then(res => setPetParams(res.data.pets));
  };
  useEffect(() => {
    fetchUserPets();
  }, []);

  function addPatient (petId : string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/headVets/' + petId + '/' + props.action.user.credenciales.id,
      { create: true }, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: 'Mascota agregada con éxito',
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
      })
      .catch(() => {
        swal({
          title: 'Error',
          text: 'Esta mascota ya es tu paciente',
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      }
      );
  }
  function volver () {
    navigate(-1);
  };
  return (
    <>
      <div className='wrapper'>
      <Navbar logged={true} />
      <div className='spacer32'></div>
      <h6 className='navigation-info-post-post ms-3'>Mascotas</h6>
      <div className='spacer-20'></div>
      <div className="row" style={{ display: 'flex', margin: '0px 20px', alignItems: 'center', padding: '0px', justifyContent: 'space-between' }}>

            {petParams.length > 0 && petParams.map(estado => (
            <div key={estado.petId} className="row" style={{ display: 'flex', margin: '0px 20px', alignItems: 'center', padding: '0px', justifyContent: 'space-between' }}>
              <div className='col-8 m-auto'>
                <Block key={estado.petId} id={estado.petId} state={estado} navigate={navigate} location={location} token={props.action.user.credenciales.token} id_actual_user={props.action.user.credenciales.id} />
              </div>
              {props.action.user.credenciales.rol === 2 && petParams.length > 0 &&
              <div className='col-3' style={{ margin: '0px' }}>

                  <button className='button-index-user-pets' role="button" aria-pressed="true" onClick={() => addPatient(estado.petId)}> Agregar Paciente </button>

              </div>
              }
            </div>
            ))}

      </div>
      {petParams.length === 0 &&
            <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className='card-masc container-mascotas-vacio'>
                <div className='title-pets'>
                    Este usuario no posee mascotas
                </div>
                <div className='imagen-vet'>
                <img src={perrito} alt="new" style={{ borderRadius: '10px', maxWidth: '100px', maxHeight: '100px' }} />
            </div>
            </div>
            </div>
            </>
      }
      <div className='spacer-12'></div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <button className='link-back-mascotas-showAll' role="button" aria-pressed="true" onClick = {() => volver()}>Volver</button>
      </div>
      </div>
      <Footer />
    </>
  );
};
export default connect(mapStateToProps)(IndexUserPets);
