import React, { MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import './AddCotutorForm.css';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';

type AddMedType = {
  sendVets: MouseEventHandler,
  handleVetChange: any,
  AddMed: Function,
  vets: Array<any>
}

function AddMedForm (props: AddMedType) {
  return (
    <div className="add-med-form">
      <div className='row-auto'>
        <ul className='nav nav-pills nav-fill' style={{ display: 'flex', flexDirection: 'row-reverse', marginLeft: '1.5vw' }}>
          <button className='closeFormbutton-med' onClick={() => props.AddMed()}><CloseIcon/></button>
        </ul>
        <div className='col-auto'>
          <div className='row'>
          <h4>Seleccione Médicos/as Tratantes</h4>
          </div>
          <div className='spacer12'></div>
          <Select
              className="select-med"
              closeMenuOnSelect={true}
              isMulti
              options = {props.vets}
              onChange={props.handleVetChange}
              placeholder="Seleccione Médicos/as Tratantes"
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          />
          <div className='spacer20'></div>
          <div className='center-button'>
            <button onClick={props.sendVets} className='med-form-button'> Asignar </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AddMedForm.propTypes = {
  sendVets: PropTypes.func,
  handleVetChange: PropTypes.func,
  AddMed: PropTypes.func,
  vets: PropTypes.array
};

export default AddMedForm;
