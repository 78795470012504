import React from 'react';
import { connect } from 'react-redux';
import { PerfilTypes } from '../../Types/PerfilTypes';
import CloseIcon from '@mui/icons-material/Close';
import './index.css';
import { Grid } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import logo from '../../Assets/Imagenes/logo.png';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function Donate(props: PerfilTypes) {
  const datos = 'Banco Estado\nFundación Mascotalerta\nCuenta Vista N° 27970067910\nRUT: 65.187.143-3\nmascotalerta@gmail.com';
  return (
  <>
      <form className='donate-popup'>
        <ul className='nav nav-pills justify-content-center nav-fill'>
          <button className='closeForm' onClick={props.closePopUpVerification}><CloseIcon/></button>
        </ul>
        <div className='register-title-div'>
          <h1 className='register-title'>Datos de transferencia</h1>
        </div>
        <div></div>
        <div className='donate-subtitle-div'>
          <Grid>
          <h5 className='register-subtitle'>Banco Estado</h5>
          <h5 className='register-subtitle'>Fundación Mascotalerta</h5>
          <h5 className='register-subtitle'>Cuenta Vista N° 27970067910</h5>
          <h5 className='register-subtitle'>RUT: 65.187.143-3</h5>
          <h5 className='register-subtitle'>mascotalerta@gmail.com</h5>
          </Grid>
          <img src={logo} className="logo-donate" />
        </div>
        <CopyToClipboard text={datos} onCopy={() => window.alert('Los datos han sido copiados a tu portapapeles')}>
        <div className='donate-buttons'>
          <button className='donate-button'>Copia los datos</button>
        </div>
        </CopyToClipboard>
      </form>
  </>
  );
}

export default connect(mapStateToProps)(Donate);
