import React from 'react';
import PropTypes from 'prop-types';
import './DeletePerfil.css';

type DeletePerfilType = {
  deletePerfilForm: React.MouseEventHandler,
  deletePerfil: React.MouseEventHandler,
}

function DeletePerfilForm (props: DeletePerfilType) {
  return (
        <div className="delete-perfil-form">
            <h4 className="delete-perfil-form-title">¿Estás seguro que quieres eliminar tu perfil?</h4>
            <div>
                <button className="sign-out-form-button" onClick={props.deletePerfilForm}>Cancelar</button>
                <button type='button' className="sign-out-form-button-red" onClick={props.deletePerfil}>Eliminar</button>
            </div>
        </div>
  );
};

DeletePerfilForm.propTypes = {
  deletePerfilForm: PropTypes.func,
  deletePerfil: PropTypes.func
};

export default DeletePerfilForm;
