import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../Navbar/index';
import { MdVerified } from 'react-icons/md';
import { Rating } from 'react-simple-star-rating';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { serviceType } from '../../Types/RequestServType';
import { PerfilTypesToken } from '../../Types/PerfilTypes';
import { FcLikePlaceholder, FcLike } from 'react-icons/fc';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import serviceImg from '../../Assets/Imagenes/blank-service-picture.png';
import Footer from '../Footer';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import wabutton from '../../Assets/Imagenes/wa_esp.png';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function ServiceShow(props: PerfilTypesToken) {
  const [service, setService] = useState<serviceType>({
    name: '',
    email: '',
    address: '',
    addressLat: 0,
    addressLng: 0,
    addressRegion: '',
    addressComuna: '',
    description: '',
    id: '',
    link: '',
    phone: '',
    platformCat: '',
    platformSubCat: [],
    profileUrlImg: '',
    siiGiro: '',
    verified: -1,
    score: 0,
    userId: ''
  });
  const [canRate, setcanRate] = useState(true);
  const [score, setscore] = useState(0);
  const { id } = useParams();
  const [coment, setComment] = useState('');
  const [coments, setcoments] = useState<Array<any>>([]);
  const [like, setlike] = useState(false);
  const [likes, setlikes] = useState(0);
  const [num, setnum] = useState('');
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [whatsApp, setWhatsApp] = useState<string>('');
  const [hoverVerified, setHoverVerified] = useState(false);
  // const [userId, setUserId] = useState('');

  async function fetchService() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/services/' + id + '/' + props.action.user.credenciales.id, { headers: { Authorization: 'Bearer ' + token } })
      .then(res => {
        console.log(res.data);
        fetchCommentsService(res.data.service.id);
        GetLikesService(res.data.service.id);
        AvailableToLike(res.data.service.id);
        setService(res.data.service);
        setscore(res.data.service.score);
        setcanRate(res.data.available);
        // setUserId(res.data.service.userId);

        const numarray = res.data.service.phone.split('');
        const num = '(' + numarray.slice(0, 4).join('') + ')' + ' ' + numarray.slice(4, 8).join('') + ' ' + numarray.slice(7, 11).join('');
        if (res.data.service.phone.slice(0, 4) === '+569') {
          setWhatsApp(res.data.service.phone.slice(1));
        } else {
          setWhatsApp('');
        }
        setnum(num);
      });
  }

  async function rateService(rate: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/serviceReviews/new/' + service.id + '/' + props.action.user.credenciales.id, {
      score: rate,
      comment: ''
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  useEffect(() => {
    fetchService();
  }, []);

  function handleRating(rate: number) {
    setscore(rate);
    setcanRate(false);
    rateService(rate);
  }

  function handleComment(e: any) {
    setComment(e.target.value);
  }

  function AvailableToLike(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/likeService/available/' + id, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      setlike(!res.data.available);
    });
  }

  function handleCommentSubmit() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/commentService/comment/' + service.id, {
      content: coment
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      window.location.reload();
    }
    );
  }

  function deleteComment(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + '/commentService/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      window.location.reload();
    }
    );
  }

  function handleLikeSumit() {
    if (!like) {
      const token = JSON.parse(localStorage.getItem('token') || '');
      axios.post(process.env.REACT_APP_URL + '/likeService/like/' + service.id, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setlikes(response.data.totalLikes);
        setlike(!like);
        console.log(response.data.totalLikes);
      }
      );
    } else {
      const token = JSON.parse(localStorage.getItem('token') || '');
      axios.delete(process.env.REACT_APP_URL + '/likeService/unlike/' + service.id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        if (response.data.newTotalLikes) {
          setlikes(response.data.newTotalLikes);
        } else {
          setlikes(0);
        }
        setlike(!like);
        console.log(response.data.newTotalLikes);
      }
      );
    }
  }

  function GetLikesService(id: any) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/likeService/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setlikes(response.data.totalLikes);
    });
  }

  function fetchCommentsService(id: any) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/commentService/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      console.log(response.data.comments);
      setcoments(response.data.comments.map((com: any) => ({ name: com.user.name, value: com.userId, label: com.content, id: com.id, userId: com.userId })));
    }
    );
  }

  return (
    <div className="wrapper">
      <link rel="stylesheet" href="path/to/font-awesome/css/font-awesome.min.css"></link>
      <Navbar logged={true} />
      <div className='spacer32'></div>
      <h6 className='navigation-info-post-post ms-3'>Servicios / {service.name}</h6>
      <div className='spacer32'></div>
      <div className='container-perfil' style={{ flexGrow: 1 }}>
        <div className='col-auto' style={{ width: '100%' }}>
          <div className='row' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignContent: 'center', margin: '0px' }}>
            <div className='col-auto'>
              <div className='row'>
                <div className='col-auto'>
                  <div className='Box-foto'>
                    <img src={service.profileUrlImg || serviceImg} alt='Image of a service' style={{ borderRadius: '10px', backgroundImage: 'none', width: '15rem' }} />
                  </div>
                  <div className='spacer20'></div>
                  <div style={{ display: 'flex' }}>
                    <h4> {likes} likes</h4>
                    <div style={{ marginLeft: '150px' }}>
                      {like ? <FcLike size={35} onClick={handleLikeSumit}></FcLike> : <FcLikePlaceholder size={35} onClick={handleLikeSumit}></FcLikePlaceholder>}
                    </div>
                  </div>
                </div>
                <div className='col-auto' style={{ marginLeft: '58px', padding: '0px' }}>
                  <div className='row'>
                    {service.verified === 1
                      ? <div style={{ position: 'relative' }} className='col-auto texto-nombre'>
                        {service.name}
                        <MdVerified
                          onMouseOver={() => setHoverVerified(true)}
                          onMouseOut={() => setHoverVerified(false)}
                          style={{ fill: '#C75100', cursor: 'pointer' }}
                        />
                        {hoverVerified &&
                          <span style={{
                            position: 'absolute',
                            backgroundColor: '#C75100',
                            color: 'white',
                            padding: '5px',
                            fontSize: '24px',
                            borderRadius: '5px',
                            bottom: '60px',
                            right: '5px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                          }}>
                            Servicio verificado
                          </span>
                        }
                      </div>
                      : <div className='col-auto texto-nombre'>
                        {service.name}
                      </div>
                    }

                    {
                      whatsApp !== '' &&
                      <div className='box-whatsapp'>
                        <a href={'https://wa.me/' + whatsApp + '?text=Hola!%20Quiero%20consultar%20por%20los%20servicios%20que%20ofrece%20en%20Mappcota.'}>
                          <img alt="Chat on WhatsApp" src={wabutton} />
                        </a>
                      </div>
                    }
                  </div>
                  <div className='row' >
                    <div className='col-auto texto-mail'>
                      {service.email}
                    </div>
                  </div>
                  <div className='row' style={{ marginTop: '20px' }} >
                    <div className='col-auto' style={{ marginRight: '58px' }}>
                      {canRate && <Rating
                        onClick={handleRating}
                        allowFraction={true}
                        fillIcon={null}
                        showTooltip={true}
                        tooltipDefaultText='Califica el servicio'></Rating>
                      }
                    </div>
                    <div className='spacer12'></div>
                    <div className='col-auto texto-mail'> Calificación: {score.toFixed() + '/5'} ⭐</div>
                  </div>
                  <div className='row' style={{ marginTop: '5px', marginLeft: '5px' }} >
                    <TextField inputProps={{ maxLength: 90 }} id="standard-basic" label="Agregar comentario" variant="standard" color='warning' onChange={handleComment} maxRows='1' />
                    <div className='col-auto' style={{ padding: '0px' }}>
                      <button className="petsitter-hire-button" style={{ marginTop: '10px' }} onClick={handleCommentSubmit}>Enviar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row' style={{ margin: '20px 0px', padding: '0px' }}>
            <div className='col-auto texto-informacion' style={{ padding: '0px' }}>
              Información del Servicio
            </div>
          </div>
          <div className='row' style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px' }}>
            <div className='col-auto' style={{ paddingRight: '150px' }}>
              <div className='row texto-campo'>
                Teléfono
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {num}
                </div>
              </div>
              <div className='spacer12'></div>
              <div className='col-auto' >
                <div className='row texto-campo'>
                  Dirección
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {service.address}
                  </div>
                </div>
                <div className='spacer12'></div>
                <div className='row texto-campo'>
                  Región
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {service.addressRegion}
                  </div>
                </div>
                <div className='spacer12'></div>
                <div className='row texto-campo'>
                  Comuna
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {service.addressComuna}
                  </div>
                </div>
                <div className='row texto-campo'>
                Descripción
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {service.description}
                </div>
              </div>
              </div>
            </div>
            <div className='col-auto' style = {{ paddingRight: '150px' }} >
              {service.link !== ''
                ? (<>
              <div className='row texto-campo'>
                Link
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {service.link}
                </div>
              </div>
              </>) : undefined}
              <div className='spacer12'></div>
              <div className='row texto-campo'>
                Comentarios
              </div>
              <div className='spacer12'></div>
              {coments.length > 0
                ? <div className='texto-form'>
                  {coments.map((vet) => (
                    <div key={vet} className='spacer12'>
                      <Card sx={{ maxHeight: 80 }}>
                        <CardContent>
                          <Typography component="div">
                            {vet.label}
                            {vet.userId === props.action.user.credenciales.id && <button className='petsitter-hire-button' onClick={() => deleteComment(vet.id)} style={{ marginLeft: '10px' }}> Eliminar </button>}
                          </Typography>
                          <Typography color="text.secondary">
                            <small>
                              {vet.name}
                            </small>
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
                </div>
                : <Typography>
                  <h6><WarningAmberRoundedIcon color="warning" style= {{ transform: 'scale(1.75) translateY(-2px) translateX(-6px)' }} />No hay comentarios aún</h6>
                </Typography>}
            </div>
          </div>
          <div style={{ margin: 'auto', width: 'fit-content' }}>
            <button className="map-btn" onClick={() => setOpenMap(!openMap)}>{openMap ? 'Cerrar Mapa' : 'Abrir Mapa'}</button>
          </div>
          {openMap &&
            <div style={{ height: '100vh', width: '90%', margin: 'auto', marginBottom: '3vh' }}>
              <GoogleMaps coords={[{ lat: service.addressLat || 0, lng: service.addressLng || 0 }]} services={[service]} />
            </div>
          }
        </div>
        <div style={{ margin: '1rem' }}><Link to='/servicios' className='link-back-veterinarians-show'>Volver</Link></div>
      </div>
      <Footer />
    </div>
  );
};
export default connect(mapStateToProps)(ServiceShow);
