import React, { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import './VerificationPetSitter.css';
import swal from 'sweetalert';
import { VerificationPetSitterTypes, verificationPetSitter } from '../../Types/PetSitterTypes';
import UploadButton from '../Common/UploadButton';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function VerificationPetSitter(props: VerificationPetSitterTypes) {
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [photosPetSitter, setPhotosPetSitter] = useState<{
    titulocarreraPhotoUrl: string,
    antecedentesPhotoUrl: string,
  }>({ titulocarreraPhotoUrl: '', antecedentesPhotoUrl: '' });

  async function submitForm(event: SyntheticEvent) {
    setLoading(true);
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/petsitters/verification/', {
      petsitterId: props.action.user.credenciales.id,
      titulocarreraPhotoUrl: photosPetSitter.titulocarreraPhotoUrl,
      antecedentesPhotoUrl: photosPetSitter.antecedentesPhotoUrl
    }, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        swal({
          title: 'Solicitud de verificación creada con éxito',
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 409) {
          swal({
            title: 'Error',
            text: 'El servicio ya tiene una solicitud activa.',
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        } else {
          swal({
            title: 'Error',
            text: String(error.response.data.message),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        }
      })
      .finally(() => {
        setLoading(false); // Desactivar la pantalla de carga
      });
  }

  const handleFileUpload = (type: 'titulocarreraPhotoUrl' | 'antecedentesPhotoUrl') => (base64: string) => {
    setPhotosPetSitter(prevPhotos => {
      const newPhotos = {
        ...prevPhotos,
        [type]: base64
      };
      const formIsValid = ImagesAreUploaded(newPhotos);
      setIsFormValid(formIsValid);
      return newPhotos;
    });
  };

  const ImagesAreUploaded = (uploadedImages: verificationPetSitter): boolean => {
    let isValid: boolean;
    if (uploadedImages.titulocarreraPhotoUrl && uploadedImages.antecedentesPhotoUrl) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  };

  if (loading) {
    return (<div className="loading-label"><CircularProgress /></div>); // Mostrar el indicador de carga mientras loading es true
  }
  return (
  <>
      <form className='registerForm' onSubmit={async (e) => await submitForm(e)}>
        <ul className='nav nav-pills justify-content-center nav-fill'>
          <button className='closeForm' onClick={props.closePopUpVerification}><CloseIcon/></button>
        </ul>
        <div className='register-title-div'>
          <h1 className='register-title'>¡Solicita tu verificación!</h1>
        </div>
        <div className='register-subtitle-div'>
          <p> Si aún estás cursando medicina veterinaria, sube tu certificado de alumno regular.</p>
        </div>
        <div className='fieldsets'>
          <fieldset className='fieldset-div-ver'>
            <UploadButton
              onFileUpload={handleFileUpload('antecedentesPhotoUrl')}
              labelName='Antecedentes personales'
            />
            <UploadButton
              onFileUpload={handleFileUpload('titulocarreraPhotoUrl')}
              labelName=' Título profesional o técnico en M.V.'
            />
          </fieldset>
        </div>
        <div className='register-buttons'>
          <button className='acept-button' value="submit" disabled={!isFormValid}>Solicitar Verificación</button>
        </div>
      </form>
  </>
  );
}

export default connect(mapStateToProps)(VerificationPetSitter);
