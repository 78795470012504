import './Card.css';
import React, { Component } from 'react';
import axios from 'axios';

type state = {
        name: string,
        id: string,
        profileUrlImg: string,
        race: string,
        species: string,
        gender: string,
        respuesta: boolean,
        microchipId: string
}
type response = {
    petId: string,
    role: string,
    userId: string
}
type propsType = {
    state: response,
    navigate : any,
    location : any,
    token : any,
    id: string,
    id_actual_user?: string
}
export default class Block extends Component < propsType, state> {
  public respuesta: boolean = false;

  componentDidMount () {
    const token = this.props.token;
    axios.get(process.env.REACT_APP_URL + '/pets/' + this.props.state.petId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        if (res.data.pet.gender === 'MALE') {
          this.setState({ gender: 'Macho' });
        } else if (res.data.pet.gender === 'FEMALE') {
          this.setState({ gender: 'Hembra' });
        } else {
          this.setState({ gender: 'Otro' });
        }
        this.setState({
          name: res.data.pet.name,
          id: this.props.state.petId,
          profileUrlImg: res.data.pet.profileUrlImg,
          race: res.data.pet.race,
          species: res.data.pet.species,
          microchipId: res.data.pet.microchipId
        });
        this.respuesta = true;
      });
  }

  redirect (id : string, navigate : any) {
    const path = '/pets/showAll';
    const replaced = path.replace(':id', id);
    navigate(replaced, { state: { state: id, show: 'edit' } });
  };

  redirect_delete (id : string, navigate : any) {
    const path = '/pets/showAll';
    const replaced = path.replace(':id', id);
    navigate(replaced, { state: { state: id, show: 'delete' } });
  };

  redirect_show (id : string, navigate : any, userId : string) {
    const path = '/pets/showAll';
    const replaced = path.replace(':id', id);
    navigate(replaced, { state: { state: id, show: 'show', userId } });
  };

  name_pet (name: string) {
    if (name.length > 8) {
      return name.substring(0, 8) + '...';
    } else {
      return name;
    }
  }

  render () {
    if (this.respuesta) {
      return (
        <>
        <div className='card-pet'>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
                <div className='pet-type'>
                    {this.name_pet(this.state.name)}
                </div>
            </div>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
              <div className='pet-type'>
                  {this.state.species}
              </div>
            </div>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
                <div className='pet-type'>
                    {this.state.gender}
                </div>
            </div>

            {!this.props.id_actual_user &&
              <div className='conteiner-edit'>
                <button onClick={() => this.redirect(this.state.id, this.props.navigate)} style={{ background: 'none', color: 'inherit', border: 'none', font: 'inherit', fontWeight: 'normal', cursor: 'pointer', outline: 'inherit', width: '120px' }}	>✏️</button>
                <button onClick={() => this.redirect_delete(this.state.id, this.props.navigate)} style={{ background: 'none', color: 'inherit', border: 'none', font: 'inherit', fontWeight: 'normal', cursor: 'pointer', outline: 'inherit', width: '120px' }}	>🗑️</button>
                <button onClick={() => this.redirect_show(this.state.id, this.props.navigate, this.props.state.userId)} style={{ background: 'none', color: 'inherit', border: 'none', font: 'inherit', fontWeight: 'normal', cursor: 'pointer', outline: 'inherit', width: '120px' }}>💬</button>
              </div>
            }
        </div>
        </>
      );
    }
  };
}
