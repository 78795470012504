import React from 'react';
import PropTypes from 'prop-types';
import '../Perfil/DeletePerfil.css';

type DeleteDenunciaType = {
  deleteDenunciaForm: React.MouseEventHandler,
  deleteDenuncia: React.MouseEventHandler,
}

function DeleteDenunciaForm (props: DeleteDenunciaType) {
  return (
        <div className="delete-perfil-form">
            <h4 className="delete-perfil-form-title">¿Estás seguro que quieres eliminar la denuncia?</h4>
            <div className="delete-perfil-form-buttons">
                <button className="delete-perfil-form-link" onClick={props.deleteDenunciaForm}>Cancelar</button>
                <button type='button' className="delete-perfil-form-button" onClick={props.deleteDenuncia}>Eliminar</button>
            </div>
        </div>
  );
};

DeleteDenunciaForm.propTypes = {
  deleteDenunciaForm: PropTypes.func,
  deleteDenuncia: PropTypes.func
};

export default DeleteDenunciaForm;
