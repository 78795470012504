import React, { SyntheticEvent, useState, useEffect } from 'react';
import axios from 'axios';
import './formHirePetSitter.css';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { dataHirePetSitter } from '../../Types/PetSitterTypes';
import CloseIcon from '@mui/icons-material/Close';
import { useParams, useNavigate } from 'react-router-dom';

type HirePetSitterFormProps = {
  toggleHirePetsitter: React.MouseEventHandler,
  togglePetSitterForm: React.MouseEventHandler
}

function HirePetSitterForm(props: HirePetSitterFormProps) {
  const [numPets, setNumPets] = useState<number>(1);
  const { id } = useParams();
  const [datos, setDatos] = useState<dataHirePetSitter[]>([{
    name: '',
    vetInfo: '',
    emergency: '',
    alimentation: '',
    dayPetSitter: '',
    startTime: '',
    endTime: '',
    comments: ''
  }]);

  useEffect(() => {
    setDatos([...Array(numPets)].map(() => ({
      name: '',
      vetInfo: '',
      emergency: '',
      alimentation: '',
      dayPetSitter: '',
      startTime: '',
      endTime: '',
      comments: ''
    })));
  }, [numPets]);

  const navigate = useNavigate();

  function redirect() {
    navigate(0);
  }

  function handleInputChange(e: SyntheticEvent, index: number) {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    setDatos(prevDatos => prevDatos.map((dato, i) => (i === index ? { ...dato, [target.name]: target.value } : dato)));
  }

  function submitRegisterVetForm(event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    datos.forEach((dato) => {
      console.log(id);
      axios.post(process.env.REACT_APP_URL + '/petsitters/hirepetsitter/' + id, dato, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          swal({
            title: 'Solicitud para contratar petsitter creada con éxito',
            icon: 'success',
            buttons: { Aceptar: true },
            timer: 2000
          });
          redirect();
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: 'Error',
            text: String(error.response.data.message),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        });
    });
  }

  return (
    <form className="registerForm" onSubmit={submitRegisterVetForm}>
      <ul className="nav nav-pills justify-content-center nav-fill">
        <button className='closeForm' onClick={props.togglePetSitterForm}><CloseIcon /></button>
      </ul>
      <>
        <div className='register-title-div'>
          <h1 className='register-title'>Contratar servicio de Petsitter</h1>
          <script>console.log(id, id)</script>
        </div>
        <div className='register-subtitle-div'>
          <p>Selecciona el número de mascotas que necesitan Petsitter </p>
        </div>
        <div className='center'>
          <select className="select-hire" value={numPets} onChange={(e) => setNumPets(Number(e.target.value))}>
            {[1, 2, 3, 4, 5].map((num) => (
              <option key={num} value={num}>{num}</option>
            ))}
          </select>
        </div>
        <div className='fieldsets'>
          {datos.map((dato, index) => (
            <fieldset key={index} className='fieldset-div-petsitter'>
              <p>
                <input
                  id={`name_${index}`}
                  placeholder=" "
                  type="text"
                  value={dato.name}
                  name="name"
                  onChange={(e) => handleInputChange(e, index)}
                  className="register-form-input-hire"
                  required
                />
                <label className="register-form-petsitter" htmlFor={`name_${index}`}>Nombre de la mascota</label>
              </p>
              <p>
                <input
                  id={`vetInfo_${index}`}
                  placeholder=" "
                  type="text"
                  value={dato.vetInfo}
                  name="vetInfo"
                  onChange={(e) => handleInputChange(e, index)}
                  className="register-form-input-hire"
                  required
                />
                <label className="register-form-petsitter" htmlFor={`vetInfo_${index}`}>Datos de la clínica veterinaria de urgencias (Nombre y dirección)</label>
              </p>
              <p>
                <input
                  id={`emergency_${index}`}
                  placeholder=" "
                  type="text"
                  value={dato.emergency}
                  name="emergency"
                  onChange={(e) => handleInputChange(e, index)}
                  className="register-form-input-hire"
                  required
                />
                <label className="register-form-petsitter" htmlFor={`emergency_${index}`}>Contacto de emergencia (Nombre, número de teléfono y/o email)</label>
              </p>
              <p>
                <input
                  id={`alimentation_${index}`}
                  name="alimentation"
                  className="register-form-input-hire"
                  type="text"
                  value={dato.alimentation}
                  placeholder=" "
                  onChange={(e) => handleInputChange(e, index)}
                  required
                />
                <label className="register-form-petsitter" htmlFor={`alimentation_${index}`}>Información alimentaria (Tipos de alimentos y horarios)</label>
              </p>
              <p>
                <input
                  id={`dayPetSitter_${index}`}
                  name="dayPetSitter"
                  className="register-form-input-hire"
                  placeholder=" "
                  type="date"
                  value={dato.dayPetSitter}
                  onChange={(e) => handleInputChange(e, index)}
                  required
                />
                <label className="register-form-petsitter" htmlFor={`dayPetSitter_${index}`}>Día a contratar Petsitter</label>
              </p>
              <p>
                <input
                  id={`startTime_${index}`}
                  name="startTime"
                  type="time"
                  value={dato.startTime}
                  onChange={(e) => handleInputChange(e, index)}
                  className="register-form-input-hire"
                  required
                />
                <label className="register-form-petsitter" htmlFor={`startTime_${index}`}>Hora de inicio del servicio</label>
              </p>
              <p>
                <input
                  id={`endTime_${index}`}
                  name="endTime"
                  type="time"
                  value={dato.endTime}
                  onChange={(e) => handleInputChange(e, index)}
                  className="register-form-input-hire"
                  required
                />
                <label className="register-form-petsitter" htmlFor={`endTime_${index}`}>Hora de término del servicio</label>
              </p>
              <p> Si desea agregar algún comentario o especificación adicional, por favor hágalo en el siguiente campo:
                <br /> <br />
                <input
                  id={`comments${index}`}
                  name="comments"
                  type="string"
                  placeholder=''
                  value={dato.comments}
                  onChange={(e) => handleInputChange(e, index)}
                  className="register-form-input-hire"
                />
                <label className="register-form-petsitter" htmlFor={`comments${index}`}>Comentarios</label>
              </p>
            </fieldset>
          ))}
          <div className='register-buttons-hire'>
            <button id='create-account'
            className='register-form-button-hire'
            value="submit"
            type="submit"
            >Registrar</button>
          </div>
        </div>
      </>
    </form>
  );
}

HirePetSitterForm.propTypes = {
  toggleHirePetsitter: PropTypes.func,
  togglePetSitterForm: PropTypes.func
};

export default HirePetSitterForm;
