import React, { SyntheticEvent, useState } from 'react';
import Navbar from '../Navbar';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import DeletePerfilForm from './DeletePerfil';
import ReportUserForm from './ReportForm';
import user from '../../Assets/Imagenes/blank-profile-picture-.png';
import { BsThreeDotsVertical } from 'react-icons/bs';
import './showUser.css';
import Footer from '../Footer';
// import { IdentityStore } from 'aws-sdk';

function mapStateToProps (state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

type showUserProps = {
  action: {
    user:{
      credenciales: {
        rol: number,
        id: string
      }
    }
  }
}

function ShowUser (props: showUserProps) {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [region, setRegion] = useState('');
  const [comune, setComune] = useState('');
  const [admin, setAdmin] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openFormReport, setOpenFormReport] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('token') || '');
  axios.get(process.env.REACT_APP_URL + '/users/' + id, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      setName(response.data.user.name);
      setImage(response.data.user.profileUrlImg);
      setRegion(response.data.user.addressRegion);
      setComune(response.data.user.addressComuna);
      if (props.action.user.credenciales.rol === 5 || props.action.user.credenciales.rol === 4 || props.action.user.credenciales.rol === 3) {
        setAdmin(true);
      }
    });
  axios.get(process.env.REACT_APP_URL + '/vets/' + id, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      console.log(response);
      setName(response.data.vet.name);
      setImage(response.data.vet.profileUrlImg);
      setRegion(response.data.vet.addressRegion);
      setComune(response.data.vet.addressComuna);
      if (props.action.user.credenciales.rol === 5 || props.action.user.credenciales.rol === 4 || props.action.user.credenciales.rol === 3) {
        setAdmin(true);
      }
    });
  // async function createPrivate (event: SyntheticEvent) {
  //   event.preventDefault();
  //   const token = JSON.parse(localStorage.getItem('token') || '');
  //   const userId = props.action.user.credenciales.id;
  //   const buddyId = id;
  //   await axios.post(process.env.REACT_APP_URL + '/chat/createPrivate', {
  //     userId,
  //     buddyId
  //   }, { headers: { Authorization: `Bearer ${token}` } })
  //     .then((response) => {
  //       const roomId = response.data.roomId;
  //       navigate('/chat/' + roomId);
  //     });
  // }

  function deleteUserForm (event: SyntheticEvent) {
    event.preventDefault();
    setOpenForm(!openForm);
  };

  function deleteUser (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + '/users/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setOpenForm(!openForm);
        navigate('/users');
      });
  }
  // console.log('imagen: ', image);

  function reportUserForm () {
    setOpenFormReport(!openFormReport);
  }

  return (
    <div>
    <div className='wrapper' style={{ height: '100vh' }}>
      <Navbar logged={true} />
      <div className='spacer32'></div>
      <h6 className='navigation-info-post-post ms-3'><Link to='/users' className='link-perfil'>Tutores</Link> / {name}</h6>
      <div className='spacer32'></div>
      <div className='container-perfil'>
        <div className='card-tutor'>
        <div className='spacer32'></div>
        <div className='col-auto' style={{ width: '100%' }}>
          <div className='row' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignContent: 'center', margin: '0px' }}>
            <div className='col-auto'>
              <div className='row'>
                <div className='col-auto'>
                  <div className='Box-foto'>
                    <img src={image || user} alt='Image of a vet' style={{ borderRadius: '10px', backgroundImage: 'none', width: '20rem' }} />
                  </div>
                </div>
                <div className='col-auto' style={{ marginLeft: '58px', padding: '0px' }}>
                  <div className='row'>
                    <div className='col-auto texto-nombre-tutor'>{name}</div>
                    <div className='col-auto' style={{ padding: '0px' }}>
                    {admin === true &&
                      <button className="sign-out-form-button-red" onClick={deleteUserForm}>Eliminar Usuario</button>
                    }
                    </div>
                  </div>
                  <div className='col-auto texto-direccion'>{comune}, {region}</div>
                  <div className='spacer20'></div>
                  {/* { (props.action.user.credenciales.id !== id) &&
                    <button onClick={createPrivate} className='button-tutor-show'>Enviar mensaje privado</button>
                  } */}
                  <Link to={'/users/pets/' + id} className='link-mascotas'><button className='button-tutor-show'>Ver mascotas</button></Link>

                  { (props.action.user.credenciales.id !== id && props.action.user.credenciales.rol === 1) &&
                    <div className='dropdown div-button-dots'>
                    <button className='button-dots' type='button' id='dropdownMenuButton' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                      <BsThreeDotsVertical size={25}/>
                    </button>
                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <li className='dropdown-item' onClick={reportUserForm}>Denunciar</li>
                    </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='spacer32'></div>
        </div>
      <div className='spacer20'></div>
      </div>
        {openForm === true &&
          <div className='delete-perfil-form-container'>
            <DeletePerfilForm deletePerfilForm={deleteUserForm} deletePerfil={deleteUser}/>
          </div>
        }
        {openFormReport === true &&
          <div className='delete-perfil-form-container'>
            <ReportUserForm reportUserForm={reportUserForm} id={id}/>
          </div>
        }
    </div>
    <Footer />
    </div>

  );
}

export default connect(mapStateToProps)(ShowUser);
