import React, { useState, SyntheticEvent } from 'react';

import './index.css';
import ig from '../../Assets/Imagenes/ig_icon.png';
import fb from '../../Assets/Imagenes/fb_wht.png';
import tw from '../../Assets/Imagenes/x_wht.png';
import yt from '../../Assets/Imagenes/yt_icon.png';
import newLogo from '../../Assets/Imagenes/LOGO BLANCO FONDO TRANSPARENTE.png';
import termsConditions from '../RegisterForm/termsCondition';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Donate from '../WelcomePage/Donate';
import Interest from '../WelcomePage/Interest';
// import chat from '../../Assets/Imagenes/chat_icon.png';

function Footer() {
  const [showTermsConditions, setShowTermsConditions] = useState<boolean>(false);
  const [toggleDonate, setToggleDonate] = useState<boolean>(false);
  const [toggleInterest, setToggleInterest] = useState<boolean>(false);

  function togglePopUpDonate(event: SyntheticEvent) {
    event.preventDefault();
    setToggleDonate(!toggleDonate);
  }

  function togglePopUpInterest(event: SyntheticEvent) {
    event.preventDefault();
    setToggleInterest(!toggleInterest);
  }

  return (
    <>
      <div className="Footer">
        <img src={newLogo} className="footer-logo" />
        <a href="https://www.instagram.com/mascotalerta/"><img src={ig} className='footer-button' /></a>
        <a href="https://www.facebook.com/mascotalerta.cl/"><img src={fb} className='footer-button' /></a>
        <a href="https://twitter.com/mascotalerta"><img src={tw} className='footer-button' /></a>
        <a href="https://www.youtube.com/@mascotalerta"><img src={yt} className='footer-button' /></a>
        <div className="FooterLegend" onClick={() => setShowTermsConditions(true)}>Términos y condiciones</div>
        {showTermsConditions &&
                    <Dialog open={showTermsConditions} scroll='paper' maxWidth='lg'>
                      <DialogActions>
                        <button className='closeForm' onClick={() => setShowTermsConditions(false)}><CloseIcon /></button>
                      </DialogActions>
                      <DialogTitle id="scroll-dialog-title">
                      <h4> <strong>TÉRMINOS Y CONDICIONES Y AVISO DE PRIVACIDAD</strong></h4>
                      </DialogTitle>
                          <DialogContent>
                            <DialogContentText
                              id="scroll-dialog-description"
                              tabIndex={-1}
                            >
                              {termsConditions()}
                            </DialogContentText>
                          </DialogContent>
                    </Dialog>
                  }
        <div className="FooterLegend" onClick={e => togglePopUpDonate(e)}>Dona a Mascotalerta</div>
        {toggleDonate === true &&
        <div className='register-form-container'>
          <div className='register-form-content'>
            <Donate closePopUpVerification={togglePopUpDonate} />
          </div>
        </div>
        }
        <div className="FooterLegend" onClick={e => togglePopUpInterest(e)}>Páginas de interés</div>
        {toggleInterest === true &&
        <div className='register-form-container'>
          <div className='register-form-content'>
            <Interest closePopUpVerification={togglePopUpInterest} />
          </div>
        </div>
        }
          {/* { (authentifier() && location.pathname !== '/') &&
            <div>
              <Link to="/chat"><img src={chat} className='chat-button' /></Link>
            </div>
          } */}
        </div>
  </>);
};

export default Footer;
