import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Grid, TextField } from '@mui/material';
import './index.css';
import './petShowAll.css';
import Block from './Card';
import { propsType, response, reduxAction } from '../../Types/petType';
import PopUpPetShowAll from '../Microchip/popUpPetShowAll';
import SelectableFilterList from '../Common/selectableFilterList';
import { speciesAndRaces } from '../../const/speciesAndRaces';

function mapStateToProps(state: reduxAction) {
  return { action: state };
}

function PetShowAllRefactor(props: propsType) {
  const token = props.action.user.credenciales.token;
  const speciesNames = Object.values(speciesAndRaces.species).map((species) => species.nameSpecies);

  const [petParams, setPetParams] = useState<Array<response>>([]);
  const [petsFiltered, setPetsFiltered] = useState<Array<response>>([]);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [microchipFilter, setMicrochipFilter] = useState<string>('');
  const [checkedFilter, setCheckedFilter] = useState<{
    species: string[];
  }>({
    species: []
  });

  const fetchPets = async () => {
    const queryParams = new URLSearchParams();

    const addFilterParam = (filterArray: string[], paramName: string) => {
      const joinedFilter = filterArray.join(',');
      if (joinedFilter) {
        queryParams.append(paramName, joinedFilter);
      }
    };
    addFilterParam(checkedFilter.species, 'species');
    if (nameFilter) {
      queryParams.append('name', nameFilter);
    }
    if (microchipFilter) {
      queryParams.append('microchip', microchipFilter);
    }
    const url = `${process.env.REACT_APP_URL}/userPets/${props.action.user.credenciales.id}?${queryParams.toString()}`;
    await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setPetParams(res.data.pets);
        setPetsFiltered(res.data.pets);
      });
  };

  const checkPetsForMicrochip = () => {
    return petParams.some(pet => pet.pet.microchipId === '');
  };

  useEffect(() => {
    fetchPets();
  }, [nameFilter, microchipFilter, checkedFilter]);

  function redirectCreate(props: propsType) {
    const path = '/pets/showAll';
    props.navigate(path, { state: { show: 'create' } });
  };

  function filterName(filter: string) {
    setNameFilter(filter);
    if (filter === '') {
      setPetsFiltered(petParams);
    } else {
      setPetsFiltered(petParams.filter((pet) => pet.pet.name.toLowerCase().includes(filter.toLowerCase())));
    }
  };

  function filterMicrochip(filter: string) {
    setMicrochipFilter(filter);
    if (filter === '') {
      setPetsFiltered(petParams);
    } else {
      setPetsFiltered(petParams.filter((pet) => pet.pet.microchipId.toLowerCase().includes(filter.toLowerCase())));
    }
  };

  const handleToggle = (
    listName: 'species',
    value: string
  ) => () => {
    const currentIndex = checkedFilter[listName].indexOf(value);
    const newChecked = [...checkedFilter[listName]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilter((prev) => ({ ...prev, [listName]: newChecked }));

    if (newChecked.length === 0) {
      setPetsFiltered(petParams);
    } else {
      setPetsFiltered(petParams.filter((pet) =>
        newChecked.some(species =>
          pet.pet.species.toLowerCase().includes(species.toLowerCase())
        )
      ));
    }
  };

  return (
    <div>
    <Grid container justifyContent={'center'} style={{ padding: '30px', height: '80vh' }}>
      <Grid item xs={3} className='filters-container'>
        <h6 className='navigation-info-post-post pt-3 ms-3'>Mascotas / Todos</h6>
        <div className='category-container'>
          <div className='category-title'>
            <h2>Nombre</h2>
          </div>
          <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nameFilter} onChange={e => filterName(e.target.value)} />
        </div>
        <div className='category-container'>
          <div className='category-title'>
            <h2>Microchip</h2>
          </div>
          <TextField id="outlined-basic" label="Microchip" variant="outlined" value={microchipFilter} onChange={e => filterMicrochip(e.target.value)} />
        </div>
        <div className='category-container'>
          <div className='category-title'>
            <h2>Especie</h2>
          </div>
          <SelectableFilterList
            items={speciesNames} checked={checkedFilter.species}
            handleToggle={(value) => handleToggle('species', value)}
          />
        </div>
      </Grid>
      <Grid
        item
        container
        justifyContent={'space-evenly'}
        alignContent={'start'}
        xs={9}
        className='card-group'
      >
        <div style={{ marginRight: '70px', marginLeft: '48px' }}>
          <div className='col-auto m-4' >
            <div className='Box-ingreso-show' style={{ justifyContent: 'center', maxWidth: 'max-content', margin: 'auto' }}>
              <div className='pet-name-show'>
                <button onClick={() => redirectCreate(props)} className='add-microchip-button'> Ingresar Mascota </button>
              </div>
            </div>
          </div>
          {petsFiltered.length > 0
            ? <div className='card-pet'>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
                <div className='pet-type'>
                    Nombre
                </div>
            </div>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
              <div className='pet-type'>
                  Especie
              </div>
            </div>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
              <div className='pet-type'>
                Sexo
              </div>
            </div>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
              <div className='pet-type'>
                Editar
              </div>
            </div>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
              <div className='pet-type'>
                Eliminar
              </div>
            </div>
            <div className='col-auto' style={{ margin: '10px', width: '100px' }}>
              <div className='pet-type'>
                  Ver más
              </div>
            </div>
        </div> : <h4>No tienes mascotas registradas</h4>}
          {petsFiltered.map((estado: response) => {
            return (
              <Grid item key={estado.petId}>
                <Block key={estado.petId}
                  id={estado.petId}
                  state={estado}
                  navigate={props.navigate}
                  location={props.location}
                  token={props.action.user.credenciales.token}
                />
              </Grid>
            );
          })
          }
          <PopUpPetShowAll shouldShowPopup={checkPetsForMicrochip} />
        </div>
      </Grid>
    </Grid>
    </div>
  );
};

export default connect(mapStateToProps)(PetShowAllRefactor);
