import React, { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import './index.css';
import { useDispatch } from 'react-redux';
import { login } from '../Store/user';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import PasswordRecoveryForm from '../PasswordRecovery/index';
import CloseIcon from '@mui/icons-material/Close';

type SignInFormProps = {
  closeSignInFormOpenSignUp: React.MouseEventHandler,
  closeSignInForm: React.MouseEventHandler,
  navigate: Function
}

function redirect(type: any, token: string, role: string, isservice: string, ispetsitter: string, isvet: string, props: SignInFormProps, dispatch: any) {
  const state = { id: type.id, token, rol: role, isservice, ispetsitter, isvet };
  dispatch(login(state));
  props.navigate('/landing');
}

function SignInForm(props: SignInFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [recoveryForm, setRecoveryForm] = useState<boolean>(false);
  const dispatch = useDispatch();

  const submitSignInForm = (event: SyntheticEvent) => {
    event.preventDefault();
    axios.post(process.env.REACT_APP_URL + '/session/login', {
      email,
      password
    })
      .then((response) => {
        if (response.data.user) {
          localStorage.setItem('token', JSON.stringify(response.data.token));
          redirect(response.data.user, response.data.token, response.data.user.role, response.data.user.isservice, response.data.user.ispetsitter, response.data.user.isvet, props, dispatch);
        }
      })
      .catch((error) => {
        swal({
          title: 'Error',
          text: String(error.response.data.error),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
        handleError(error.response.data.error);
      });
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submitSignInForm(event);
    }
  };

  function handleError(err: any) {
    setError(err);
  }

  function toggleRecoveryForm(event: SyntheticEvent) {
    event.preventDefault();
    setRecoveryForm(!recoveryForm);
  }

  return (
    <>
      {recoveryForm
        ? <PasswordRecoveryForm toggleRecoveryForm={toggleRecoveryForm} />
        : <form className="sign-in-form" onSubmit={submitSignInForm}>
            <div className='sign-in-title-div'>
              <h1 className='sign-in-title'>¡Inicia Sesión!</h1>
              <button className='closeForm' onClick={props.closeSignInForm}><CloseIcon /></button>
            </div>
            {error !== '' &&
              <p className="error-message">{error}</p>
            }
            <div className="fieldset-area">
              <fieldset className="fieldset">
                <p>
                  <input id="email" type="text" value={email} name="email" onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress} required />
                  <label htmlFor="email">Email</label>
                </p>
              </fieldset>
              <fieldset className="fieldset">
                <p>
                  <input id="password" type="password" value={password} name="password" onChange={e => setPassword(e.target.value)} onKeyPress={handleKeyPress} required />
                  <label htmlFor="password">Contraseña</label>
                </p>
              </fieldset>
            </div>
            <div className="sign-in-buttons">
              <button className="sign-in-form-button" id="sign-in" type="submit">Iniciar Sesión</button>
              <button className="sign-in-form-button" type="button" onClick={(e) => toggleRecoveryForm(e)}>Olvidé Mi Contraseña</button>
              <button className="sign-in-form-button" type="button" onClick={props.closeSignInFormOpenSignUp}>¿Aún no tienes una cuenta? ¡Regístrate!</button>
            </div>
          </form>
      }
    </>
  );
};

SignInForm.propTypes = {
  closeSignInForm: PropTypes.func,
  closeSignInFormOpenSignUp: PropTypes.func
};

export default SignInForm;
