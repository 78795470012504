export const productsAndServices = {
  products: [
    {
      category: 'ACCESORIOS Y JUGUETES',
      subCategories: [
        'ARNESES',
        'BOLSAS Y DISPENSADORES',
        'BOZALES',
        'CAMAS',
        'CASAS',
        'COLLARES Y PLACAS',
        'CORREAS',
        'JAULAS DE TRANSPORTE',
        'JUGUETES',
        'JUGUETES DE ENTRENAMIENTO',
        'PLATOS Y BEBEDEROS',
        'ROPA',
        'SABANILLAS',
        'OTRO'
      ]
    },
    {
      category: 'ALIMENTOS Y SNACKS',
      subCategories: [
        'ALIMENTO HÚMEDO',
        'ALIMENTO MEDICADO',
        'ALIMENTO NATURAL',
        'ALIMENTO SECO',
        'GALLETAS',
        'HUESOS CARTÍLAGO',
        'HUESOS NATURALES',
        'PREMIOS ADIESTRAMIENTO',
        'SNACKS DENTALES',
        'SNACKS HÚMEDOS',
        'SNACKS NATURALES',
        'OTRO'
      ]
    },
    {
      category: 'CUIDADO E HIGIENE',
      subCategories: [
        'CEPILLOS',
        'CORTAUÑAS',
        'CUIDADO DENTAL',
        'CUIDADO DERMATO',
        'CUIDADO DERMATOLÓGICO',
        'CUIDADO OJOS Y OIDOS',
        'CUIDADO PIEL Y PELO',
        'LIMPIEZA Y ASEO',
        'UTENSILIOS DE BELLEZA',
        'OTRO'
      ]
    },
    {
      category: 'FARMACIA Y MEDICAMENTOS',
      subCategories: [
        'ANTIBIOTICOS',
        'ANTIMICÓTICOS',
        'ANTIPARASITARIOS EXTERNOS',
        'ANTIPARASITARIOS INTERNOS',
        'COLIRIOS',
        'COLLARES PARA PULGAS',
        'INMUNOMODULADORES',
        'SHAMPOOS MEDICADOS',
        'SUPLEMENTOS NUTRICIONALES',
        'OTRO'
      ]
    },
    {
      category: 'OTRO',
      subCategories: []
    }
  ],
  services: [
    {
      category: 'ADIESTRAMIENTO',
      subCategories: [
        'ADIESTRAMIENTO CANINO',
        'ADIESTRAMIENTO FELINO',
        'CAPACITACIONES',
        'CURSOS DE ADIESTRAMIENTO'
      ]
    },
    {
      category: 'CAFETERIAS PET FRIENDLY',
      subCategories: ['CAFETERIAS PARA GATOS', 'CAFETERIAS PARA PERROS']
    },
    {
      category: 'CONSULTAS DE ESPECIALIDAD MV',
      subCategories: [
        'CARDIOLOGÍA',
        'DERMATOLOGÍA',
        'DOCENCIA',
        'ETOLOGÍA CLÍNICA',
        'GASTROENTEROLOGÍA',
        'INOCUIDAD ALIMENTARIA',
        'MASCOTAS NO CONVENCIONALES',
        'MEDICINA ACUÍCOLA',
        'MEDICINA AVES',
        'MEDICINA BOVINOS',
        'MEDICINA CERDOS',
        'MEDICINA EQUINOS',
        'MEDICINA PEQUEÑOS RUMIANTES',
        'NEUROLOGÍA',
        'ODONTOLOGÍA',
        'OFTALMOLOGÍA',
        'RESPIRATORIO',
        'SALUD PÚBLICA Y ZOONOSIS',
        'OTRO'
      ]
    },
    {
      category: 'CREMATORIOS Y CEMENTERIOS',
      subCategories: ['CEMENTERIOS MASCOTAS', 'CREMATORIOS MASCOTAS']
    },
    {
      category: 'FOTOGRAFIA Y RETRATOS',
      subCategories: ['LAPIZ', 'OLEO', 'OTRO', 'SERVICIOS A DOMICILIO']
    },
    {
      category: 'HOTELES Y GUARDERÍA',
      subCategories: [
        'GUARDERÍA CANINA',
        'GUARDERÍA FELINA',
        'HOTELES MASCOTAS NO CONVENCIONALES',
        'HOTELES PARA GATOS',
        'HOTELES PARA PERROS'
      ]
    },
    {
      category: 'MEDICO/A VETERINARIO/A DOMICILIO',
      subCategories: [
        'MEDICO/A VETERINARIO/A ANIMALES MAYORES',
        'MÉDICO/A VETERINARIO/A ANIMALES MENORES'
      ]
    },
    {
      category: 'ORGANIZADOR DE EVENTOS',
      subCategories: [
        'ACCESORIOS PARA EVENTOS',
        'CUMPLEAÑOS',
        'REPOSTERIA MASCOTAS',
        'OTRO'
      ]
    },
    {
      category: 'PASEO DE MASCOTAS',
      subCategories: [
        'PASEO CANINO',
        'PASEO FELINO',
        'OTRO'
      ]
    },
    {
      category: 'PELUQUERÍA Y ESTÉTICA',
      subCategories: [
        'PELUQUERIA CANINA A DOMICILIO',
        'PELUQUERIA CANINA',
        'PELUQUERIA FELINA A DOMICILIO',
        'PELUQUERIA FELINA',
        'PELUQUERIA PROFESIONAL'
      ]
    },
    {
      category: 'SEGUROS PARA MASCOTAS',
      subCategories: []
    },
    {
      category: 'SERVICIOS VETERINARIOS',
      subCategories: [
        'CLINICAS VETERINARIAS',
        'CONSULTAS DE ESPECIALIDAD',
        'CONSULTAS PARTICULARES',
        'HOSPITALES VETERINARIOS'
      ]
    },
    {
      category: 'TRANSPORTE PARA MASCOTAS',
      subCategories: [
        'ASESORIA TRANSPORTE MASCOTAS',
        'SERVICIO DE TRASLADO MASCOTAS',
        'TAXI PET'
      ]
    },
    {
      category: 'OTRO',
      subCategories: []
    }
  ]
};
