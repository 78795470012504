import React, { MouseEventHandler } from 'react';
import PropTypes from 'prop-types';
import './AddMedForm.css';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';

type AddCotutorType = {
  sendUsers: MouseEventHandler,
  handleUserChange: any,
  AddCotutor: Function,
  users: Array<any>
}

function AddCotutorForm (props: AddCotutorType) {
  return (
    <div className="add-cotutor-form">
      <div className='row-auto'>
        <ul className='nav nav-pills nav-fill' style={{ display: 'flex', flexDirection: 'row-reverse', marginLeft: '4vw' }}>
          <button className='closeFormbutton-cotutor' onClick={() => props.AddCotutor()}><CloseIcon/></button>
        </ul>
        <div className='col-auto'>
          <div className='row'>
          <h4>Seleccione Cotutores/as</h4>
          </div>
          <div className='spacer12'></div>
          <Select
              className="select-med"
              closeMenuOnSelect={true}
              isMulti
              options = {props.users}
              onChange={props.handleUserChange}
              placeholder="Seleccione Cotutores/as"
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          />
          <div className='spacer20'></div>
          <div className='center-button'>
            <button onClick={props.sendUsers} className='cotutor-form-button'> Agregar </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AddCotutorForm.propTypes = {
  sendUsers: PropTypes.func,
  handleUserChange: PropTypes.func,
  AddCotutor: PropTypes.func,
  users: PropTypes.array
};

export default AddCotutorForm;
