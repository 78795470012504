import React, { SyntheticEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';
import swal from 'sweetalert';
import { Alert, Grid, Pagination } from '@mui/material';
import { serviceType } from '../../Types/RequestServType';
import { productsAndServices } from '../../const/productsAndServices';
import { PerfilTypesToken } from '../../Types/PerfilTypes';
import RegisterServiceForm from '../Servicios/registerServiceForm';
import Footer from '../Footer';
import Navbar from '../Navbar';
import RegionesYcomunas from '../RegisterForm/regionesComunas';
import SelectableFilterList from '../Common/selectableFilterList';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import serviceImg from '../../Assets/Imagenes/blank-service-picture.png';
import '../GoogleMaps/googleMaps.css';
import './showAll.css';
import AnnouncementIcon from '@mui/icons-material/LightbulbOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

function mapStateToProps(state: any) {
  return { action: state };
}

function ServicesShowAll(props: PerfilTypesToken) {
  const servicesInPage: number = 10;
  const regionsNames = Object.values(RegionesYcomunas.regiones).map((region) => region.NombreRegion);
  const productsCategories = Object.values(productsAndServices.products).map((product) => product.category);
  const servicesCategories = Object.values(productsAndServices.services).map((service) => service.category);
  const [registerService, setRegisterService] = useState<boolean>(false);
  const [services, setServices] = useState<Array<serviceType>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [comunasOptions, setComunasOptions] = useState<Array<string>>([]);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [subcategories, setSubcategories] = useState<{
    products: string[];
    services: string[];
  }>({
    products: [],
    services: []
  });
  const [checkedFilter, setCheckedFilter] = useState<{
    productCategory: string[];
    productSubcategory: string[];
    serviceCategory: string[];
    serviceSubcategory: string[];
    region: string[];
    comunas: string[];
  }>({
    productCategory: [],
    productSubcategory: [],
    serviceCategory: [],
    serviceSubcategory: [],
    region: [],
    comunas: []
  });
  const [nameFilter, setNameFilter] = useState<string>('');

  function toggleRegisterService(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(!registerService);
  }

  function closeServiceOpenRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(false);
  }

  function closeServiceOpenLogIn(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(false);
  }

  function toggleServiceRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(!registerService);
  }

  useEffect(() => {
    fetchServices(currentPage);
  }, [currentPage, checkedFilter, nameFilter]);

  async function fetchServices(page: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('limit', servicesInPage.toString());

    const addFilterParam = (filterArray: string[], paramName: string) => {
      const joinedFilter = filterArray.join(',');
      if (joinedFilter) {
        queryParams.append(paramName, joinedFilter);
      }
    };
    addFilterParam([...checkedFilter.productCategory, ...checkedFilter.serviceCategory], 'category');
    addFilterParam([...checkedFilter.productSubcategory, ...checkedFilter.serviceSubcategory], 'subcat');
    addFilterParam(checkedFilter.region, 'region');
    addFilterParam(checkedFilter.comunas, 'comuna');
    if (nameFilter) {
      queryParams.append('name', nameFilter);
    }

    const url = `${process.env.REACT_APP_URL}/services?${queryParams.toString()}`;
    await axios.get(url, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setServices(response.data.services);
        setTotalPages(response.data.pagination.totalPages);
      });
  }

  async function preDeleteService(id: string) {
    swal({
      title: 'Alerta',
      text: '¿Estás seguro que quieres eliminar este servicio?',
      icon: 'warning',
      buttons: ['Cancelar', true],
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteService(id);
        }
      });
  }

  async function deleteService(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.delete(process.env.REACT_APP_URL + `/services/${id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: 'Se eliminó el servicio',
          icon: 'success',
          timer: 2000
        });
        fetchServices(currentPage);
      });
  }

  const handleToggle = (
    listName: 'productCategory' | 'productSubcategory' | 'serviceCategory' | 'serviceSubcategory' | 'region' | 'comunas',
    value: string
  ) => () => {
    const currentIndex = checkedFilter[listName].indexOf(value);
    const newChecked = [...checkedFilter[listName]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilter((prev) => ({ ...prev, [listName]: newChecked }));

    if (listName === 'productCategory' || listName === 'serviceCategory') {
      const type = listName === 'productCategory' ? 'products' : 'services';
      const selectedCategory = productsAndServices[type].find((item) => item.category === value);
      setSubcategories((prev) => {
        const newSubcategories = [...prev[type]];
        if (currentIndex === -1 && selectedCategory) {
          return { ...prev, [type]: [...newSubcategories, ...selectedCategory.subCategories] };
        } else {
          const newSubcategoriesFiltered = newSubcategories.filter(
            (sub) => !selectedCategory?.subCategories.includes(sub)
          );
          return { ...prev, [type]: newSubcategoriesFiltered };
        }
      });
    } else if (listName === 'region') {
      const selectedRegion = RegionesYcomunas.regiones.find(region => region.NombreRegion === value);
      setComunasOptions((prev) => {
        if (currentIndex === -1 && selectedRegion) {
          prev.push(...selectedRegion.comunas);
          return prev.sort();
        } else {
          const newCommunesFiltered = prev.filter(
            (com) => !selectedRegion?.comunas.includes(com)
          );
          return newCommunesFiltered.sort();
        }
      });
    }
  };

  return (
    <>
      <div>
        <Navbar logged={true} />
        <Grid container justifyContent={'center'} style={{ padding: '30px' }}>
          <Grid item xs={3} className='filters-container'>
          <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                container
                justifyContent="center"
                alignItems="baseline"
                xs={9}
                style={{ marginTop: 20 }}
              >
                <Alert icon={<AnnouncementIcon fontSize="inherit" />} severity="info" style={{ marginBottom: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ marginBottom: '10px' }}>
                      ¿Quieres agregar tus productos o servicios?
                    </span>
                    <button className="register-vet-button" onClick={(e) => toggleRegisterService(e)}>Únete</button>
                  </div>
                </Alert>
              </Grid>
            </Grid>
            <h6 className='navigation-info-post-post pt-3 ms-3'>Productos y Servicios / Todos</h6>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Nombre</h2>
              </div>
              <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nameFilter} onChange={e => setNameFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Categorías Productos</h2>
              </div>
              <SelectableFilterList
                items={productsCategories}
                checked={checkedFilter.productCategory}
                handleToggle={(value) => handleToggle('productCategory', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Subcategorías Productos</h2>
              </div>
              <SelectableFilterList
                items={subcategories.products}
                checked={checkedFilter.productSubcategory}
                handleToggle={(value) => handleToggle('productSubcategory', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Categorías Servicios</h2>
              </div>
              <SelectableFilterList
                items={servicesCategories}
                checked={checkedFilter.serviceCategory}
                handleToggle={(value) => handleToggle('serviceCategory', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Subcategorías Servicios</h2>
              </div>
              <SelectableFilterList
                items={subcategories.services}
                checked={checkedFilter.serviceSubcategory}
                handleToggle={(value) => handleToggle('serviceSubcategory', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Región</h2>
              </div>
              <SelectableFilterList
                items={regionsNames} checked={checkedFilter.region}
                handleToggle={(value) => handleToggle('region', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Comuna</h2>
              </div>
              <SelectableFilterList
                items={comunasOptions} checked={checkedFilter.comunas}
                handleToggle={(value) => handleToggle('comunas', value)}
              />
            </div>
          </Grid>
          {
            services.length > 0 ? (
              <Grid
                item
                container
                alignContent={'start'}
                xs={9}
                className='card-group'
              >
                <Grid container item xs={12} justifyContent={'center'} style={{ marginBottom: '15px' }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="secondary"
                    size='large'
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'gray', // Cambiar el color del texto
                        '&.Mui-selected': {
                          backgroundColor: '#287685', // Color de fondo cuando está seleccionado
                          color: 'white' // Color del texto cuando está seleccionado
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: '#287685' // Color de fondo cuando está seleccionado
                        },
                        '&:hover': {
                          backgroundColor: 'lightblue' // Color de fondo al pasar el ratón
                        }
                      }
                    }}
                  />
                </Grid>
                {services.map((service) => (
                  <Grid item>
                    <div key={service.id} className="card card-vet" style={{ padding: '20px' }}>
                      <img src={service.profileUrlImg || serviceImg} className="card-img-top img-card-vet" alt={`${service.name} logo`} />
                      <div className="card-body">
                        {service.verified === 1
                          ? <h5 className="card-title comuna-vet">{service.name} <MdVerified style={{ fill: '#C75100' }}
                          title='Servicio verificado por Mappcota'/></h5>
                          : <h5 className="card-title">{service.name}</h5>}
                        <p className="card-text comuna-vet">{service.addressComuna}, {service.addressRegion}</p>
                      </div>
                      {props.action.user.credenciales.rol === 5 && (
                        <button className="profile-button-red" onClick={() => preDeleteService(service.id)}>
                          Eliminar cuenta
                        </button>
                      )}
                      <Link to={{ pathname: '/servicios/' + service.id }}>
                        <button className="profile-button">Ve su perfil</button>
                      </Link>
                    </div>
                  </Grid>
                )
                )}
              </Grid>
            ) : (
              <Grid item container justifyContent={'center'} alignItems={'baseline'} xs={9}>
                <Alert icon={<WarningAmberOutlinedIcon fontSize="inherit" />} severity="info">No hay servicios registrados</Alert>
              </Grid>
            )
          }
          <Grid container item xs={3} justifyContent={'center'}>
            <button className="map-btn" onClick={() => setOpenMap(!openMap)}>{openMap ? 'Cerrar Mapa' : 'Abrir Mapa'}</button>
          </Grid>
          {openMap && <Grid item xs={10} style={{ height: '100vh' }}>
            <GoogleMaps coords={services.map((service) => ({ lat: service.addressLat || 0, lng: service.addressLng || 0 }))} services={services} />
          </Grid>}
        </Grid>
      </div >
      {registerService === true && (
        <div className="register-form-container">
          <div className="register-form-content">
            <RegisterServiceForm
              closeServiceOpenLogIn={closeServiceOpenLogIn}
              closeServiceOpenRegister={closeServiceOpenRegister}
              toggleServiceRegister={toggleServiceRegister}
              toggleSignUpUser={closeServiceOpenRegister}
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default connect(mapStateToProps)(ServicesShowAll);
