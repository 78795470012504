export const vetsAreas = [
  'Cardiología',
  'Dermatología',
  'Docencia',
  'Etología Clínica',
  'Gastroenterología',
  'General',
  'Inocuidad Alimentaria',
  'Medicina Acuícola',
  'Medicina Aves',
  'Medicina Bovinos',
  'Medicina Cerdos',
  'Medicina Equinos',
  'Medicina Pequeños Rumiantes',
  'Mascotas no convencionales',
  'Neurología',
  'Odontología',
  'Oftalmología',
  'Respiratorio',
  'Salud Pública y Zoonosis'
];
