import React, { SyntheticEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { MdVerified } from 'react-icons/md';
import swal from 'sweetalert';
import { Alert, Grid, Pagination } from '@mui/material';
import { hotelType } from '../../Types/HotelTypes';
import { PerfilTypesToken } from '../../Types/PerfilTypes';
import RegisterHotelForm from '../Hotels/RegisterHotelForm';
import Footer from '../Footer';
import Navbar from '../Navbar';
import RegionesYcomunas from '../RegisterForm/regionesComunas';
import SelectableFilterList from '../Common/selectableFilterList';
import serviceImg from '../../Assets/Imagenes/blank-service-picture.png';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AnnouncementIcon from '@mui/icons-material/LightbulbOutlined';

// import './indexHotels.css';

function mapStateToProps(state: any) {
  return { action: state };
}

function HotelsShowAll(props: PerfilTypesToken) {
  const hotelsInPage: number = 10;
  const regionsNames = Object.values(RegionesYcomunas.regiones).map((region) => region.NombreRegion);
  const [registerHotel, setRegisterHotel] = useState<boolean>(false);
  const [hotels, setHotels] = useState<Array<hotelType>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [comunasOptions, setComunasOptions] = useState<Array<string>>([]);
  const [checkedFilter, setCheckedFilter] = useState<{
    region: string[];
    comunas: string[];
  }>({
    region: [],
    comunas: []
  });
  const [nameFilter, setNameFilter] = useState<string>('');
  const [isHotel, setIsHotel] = useState<boolean>(false);

  function toggleRegisterHotel(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterHotel(!registerHotel);
  }

  function closeHotelOpenRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterHotel(false);
  }

  function closeHotelOpenLogIn(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterHotel(false);
  }

  function toggleHotelRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterHotel(!registerHotel);
  }

  useEffect(() => {
    fetchHotels(currentPage);
  }, [currentPage, checkedFilter, nameFilter]);

  async function fetchHotels(page: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('limit', hotelsInPage.toString());

    const addFilterParam = (filterArray: string[], paramName: string) => {
      const joinedFilter = filterArray.join(',');
      if (joinedFilter) {
        queryParams.append(paramName, joinedFilter);
      }
    };
    addFilterParam(checkedFilter.region, 'region');
    addFilterParam(checkedFilter.comunas, 'comuna');
    if (nameFilter) {
      queryParams.append('name', nameFilter);
    }

    const url = `${process.env.REACT_APP_URL}/hotels?${queryParams.toString()}`;
    await axios.get(url, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setHotels(response.data.hotels);
        setTotalPages(response.data.pagination.totalPages);
      });

    await axios.get(process.env.REACT_APP_URL + `/hotels/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        if (response.data.hotel.IsHotel[0] === undefined) {
          setIsHotel(false);
        } else {
          setIsHotel(true);
        }
      });
  }

  async function preDeleteHotel(id: string) {
    swal({
      title: 'Alerta',
      text: '¿Estás seguro que quieres eliminar este servicio?',
      icon: 'warning',
      buttons: ['Cancelar', true],
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteHotel(id);
        }
      });
  }

  async function deleteHotel(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.delete(process.env.REACT_APP_URL + `/hotels/${id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: 'Se eliminó el servicio',
          icon: 'success',
          timer: 2000
        });
        fetchHotels(currentPage);
      });
  }

  const handleToggle = (
    listName: 'region' | 'comunas',
    value: string
  ) => () => {
    const currentIndex = checkedFilter[listName].indexOf(value);
    const newChecked = [...checkedFilter[listName]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilter((prev) => ({ ...prev, [listName]: newChecked }));
    if (listName === 'region') {
      const selectedRegion = RegionesYcomunas.regiones.find(region => region.NombreRegion === value);
      setComunasOptions((prev) => {
        if (currentIndex === -1 && selectedRegion) {
          prev.push(...selectedRegion.comunas);
          return prev.sort();
        } else {
          const newCommunesFiltered = prev.filter(
            (com) => !selectedRegion?.comunas.includes(com)
          );
          return newCommunesFiltered.sort();
        }
      });
    }
  };

  return (
    <>
      <div>
        <Navbar logged={true} />
        <Grid container justifyContent={'center'} style={{ padding: '30px' }}>
          <Grid item xs={3} className='filters-container'>
          <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                container
                justifyContent="center"
                alignItems="baseline"
                xs={9}
                style={{ marginTop: 20 }}
              >
                {!isHotel && (
                <Alert icon={<AnnouncementIcon fontSize="inherit" />} severity="info" style={{ marginBottom: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ marginBottom: '10px' }}>
                      ¿Quieres agregar tu hotel para mascotas?
                    </span>
                    <button className="register-vet-button" onClick={(e) => toggleRegisterHotel(e)}>Únete</button>
                  </div>
                </Alert>
                )}
              </Grid>
            </Grid>
            <h6 className='navigation-info-post-post pt-3 ms-3'>Hoteles</h6>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Nombre</h2>
              </div>
              <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nameFilter} onChange={e => setNameFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Región</h2>
              </div>
              <SelectableFilterList
                items={regionsNames} checked={checkedFilter.region}
                handleToggle={(value) => handleToggle('region', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Comuna</h2>
              </div>
              <SelectableFilterList
                items={comunasOptions} checked={checkedFilter.comunas}
                handleToggle={(value) => handleToggle('comunas', value)}
              />
            </div>
          </Grid>
          {
            hotels.length > 0 ? (
              <Grid
                item
                container
                alignContent={'start'}
                xs={9}
                className='card-group'
              >
                <Grid container item xs={12} justifyContent={'center'} style={{ marginBottom: '15px' }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="secondary"
                    size='large'
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'gray', // Cambiar el color del texto
                        '&.Mui-selected': {
                          backgroundColor: '#287685', // Color de fondo cuando está seleccionado
                          color: 'white' // Color del texto cuando está seleccionado
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: '#287685' // Color de fondo cuando está seleccionado
                        },
                        '&:hover': {
                          backgroundColor: 'lightblue' // Color de fondo al pasar el ratón
                        }
                      }
                    }}
                  />
                </Grid>
                {hotels.map((service) => (
                  <Grid item>
                    <div key={service.id} className="card card-vet" style={{ padding: '20px' }}>
                      <img src={service.profileUrlImg || serviceImg} className="card-img-top img-card-vet" alt={`${service.hotelName} logo`} />
                      <div className="card-body">
                        {service.verified_status === 'verified'
                          ? <h5 className="card-title comuna-vet">{service.hotelName} <MdVerified style={{ fill: '#C75100' }}
                           title='Hotel verificado, ingrese al perfil del hotel para más detalles' /></h5>
                          : <h5 className="card-title">{service.hotelName}</h5>}
                        <p className="card-text comuna-vet">{service.hotelAddressComuna}, {service.hotelAddressRegion}</p>
                      </div>
                      {props.action.user.credenciales.rol === 5 && (
                        <button className="profile-button-red" onClick={() => preDeleteHotel(service.id)}>
                          Eliminar cuenta
                        </button>
                      )}
                      <Link to={{ pathname: '/hotels/' + service.id }}>
                        <button className="profile-button">Ver su perfil</button>
                      </Link>
                    </div>
                  </Grid>
                )
                )}
              </Grid>
            ) : (
              <Grid item container justifyContent={'center'} alignItems={'baseline'} xs={9}>
                <Alert icon={<WarningAmberOutlinedIcon fontSize="inherit" />} severity="info">No hay hoteles registrados</Alert>
              </Grid>
            )
          }
        </Grid>
      </div >
      {registerHotel === true && (
        <div className="register-form-container">
          <div className="register-form-content">
            <RegisterHotelForm
              closeHotelOpenRegister={closeHotelOpenRegister}
              closeHotelOpenLogIn={closeHotelOpenLogIn}
              toggleHotelRegister={toggleHotelRegister}
              toggleSignUpUser={closeHotelOpenRegister}
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default connect(mapStateToProps)(HotelsShowAll);
