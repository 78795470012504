import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/index';
import PetShowAll from '../../Components/RegisterPet/petShowAll';
import PetEdit from '../../Components/RegisterPet/petEdit';
import PetDelete from '../../Components/RegisterPet/petDelete';
import PetShow from '../../Components/RegisterPet/petShow';
import PetRegister from '../../Components/RegisterPet/';
import Footer from '../../Components/Footer/index';

function Mascotas (props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [userId, setUserId] = useState('');
  function handleCallback (userIdData) {
    setUserId(userIdData);
  }
  if (location.state) {
    if (location.state.show === 'edit') {
      return (
        <div className="App">
          <Navbar {...props} parentCallback = {handleCallback} navigate={navigate} logged={true}/>
          <h1>{userId}</h1>
          <PetEdit {...props} navigate={navigate} location={location} />
          <Footer />
        </div>
      );
    } else if (location.state.show === 'delete') {
      return (
        <div className="App">
          <Navbar {...props} parentCallback = {handleCallback} navigate={navigate} logged={true}/>
          <h1>{userId}</h1>
          <PetDelete {...props} navigate={navigate} location={location} />
          <Footer />
        </div>
      );
    } else if (location.state.show === 'show') {
      return (
        <div className="App">
          <Navbar {...props} parentCallback = {handleCallback} navigate={navigate} logged={true}/>
          <h1>{userId}</h1>
          <PetShow {...props} navigate={navigate} location={location} />
          <Footer />
        </div>
      );
    } else if (location.state.show === 'create') {
      return (
          <div className="App">
            <Navbar {...props} parentCallback = {handleCallback} navigate={navigate} logged={true}/>
            <h1>{userId}</h1>
            <PetRegister {...props} navigate={navigate} location={location} />
            <Footer />
          </div>
      );
    } else {
      return (
      <div className="App">
        <Navbar {...props} parentCallback = {handleCallback} navigate={navigate} logged={true}/>
        <h1>{userId}</h1>
        <PetShowAll {...props} navigate={navigate} location={location} />
        <Footer />
      </div>
      );
    }
  } else {
    return (
    <div className="App">
      <Navbar {...props} parentCallback = {handleCallback} navigate={navigate} logged={true}/>
      <h1>{userId}</h1>
      <PetShowAll {...props} navigate={navigate} location={location} />
      <Footer />
    </div>
    );
  }
}

export default Mascotas;
