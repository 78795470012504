import React, { SyntheticEvent, useState, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import RegionesYcomunas from './regionesComunas';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { prettifyRut, formatRut } from 'react-rut-formatter';
import termsConditions from './termsCondition';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type RegisterFormProps = {
  mode: string,
  closeRegisterOpenSignIn: React.MouseEventHandler,
  toggleSignUpVet: React.MouseEventHandler,
  toggleSignUpUser: React.MouseEventHandler,
  closeRegisterOpenService: React.MouseEventHandler,
  navigate: Function,
  closePopupRegister: Function
}

function redirect(id: string, props: RegisterFormProps) {
  props.navigate('/');
}

type RegisterData = {
  email: string,
  password: string,
  name: string,
  addressComuna: string,
  addressRegion: string,
  address: string,
  phone: string,
  role: number,
  rut: string,
  birthdate: string,
  termsConditionsAccepted: boolean,
  area: string,
  gender: string
}

function RegisterForm(props: RegisterFormProps) {
  const [datos, setDatos] = useState<RegisterData>({
    email: '',
    password: '',
    name: '',
    addressComuna: '',
    addressRegion: '',
    address: '',
    phone: '',
    role: 1,
    rut: '',
    birthdate: '',
    termsConditionsAccepted: false,
    area: '',
    gender: 'MALE'
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [comunas, setComunas] = useState<Array<string>>([]);
  const [passwordNotEqual, setPasswordNotEqual] = useState<boolean>();
  const [badPhoneFormat, setBadPhoneFormat] = useState<boolean>();
  const [agree, setAgree] = useState<boolean>(false);
  const [rut, setRut] = useState<string>('');
  const [showTermsConditions, setShowTermsConditions] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    if (props.mode === 'vet') {
      setDatos({
        ...datos,
        role: 2
      });
    }
  }, []);

  function handleKeyPress(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      if (validateFields()) {
        submitRegisterForm(event);
      } else {
        swal('Error', 'Por favor llena todos los campos y verifica que tus contraseñas coincidan.', 'error');
      }
    }
  }

  const validateFields = () => {
    const newErrors = [];
    if (!datos.email) newErrors.push('Email is required');
    if (!datos.password) newErrors.push('Password is required');
    if (!datos.name) newErrors.push('Name is required');
    if (!datos.addressComuna) newErrors.push('Comuna is required');
    if (!datos.addressRegion) newErrors.push('Region is required');
    if (!datos.address) newErrors.push('Address is required');
    if (!datos.phone) newErrors.push('Phone is required');
    if (!datos.rut) newErrors.push('RUT is required');
    if (!datos.birthdate) newErrors.push('Birthdate is required');
    if (!agree) newErrors.push('Terms and Conditions must be accepted');
    if (datos.password !== passwordConfirmation) newErrors.push('Passwords do not match');
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  function handleComunas(event: SyntheticEvent) {
    event.preventDefault();
    const currentRegion = (event.target as HTMLInputElement).value;
    setDatos({
      ...datos,
      addressRegion: currentRegion
    });
    Object.values(RegionesYcomunas)[0].forEach(region => {
      if (region.NombreRegion === currentRegion) {
        setComunas(region.comunas.sort());
      }
    });
  };

  function handleInputChange(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    if (target.name === 'rut') {
      setRut(prettifyRut(target.value));
      setDatos({
        ...datos,
        [target.name]: formatRut(target.value)
      });
    } else if (target.name === 'phone') {
      setPhone(prettifyPhoneNumber(target.value));
      setDatos({
        ...datos,
        [target.name]: formatPhoneNumber(target.value)
      });
    } else {
      setDatos({
        ...datos,
        [target.name]: target.value
      });
    }
  }

  function prettifyPhoneNumber(phoneNumber:string) {
    const formattedNumber = phoneNumber.replace(/\s/g, '');
    if (formattedNumber.length > 4) {
      const countryCode = formattedNumber.slice(0, 4); // +569
      const firstPart = formattedNumber.slice(4, 8);
      const secondPart = formattedNumber.slice(8);
      return `${countryCode} ${firstPart} ${secondPart}`;
    }
    return phoneNumber;
  }

  function formatPhoneNumber(phoneNumber:string) {
    return phoneNumber.replace(/\s/g, '');
  }

  function submitRegisterForm(event: SyntheticEvent) {
    event.preventDefault();
    setLoading(true);
    console.log(datos);
    datos.termsConditionsAccepted = agree;
    const phoneRegex = /^(\+56)\d{9}$/;
    if (datos.password === passwordConfirmation && phoneRegex.test(datos.phone)) {
      if (datos.role === 2) {
        const token = JSON.parse(localStorage.getItem('token') || '');
        axios.post(process.env.REACT_APP_URL + '/create/vet', datos, { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            swal({
              title: 'Registro exitoso',
              text: 'Ingresa al mail ' + String(datos.email) + ' y valida tu cuenta para poder iniciar sesión',
              icon: 'success',
              buttons: { Aceptar: true },
              timer: 10000
            });
            props.closePopupRegister();
            redirect(response.data.newUser.id, props);
          })
          .catch((error) => {
            setAgree(false);
            swal({
              title: 'Error',
              text: String(error.response.data.message),
              icon: 'error',
              buttons: { Aceptar: true },
              timer: 2000
            });
          }
          );
      } else {
        axios.post(process.env.REACT_APP_URL + '/create/user', datos)
          .then((response) => {
            swal({
              title: 'Registro exitoso',
              text: 'Ingresa al mail ' + String(datos.email) + ' y valida tu cuenta para poder iniciar sesión',
              icon: 'success',
              buttons: { Aceptar: true },
              timer: 10000
            });
            props.closePopupRegister();
            redirect(response.data.newUser.id, props);
          })
          .catch((error) => {
            setAgree(false);
            swal({
              title: 'Error',
              text: String(error.response.data.message),
              icon: 'error',
              buttons: { Aceptar: true },
              timer: 2000
            });
          }
          )
          .finally(() => {
            setLoading(false); // Desactivar la pantalla de carga
          });
      }
    }
    if (datos.password !== passwordConfirmation) {
      setPasswordNotEqual(true);
    } else {
      setPasswordNotEqual(false);
    }
    if (!phoneRegex.test(datos.phone)) {
      setBadPhoneFormat(true);
    } else {
      setBadPhoneFormat(false);
    }
  }
  console.log(errors);
  if (loading) {
    return (<div className="loading-label"><CircularProgress /></div>); // Mostrar el indicador de carga mientras loading es true
  }

  return (
    <form className="registerForm" onSubmit={submitRegisterForm} onKeyPress={handleKeyPress}>
      <ul className="nav nav-pills justify-content-center nav-fill">
        <button className='closeForm' onClick={props.toggleSignUpUser}><CloseIcon /></button>
      </ul>
      <>
        <div className='register-title-div'>
          <h1 className='register-title'>¡Regístrate!</h1>
        </div>
        <div className='register-subtitle-div'>
          <p>Un RUT solo puede estar conectado a una cuenta</p>
        </div>
        <div className='fieldsets'>
            <fieldset className='fieldset-div'>
              <p>
                <input id="name" placeholder=" " type="text" value={datos.name} name="name" onChange={(e) => handleInputChange(e)} className="register-form-input" required />
                <label htmlFor="name">Nombre Completo</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              {badPhoneFormat === true && <p className='formError'>Formato de teléfono inválido.</p>}
              <p>
                <input id="phone" placeholder=" " type="text" name="phone" className="register-form-input" value={phone} onChange={(e) => handleInputChange(e)} required />
                <label htmlFor="phone">Teléfono (+56 y 9 dígitos)</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <p>
                <select placeholder=" " id="addressRegion" name="addressRegion" className="register-selector form-select" value={datos.addressRegion} onChange={e => handleComunas(e)} required>
                  <option value="" defaultValue="" disabled hidden></option>
                  {Object.values(RegionesYcomunas)[0].map((region) => {
                    return <option key={region.NombreRegion}>{region.NombreRegion}</option>;
                  })}
                </select>
                <label htmlFor="addressRegion">Región</label>
              </p>
            </fieldset>

            <fieldset className='fieldset-div'>
              <p>
                <select
                  id="addressComuna"
                  name="addressComuna"
                  className="register-selector form-select"
                  value={datos.addressComuna}
                  onChange={(e) => handleInputChange(e)}
                  required
                >
                  <option value="" defaultValue="" disabled hidden></option>
                  {comunas.map((comuna) => (<option key={comuna}>{comuna}</option>))}
                </select>
                <label htmlFor="addressComuna">Comuna</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <p>
                <input id="address" placeholder=" " type="address" name="address" value={datos.address} onChange={(e) => handleInputChange(e)} className="register-form-input" required />
                <label htmlFor="address">Dirección</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <p>
                <input id="email" placeholder=" " type="email" name="email" value={datos.email} onChange={(e) => handleInputChange(e)} className="register-form-input" required />
                <label htmlFor="email">Correo Electrónico</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
                <p>
                    <input placeholder=" " value={rut} id="rut" name="rut" onChange={(e) => handleInputChange(e)} className="register-form-input" required/>
                    <label htmlFor='rut'>RUT (con punto y guión)</label>
                </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <p>
                <input id="password" placeholder=" " type="password" value={datos.password} name='password' onChange={(e) => handleInputChange(e)} className="register-form-input" required />
                <label htmlFor='password'>Contraseña</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <p>
                <input placeholder=" " type="password" id="contraseñaConfirmation" name='passwordConfirmation' value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)} className="register-form-input" required />
                <label htmlFor='contraseñaConfirmation'>Confirmar contraseña</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <p>
                <input placeholder=" " type="date" id="birthdate" name='birthdate' value={datos.birthdate} onChange={(e) => handleInputChange(e)} className="register-form-input" required />
                <label htmlFor='contraseñaConfirmation'>Fecha de Nacimiento</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <p>
                <select
                  id="gender"
                  name="gender"
                  className="register-selector form-select"
                  value={datos.gender}
                  onChange={(e) => handleInputChange(e)}
                  required
                >
                  <option value="MALE">Masculino</option>
                  <option value="FEMALE">Femenino</option>
                  <option value="OTHER">Otro</option>
                </select>
                <label htmlFor="addressComuna">Género</label>
              </p>
            </fieldset>
            <fieldset className='fieldset-div'>
              <div className='row'>
                <div className='col-sm-1'>
                  <input className='check-terms' type="checkbox" id="agree" name='termsConditionsAccepted' onChange={() => setAgree(!agree)} required/>
                </div>
                <div className='col-sm-9'>
                  <div className='link-terms'>Acepto los términos, condiciones y las políticas de privacidad</div>
                </div>
                <a onClick={() => setShowTermsConditions(true)} className='link-terms2'> Mostrar términos y condiciones </a>
                <div>
                  {showTermsConditions &&
                    <Dialog open={showTermsConditions} scroll='paper' maxWidth='lg'>
                      <DialogActions>
                        <button className='closeForm' onClick={() => setShowTermsConditions(false)}><CloseIcon /></button>
                      </DialogActions>
                      <DialogTitle id="scroll-dialog-title">
                      <h4> <strong>TÉRMINOS Y CONDICIONES Y AVISO DE PRIVACIDAD</strong></h4>
                      </DialogTitle>
                          <DialogContent>
                            <DialogContentText
                              id="scroll-dialog-description"
                              tabIndex={-1}
                            >
                              {termsConditions()}
                            </DialogContentText>
                          </DialogContent>
                    </Dialog>
                  }
                </div>
              </div>
              {errors.length > 0 && (
              <div className="error-messages">
                {errors.map((error, index) => (
                  <p key={index} className="error">{error}</p>
                ))}
              </div>
              )}
            </fieldset>
            {passwordNotEqual === true && <p className='password-error'>Las contraseñas no coinciden</p>}
          </div>
        <div className='register-buttons'>
          <button id='create-account' className='register-form-button' type="submit">Crear Cuenta</button>
          <button type='button' className='register-form-button' onClick={props.closeRegisterOpenSignIn}>¿Tienes Cuenta? ¡Ingresa!</button>
        </div>
      </>
    </form>
  );
}

RegisterForm.propTypes = {
  navigate: PropTypes.func,
  closeRegisterForm: PropTypes.func,
  closeRegisterOpenSignIn: PropTypes.func,
  closeRegisterOpenService: PropTypes.func
};

export default RegisterForm;
