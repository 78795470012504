import React, { useState, useEffect, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './index.css';
import perrito1 from '../../Assets/Imagenes/huella.png';
import { PostType } from '../../Types/PostTypes';
import { Alert, Grid } from '@mui/material';
import Donate from './Donate';
import PaidIcon from '@mui/icons-material/Paid';

function mapStateToProps (state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function WelcomePage (props: any) {
  const [toggleDonate, setToggleDonate] = useState<boolean>(false);
  const [userData, setUserData] = useState({
    id: '',
    address: '',
    addressRegion: '',
    addressComuna: '',
    name: ''
  });
  const [posts, setPosts] = useState<Array<PostType>>([]);

  async function fetchUserData () {
    if (props.action.user.credenciales.rol === 2) {
      const token = JSON.parse(localStorage.getItem('token') || '');
      await axios.get(process.env.REACT_APP_URL + '/vets/' + props.action.user.credenciales.id, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
          setUserData({
            id: res.data.vet.id,
            address: res.data.vet.address,
            addressRegion: res.data.vet.addressRegion,
            addressComuna: res.data.vet.addressComuna,
            name: res.data.vet.name
          });
        });
    } else {
      const token = JSON.parse(localStorage.getItem('token') || '');
      await axios.get(process.env.REACT_APP_URL + '/users/' + props.action.user.credenciales.id, { headers: { Authorization: 'Bearer ' + token } })
        .then(res => {
          setUserData({
            id: res.data.user.id,
            address: res.data.user.address,
            addressRegion: res.data.user.addressRegion,
            addressComuna: res.data.user.addressComuna,
            name: res.data.user.name
          });
        });
    }
  }

  async function fetchPosts () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/posts/detailed', { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setPosts(response.data.posts);
      });
  }

  useEffect(() => {
    fetchUserData();
    fetchPosts();
  }, []);

  function mapPostsToGroups () {
    const arrayLength = 3;
    // console.log(posts);
    const finalArray = [];
    for (let i = 0; i < posts.length; i += arrayLength) {
      finalArray.push(posts.slice(i, i + arrayLength));
    }
    // console.log(finalArray);
    return finalArray;
  }

  function togglePopUpDonate(event: SyntheticEvent) {
    event.preventDefault();
    setToggleDonate(!toggleDonate);
  }

  return (
    <>
    <div className='wrapper'>
    <Navbar logged={true} />
    <div className='welcomePage-container'>
        <h1 className='welcomePage-title'>¡Bienvenido a Mappcota, {userData.name}!</h1>
        <div className='row'><Link className='contenedor-mascota-button' to='/pets/showAll'>
                    <button className='mascota-button'>Registra a tu mascota aquí</button>
                  </Link></div>
                  <div className='spacer32'></div>
            <Grid item container justifyContent={'center'} alignItems={'baseline'} xs={9}>
              <Alert icon={<PaidIcon fontSize="inherit" />} severity="info" color="warning">
              <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ marginBottom: '10px' }}>
                      ¿Quieres realizar una donación a la Fundación Mascotalerta?
                    </span>
                    <button className="donate-button-in" onClick={e => togglePopUpDonate(e)}>Dona aquí</button>
                  </div>
              </Alert>
            </Grid>
        {mapPostsToGroups().map((group, index) => (
            <div key={index} className='welcomePage-subContainers-container' style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '5%' }}>
                {group.map((post, index) => {
                  return (
                        <ul className='welcomePage-subContainer' key={index}>
                            <Link to={{ pathname: '/blogs/' + post.id }} className='welcomePage-subContainer-title'>{post.title}</Link>
                            <hr className="solid"></hr>
                            <Link to={{ pathname: '/blogs/' + post.id }} className='welcomePage-subContainer-item' key={post.title}><img src={post.urlImg !== '' ? post.urlImg : perrito1} className='post-landing'/></Link>
                        </ul>
                  );
                })}
            </div>
        ))}
    </div>
    </div>
    {toggleDonate === true &&
        <div className='register-form-container'>
          <div className='register-form-content'>
            <Donate closePopUpVerification={togglePopUpDonate} />
          </div>
        </div>
      }
    <Footer />
    </>
  );
};

WelcomePage.propTypes = {
  navigate: PropTypes.func,
  action: PropTypes.shape({
    user: PropTypes.shape({
      /* eslint-disable no-mixed-spaces-and-tabs */
      credenciales: PropTypes.shape({
    	  id: PropTypes.string,
        token: PropTypes.string,
        rol: PropTypes.number
      })
      /* eslint-enable no-mixed-spaces-and-tabs */
    })
  })
};

export default connect(mapStateToProps)(WelcomePage);
