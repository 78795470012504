import React, { useState, useEffect, SyntheticEvent } from 'react';
// import lapiz from '../../Assets/Imagenes/lapiz.svg';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { MdVerified } from 'react-icons/md';
import './Perfil.css';
import moment from 'moment';
import DeletePerfilForm from './DeletePerfil';
import Navbar from '../Navbar/index';
import Footer from '../Footer';
// import perfil from '../../Assets/Imagenes/perfil.png';
import userImg from '../../Assets/Imagenes/blank-profile-picture-.png';
import { PerfilTypes } from '../../Types/PerfilTypes';
import { userDataType } from '../../Types/UserTypes';
import { prettifyRut } from 'react-rut-formatter';
import VerificationPetSitter from './VerificationPetSitter';
import { serviceType } from '../../Types/RequestServType';
import Services from './services';
import Solicitudes from './solicitudes';
import VerificationHotelForm from './VerificationHotelForm';

function mapStateToProps (state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function PerfilShow (props: PerfilTypes) {
  const [datos, setDatos] = useState<userDataType>({
    id: '',
    rut: '',
    address: '',
    addressComuna: '',
    addressRegion: '',
    name: '',
    phone: '',
    birthdate: '',
    email: '',
    gender: '',
    profileUrlImg: '',
    ispetsitter: '',
    siiGiro: '',
    ishotel: '',
    platformCat: '',
    link: '',
    description: '',
    area: '',
    verified_status: '',
    verified: 0,
    nRequests: 0,
    deniedReason: '',
    ServiceVerificationRequest: []
  });
  const [respuesta, setRespuesta] = useState<boolean>(false);
  const [openDeleteForm, setOpenDeleteForm] = useState<boolean>(false);
  const [petSitterVerification, setPetSitterVerification] = useState(false);
  const [openServices, setOpenServices] = useState<boolean>(false);
  const [openSolicitudes, setOpenSolicitudes] = useState<boolean>(false);
  const [services, setServices] = useState<Array<serviceType>>([]);
  const [verification, setVerification] = useState<boolean>(false);
  const navigate = useNavigate();

  const [hotelVerification, setHotelVerification] = useState(false);
  // const [redirectBool, setRedirectBool] = useState<boolean>(false);
  const [hotelName, setHotelName] = useState('');
  const [hotelImgUrl, setHotelImgUrl] = useState('');
  const [petSitterName, setPetSitterName] = useState('');
  const [serviceImgUrl, setServiceImgUrl] = useState('');
  const [hotelVerified, setHotelVerified] = useState(false);
  const [petsitterVerified, setPetsitterVerified] = useState(false);
  const [petsitterVerifiedButton, setPetsitterVerifiedButton] = useState(false);
  const [hotelVerifiedButton, setHotelVerifiedButton] = useState(false);

  async function fetchUserData (userType:string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + `/${userType}s/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setDatos(
          response.data[userType]
        );
        if (response.data[userType].gender === 'MALE') {
          setDatos((prevState) => ({
            ...prevState,
            gender: 'Masculino'
          }));
        } else if (response.data[userType].gender === 'FEMALE') {
          setDatos((prevState) => ({
            ...prevState,
            gender: 'Femenino'
          }));
        } else {
          setDatos((prevState) => ({
            ...prevState,
            gender: 'Otro'
          }));
        }
        setRespuesta(true);
        const numarray = response.data[userType].phone.split('');
        const num = '(' + numarray.slice(0, 4).join('') + ')' + ' ' + numarray.slice(4, 8).join('') + ' ' + numarray.slice(8, 12).join('');
        setDatos((prevState) => ({
          ...prevState,
          phone: num
        }));

        if (props.action.user.credenciales.rol === 2) {
          if (response.data.vet.verified_status === 'waiting verification') {
            setVerification(true);
          }
        }
      });

    await axios.get(process.env.REACT_APP_URL + '/services', { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        const allServices = response.data.services;
        const myServices = [];
        for (const i in allServices) {
          if (allServices[i].userId === props.action.user.credenciales.id) {
            myServices.push(allServices[i]);
          }
        }
        setServices(myServices);
      });

    await axios.get(process.env.REACT_APP_URL + `/petsitters/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        if (response.data.petsitter.IsPetsitter.length !== 0) {
          setPetSitterName(response.data.petsitter.IsPetsitter[0].name);
          setServiceImgUrl(response.data.petsitter.IsPetsitter[0].profileUrlImg);
          if (response.data.verified_status === 'verified' || response.data.verified_status === 'waiting verification') {
            setPetsitterVerifiedButton(true);
          } else {
            setPetsitterVerifiedButton(false);
          }
          if (response.data.verified_status === 'verified') {
            setPetsitterVerified(true);
          } else {
            setPetsitterVerified(false);
          }
        }
      });
    await axios.get(process.env.REACT_APP_URL + `/hotels/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        console.log(response.data);
        setHotelName(response.data.hotel.IsHotel[0].name);
        setHotelImgUrl(response.data.hotel.IsHotel[0].profileUrlImg);
        if (response.data.verified_status === 'verified' || response.data.verified_status === 'waiting verification') {
          setHotelVerifiedButton(true);
        } else {
          setHotelVerifiedButton(false);
        }
        if (response.data.verified_status === 'verified') {
          setHotelVerified(true);
        } else {
          setHotelVerified(false);
        }
      });
  }

  useEffect(() => {
    if (props.action.user.credenciales.rol === 1 || props.action.user.credenciales.rol === 4) {
      fetchUserData('user');
    } else {
      fetchUserData('vet');
    }
  }, [respuesta]);

  function deletePerfilForm (event: SyntheticEvent) {
    event.preventDefault();
    setOpenDeleteForm(!openDeleteForm);
  };

  function deletePerfil (event: SyntheticEvent) {
    event.preventDefault();
    if (props.action.user.credenciales.isService) {
      const token = JSON.parse(localStorage.getItem('token') || '');
      axios.delete(process.env.REACT_APP_URL + '/services/' + datos.id, { headers: { Authorization: 'Bearer ' + token } })
        .then(() => {
          setOpenDeleteForm(!openDeleteForm);
        });
    } else {
      if (props.action.user.credenciales.rol === 1) {
        const token = JSON.parse(localStorage.getItem('token') || '');
        axios.delete(process.env.REACT_APP_URL + '/users/' + datos.id, { headers: { Authorization: 'Bearer ' + token } })
          .then(() => {
            setOpenDeleteForm(!openDeleteForm);
          });
      } else {
        const token = JSON.parse(localStorage.getItem('token') || '');
        axios.delete(process.env.REACT_APP_URL + '/vets/' + datos.id, { headers: { Authorization: 'Bearer ' + token } })
          .then(() => {
            setOpenDeleteForm(!openDeleteForm);
          });
      }
    }
    navigate('/');
  }

  function toggleServices (event: SyntheticEvent) {
    event.preventDefault();
    setOpenServices(!openServices);
  };

  function toggleSolicitudes (event: SyntheticEvent) {
    event.preventDefault();
    setOpenSolicitudes(!openSolicitudes);
  };

  function togglePetSitterVerification(event: SyntheticEvent) {
    event.preventDefault();
    setPetSitterVerification(!petSitterVerification);
  }

  function togglePetSitterVerificationForm(event: SyntheticEvent) {
    event.preventDefault();
    setPetSitterVerification(!petSitterVerification);
  }

  function toggleHotelVerification(event: SyntheticEvent) {
    event.preventDefault();
    setHotelVerification(!hotelVerification);
  }

  function toggleHotelVerificationForm(event: SyntheticEvent) {
    event.preventDefault();
    setHotelVerification(!hotelVerification);
  }
  function closePopUpVerification(event: SyntheticEvent) {
    event.preventDefault();
    setPetSitterVerification(!petSitterVerification);
  }

  function closePopUpHotelVerification(event: SyntheticEvent) {
    event.preventDefault();
    setHotelVerification(!hotelVerification);
  }

  return (
    <>
      <div className='wrapper'>
      <Navbar logged={true}/>
      {!respuesta
        ? <div>Cargando</div>
        : <div>
          <div className='spacer32'></div>
          <h6 className='navigation-info-post-post ms-3'>Perfil / {datos.name}</h6>
          <div className='spacer32'></div>

          <div className='container-perfil-vet'>
            <div className='main-body'>
              <div className='row gutters-sm'>
                <div className='col-md-4 mb-3'>
                  <div className='card-perfil-vet h-100'>
                    <div className='card-body-perfil-vet'>
                      <div className='d-flex flex-column align-items-center text-center'>
                        <img src={datos.profileUrlImg !== null ? datos.profileUrlImg : userImg} alt="new" className='rounded-circle' width='118' height='118'/>
                        <div className='mt-2' style={{ width: '20vw' }}>

                          <div className='col-auto texto-nombre-perfil-vet'> {datos.name} </div>
                          {/* <p className='text-secondary mb-1 text-especialidad'>{datos.area ? datos.area : undefined}</p> */}
                          {(props.action.user.credenciales.rol === 2 && verification) && <p className='text-secondary mb-1 text-especialidad'>Verificación de Médico/a Veterinario/a en proceso</p>}
                          <div className='profile-buttons-container'>
                            <Link to={'/perfil/edit/' + props.action.user.credenciales.id} className='edit-container'> <button className='service-button'>  Editar perfil </button> </Link>
                            <button className='service-button-red' onClick={deletePerfilForm}>Eliminar perfil</button>

                            {openDeleteForm === true &&
                              <div className='delete-perfil-form-container'>
                                <DeletePerfilForm deletePerfilForm={deletePerfilForm} deletePerfil={deletePerfil}/>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-8 mb-3'>
                  <div className='card-perfil-vet h-100'>
                    <div className='card-body-perfil-vet'>
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Mail</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {datos.email}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Teléfono</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {datos.phone}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Dirección</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {datos.address}, {datos.addressComuna}, {datos.addressRegion}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                          <div className='col-sm-3'>
                            <h6 className='mb-0'>RUT</h6>
                          </div>
                          <div className='col-sm-9 text-secondary'>
                            {prettifyRut(datos.rut)}
                          </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Fecha de nacimiento</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {moment.utc(datos.birthdate).format('DD-MM-YYYY')}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-sm-3'>
                          <h6 className='mb-0'>Género</h6>
                        </div>
                        <div className='col-sm-9 text-secondary'>
                          {datos.gender}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='my-services'>
                <div className='mb-3 minw-50'>
                  <div className='card-perfil-vet h-100'>
                    <div className='card-body-perfil-vet'>
                      <h6 className="d-flex align-items-center mb-3">Mis servicios</h6>
                      <div className='my-services-container'>
                        {(services.length === 0 && datos.ispetsitter === 'NO' && datos.ishotel === 'NO')
                          ? <div className='noservice'>No tienes servicios publicados. Para ofrecer uno, debes inscribirlo en Servicios.</div>
                          : <>{services.map((service) =>
                              <div className='d-flex mb-3'>
                                <div className='service-card'>
                                  <div className='card-body-perfil-vet'>
                                    <div className='d-flex flex-column align-items-center text-center'>
                                      <img src={service.profileUrlImg || userImg} alt="new" className='rounded-circle' width='118' height='118'/>
                                      <div className='mt-2'>
                                        <div className='col-auto texto-nombre-perfil-vet'> {service.name} </div>
                                        <p className='text-secondary mb-1 text-especialidad'> {service.platformCat} </p>
                                        <div className='service-row-container'>
                                          <div className='service-buttons-container'>
                                            <Link to={'/servicios/edit/' + service.id} className='edit-container'> <button className='service-button'> Editar </button> </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          )}
                          {datos.ispetsitter !== 'NO' &&
                          <div className='d-flex mb-3'>
                            <div className='service-card'>
                              <div className='d-flex flex-column align-items-center text-center'>
                                <img src={serviceImgUrl || userImg} alt="new" className='rounded-circle' width='118' height='118' />
                                <div className='mt-2'> {petsitterVerified
                                  ? <div className='col-auto texto-nombre-perfil-vet'> {petSitterName} <MdVerified
                                      title = '¡Felicidades, tu servicio ha sido verificado!'
                                      style={{ fill: '#C75100' }} />
                                    </div>
                                  : ((!petsitterVerified && petsitterVerifiedButton)
                                      ? <div className='col-auto texto-nombre-perfil-vet'> {petSitterName} <MdVerified
                                          title='Tu servicio está en proceso de verificación'
                                          style={{ fill: '#bbbbbb' }} />
                                        </div>
                                      : <div className='col-auto texto-nombre-perfil-vet'> {petSitterName} <MdVerified
                                          title='¡Recuerda solicitar tu verificación!'
                                          style={{ fill: '#bbbbbb' }} />
                                        </div>)
                                    }
                                  <p className='text-secondary mb-1 text-especialidad'> PETSITTER </p>
                                  <div className='service-row-container'>
                                    <div className='service-buttons-container'>
                                    <Link to={'/petsitters/edit/' + props.action.user.credenciales.id} className='edit-container'><button className='service-button'> Editar </button></Link>
                                    <button className='service-button' onClick={(e) => togglePetSitterVerification(e)} disabled = {petsitterVerifiedButton}> Solicitar verificación </button>
                                    </div>
                                    <div className='service-buttons-container'>
                                      <button className='service-button' onClick={toggleServices}> Mis próximos servicios </button>
                                      {openServices === true &&
                                        <div className='services-and-applications-container'>
                                          <Services id={props.action.user.credenciales.id} close={toggleServices}/>
                                        </div>
                                      }
                                      <button className='service-button' onClick={toggleSolicitudes}> Solicitudes pendientes </button>
                                      {openSolicitudes === true &&
                                        <div className='services-and-applications-container'>
                                          <Solicitudes id={props.action.user.credenciales.id} close={toggleSolicitudes}/>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>}
                          {datos.ishotel !== 'NO' &&
                          <div className='d-flex mb-3'>
                            <div className='service-card'>
                              <div className='card-body-perfil-vet'>
                                <div className='d-flex flex-column align-items-center text-center'>
                                  <img src={hotelImgUrl || userImg} alt="new" className='rounded-circle' width='118' height='118'/>
                                  <div className='mt-2'>
                                  {hotelVerified
                                    ? <div className='col-auto texto-nombre-perfil-vet'> {hotelName} <MdVerified
                                        title = '¡Felicidades, tu hotel ha sido verificado!'
                                        style={{ fill: '#C75100' }} />
                                        </div>
                                    : ((!hotelVerified && hotelVerifiedButton)
                                        ? <div className='col-auto texto-nombre-perfil-vet'> {hotelName} <MdVerified
                                            title='Tu hotel está en proceso de verificación'
                                            style={{ fill: '#bbbbbb' }} />
                                          </div>
                                        : <div className='col-auto texto-nombre-perfil-vet'> {hotelName} <MdVerified
                                            title='¡Recuerda solicitar tu verificación!'
                                            style={{ fill: '#bbbbbb' }} />
                                          </div>)
                                    }
                                    <p className='text-secondary mb-1 text-especialidad'> HOTEL PARA MASCOTAS </p>
                                    <div className='service-row-container'>
                                      <div className='service-buttons-container'>
                                        <Link to={'/hotels/edit/' + props.action.user.credenciales.id} className='edit-container'> <button className='service-button'> Editar </button> </Link>
                                        <button className='service-button' onClick={(e) => toggleHotelVerification(e)} disabled={hotelVerifiedButton}> Solicitar verificación </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>}
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          </div>

      }
      </div>
      {petSitterVerification === true &&
        <div className='register-form-container'>
          <div className='register-form-content'>
          <VerificationPetSitter
              closePopUpVerification={closePopUpVerification}
              togglePetSitterVerification={togglePetSitterVerification}
              togglePetSitterVerificationForm={togglePetSitterVerificationForm}
            />
          </div>
        </div>
      }
      {hotelVerification === true &&
        <div className='register-form-container'>
          <div className='register-form-content'>
          <VerificationHotelForm
              closePopUpVerification={closePopUpHotelVerification}
              toggleHotelVerification={toggleHotelVerification}
              toggleHotelVerificationForm={toggleHotelVerificationForm}
            />
          </div>
        </div>
      }
      <Footer />
    </>
  );
}

export default connect(mapStateToProps)(PerfilShow);
