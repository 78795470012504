import React, { SyntheticEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userDataTypeVet } from '../../../Types/UserTypes';
import './IndexVeterinarians.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Footer from '../../Footer';
import Navbar from '../../Navbar';
import RegisterVetForm from '../../RegisterFormVet';
import { Alert, Grid, TextField, Pagination } from '@mui/material';
import SelectableFilterList from '../../Common/selectableFilterList';
import RegionesYcomunas from '../../RegisterForm/regionesComunas';
import vetMaleImg from '../../../Assets/Imagenes/med_vet_m.png';
import vetFemaleImg from '../../../Assets/Imagenes/med_vet_f.png';
import { MdVerified } from 'react-icons/md';
import { PerfilTypesToken } from '../../../Types/PerfilTypes';
import swal from 'sweetalert';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AnnouncementIcon from '@mui/icons-material/LightbulbOutlined';

function mapStateToProps(state: any) {
  return { action: state };
}

function IndexVeterinarians(props: PerfilTypesToken) {
  const vetsInPage: number = 10;
  const regionsNames = Object.values(RegionesYcomunas.regiones).map((region) => region.NombreRegion);

  const [vets, setVets] = useState<Array<userDataTypeVet>>([]);
  const [meVet, setMeVet] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [comunasOptions, setComunasOptions] = useState<Array<string>>([]);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [areaFilter, setAreaFilter] = useState<string>('');
  const [checkedFilter, setCheckedFilter] = useState<{
    region: string[];
    comunas: string[];
  }>({
    region: [],
    comunas: []
  });

  const handleToggle = (
    listName: 'region' | 'comunas',
    value: string
  ) => () => {
    const currentIndex = checkedFilter[listName].indexOf(value);
    const newChecked = [...checkedFilter[listName]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilter((prev) => ({ ...prev, [listName]: newChecked }));

    if (listName === 'region') {
      const selectedRegion = RegionesYcomunas.regiones.find(region => region.NombreRegion === value);
      setComunasOptions((prev) => {
        if (currentIndex === -1 && selectedRegion) {
          prev.push(...selectedRegion.comunas);
          return prev.sort();
        } else {
          const newCommunesFiltered = prev.filter(
            (com) => !selectedRegion?.comunas.includes(com)
          );
          return newCommunesFiltered.sort();
        }
      });
    }
  };

  const [registerService, setRegisterService] = useState<boolean>(false);

  function toggleRegisterService(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(!registerService);
  }

  function closeServiceOpenRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(false);
  }

  function closeServiceOpenLogIn(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(false);
  }

  function toggleServiceRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(!registerService);
  }

  async function fetchVets(page: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('limit', vetsInPage.toString());

    const addFilterParam = (filterArray: string[], paramName: string) => {
      const joinedFilter = filterArray.join(',');
      if (joinedFilter) {
        queryParams.append(paramName, joinedFilter);
      }
    };

    addFilterParam(checkedFilter.region, 'region');
    addFilterParam(checkedFilter.comunas, 'comuna');

    if (nameFilter) {
      queryParams.append('name', nameFilter);
    }
    if (areaFilter) {
      queryParams.append('area', areaFilter);
    }

    const url = `${process.env.REACT_APP_URL}/vets?${queryParams.toString()}`;
    await axios.get(url, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setVets([]);
        console.log(response);
        for (let i = 0; i < response.data.vets.length; i++) {
          if (response.data.vets[i].id === props.action.user.credenciales.id) {
            if (response.data.vets[i].verified_status === 'verified') {
              setMeVet(1);
            } else if (response.data.vets[i].verified_status === 'waiting verification') {
              setMeVet(0);
            } else if (response.data.vets[i].verified_status === 'verification denied') {
              setMeVet(2);
            }
          }
          if (response.data.vets[i].verified_status === 'verified') {
            setVets((prev) => [...prev, response.data.vets[i]]);
          }
        }
        setTotalPages(response.data.totalPages);
      });
  }
  useEffect(() => {
    fetchVets(currentPage);
  }, [currentPage, checkedFilter, nameFilter, areaFilter]);

  async function preDeleteVet(id: string) {
    swal({
      title: 'Alerta',
      text: '¿Estás seguro que quieres eliminar a este Médico/a Veterinario/a?',
      icon: 'warning',
      buttons: ['Cancelar', true],
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteVet(id);
        }
      });
  }

  async function deleteVet(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.delete(process.env.REACT_APP_URL + `/vets/${id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: 'Se eliminó al Médico/a Veterinario/a',
          icon: 'success',
          timer: 2000
        });
        fetchVets(currentPage);
      });
  };

  return (
    <>
      <div>
        <Navbar logged={true} />
        <Grid container justifyContent={'center'} style={{ padding: '30px' }}>
          <Grid item xs={3} className='filters-container'>
          <Grid container justifyContent="center" alignItems="center">
              <Grid
                item
                container
                justifyContent="center"
                alignItems="baseline"
                xs={9}
                style={{ marginTop: 20 }}
              >
                {(props.action.user.credenciales.rol === 1 || (props.action.user.credenciales.rol === 2 && meVet !== 1 && meVet !== 0)) &&
                <Alert icon={<AnnouncementIcon fontSize="inherit" />} severity="info" style={{ marginBottom: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ marginBottom: '10px' }}>
                      ¿Quieres unirte como médico/a veterinario/a?
                    </span>
                    <button className="register-vet-button" onClick={(e) => toggleRegisterService(e)}>Regístrate</button>
                  </div>
                </Alert>
                }

                {(props.action.user.credenciales.rol === 2 && meVet === 0) &&
                <Alert icon={<AnnouncementIcon fontSize="inherit" />} severity="info" style={{ marginBottom: '20px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <span style={{ marginBottom: '10px' }}>
                      Tu solicitud de registro como Médico/a Veterinario/a ya fue enviada. Debes esperar a que sea aceptada.
                    </span>
                  </div>
                </Alert>
                }
              </Grid>
            </Grid>
            <h6 className='navigation-info-post-post pt-3 ms-3'>Médicos/as Veterinarios/as / Todos</h6>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Nombre</h2>
              </div>
              <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nameFilter} onChange={e => setNameFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Especialidad</h2>
              </div>
              <TextField id="outlined-basic" label="Especialidad" variant="outlined" value={areaFilter} onChange={e => setAreaFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Región</h2>
              </div>
              <SelectableFilterList
                items={regionsNames} checked={checkedFilter.region}
                handleToggle={(value) => handleToggle('region', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Comuna</h2>
              </div>
              <SelectableFilterList
                items={comunasOptions} checked={checkedFilter.comunas}
                handleToggle={(value) => handleToggle('comunas', value)}
              />
            </div>
          </Grid>
          {
            vets.length > 0 ? (
              <Grid
                item
                container
                justifyContent={'space-evenly'}
                alignContent={'start'}
                xs={9}
                className="card-group"
              >
                <Grid container item xs={12} justifyContent={'center'} style={{ marginBottom: '15px' }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="secondary"
                    size='large'
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'gray', // Cambiar el color del texto
                        '&.Mui-selected': {
                          backgroundColor: '#287685', // Color de fondo cuando está seleccionado
                          color: 'white' // Color del texto cuando está seleccionado
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: '#287685' // Color de fondo cuando está seleccionado
                        },
                        '&:hover': {
                          backgroundColor: 'lightblue' // Color de fondo al pasar el ratón
                        }
                      }
                    }}
                  />
                </Grid>
                {vets.map((vet) => (
                  <Grid item key={vet.id}>
                    <div className="card card-vet" style={{ padding: '20px' }}>
                      <div style= {{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '330px' }}>
                      {vet.gender === 'FEMALE' ? <img src={vet.profileUrlImg || vetFemaleImg} className="card-img-top img-card-vet img-card-vet2" alt={`${vet.name} logo`} /> : <img src={vet.profileUrlImg || vetMaleImg} className="card-img-top img-card-vet img-card-vet2" alt={`${vet.name} logo`} />}
                      <div className="card-body">
                        {vet.verified_status === 'verified' ? (
                          <h5 className="card-title comuna-vet">
                            {vet.name} <MdVerified style={{ fill: '#C75100' }} title='Médico/a Veterinario/a verificado/a por Mappcota' />
                          </h5>
                        ) : (
                          <h5 className="card-title comuna-vet">{vet.name}</h5>
                        )}
                        <p className="card-text" style= {{ marginBottom: 'var(--bs-card-title-spacer-y)' }}>
                          {vet.addressComuna},{vet.addressRegion}
                        </p>
                        <p className="card-text">{vet.area}</p>
                      </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        {props.action.user.credenciales.rol === 5 && (
                            <button className="profile-button-red" onClick={() => preDeleteVet(vet.id)}>
                              Eliminar cuenta
                            </button>
                        )}
                        <Link to={{ pathname: '/veterinario/' + vet.id }}>
                          <button className="profile-button" id="boton-vet">Ve su perfil</button>
                        </Link>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item container justifyContent={'center'} alignItems={'baseline'} xs={9}>
                <Alert icon={<WarningAmberOutlinedIcon fontSize="inherit" />} severity="info">No hay médicos/as veterinarios/as registrados</Alert>
              </Grid>
            )
          }
        </Grid>
      </div>
      {registerService === true && (
        <div className="register-form-container">
          <div className="register-form-content">
            <RegisterVetForm
              id={props.action.user.credenciales.id}
              role={props.action.user.credenciales.rol}
              closeServiceOpenLogIn={closeServiceOpenLogIn}
              closeServiceOpenRegister={closeServiceOpenRegister}
              toggleServiceRegister={toggleServiceRegister}
              toggleSignUpUser={closeServiceOpenRegister}
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

export default connect(mapStateToProps)(IndexVeterinarians);
