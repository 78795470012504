import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { response, reduxAction } from '../../../Types/petType';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}
type AssignVetProps = {
    toggleAssignVet: React.MouseEventHandler,
    action: reduxAction
  }
function AssignVet(props: AssignVetProps) {
  const { id } = useParams();
  const [pets, setPets] = useState<Array<response>>([]);
  getPets();
  async function getPets() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    const url = `${process.env.REACT_APP_URL}/userPets/${props.action.user.credenciales.id}`;
    await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setPets(res.data.pets);
      });
  }

  async function assignPet(petId: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/headVets/' + petId + '/' + id,
      { create: true }, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: 'El médico/a ha sido asignado a tu mascota',
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        window.location.reload();
      })
      .catch(() => {
        swal({
          title: 'Este médico/a ya está asignado a esta mascota',
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      });
  }

  return (
      <>
      <div className="registerForm" style={{ justifyContent: 'center' }}>
        <ul className="nav nav-pills justify-content-center nav-fill">
          <button className="closeForm" onClick={props.toggleAssignVet}>
            <CloseIcon />
          </button>
        </ul>
        {pets.length === 0 && <h1 className="register-title" style={{ marginBottom: '3vh' }}>No tienes mascotas registradas</h1>}
        {pets.length > 0 && (
        <div>
          <div className="register-title-div">
            <h1 className="register-title" style={{ marginBottom: '40px' }}>¡Elige a tu mascota!</h1>
          </div>
          <div className='spacer32'></div>
          <div className="fieldsets-hotel" style={{ marginBottom: '50px' }} >
            <fieldset className="fieldset-div-checks">
                <table className="table table-hover" style= {{ alignItems: 'center' }}>
                  <tbody>
                    {pets.map((pet) => (
                      <tr className="fila-tabla" key={pet.petId}>
                        <td className="celda">{pet.pet.name} </td>
                        <td>
                          <button
                            className="solicitar-acceso-boton"
                            style={{ padding: '5px' }}
                            onClick={() => assignPet(pet.petId)}
                          >
                            Asignar
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </fieldset>
          </div>
        </div>
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps)(AssignVet);
