import React, { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import './verification.css';
import swal from 'sweetalert';
import { PerfilTypes } from '../../Types/PerfilTypes';
import UploadButton from '../Common/UploadButton';
import { UploadedImages } from '../../Types/VetTypes';
import CloseIcon from '@mui/icons-material/Close';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function VerificationForm(props: PerfilTypes) {
  const [isFormValid, setIsFormValid] = useState(false);
  const [colmevetNumber, setColmevetNumber] = useState<number>();
  const [photos, setPhotos] = useState<{
    titlePhotoUrl: string,
    carnetPhotoUrl: string
  }>({ titlePhotoUrl: '', carnetPhotoUrl: '' });

  async function submitForm(event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');

    await axios.post(process.env.REACT_APP_URL + '/vets/verificationRequests', {
      vetId: props.action.user.credenciales.id,
      colmevetNumber,
      titlePhotoUrl: photos.titlePhotoUrl,
      carnetPhotoUrl: photos.carnetPhotoUrl
    }, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        swal({
          title: 'Solicitud de verificación creada con éxito',
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        window.location.reload();
      })
      .catch((error) => {
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      });
  }

  const handleFileUpload = (type: 'titlePhotoUrl' | 'carnetPhotoUrl') => (base64: string) => {
    setPhotos(prevPhotos => {
      const newPhotos = {
        ...prevPhotos,
        [type]: base64
      };
      const formIsValid = ImagesAreUploaded(newPhotos);
      setIsFormValid(formIsValid);
      return newPhotos;
    });
  };

  const ImagesAreUploaded = (uploadedImages: UploadedImages): boolean => {
    let isValid: boolean;
    if (uploadedImages.titlePhotoUrl && uploadedImages.carnetPhotoUrl) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  };

  return (
  <>
      <form className='registerForm' onSubmit={async (e) => await submitForm(e)}>
        <ul className='nav nav-pills justify-content-center nav-fill'>
          <button className='closeForm' onClick={props.closePopUpVerification}><CloseIcon/></button>
        </ul>
        <div className='register-title-div'>
          <h1 className='register-title'>¡Solicita tu verificación!</h1>
        </div>
        <div className='fieldsets-ver'>
          <fieldset className='fieldset-ver-div'>
            <p>
              <input
                id="colmevetNumber"
                placeholder=" "
                type="number"
                value={colmevetNumber}
                name="colmevetNmber"
                onChange={e => setColmevetNumber(parseInt(e.target.value))}
                className="ver-form-input"
                maxLength={4}
                minLength={4}
              />
              <label htmlFor="colmevetNumber">Número Colmevet (opcional)</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-vertitle-div'>
            <UploadButton
              onFileUpload={handleFileUpload('titlePhotoUrl')}
              labelName='Título Profesional'
            />
          </fieldset>
          <fieldset className='fieldset-verarch-div'>
            <UploadButton
              onFileUpload={handleFileUpload('carnetPhotoUrl')}
              labelName='Cédula de Identidad chilena'
            />
          </fieldset>
        </div>
        <div className='register-buttons'>
          <button className='acept-button' value="submit" disabled={!isFormValid}>Solicitar Verificación</button>
        </div>
      </form>
  </>
  );
}

export default connect(mapStateToProps)(VerificationForm);
