import React from 'react';
import './PetsHealth.css';
import { deleteFichaFormProps } from '../../Types/petHealthTypes';
function DeleteFichaForm (props : deleteFichaFormProps) {
  return (
    <>
        <div className="sign-out-form">
            <h4 className="sign-out-form-title">¿Estás seguro que quieres eliminar esta ficha médica?</h4>
            <div className="delete-perfil-form-buttons">
                <button className="sign-out-form-button" onClick={props.deleteFichaForm}>Cancelar</button>
                <button type='button' className="sign-out-form-button-red" onClick={props.deleteFicha}>Eliminar</button>
            </div>
        </div>
    </>
  );
};

export default DeleteFichaForm;
