import React, { SyntheticEvent, useState, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import termsConditions from './termsCondition';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { vetsAreas } from '../../const/vetsAreas';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useDispatch } from 'react-redux';
import useSignOut from '../SignOutForm/instantSignOut';
import UploadButton from '../Common/UploadButton';
import { UploadedImages } from '../../Types/VetTypes';

type RegisterVetFormProps = {
  id: string,
  role: number,
  toggleServiceRegister: React.MouseEventHandler,
  closeServiceOpenRegister: React.MouseEventHandler,
  closeServiceOpenLogIn: React.MouseEventHandler,
  toggleSignUpUser: React.MouseEventHandler
}

type RegisterData = {
  termsConditionsAccepted: boolean
  area: string,
}

function RegisterVetForm(props: RegisterVetFormProps) {
  const dispatch = useDispatch();
  const [datos, setDatos] = useState<RegisterData>({
    termsConditionsAccepted: false,
    area: ''
  });
  const [agree, setAgree] = useState<boolean>(false);
  const [showTermsConditions, setShowTermsConditions] = useState<boolean>(false);
  const [showRegisterSucceed, setShowRegisterSucceed] = useState<boolean>(false);
  const [colmevetNumber, setColmevetNumber] = useState<number>();
  const [isFormValid, setIsFormValid] = useState(false);
  const [photos, setPhotos] = useState<{
    titlePhotoUrl: string,
    carnetPhotoUrl: string
  }>({ titlePhotoUrl: '', carnetPhotoUrl: '' });

  useEffect(() => {
    setDatos({
      ...datos
    });
  }, []);

  const signOut = useSignOut(dispatch);
  const handleDialogClose = (event: any) => {
    signOut(event); // Llama a la función signOut para cerrar la sesión
    setShowRegisterSucceed(false); // Cierra el diálogo
  };

  function handleInputChange(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    if (target.name === 'rut') {
      setDatos({
        ...datos
      });
    } else {
      setDatos({
        ...datos,
        [target.name]: target.value
      });
    }
  }

  function submitRegisterVetForm () {
    return function (event: SyntheticEvent) {
      event.preventDefault();
      datos.termsConditionsAccepted = agree;
      const token = JSON.parse(localStorage.getItem('token') || '');
      if (props.role === 1) {
        axios.post(process.env.REACT_APP_URL + '/create/vet', datos, { headers: { Authorization: `Bearer ${token}` } })
          .then(() => {
            axios.post(process.env.REACT_APP_URL + '/vets/verificationRequests', {
              vetId: props.id,
              colmevetNumber,
              titlePhotoUrl: photos.titlePhotoUrl,
              carnetPhotoUrl: photos.carnetPhotoUrl
            }, { headers: { Authorization: `Bearer ${token}` } })
              .then(() => {
                setShowRegisterSucceed(true);
              })
              .catch((error) => {
                swal({
                  title: 'Error',
                  text: String(error.response.data.message),
                  icon: 'error',
                  buttons: { Aceptar: true },
                  timer: 2000
                });
              });
          })
          .catch((error) => {
            swal({
              title: 'Error',
              text: String(error.response.data.message),
              icon: 'error',
              buttons: { Aceptar: true },
              timer: 2000
            });
          }
          );
      } else {
        axios.post(process.env.REACT_APP_URL + '/vets/verificationRequests', {
          vetId: props.id,
          colmevetNumber,
          titlePhotoUrl: photos.titlePhotoUrl,
          carnetPhotoUrl: photos.carnetPhotoUrl
        }, { headers: { Authorization: `Bearer ${token}` } })
          .then(() => {
            setShowRegisterSucceed(true);
          })
          .catch((error) => {
            swal({
              title: 'Error',
              text: String(error.response.data.message),
              icon: 'error',
              buttons: { Aceptar: true },
              timer: 2000
            });
          });
      }
    };
  }

  const handleFileUpload = (type: 'titlePhotoUrl' | 'carnetPhotoUrl') => (base64: string) => {
    setPhotos(prevPhotos => {
      const newPhotos = {
        ...prevPhotos,
        [type]: base64
      };
      const formIsValid = ImagesAreUploaded(newPhotos);
      setIsFormValid(formIsValid);
      return newPhotos;
    });
  };

  const ImagesAreUploaded = (uploadedImages: UploadedImages): boolean => {
    let isValid: boolean;
    if (uploadedImages.titlePhotoUrl && uploadedImages.carnetPhotoUrl) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  };

  return (
    <form className="vetForm" onSubmit={submitRegisterVetForm()}>
      <ul className="nav nav-pills justify-content-center nav-fill">
        <button className='closeForm' onClick={props.toggleSignUpUser}><CloseIcon /></button>
      </ul>
      <div className='register-title-div'>
        <h1 className='register-title'>¡Regístrate como médico/a veterinario/a!</h1>
      </div>
      <div className='register-subtitle-div'>
        <p>No olvides leer los términos y condiciones</p>
      </div>
      <div className='vet-form-container'>
        <fieldset className='fieldset-div'>
          <p>
            <input
              id="colmevetNumber"
              placeholder=" "
              type="number"
              value={colmevetNumber}
              name="colmevetNmber"
              onChange={e => setColmevetNumber(parseInt(e.target.value))}
              className="colmevet-number"
              maxLength={4}
              minLength={4}
            />
            <label htmlFor="colmevetNumber">Número Colmevet (opcional)</label>
          </p>
        </fieldset>
        <fieldset className='fieldset-div'>
          <p>
            <select placeholder=" " id="area" name="area" className="register-selector form-select" value={datos.area} onChange={e => handleInputChange(e)} required>
              <option value="" defaultValue="" disabled hidden></option>
              {vetsAreas.map((area) => {
                return <option key={area}>{area}</option>;
              })}
            </select>
            <label htmlFor="addressRegion">Área de desempeño</label>
          </p>
        </fieldset>
        <fieldset className='fieldset-titulo'>
          <UploadButton
            onFileUpload={handleFileUpload('titlePhotoUrl')}
            labelName='Título Profesional de Med. Veterinaria'
          />
        </fieldset>
        <fieldset className='fieldset-ci'>
          <UploadButton
            onFileUpload={handleFileUpload('carnetPhotoUrl')}
            labelName='Cédula de Identidad chilena'
          />
        </fieldset>
        <div className='terms-and-conditions'>
          <input className='terms-check' type="checkbox" id="agree" name='termsConditionsAccepted' onChange={() => setAgree(!agree)} required/>
          <div className='terms-label'>
            <div className='accept-terms'>Acepto los términos, condiciones y las políticas de privacidad</div>
            <a onClick={() => setShowTermsConditions(true)} className='show-terms'> Mostrar términos y condiciones </a>
          </div>
          <div>
            {showTermsConditions &&
              <Dialog open={showTermsConditions} scroll='paper' maxWidth='lg'>
                <DialogActions>
                  <button className='closeForm' onClick={() => setShowTermsConditions(false)}><CloseIcon /></button>
                </DialogActions>
                <DialogTitle id="scroll-dialog-title">
                  <h4> <strong>TÉRMINOS Y CONDICIONES Y AVISO DE PRIVACIDAD</strong></h4>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                  >
                  {termsConditions()}
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            }
          </div>
          <div>
            {showRegisterSucceed &&
              <Dialog open={showRegisterSucceed} scroll='paper' maxWidth='lg' className="custom-dialog">
                <DialogActions>
                  <button className='closeForm' onClick={handleDialogClose}><CloseIcon /></button>
                </DialogActions>
                <DialogTitle className="dialog-title" id="scroll-dialog-title">
                  <h4> <CheckCircleIcon className="success-icon" /> <strong>Has enviado tu solicitud con éxito!</strong></h4>
                </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        tabIndex={-1}
                        className="dialog-content-text"
                      >
                        <div className='spacer32'></div>
                        <p className='pop-up-text'> Para ver los cambios en tu cuenta, debes esperar a que el administrador te acepte y luego iniciar sesión </p>
                      </DialogContentText>
                    </DialogContent>
              </Dialog>
            }
          </div>
        </div>
        <div className='submit-button-container'>
          <button id='create-account' className='register-form-button' value="submit" disabled={!isFormValid}>Registrar</button>
        </div>
      </div>
    </form>
  );
}

RegisterVetForm.propTypes = {
  toggleServiceRegister: PropTypes.func,
  closeServiceOpenRegister: PropTypes.func,
  closeServiceOpenLogIn: PropTypes.func
};

export default RegisterVetForm;
