import React from 'react';
import GoogleMapReact, { Coords } from 'google-map-react';
import { serviceType } from '../../Types/RequestServType';

interface GoogleMapsProps {
  coords: Coords[];
  services: serviceType[];
}

interface HandlerProps extends GoogleMapsProps {
  map: any;
  maps: any;
}

const GoogleMaps = ({ coords, services }: GoogleMapsProps) => {
  const infoWindowString = (service: serviceType) => `
  <div>
    <div style="font-size: 16px;">
      ${service.name}
    </div>
    <div style="font-size: 14px;">
      <span style="color: grey;">
      ${service.score}
      </span>
      <span style="color: orange;">${String.fromCharCode(9733).repeat(Math.floor(service.score))}</span><span style="color: lightgrey;">${String.fromCharCode(9733).repeat(5 - Math.floor(service.score))}</span>
    </div>
  </div>`;

  // Refer to https://github.com/google-map-react/google-map-react#use-google-maps-api
  const handleApiLoaded = ({ map, maps, services, coords }: HandlerProps) => {
    const markers: any[] = [];
    const infowindows: any[] = [];
    const serviceCoords = services.map((service, index) => (
      { service, coord: coords[index] }
    ));

    serviceCoords.forEach((serviceCoord) => {
      const { service, coord } = serviceCoord;
      markers.push(new maps.Marker({
        position: {
          lat: coord.lat,
          lng: coord.lng
        },
        map
      }));

      infowindows.push(new maps.InfoWindow({
        content: infoWindowString(service)
      }));
    });

    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker);
      });
    });
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        defaultZoom={10}
        defaultCenter={coords[0]}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY || '' }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded({ map, maps, services, coords })}
      />
    </div>
  );
};

export default GoogleMaps;
