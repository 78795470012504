import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { UploadButtonProps } from '../../Types/PostTypes';
import './UploadButton.css';

function convertToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function UploadButton({ onFileUpload, labelName }: UploadButtonProps) {
  const [uploadedImage, setUploadedImage] = useState<string>();

  function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      convertToBase64(file).then((base64) => {
        setUploadedImage(base64 as string);
        if (onFileUpload) {
          onFileUpload(base64 as string);
        }
      });
    }
  }

  return (
    <div className='upload-button-container'>
      <div className='button-and-label-container'>
        <label className='image-label' htmlFor='image'>{labelName}</label>
        <Button
          sx={{
            backgroundColor: '#00BBC7',
            color: '#000',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: '#287685',
              color: '#fff'
            }
          }}
          component='label'
          variant='contained'
          startIcon={<CloudUploadIcon />}
        >
          Subir archivo
          <input
            id='image'
            type="file"
            accept='image/*'
            hidden
            onChange={onFileChange}
          />
        </Button>
      </div>
      {
        uploadedImage &&
        <div className='image-preview-container mb-5'>
          <img
            src={uploadedImage}
            alt="Vista previa"
            style={{ maxWidth: '200px' }}
            className='mg-auto d-block'
          />
        </div>
      }
    </div>
  );
}

export default UploadButton;
