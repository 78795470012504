import React, { useState, SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import './ReportForm.css';
import axios from 'axios';
// import { Link, useParams } from 'react-router-dom';
import swal from 'sweetalert';

type ReportType = {
  reportUserForm: Function,
  id: any
}

function ReportUserForm (props: ReportType) {
  const [reason, setReason] = useState('');
  const [other, setOther] = useState('');

  function reportUser (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    console.log(token);
    // [POST] /reportUser/report/:reportedId:
    console.log(reason);

    if (reason === '3') {
      console.log('es otro');
      axios.post(process.env.REACT_APP_URL + '/reportUser/report/' + props.id, { reason: 'OTHER', other }, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
          swal({
            title: 'Error',
            text: String(error.response.data.error),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        }
        );
      props.reportUserForm();
    } else if (reason === '1') {
      console.log('es spam');
      axios.post(process.env.REACT_APP_URL + '/reportUser/report/' + props.id, { reason: 'SPAM' }, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
          swal({
            title: 'Error',
            text: String(error.response.data.error),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        }
        );
      props.reportUserForm();
    } else if (reason === '2') {
      console.log('es inapropiado');
      axios.post(process.env.REACT_APP_URL + '/reportUser/report/' + props.id, { reason: 'INAPROPRIATE' }, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          console.log(response);
        })
        .catch((error) => {
          console.log('error.response.status' + error.response.status);
          if (error.response.status === 409) {
            swal({
              title: 'Error',
              text: 'Ya has denunciado a este usuario',
              icon: 'error',
              buttons: { Aceptar: true },
              timer: 5000
            });
          } else {
            swal({
              title: 'Error',
              text: String(error.response.data.error),
              icon: 'error',
              buttons: { Aceptar: true },
              timer: 5000
            });
          }
        }
        );
      props.reportUserForm();
    }
  }

  // const token = JSON.parse(localStorage.getItem('token') || '');
  //   axios.delete(process.env.REACT_APP_URL + '/users/' + id, { headers: { Authorization: `Bearer ${token}` } })
  //     .then(() => {
  //       setOpenForm(!openForm);
  //       navigate('/users');
  //     });

  return (
        <div className="report-form">
            <div className='row'>
                <div className='col-auto'>
                  <div className='row'>
                  <button className='closeFormbutton' onClick={() => props.reportUserForm()}> X </button>
                  </div>
                    <div className='row'>
                    <h4 className="report-form-title">¿Por qué quieres reportar a este usuario?</h4>
                    </div>
                    <form onSubmit={(e) => reportUser(e)}>
                    <div className='row'>
                            <select className="report-form-select col-md-8" onChange={(e) => setReason(e.target.value)}>
                                <option value="0" hidden>Selecciona una razón</option>
                                <option value="1">El usuario manda spam</option>
                                <option value="2">El usuario sube contenido inapropiado</option>
                                <option value="3">Otro</option>
                            </select>
                            <button className="report-form-button col-sm-2" value="submit">Enviar</button>
                    </div>
                    <div className='row'>
                            {reason === '3' &&
                            <input className='report-input col-md-8' type="text" id="spam" name="report" value={other} onChange={e => setOther(e.target.value)} />}
                    </div>
                        </form>
                </div>
            </div>
        </div>
  );
};

ReportUserForm.propTypes = {
  reportUserForm: PropTypes.func,
  id: PropTypes.any
};

export default ReportUserForm;
