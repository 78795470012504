import RegionesYcomunas from '../RegisterForm/regionesComunas';
import { connect } from 'react-redux';
import { PerfilTypesToken } from '../../Types/PerfilTypes';
import { userDataTypeAllUsers } from '../../Types/UserTypes';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../Navbar';
import './allUsers.css';
import { Alert, Grid, TextField, Pagination } from '@mui/material';
import SelectableFilterList from '../Common/selectableFilterList';
import Footer from '../Footer';
import avatar from '../../Assets/Imagenes/blank-profile-picture-.png';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { prettifyRut } from 'react-rut-formatter';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function IndexUsers(props: PerfilTypesToken) {
  const usersInPage: number = 10;
  const regionsNames = Object.values(RegionesYcomunas.regiones).map((region) => region.NombreRegion);
  const [users, setUsers] = useState<Array<userDataTypeAllUsers>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [comunasOptions, setComunasOptions] = useState<Array<string>>([]);
  const [nameFilter, setNameFilter] = useState<string>('');
  const [rutFilter, setRutFilter] = useState<string>('');
  const [checkedFilter, setCheckedFilter] = useState<{
    region: string[];
    comunas: string[];
  }>({
    region: [],
    comunas: []
  });
  const [verified, setVerified] = useState<boolean>(false);

  const handleToggle = (
    listName: 'region' | 'comunas',
    value: string
  ) => () => {
    const currentIndex = checkedFilter[listName].indexOf(value);
    const newChecked = [...checkedFilter[listName]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilter((prev) => ({ ...prev, [listName]: newChecked }));

    if (listName === 'region') {
      const selectedRegion = RegionesYcomunas.regiones.find(region => region.NombreRegion === value);
      setComunasOptions((prev) => {
        if (currentIndex === -1 && selectedRegion) {
          prev.push(...selectedRegion.comunas);
          return prev.sort();
        } else {
          const newCommunesFiltered = prev.filter(
            (com) => !selectedRegion?.comunas.includes(com)
          );
          return newCommunesFiltered.sort();
        }
      });
    }
  };

  async function fetchUsersData(page: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('limit', usersInPage.toString());
    const addFilterParam = (filterArray: string[], paramName: string) => {
      const joinedFilter = filterArray.join(',');
      if (joinedFilter) {
        queryParams.append(paramName, joinedFilter);
      }
    };

    addFilterParam(checkedFilter.region, 'region');
    addFilterParam(checkedFilter.comunas, 'comuna');
    if (nameFilter) {
      queryParams.append('name', nameFilter);
    }
    if (rutFilter) {
      queryParams.append('rut', rutFilter);
    }
    setUsers([]);
    const url = `${process.env.REACT_APP_URL}/userPets/all?${queryParams.toString()}`;
    await axios.get(url, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        console.log(response.data.ownerPets);
        setUsers([...response.data.ownerPets]);
        setTotalPages(response.data.pagination.totalPages);
        console.log(response.data.ownerPets);
      });

    if (props.action.user.credenciales.rol === 2) {
      await axios.get(`${process.env.REACT_APP_URL}/vets/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
        .then((response) => {
          if (response.data.vet.verified_status === 'verified') {
            setVerified(true);
          }
        });
    }
  }

  useEffect(() => { fetchUsersData(currentPage); }, [currentPage, nameFilter, rutFilter, checkedFilter]);

  async function preDeleteUser(id: string) {
    swal({
      title: 'Alerta',
      text: '¿Estás seguro que quieres eliminar a este Médico/a Veterinario/a?',
      icon: 'warning',
      buttons: ['Cancelar', true],
      dangerMode: true
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteUser(id);
        }
      });
  }

  async function deleteUser(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.delete(process.env.REACT_APP_URL + `/users/${id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: 'Se eliminó al usuario',
          icon: 'success',
          timer: 2000
        });
        fetchUsersData(currentPage);
      });
  }

  return (
    <>
      <div className="wrapper">
        <Navbar logged={true} />
       {(props.action.user.credenciales.rol === 5 || (props.action.user.credenciales.rol === 2 && verified)) && (
        <Grid container justifyContent={'center'} style={{ padding: '30px' }}>
          <Grid item xs={3} className='filters-container'>
            <h6 className='navigation-info-post-post pt-3 ms-3'>Tutores / Todos</h6>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Nombre</h2>
              </div>
              <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nameFilter} onChange={e => setNameFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>RUT</h2>
              </div>
              <TextField id="outlined-basic" type="tel" label="RUT" variant="outlined" value={rutFilter} onChange={e => setRutFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Región</h2>
              </div>
              <SelectableFilterList
                items={regionsNames} checked={checkedFilter.region}
                handleToggle={(value) => handleToggle('region', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Comuna</h2>
              </div>
              <SelectableFilterList
                items={comunasOptions} checked={checkedFilter.comunas}
                handleToggle={(value) => handleToggle('comunas', value)}
              />
            </div>
          </Grid>
          {
            users.length > 0 ? (
              <Grid
                item
                container
                justifyContent={'space-evenly'}
                alignContent={'start'}
                xs={9}
                className="card-group"
              >
                <Grid container item xs={12} justifyContent={'center'} style={{ marginBottom: '15px' }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="secondary"
                    size='large'
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'gray', // Cambiar el color del texto
                        '&.Mui-selected': {
                          backgroundColor: '#287685', // Color de fondo cuando está seleccionado
                          color: 'white' // Color del texto cuando está seleccionado
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: '#287685' // Color de fondo cuando está seleccionado
                        },
                        '&:hover': {
                          backgroundColor: 'lightblue' // Color de fondo al pasar el ratón
                        }
                      }
                    }}
                  />
                </Grid>
                {users.map((user) => (
                    <Grid item>
                    <div key={user.user.id} className="card card-vet" style={{ padding: '20px' }}>
                      <img src={user.user.profileUrlImg || avatar} className="card-img-top img-card-vet" alt={`${user.user.name} logo`} />
                      <div className="card-body">
                        <h5 className="card-title comuna-vet">{user.user.name}</h5>
                        <p className="card-text">
                          {user.user.addressComuna},{user.user.addressRegion}
                        </p>
                        <p className="card-text">{prettifyRut(user.user.rut)}</p>
                      </div>
                      {props.action.user.credenciales.rol === 5 && (
                        <button className="profile-button-red" onClick={() => preDeleteUser(user.user.id)}>
                          Eliminar cuenta
                        </button>
                      )}
                      <Link to={{ pathname: '/users/' + user.user.id }}>
                        <button className="profile-button">Ve su perfil</button>
                      </Link>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
            <Grid item container justifyContent={'center'} alignItems={'baseline'} xs={9}>
              <Alert icon={<WarningAmberOutlinedIcon fontSize="inherit" />} severity="info">No hay tutores registrados</Alert>
            </Grid>
            )}
        </Grid>
       )}
      </div>
      <Footer />
    </>
  );
}

export default connect(mapStateToProps)(IndexUsers);
