import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import './index.css';
import { connect } from 'react-redux';
import { reduxAction } from '../../Types/petType';
import CloseIcon from '@mui/icons-material/Close';

function mapStateToProps(state: reduxAction) {
  return { action: state };
}

interface PopUpPetShowAllProps {
  shouldShowPopup: () => boolean;
}

function PopUpPetShowAll({ shouldShowPopup }: PopUpPetShowAllProps) {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const show = shouldShowPopup();
    setModal(show);
  }, [shouldShowPopup]);

  const closeModal = () => {
    setModal(false);
  };

  const bodyPopUp = (
    <div className="Pop-up">
      <div className="title-pop-up">
        <h1 className="alert-text-pop-up">¡ALERTA!</h1>
        <button className='close-pop-up' onClick={closeModal}><CloseIcon /></button>
      </div>
      <div className="subtitle-pop-up">
        <h3>Tienes mascotas sin microchip</h3>
      </div>
      <h5>De acuerdo con la Ley 21.020: Tenencia Responsable de Mascotas y Animales de Compañía,
          tienes que inscribir a tus mascotas en el Registro Nacional de Mascotas</h5>
    </div>
  );

  return (
    <div className="PopUp">
      <Modal open={modal} onClose={closeModal} disableEscapeKeyDown={true} disableBackdropClick={true}>
        {bodyPopUp}
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps)(PopUpPetShowAll);
