import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Navbar from '../Navbar';
import Footer from '../Footer';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';

import './PostShow.css';

function mapStateToProps (state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function PostShow () {
  const [posts, setPosts] = useState<Array<any>>([]);
  const url = 'https://graph.instagram.com/me/media';
  const fields = '?fields=media_type,media_url,caption,permalink,thumbnail_url&access_token=';
  const apiFetchUrl = url + fields + process.env.REACT_APP_INSTAGRAM_TOKEN;

  const fetchPost = async () => {
    await axios.get(apiFetchUrl)
      .then(res => {
        console.log(res.data);
        setPosts(res.data.data);
      });
  };
  useEffect(() => {
    fetchPost();
  }, []);

  if (posts.length === 0) {
    return (<><Navbar logged={true} />
    <div className="loading-label" style={{ display: 'flex', marginLeft: '50vw', marginTop: '20px' }}><CircularProgress /></div>
    <Footer/></>); // Mostrar el indicador de carga
  }

  return (
    <div className='post-background'>
        <Navbar logged={true} />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" justifyContent="center" padding="2.5vh 2.5vw 2.5vh 2.5vw">
                {posts.map((post) => (
                    <Grid item xs={2} sm={4} md={4} key={post.media_url} >
                      <a href={post.permalink} target="_blank" rel="noreferrer">
                        <figure className='post-instagram'>
                          {(post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM')
                            ? <img src={post.media_url} style={{ borderRadius: '16px' }} />
                            : <img src={post.thumbnail_url} style={{ borderRadius: '16px' }} />
                          }
                          <figcaption className='caption-instagram'>
                            <p>{post.caption}</p>
                          </figcaption>
                        </figure>
                      </a>
                    </Grid>
                ))}
            </Grid>
          </Box>
        <Footer />
    </div>
  );
}

export default connect(mapStateToProps)(PostShow);
