import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logout } from '../Store/user';
import PropTypes from 'prop-types';
import './index.css';
import { useDispatch } from 'react-redux';

type SignOutFormProps = {
  closeSignOutForm: React.MouseEventHandler,
  navigate: Function
}

function signOut (dispatch: any) {
  const navigate = useNavigate();
  return function (event: SyntheticEvent) {
    event.preventDefault();
    // const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/session/logout')
      .then(() => {
        dispatch(logout());
        navigate('/');
      });
  };
};

function SignOutForm (props: SignOutFormProps) {
  const dispatch = useDispatch();
  return (
        <div className="sign-out-form">
            <h4 className="sign-out-form-title">¿Estás seguro de que quieres cerrar la sesión?</h4>
            <div className="sign-out-form-buttons">
                <button className="sign-out-form-button" onClick={props.closeSignOutForm}>Cancelar</button>
                <button className="sign-out-form-button-red" onClick={signOut(dispatch)}>Cerrar Sesión</button>
            </div>
        </div>
  );
};

SignOutForm.propTypes = {
  navigate: PropTypes.func,
  closeSignOutForm: PropTypes.func
};

export default SignOutForm;
