import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import newLogo from '../../Assets/Imagenes/LOGO FONDO TRANSPARENTE.png';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import perfil from '../../Assets/Imagenes/perfil_blk.png';
import people from '../../Assets/Imagenes/paseo.png';
import blog from '../../Assets/Imagenes/blog.png';
import service from '../../Assets/Imagenes/servicio.png';
import salirNegro from '../../Assets/Imagenes/salir_blk.png';
import closeSession from '../../Assets/Imagenes/salir_bueno.png';
import RegisterForm from '../RegisterForm';
import SignInForm from '../SignInForm';
import SignOutForm from '../SignOutForm';
import RegisterServiceForm from '../Servicios/registerServiceForm';
import PropTypes from 'prop-types';
import darkHuella from '../../Assets/Imagenes/mascotas_blk.png';
import verified from '../../Assets/Imagenes/verificaciones_bueno.png';
import vet from '../../Assets/Imagenes/veterinario.png';
import darkPatients from '../../Assets/Imagenes/paciente_blk.png';
import './index.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import alerta from '../../Assets/Imagenes/denuncia_bueno.png';
import petsitter from '../../Assets/Imagenes/petsitter.png';
import { userDataType } from '../../Types/UserTypes';
import userImg from '../../Assets/Imagenes/blank-profile-picture-.png';
import hotelBlanco from '../../Assets/Imagenes/Hotel.png';
import perfilBlanco from '../../Assets/Imagenes/perfil_blanco.png';
import mascotaBlanco from '../../Assets/Imagenes/mascotas_blanco.png';
import pacienteBlanco from '../../Assets/Imagenes/paciente_blanco.png';
import salirBlanco from '../../Assets/Imagenes/salir_blanco.png';

<link
  rel='stylesheet'
  href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
  integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
  crossOrigin='anonymous'
/>;

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function Navbar(props: any) {
  const navigate = useNavigate();
  const getNavbarStyle = ({ isActive }: { isActive: boolean }) => ({
    color: isActive ? '#9bb9fa' : '#fff',
    borderRadius: '4px',
    border: isActive ? '1px solid #9bb9fa' : '0px'
  });
  const [imageSrc, setImageSrc] = useState(perfil);
  const [mascotaSrc, setMascotaSrc] = useState(darkHuella);
  const [pacienteSrc, setPacienteSrc] = useState(darkPatients);
  const [salirSrc, setSalirSrc] = useState(salirNegro);
  const handleMouseOver = () => {
    setImageSrc(perfilBlanco);
  };

  const handleMouseOut = () => {
    setImageSrc(perfil);
  };

  const handlePetsMouseOver = () => {
    setMascotaSrc(mascotaBlanco);
  };

  const handlePetsMouseOut = () => {
    setMascotaSrc(darkHuella);
  };

  const handlePacientesMouseOver = () => {
    setPacienteSrc(pacienteBlanco);
  };

  const handlePacientesMouseOut = () => {
    setPacienteSrc(darkPatients);
  };

  const handleSalirMouseOver = () => {
    setSalirSrc(salirBlanco);
  };

  const handleSalirMouseOut = () => {
    setSalirSrc(salirNegro);
  };

  const [datos, setDatos] = useState<userDataType>({
    id: '',
    rut: '',
    address: '',
    addressComuna: '',
    addressRegion: '',
    name: '',
    phone: '',
    birthdate: '',
    email: '',
    gender: '',
    profileUrlImg: '',
    ispetsitter: '',
    siiGiro: '',
    platformCat: '',
    link: '',
    description: '',
    area: '',
    ishotel: '',
    verified_status: '',
    verified: 0,
    nRequests: 0,
    deniedReason: '',
    ServiceVerificationRequest: []
  });

  const [signingUpUser, setSigningUpUser] = useState<boolean>(false);
  const [signingUpVet, setSigningUpVet] = useState<boolean>(false);
  const [signingIn, setSigningIn] = useState<boolean>(false);
  const [signingOut, setSigningOut] = useState<boolean>(false);
  const [registerService, setRegisterService] = useState<boolean>(false);

  async function fetchUserData (userType:string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + `/${userType}s/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setDatos(
          response.data[userType]
        );
        if (response.data[userType].gender === 'MALE') {
          setDatos((prevState) => ({
            ...prevState,
            gender: 'Masculino'
          }));
        } else if (response.data[userType].gender === 'FEMALE') {
          setDatos((prevState) => ({
            ...prevState,
            gender: 'Femenino'
          }));
        } else {
          setDatos((prevState) => ({
            ...prevState,
            gender: 'Otro'
          }));
        }
      });
  }

  useEffect(() => {
    try {
      if (props.action.user.credenciales.rol === 1 || props.action.user.credenciales.rol === 4) {
        fetchUserData('user');
      } else {
        fetchUserData('vet');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);
  function signIn(event: SyntheticEvent) {
    event.preventDefault();
    setSigningIn(true);
  }

  function toggleSignUpUser(event: SyntheticEvent) {
    event.preventDefault();
    setSigningUpUser(!signingUpUser);
  }

  function toggleSignUpVet(event: SyntheticEvent) {
    event.preventDefault();
    setSigningUpVet(!signingUpVet);
  }

  function signOut(event: SyntheticEvent) {
    event.preventDefault();
    setSigningOut(true);
  }

  function closeRegisterOpenSignIn(event: SyntheticEvent) {
    event.preventDefault();
    setSigningUpUser(false);
    setSigningIn(true);
  }

  function closeSignInForm(event: SyntheticEvent) {
    event.preventDefault();
    setSigningIn(false);
  }

  function closeSignInFormOpenSignUp(event: SyntheticEvent) {
    event.preventDefault();
    setSigningIn(false);
    setSigningUpUser(true);
  }

  function closeSignOutForm(event: SyntheticEvent) {
    event.preventDefault();
    setSigningOut(false);
  }

  function toggleServiceRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(!registerService);
  }

  function closeServiceOpenRegister(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(false);
    setSigningUpUser(true);
  }

  function closeServiceOpenLogIn(event: SyntheticEvent) {
    event.preventDefault();
    setRegisterService(false);
    setSigningIn(true);
  }

  function closeRegisterUserOpenService(event: SyntheticEvent) {
    event.preventDefault();
    setSigningUpUser(false);
    setRegisterService(true);
  }

  function closeRegisterVetOpenService(event: SyntheticEvent) {
    event.preventDefault();
    setSigningUpVet(false);
    setRegisterService(true);
  }

  function closePopUpRegister() {
    setSigningUpUser(false);
    setSigningUpVet(false);
  }

  function handlePetsNavigate() {
    navigate('/pets/showAll');
  }

  return (
    <>
      <div className="Navbar">
        {!props.logged && (
          <>
            <img src={newLogo} className="home-button" />
            <div className="not-logged-buttons">
              <button
                onClick={(e) => toggleSignUpUser(e)}
                className="register-button"
              >
                Regístrate
              </button>
              <button onClick={signIn} className="sign-in-button">
                Ingresar
              </button>
            </div>
          </>
        )}
        {props.logged && (
          <>
            <Link to="/landing">
              <img src={newLogo} className="home-button" />
            </Link>
            <div className="logged-buttons">
              {props.action.user.credenciales.rol === 5 ? (
                <>
                  <NavLink
                    to="/adminverification"
                    className="navbar-item-center"
                    style={{ ...getNavbarStyle, fontSize: '14px' }}
                  >
                    <img src={verified} className="verification-img" />
                    Verificaciones
                  </NavLink>
                  <NavLink
                    to="/admindenuncias"
                    className="navbar-item-center"
                    style={{ ...getNavbarStyle, fontSize: '14px' }}
                  >
                    <img src={alerta} className="verification-img" />
                    Denuncias
                  </NavLink>
                </>
              ) : undefined}
              <NavLink
                to="/blogs"
                className="navbar-item-center"
                style={{ ...getNavbarStyle, fontSize: '14px' }}
              >
                <img src={blog} className="navbar-button" />
                Blogs
              </NavLink>
              <NavLink
                to="/servicios"
                className="navbar-item-center"
                style={{ ...getNavbarStyle, fontSize: '14px' }}
              >
                <img src={service} className="navbar-button" />
                Servicios
              </NavLink>
              <NavLink
                to="/petsitters"
                className="navbar-item-center"
                style={{ ...getNavbarStyle, fontSize: '14px' }}
              >
                <img src={petsitter} className="navbar-button" />
                Petsitters
              </NavLink>
              <NavLink
                to="/hoteles"
                className="navbar-item-center"
                style={{ ...getNavbarStyle, fontSize: '14px' }}
              >
                <img src={hotelBlanco} className="navbar-button" />
                Hoteles
              </NavLink>
              <NavLink
                to="/veterinarios"
                className="navbar-item-center"
                style={{ ...getNavbarStyle, fontSize: '14px' }}
              >
                <img src={vet} className="navbar-button" />
                M.Veterinarios/as
              </NavLink>
              {(props.action.user.credenciales.rol === 2 && datos.verified_status === 'verified') &&
                <NavLink
                  to="/users"
                  className="navbar-item-center"
                  style={{ ...getNavbarStyle, fontSize: '14px' }}
                >
                  <img src={people} className="navbar-button" />
                  Tutores
                </NavLink>
              }
              {(props.action.user.credenciales.rol === 5) &&
                <NavLink
                  to="/allUsers"
                  className="navbar-item-center"
                  style={{ ...getNavbarStyle, fontSize: '14px' }}
                >
                  <img src={people} className="navbar-button" />
                  Usuarios
                </NavLink>
              }
              {props.action.user.credenciales.rol !== 5 ? (
              <Dropdown style={{ backgroundColor: '#00000000 !important' }}>
              <Dropdown.Toggle className="dropdown-toggle-custom" style={{ border: 'none', fontSize: '14px', backgroundColor: '#00000000' }}>
                <div className="navbar-dropdown">
                <img src={datos.profileUrlImg ? datos.profileUrlImg : userImg} className="navbar-button" style={datos.profileUrlImg ? { borderRadius: '300%', padding: '9px 9px 9px', objectFit: 'contain', transform: 'scale(1.6)', width: '52px', height: '52px', marginTop: '4px' }
                  : { borderRadius: '300%', padding: '9px 9px 9px', objectFit: 'contain', transform: 'scale(1.6)', width: '48px', height: '48px', marginTop: '5px' }}/>
                </div>
                Cuenta
              </Dropdown.Toggle>
              <Dropdown.Menu className='dropdown-menu'>
              {props.action.user.credenciales.rol === 1 || (props.action.user.credenciales.rol === 2 && datos.verified_status !== 'verified') ? (
                <Dropdown.Item href={`/perfil/${props.action.user.credenciales.id}`}>
                    <div className="navbar-dropdown-item" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <img src={imageSrc} className="navbar-button" />
                    Perfil
                    </div>
                </Dropdown.Item>
              ) : undefined}
              {props.action.user.credenciales.rol === 2 && datos.verified_status === 'verified' ? (
                <Dropdown.Item href={`/perfilVet/${props.action.user.credenciales.id}`}>
                   <div className="navbar-dropdown-item" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                  <img src={imageSrc} className="navbar-button" />
                  Perfil
                </div>
                </Dropdown.Item>
              ) : undefined}
              {props.action.user.credenciales.rol === 1 || props.action.user.credenciales.rol === 2 ? (
                        <Dropdown.Item onClick={handlePetsNavigate}>
                            <div className="navbar-dropdown-item" onMouseOver={handlePetsMouseOver} onMouseOut={handlePetsMouseOut}>
                            <img src={mascotaSrc} className="navbar-button" />
                            Mis Mascotas
                            </div>
                        </Dropdown.Item>
              ) : undefined}
              {props.action.user.credenciales.rol === 2 && datos.verified_status === 'verified' ? (
                        <Dropdown.Item href="/patients">
                            <div className="navbar-dropdown-item" onMouseOver={handlePacientesMouseOver} onMouseOut={handlePacientesMouseOut}>
                            <img src={pacienteSrc} className="navbar-button" />
                            Mis Pacientes
                            </div>
                        </Dropdown.Item>
              ) : undefined}
                  <Dropdown.Item href="/">
                  <div className="navbar-dropdown-item" onClick={signOut} onMouseOver={handleSalirMouseOver} onMouseOut={handleSalirMouseOut}>
                  <img src={salirSrc} className="navbar-button"/>
                  Salir
                  </div>
                  </Dropdown.Item>
              </Dropdown.Menu>
              </Dropdown>
              ) : undefined}
              {props.action.user.credenciales.rol === 5 ? (
                <NavLink
                onClick={signOut}
                to="/"
                className="navbar-item-center"
                style={{ ...getNavbarStyle, fontSize: '14px' }}
              >
                <img src={closeSession} className="navbar-button" />
                Salir
              </NavLink>
              ) : undefined}

            </div>
          </>
        )}
      </div>
      {signingUpUser === true && (
        <div className="register-form-container">
          <div className="register-form-content">
            <RegisterForm
              mode={'user'}
              closePopupRegister={closePopUpRegister}
              closeRegisterOpenService={closeRegisterUserOpenService}
              closeRegisterOpenSignIn={closeRegisterOpenSignIn}
              toggleSignUpUser={toggleSignUpUser}
              toggleSignUpVet={toggleSignUpVet}
              navigate={props.navigate}
            />
          </div>
        </div>
      )}

      {signingUpVet === true && (
        <div className="register-form-container">
          <div className="register-form-content">
            <RegisterForm
              mode={'vet'}
              closePopupRegister={closePopUpRegister}
              closeRegisterOpenService={closeRegisterVetOpenService}
              closeRegisterOpenSignIn={closeRegisterOpenSignIn}
              toggleSignUpUser={toggleSignUpUser}
              toggleSignUpVet={toggleSignUpVet}
              navigate={props.navigate}
            />
          </div>
        </div>
      )}

      {signingIn === true && (
        <div className="sign-in-form-container">
          <div className="register-form-content">
            <SignInForm
              closeSignInFormOpenSignUp={closeSignInFormOpenSignUp}
              closeSignInForm={closeSignInForm}
              navigate={props.navigate}
            />
          </div>
        </div>
      )}

      {signingOut === true && (
        <div className="sign-out-form-container">
          <div className="register-form-content">
            <SignOutForm
              closeSignOutForm={closeSignOutForm}
              navigate={props.navigate}
            />
          </div>
        </div>
      )}

      {registerService === true && (
        <div className="register-form-container">
          <div className="register-form-content">
            <RegisterServiceForm
              closeServiceOpenLogIn={closeServiceOpenLogIn}
              closeServiceOpenRegister={closeServiceOpenRegister}
              toggleServiceRegister={toggleServiceRegister}
              toggleSignUpUser={closeServiceOpenRegister}
            />
          </div>
        </div>
      )}
    </>
  );
}

Navbar.propTypes = {
  logged: PropTypes.bool,
  navigate: PropTypes.func,
  handleCallback: PropTypes.func,
  action: PropTypes.shape({
    user: PropTypes.shape({
      /* eslint-disable no-mixed-spaces-and-tabs */
      credenciales: PropTypes.shape({
        id: PropTypes.string,
        token: PropTypes.string,
        rol: PropTypes.number
      })
      /* eslint-enable no-mixed-spaces-and-tabs */
    })
  })
};
export default connect(mapStateToProps)(Navbar);
