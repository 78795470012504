import React, { useState, useEffect, FC, ReactElement } from 'react';
import perrito from '../../Assets/Imagenes/perrito.svg';
import axios from 'axios';
import { connect } from 'react-redux';
import './PetsHealth.css';
import moment from 'moment';
import dermogramaCanino from '../../Assets/Imagenes/Dermograma/Canino/Dermograma.png';
import dermogramaFelino from '../../Assets/Imagenes/Dermograma/Felino/Dermograma.png';
import Footer from '../Footer';
import Navbar from '../Navbar';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import DeleteFichaForm from './deletePetHealthEntry';
import { petEntry, propsType, reduxAction } from '../../Types/petHealthTypes';
import { Pet } from '../../Types/petType';
import { prettifyRut } from 'react-rut-formatter';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}
const PetHealthShow : FC <propsType> = (props): ReactElement => {
  const [petHealthEntry, setPetHealthEntry] = useState<petEntry>();
  const [pet, setPet] = useState<Pet>();
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const { id } = useParams();
  const [rutVet, setRutVet] = useState<string>('');
  const [phoneVet, setPhoneVet] = useState<string>('');
  const [phoneNumber1, setPhoneNumber1] = useState<string>('');
  const [phoneNumber2, setPhoneNumber2] = useState<string>('');
  const [rutTutor, setRutTutor] = useState<string>('');
  const navigate = useNavigate();
  const location : any = useLocation();
  const [examChecks, setExamChecks] = useState({
    hemograma: false,
    perfil_bioquimico: false,
    hem_pb: false,
    hem_pb_t4: false,
    hem_pb_t4_tsh: false,
    prequirurgico_basico: false,
    prequirurgico_completo: false,
    protombina: false,
    tromboplastina: false,
    panel_coagulacion: false,
    coagulacion_infecc: false,
    perfil_renal: false,
    perfil_hepatico: false,
    perfil_lipidico: false,
    t4_tsh: false,
    perfil_lipidico_t4_tsh: false,
    felv_fiv: false,
    parvovirus_distemper: false,
    brucelosis: false,
    distemper: false,
    leucemia_felina: false,
    inmunodeficiencia_felina: false,
    ehrlichia_anaplasma: false,
    parvovirus: false,
    toxoplasma: false,
    cultivo_antibiograma_citologico_oidos: false,
    directo_piel_cultivo_dermatofitos: false,
    directo_piel_frotis_malassesia_cultivo_dermatofitos: false,
    urianalisis: false,
    urocultivo_antibiograma: false,
    coproparasitario: false,
    coprocultivo: false,
    cultivo_antibiograma: false,
    directo_piel_pelos: false,
    radiografia: false,
    ecotomografia: false
  });
  const fetchPetEntry = async () => {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/healthEntry/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setRutVet(prettifyRut(response.data.healthEntry.rut));
        setPhoneVet(prettifyPhoneNumber(response.data.healthEntry.phoneVet));
        setPhoneNumber1(prettifyPhoneNumber(response.data.healthEntry.phoneNumber1));
        try {
          setPhoneNumber2(prettifyPhoneNumber(response.data.healthEntry.phoneNumber2));
        } catch (error) {
          setPhoneNumber2('');
        }
        setRutTutor(prettifyRut(response.data.healthEntry.documentNumber));
        setPetHealthEntry(response.data.healthEntry);
        setExamChecks(response.data.healthEntry);
      });
  };
  const token = props.action.user.credenciales.token;
  const fetchPetInfo = async () => {
    await axios.get(process.env.REACT_APP_URL + '/pets/' + location?.state?.petId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setPet(res.data.pet);
      });
  };

  useEffect(() => {
    fetchPetEntry();
    fetchPetInfo();
  }, []);

  function deletePetHealthEntry () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + '/healthEntry/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setOpenDeleteForm(
          !openDeleteForm
        );
      });
    navigate(-1);
  }

  function deletePetHealthEntryForm () {
    setOpenDeleteForm(
      !openDeleteForm
    );
  };
  function prettifyPhoneNumber(phoneNumber:string) {
    const formattedNumber = phoneNumber.replace(/\s/g, '');
    if (formattedNumber.length > 4) {
      const countryCode = formattedNumber.slice(0, 4); // +569
      const firstPart = formattedNumber.slice(4, 8);
      const secondPart = formattedNumber.slice(8);
      return `${countryCode} ${firstPart} ${secondPart}`;
    }
    return phoneNumber;
  }
  function volver () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/healthEntry/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setPetHealthEntry(response.data.petHealthEntry);
      });
    navigate(-1);
  };

  function editar () {
    const path = '/petsHealth/edit/' + id;
    navigate(path, { state: { petId: location.state.petId } });
  };

  function fetchPdf (file: string | undefined) {
    if (file === undefined) {
      return;
    }
    fetch(file).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.download = 'Terminos y condiciones.pdf';
        alink.href = fileURL;
        alink.click();
      });
    });
  }
  if (petHealthEntry !== undefined) {
    return (
    <>
      <Navbar logged={true}/>
      <body>
      <div className='spacer32'></div>
      <h6 className='navigation-info-post-post ms-3'><Link to={'/pets/showAll'} className='link-perfil'>Mascotas</Link> / Ficha Médica</h6>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='title-pets'> Ficha Médica Veterinaria: {moment.utc(petHealthEntry.controlDate).format('DD/MM/YYYY')}</div>
        </div>
        <div className='container-fichas' style={{ marginTop: '30px', flexDirection: 'column' }}>
          <div className='col-auto' style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="card" style={{
              width: '900px',
              marginBottom: '50px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }}>
              <div className="card-body form-title-container">
                <div className = "texto-titulo-card"> Datos del paciente</div>
                <img src={pet?.profileUrlImg !== '' ? pet?.profileUrlImg : perrito}
                className="img-thumbnail-med" alt="Foto de perfil de la mascota" />
                <div>
                <h4 className="texto-campo" style={{ fontSize: '26px', margin: '4%', color: '#000' }}>{pet?.name}</h4>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="pet-info">
                    <h6 className="texto-campo-card">
                      Microchip: <span>{pet?.microchipId ? pet.microchipId : 'No hay microchip ingresado'}</span>
                    </h6>
                    <h6 className="texto-campo-card">Especie: <span>{pet?.species}</span></h6>
                    <h6 className="texto-campo-card" style={{ textTransform: 'capitalize' }}>Raza: <span>{pet?.race.toLocaleLowerCase()}</span></h6>
                    <h6 className="texto-campo-card">
                      Nacimiento: <span>{pet?.birthdate ? moment.utc(pet.birthdate).format('DD/MM/YYYY') : ''}</span>
                    </h6>
                    {pet?.gender === 'MALE' &&
                      <h6 className="texto-campo-card">Género: <span>Macho</span></h6>
                    }
                    {pet?.gender === 'FEMALE' &&
                      <h6 className="texto-campo-card">Género: <span>Hembra</span></h6>
                    }
                    {pet?.gender === 'OTHER' &&
                      <h6 className="texto-campo-card">Género: <span>Otro </span></h6>
                    }
                    <h6 className="texto-campo-card">Patrón: <span>{pet?.pattern}</span></h6>
                    <h6 className="texto-campo-card">Color: <span>{pet?.color}</span></h6>
                    <h6 className="texto-campo-card">Estado Reproductivo: <span>{pet?.reproductiveState}</span></h6>
                    <h6 className="texto-campo-card">Método de Obtención: <span>{pet?.obtencion}</span></h6>
                    <h6 className="texto-campo-card">Razón de Tenencia: <span>{pet?.razonTenencia}</span></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div className='col-auto'>
                <div className='container-form'>
                <div className='row g-3'>
                <div className='col-auto mx-auto'>
                  <div className='form-label texto-campo'>
                    Datos Médico/a Veterinario/a
                  </div>
                </div>
              </div>
              <div className='spacer-12'></div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Nombre Completo
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.nameVet}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    RUT
                  </div>
                  <div className='box-campo'>
                    {rutVet}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Correo
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.mailVet}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Teléfono de Contacto
                  </div>
                  <div className='box-campo'>
                    {phoneVet}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Lugar
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.place}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Profesión
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.profession}
                  </div>
                </div>
              </div>
              </div>
              <div className='container-form'>
                <div className='row g-3'>
                <div className='col-auto mx-auto'>
                  <div className='form-label texto-campo'>
                    Datos del Responsable
                  </div>
                </div>
              </div>
              <div className='spacer-12'></div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Nombre Completo
                  </div>
                  <div className='box-campo long-info'>
                    {petHealthEntry?.fullName}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Nacionalidad
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.nationality}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Tipo de Documento
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.documentation}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Número de Documento
                  </div>
                  <div className='box-campo'>
                    {rutTutor}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Fecha de Nacimiento
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.birthdayDate ? moment.utc(petHealthEntry.birthdayDate).format('DD/MM/YYYY') : ''}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Región
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.region}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Comuna
                  </div>
                  <div className='box-campo'>
                    {petHealthEntry?.comuna}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Domicilio
                  </div>
                  <div className='box-campo long-info'>
                    {petHealthEntry?.homeAddress}
                  </div>
                </div>
              </div>
              {petHealthEntry?.phoneNumber2 !== null
                ? (
                    <div className='row g-3'>
                      <div className='col'>
                        <div className='form-label texto-campo'>
                          Teléfono de Contacto 1
                        </div>
                        <div className='box-campo'>
                          {phoneNumber1}
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-label texto-campo'>
                          Teléfono de Contacto 2
                        </div>
                        <div className='box-campo'>
                          {phoneNumber2}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='row g-3 long-info'>
                      <div className='form-label texto-campo' style={{ paddingLeft: '0px' }}>
                        Teléfono de Contacto 1
                      </div>
                      <div className='box-campo'>
                        {phoneNumber1}
                      </div>
                    </div>
                  )
              }
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Correo
                  </div>
                  <div className='box-campo long-info'>
                    {petHealthEntry?.mail}
                  </div>
                </div>
              </div>
              </div>
              <div className='spacer-12'></div>
              <div className='container-form'>
              <div className='spacer-12'></div>
                <div className='row g-3'>
                <div className='col-auto mx-auto'>
                  <div className='form-label texto-campo'>
                    Datos Mascota
                  </div>
                </div>
              </div>
              <div className='spacer-12'></div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Temperatura (°C)
                    </div>
                      <div className='box-campo'>
                        {petHealthEntry?.temperature}
                      </div>
                  </div>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Peso (Kg)
                    </div>
                      <div className='box-campo'>
                        {petHealthEntry?.weight}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Condición Corporal
                    </div>
                      <div className='box-campo'>
                        {petHealthEntry?.corporalCondition}
                      </div>
                  </div>
                  <div className="col">
                    <div className='form-label texto-campo'>
                      Deshidratación (%)
                    </div>
                      <div className='box-campo'>
                          {petHealthEntry?.dehydration}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Color Mucosa
                    </div>
                    <div className='box-campo'>
                      {petHealthEntry?.mucousColour}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Genitales
                    </div>
                      <div className='box-campo'>
                        {petHealthEntry?.genitals}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Piel
                    </div>
                    <div className='box-campo'>
                          {petHealthEntry?.skin}
                      </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Linfonodos
                    </div>
                    <div className='box-campo'>
                          {petHealthEntry?.lymphoNodes}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Pulso Femoral
                    </div>
                      <div className='box-campo'>
                          {petHealthEntry?.femoralPulse}
                      </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Riesgo de Anestesia
                    </div>
                    <div className='box-campo'>
                          {petHealthEntry?.anesthesiaRisk}
                      </div>
                  </div>
                </div>
                <div className='spacer-12'></div>
                <div className='spacer-12'></div>
                <div className='row g-3'>
                </div>
                <div className='row g-3'>
                </div>
                <div className='spacer-12'></div>
                <div className='spacer-12'></div>
                <div className='row g-3'>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Vacuna
                    </div>
                    <div className='box-campo'>
                          {petHealthEntry?.vaccine}
                      </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Vacuna(s) Vigentes
                    </div>
                    <div className='box-campo'>
                          {petHealthEntry?.currentVaccine}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Vacuna Antirrábica Vigente
                    </div>
                      {petHealthEntry.arVaccine && petHealthEntry?.arVaccine.toString() === 'true' &&
                      <div className='box-campo'>
                        Sí
                      </div>
                      }
                      {petHealthEntry.arVaccine && petHealthEntry?.arVaccine.toString() === 'false' &&
                        <div className='box-campo'>
                        No
                      </div>
                      }
                      {!petHealthEntry.arVaccine &&
                      <div className='box-campo'>
                      No responde
                    </div>
                    }
                    </div>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Antiparasitario Externo
                    </div>
                    {petHealthEntry?.apExternal && petHealthEntry?.apExternal.toString() === 'true' &&
                    <div className='box-campo'>
                      Sí
                    </div>
                    }
                    {petHealthEntry?.apExternal && petHealthEntry?.apExternal.toString() === 'false' &&
                      <div className='box-campo'>
                      No
                    </div>
                    }{!petHealthEntry.apExternal &&
                      <div className='box-campo'>
                      No responde
                    </div>
                    }
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Antiparasitario Interno
                    </div>
                      {petHealthEntry?.apInternal && petHealthEntry?.apInternal.toString() === 'true' &&
                      <div className='box-campo'>
                        Sí
                      </div>
                      }
                      {petHealthEntry?.apInternal && petHealthEntry?.apInternal.toString() === 'false' &&
                        <div className='box-campo'>
                        No
                      </div>
                      }{!petHealthEntry.apInternal &&
                        <div className='box-campo'>
                        No responde
                      </div>
                      }
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Palpación Abdominal
                    </div>
                    <div className='box-campo'>
                          {petHealthEntry?.abdominalPalpation}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Tiempo Llenado Capilar
                    </div>
                      <div className='box-campo'>
                            {petHealthEntry?.capillaryRefillTime}
                        </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Celo Actual
                    </div>
                      <div className='box-campo'>
                            {petHealthEntry?.inHeat}
                        </div>
                  </div>
                </div>
                {petHealthEntry?.inHeat.toString() !== 'NA'
                  ? (<>
                      <div className='row g-3'>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Último Celo
                          </div>
                          <div className='box-campo'>
                            {petHealthEntry?.uInHeat ? moment.utc(petHealthEntry.uInHeat).format('DD/MM/YYYY') : ''}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Gestación Actual
                          </div>
                          <div className='box-campo'>
                            {petHealthEntry?.currentGestation}
                          </div>
                        </div>
                      </div>
                      <div className='row g-3'>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Partos Previos
                          </div>
                          <div className='box-campo'>
                            {petHealthEntry?.pastBirths}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Anticonceptivos
                          </div>
                          <div className='box-campo'>
                            {petHealthEntry?.contraceptives}
                          </div>
                        </div>
                      </div>
                      </>
                    ) : (
                      <div className='row g-3 long-info'>
                        <div className='form-label texto-campo' style={{ paddingLeft: '0px' }}>
                          Anticonceptivos
                        </div>
                        <div className='box-campo'>
                          {petHealthEntry?.contraceptives}
                        </div>
                      </div>
                    )
                }
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Enfermedades Previas
                    </div>
                    <div className='box-campo long-info'>
                      <ul>
                        {petHealthEntry?.previousDeseases.length > 0 ? (
                          petHealthEntry?.previousDeseases.map((item, index) => (
                            <li key={index}>{item !== 'Otra' ? item : petHealthEntry.otherPreviousDeseases}</li>
                          ))
                        ) : (
                          <p>No hay elementos que mostrar.</p>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='row g-3'>
                  {((pet?.species === 'Gato') || (pet?.species === 'Perro')) && (
                    <div className='col'>
                      <div className='form-label texto-campo'>Dermograma</div>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {pet.species === 'Gato' && (
                          <div className='imagen-contenedor'>
                            <img src={dermogramaFelino} alt="Dermograma de un gato" className="img-thumbnail-med long-info imagen-base"/>
                            {petHealthEntry?.petChartPlaces.length > 0 && (
                              petHealthEntry?.petChartPlaces.map((item, index) => (
                                <img src={require(`../../Assets/Imagenes/Dermograma/Felino/${item}.png`)} alt={`Gato ${index + 1}`} className='img-thumbnail-med long-info imagen-overlay'/>
                              ))
                            )}
                          </div>
                        )}
                        {pet.species === 'Perro' && (
                          <div className='imagen-contenedor'>
                            <img src={dermogramaCanino} alt="Dermograma de un gato" className="img-thumbnail-med long-info imagen-base"/>
                            {petHealthEntry?.petChartPlaces.length > 0 && (
                              petHealthEntry?.petChartPlaces.map((item, index) => (
                                <img src={require(`../../Assets/Imagenes/Dermograma/Canino/${item}.png`)} alt={`Perro ${index + 1}`} className='img-thumbnail-med long-info imagen-overlay'/>
                              ))
                            )}
                          </div>
                        )}
                      </div>
                      <div className='spacer-12'></div>
                      <div className='form-label texto-campo'>¿Dónde se encuentra la lesión?</div>
                      <div className='box-campo long-info' style={{ marginLeft: '23px' }}>
                        <ul>
                          {petHealthEntry?.petChartPlaces.length > 0 ? (
                            petHealthEntry?.petChartPlaces.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))
                          ) : (
                            <p>No hay elementos que mostrar.</p>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', padding: '20px', flexDirection: 'column', marginTop: '0px' }}>
                    <div className='row g-3'>
                      <div className='col'>
                      <div className='form-label texto-campo'>
                        Primera vez que asiste
                      </div>
                        {petHealthEntry?.firstTime && petHealthEntry?.firstTime.toString() === 'true' &&
                            <div className='box-campo'>
                              Sí
                            </div>
                        }
                        {petHealthEntry?.firstTime && petHealthEntry?.firstTime.toString() === 'false' &&
                            <div className='box-campo'>
                              No
                            </div>
                        }
                        {!petHealthEntry.firstTime &&
                          <div className='box-campo'>
                          No responde
                        </div>
                        }
                      </div>
                      <div className='col'>
                        <div className='form-label texto-campo'>
                          Fecha Control:
                        </div>
                        <div className='box-campo'>
                        {moment.utc(petHealthEntry.controlDate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='spacer-20'></div>
                <div className='med-record'>
                  <div className='row g-3'>
                        <div className='form-label texto-campo'>
                          Motivo de Consulta
                        </div>
                        <div className='box-campo-final'>
                          {petHealthEntry?.visitReason}
                        </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Anamnesis
                    </div>
                    <div className='box-campo-final'>
                      {petHealthEntry?.anamnesis}
                    </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Antecedentes Clínicos
                    </div>
                    <div className='box-campo-final'>
                      {petHealthEntry?.clinicalBackground}
                    </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Observaciones
                    </div>
                    <div className='box-campo-final'>
                      {petHealthEntry?.observations}
                    </div>
                  </div>
                  {props.action.user.credenciales.id === petHealthEntry?.vetId &&
                  <>
                    <div className='spacer-20'></div>
                    <div className='row g-3'>
                      <div className='form-label texto-campo'>
                        Prediagnóstico
                      </div>
                      <div className='box-campo-final'>
                        {petHealthEntry?.initialEv}
                      </div>
                    </div>
                  </>
                  }
                  <div className='spacer-20'></div>
                  <div className='form-label texto-campo'>
                    Solicitud de Exámenes Complementarios
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='hemograma' checked={examChecks.hemograma} className='exam-check'/>
                    <h6>Hemograma completo</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='perfil_bioquimico' checked={examChecks.perfil_bioquimico} className='exam-check'/>
                    <h6>Perfil bioquímico completo</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='hem_pb' checked={examChecks.hem_pb} className='exam-check'/>
                    <h6>Hemograma + Perfil bioquímico</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='hem_pb_t4' checked={examChecks.hem_pb_t4} className='exam-check'/>
                    <h6>Hemograma + Perfil bioquímico + T4</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='hem_pb_t4_tsh' checked={examChecks.hem_pb_t4_tsh} className='exam-check'/>
                    <h6>Hemograma + Perfil bioquímico + T4 + TSH</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='prequirurgico_basico' checked={examChecks.prequirurgico_basico} className='exam-check'/>
                    <h6>Pre quirúrgico básico (Hemograma + Nus + Crea + Alt + Ast)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='prequirurgico_completo' checked={examChecks.prequirurgico_completo} className='exam-check'/>
                    <h6>Pre quirúrgico completo (Perfil + Hemograma + TP + TTPA + PLQ)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='protombina' checked={examChecks.protombina} className='exam-check'/>
                    <h6>Tiempo de protrombina (PT)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='tromboplastina' checked={examChecks.tromboplastina} className='exam-check'/>
                    <h6>Tiempo de tromboplastina parcial act. (TTPA)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='panel_coagulacion' checked={examChecks.panel_coagulacion} className='exam-check'/>
                    <h6>Panel coagulación (TP + TTPA + Plaquetas)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='coagulacion_infecc' checked={examChecks.coagulacion_infecc} className='exam-check'/>
                    <h6>Coagulación infecc (TP + TTPA + Plaquetas + Erlichia)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='perfil_renal' checked={examChecks.perfil_renal} className='exam-check'/>
                    <h6>Perfil renal</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='perfil_hepatico' checked={examChecks.perfil_hepatico} className='exam-check'/>
                    <h6>Perfil hepático</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='perfil_lipidico' checked={examChecks.perfil_lipidico} className='exam-check'/>
                    <h6>Perfil lipídico</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='perfil_lipidico_t4_tsh' checked={examChecks.perfil_lipidico_t4_tsh} className='exam-check'/>
                    <h6>Perfil lipídico + T4 + TSH</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='t4_tsh' checked={examChecks.t4_tsh} className='exam-check'/>
                    <h6>T4 + TSH</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='felv_fiv' checked={examChecks.felv_fiv} className='exam-check'/>
                    <h6>FELV + FIV (Elisa o Inmunocromatográfica)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='parvovirus_distemper' checked={examChecks.parvovirus_distemper} className='exam-check'/>
                    <h6>Parvovirus ag + Distemper AG (Inmunocromatográfica)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='brucelosis' checked={examChecks.brucelosis} className='exam-check'/>
                    <h6>Brucelosis canina AC (Inmunocromatográfica)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='distemper' checked={examChecks.distemper} className='exam-check'/>
                    <h6>Distemper AG (Inmunocromatografía)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='leucemia_felina' checked={examChecks.leucemia_felina} className='exam-check'/>
                    <h6>Leucemia felina (FELV) AG (Elisa)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='inmunodeficiencia_felina' checked={examChecks.inmunodeficiencia_felina} className='exam-check'/>
                    <h6>Inmunodeficiencia felina (FIV) AC (Inmunocromatografía)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='ehrlichia_anaplasma' checked={examChecks.ehrlichia_anaplasma} className='exam-check'/>
                    <h6>Ehrlichia-anaplasma (Inmunocromatografía o Elisa)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='parvovirus' checked={examChecks.parvovirus} className='exam-check'/>
                    <h6>Parvovirus AG (Inmunocromatografía)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='toxoplasma' checked={examChecks.toxoplasma} className='exam-check'/>
                    <h6>Toxoplasma (Inmunocromatografía)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='cultivo_antibiograma_citologico_oidos' checked={examChecks.cultivo_antibiograma_citologico_oidos} className='exam-check'/>
                    <h6>Cultivo y antibiograma + Citológico oídos</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='directo_piel_cultivo_dermatofitos' checked={examChecks.directo_piel_cultivo_dermatofitos} className='exam-check'/>
                    <h6>Directo de piel más cultivo dermatofitos</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='directo_piel_frotis_malassesia_cultivo_dermatofitos' checked={examChecks.directo_piel_frotis_malassesia_cultivo_dermatofitos} className='exam-check'/>
                    <h6>Directo piel + Frotis malassesia + Cultivo dermatofitos</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='urianalisis' checked={examChecks.urianalisis} className='exam-check'/>
                    <h6>Urianálisis</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='urocultivo_antibiograma' checked={examChecks.urocultivo_antibiograma} className='exam-check'/>
                    <h6>Urocultivo + Antibiograma</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='coproparasitario' checked={examChecks.coproparasitario} className='exam-check'/>
                    <h6>Coproparasitario</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='coprocultivo' checked={examChecks.coprocultivo} className='exam-check'/>
                    <h6>Coprocultivo</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='cultivo_antibiograma' checked={examChecks.cultivo_antibiograma} className='exam-check'/>
                    <h6>Cultivo + Antibiograma (Torula Stuart)</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='directo_piel_pelos' checked={examChecks.directo_piel_pelos} className='exam-check'/>
                    <h6>Directo de piel y pelos</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='radiografia' checked={examChecks.radiografia} className='exam-check'/>
                    <h6>Radiografía</h6>
                  </div>
                  <div className='check-label'>
                    <input type='checkbox' name='ecotomografia' checked={examChecks.ecotomografia} className='exam-check'/>
                    <h6>Ecotomografía</h6>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Indicaciones
                    </div>
                    <div className='box-campo-final'>
                      {petHealthEntry?.indications}
                    </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Archivos
                    </div>
                    {petHealthEntry?.files !== undefined &&
                      petHealthEntry?.files.map((file, index) => {
                        return (
                          <a key={index} onClick={() => fetchPdf(file.fileUrl)} className='link-terms'> Descargar {file.name} </a>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='buttons-med'>
            {openDeleteForm === true &&
              <div className='delete-perfil-form-container'>
                <DeleteFichaForm deleteFichaForm={deletePetHealthEntryForm} deleteFicha={deletePetHealthEntry}/>
              </div>
              }
                <button onClick={() => volver()} className='button-crear-ficha'>Volver</button>
              { props.action.user.credenciales.rol === 2 && props.action.user.credenciales.id === petHealthEntry?.vetId &&
              <>
              <button onClick={() => editar()} className='button-crear-ficha'>Editar</button>
              {props.action.user.credenciales.id === petHealthEntry?.vetId &&
                <button onClick={deletePetHealthEntryForm} className='button-crear-ficha'>
                  Eliminar
                </button>
              }
              </>
              }
            </div>
          </div>
        </body>
        <Footer/>
      </>
    );
  } else {
    return (<>
    </>);
  }
};
export default connect(mapStateToProps)(PetHealthShow);
