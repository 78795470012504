import React, { useEffect, useState, SyntheticEvent } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../Navbar/index';
import { MdVerified } from 'react-icons/md';
import { Rating } from 'react-simple-star-rating';
import { connect } from 'react-redux';
import { TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import { PerfilTypesToken } from '../../Types/PerfilTypes';
import { FcLikePlaceholder, FcLike } from 'react-icons/fc';
import serviceImg from '../../Assets/Imagenes/blank-service-picture.png';
import Footer from '../Footer';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import wabutton from '../../Assets/Imagenes/wa_esp.png';
import { hotelType } from '../../Types/HotelTypes';
import VerifiedInfo from './VerifiedInfo';
// import HireHotelForm from '../Servicios/formHireHotel';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function HotelShow(props: PerfilTypesToken) {
  const [hotel, setHotel] = useState<hotelType>({
    name: '',
    email: '',
    address: '',
    addressLat: 0,
    addressLng: 0,
    addressRegion: '',
    addressComuna: '',
    description: '',
    id: '',
    link: '',
    phone: '',
    profileUrlImg: '',
    siiGiro: '',
    verified: -1,
    score: 0,
    userId: '',
    verified_status: '',
    hotelName: '',
    hotelAddressComuna: '',
    hotelAddressRegion: '',
    platformCat: '',
    platformSubCat: []
  });
  const [canRate, setcanRate] = useState(true);
  const { id } = useParams();
  const [coment, setComment] = useState('');
  const [coments, setcoments] = useState<Array<any>>([]);
  const [like, setlike] = useState(false);
  const [likes, setlikes] = useState(0);
  const [num, setnum] = useState('');
  const [whatsApp, setWhatsApp] = useState<string>('');
  const [hoverVerified, setHoverVerified] = useState(false);
  const [openMap, setOpenMap] = useState<boolean>(false);
  // const [hotelService, setHotelService] = useState(false);
  const [profileImg, setprofileImg] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [addressComuna, setAddressComuna] = useState<string>('');
  const [addressRegion, setAddressRegion] = useState<string>('');
  const [description, setdescription] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [addressLat, setAddressLat] = useState<number>(0);
  const [addressLng, setAddressLng] = useState<number>(0);
  const [hotelId, setHotelId] = useState<string>('');
  const [verified, setVerified] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [rating, setRating] = useState<number>(0);
  const [toggleVerifiedInfo, setToggleVerifiedInfo] = useState<boolean>(false);

  async function fetchHotel() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/hotels/' + id + '/', { headers: { Authorization: 'Bearer ' + token } })
      .then(res => {
        console.log(res.data);
        fetchCommentsHotel(res.data.hotel.id);
        GetLikesHotel(res.data.hotel.id);
        AvailableToLike(res.data.hotel.id);
        setprofileImg(res.data.hotel.IsHotel[0].profileUrlImg);
        setHotelId(res.data.hotel.id);
        setAddressComuna(res.data.hotel.IsHotel[0].addressComuna);
        setAddressRegion(res.data.hotel.IsHotel[0].addressRegion);
        setdescription(res.data.hotel.IsHotel[0].description);
        setAddressLat(res.data.hotel.IsHotel[0].addressLat);
        setAddressLng(res.data.hotel.IsHotel[0].addressLng);
        setAddress(res.data.hotel.IsHotel[0].address);
        setcanRate(res.data.available);
        setRating(res.data.hotel.IsHotel[0].score);
        setEmail(res.data.hotel.email);
        setName(res.data.hotel.IsHotel[0].name);
        setVerified(res.data.hotel.ishotel);
        setHotel(res.data.hotel.IsHotel[0]);

        const numarray = res.data.hotel.phone.split('');
        const num = '(' + numarray.slice(0, 4).join('') + ')' + ' ' + numarray.slice(4, 8).join('') + ' ' + numarray.slice(7, 11).join('');
        if (res.data.hotel.phone.slice(0, 4) === '+569') {
          setWhatsApp(res.data.hotel.phone.slice(1));
        } else {
          setWhatsApp('');
        }
        setnum(num);
      });
  }

  async function rateHotel(rate: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/hotelReviews/' + hotelId + '/', {
      userId: props.action.user.credenciales.id,
      score: rate
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  useEffect(() => {
    fetchHotel();
  }, []);

  function handleRating(rate: number) {
    setRating(rate);
    setcanRate(false);
    rateHotel(rate);
  }

  function handleComment(e: any) {
    setComment(e.target.value);
  }

  function AvailableToLike(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/likehotel/available/' + id, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      setlike(!res.data.available);
    });
  }

  function handleCommentSubmit() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/commentHotel/addcomment/' + hotelId, {
      content: coment
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      window.location.reload();
    }
    );
  }

  function deleteComment(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + '/commentHotel/deletecomment/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      window.location.reload();
    }
    );
  }

  function handleLikeSumit() {
    if (!like) {
      const token = JSON.parse(localStorage.getItem('token') || '');
      axios.post(process.env.REACT_APP_URL + '/likeHotel/like/' + hotelId, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        setlikes(response.data.totalLikes);
        setlike(!like);
        console.log(response.data.totalLikes);
      }
      );
    } else {
      const token = JSON.parse(localStorage.getItem('token') || '');
      axios.delete(process.env.REACT_APP_URL + '/likeHotel/unlike/' + hotelId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        if (response.data.newTotalLikes) {
          setlikes(response.data.newTotalLikes);
        } else {
          setlikes(0);
        }
        setlike(!like);
        console.log(response.data);
        console.log(response.data.newTotalLikes);
      }
      );
    }
  }

  function GetLikesHotel(id: any) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/likeHotel/getlikes/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      setlikes(response.data.totalLikes);
    });
  }

  function fetchCommentsHotel(id: any) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/commentHotel/' + id, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      console.log(response.data.comments);
      setcoments(response.data.comments.map((com: any) => ({ name: com.user.name, value: com.userId, label: com.content, id: com.id, userId: com.userId })));
    }
    );
  }

  function ShowVerifiedInfo (event: SyntheticEvent) {
    event.preventDefault();
    setToggleVerifiedInfo(!toggleVerifiedInfo);
  }

  return (
    <div className="wrapper">
      <link rel="stylesheet" href="path/to/font-awesome/css/font-awesome.min.css"></link>
      <Navbar logged={true} />
      <div className='spacer32'></div>
      <h6 className='navigation-info-post-post ms-3'>Hotels / {name}</h6>
      <div className='spacer32'></div>
      <div className='container-perfil' style={{ flexGrow: 1 }}>
        <div className='col-auto' style={{ width: '100%' }}>
          <div className='row' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignContent: 'center', margin: '0px' }}>
            <div className='col-auto'>
              <div className='row'>
                <div className='col-auto'>
                  <div className='Box-foto'>
                    <img src={profileImg || serviceImg} alt='Image of a hotel' style={{ borderRadius: '10px', backgroundImage: 'none', width: '15rem' }} />
                  </div>
                  <div className='spacer20'></div>
                  <div style={{ display: 'flex' }}>
                    <h4> {likes} likes</h4>
                    <div style={{ marginLeft: '150px' }}>
                      {like ? <FcLike size={35} onClick={handleLikeSumit}></FcLike> : <FcLikePlaceholder size={35} onClick={handleLikeSumit}></FcLikePlaceholder>}
                    </div>
                  </div>
                </div>
                <div className='col-auto' style={{ marginLeft: '58px', padding: '0px' }}>
                  <div className='row'>
                    {verified === 'YES'
                      ? <div style={{ position: 'relative' }} className='col-auto texto-nombre'>
                        {name}
                        <MdVerified
                          onMouseOver={() => setHoverVerified(true)}
                          onMouseOut={() => setHoverVerified(false)}
                          onClick={e => ShowVerifiedInfo(e)}
                          style={{ fill: '#C75100', cursor: 'pointer', marginLeft: '8px' }}
                        />
                        {hoverVerified &&
                          <span style={{
                            position: 'absolute',
                            backgroundColor: '#C75100',
                            border: 'solid',
                            borderColor: '#C75100',
                            color: 'white',
                            padding: '5%',
                            fontSize: '16px',
                            borderRadius: '5px',
                            bottom: '60px',
                            width: '600px',
                            right: '-350px',
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                          }}>
                            <table className='table-verified'>
                            <tbody>
                            <h4><tr><b>Hotel verificado:</b></tr></h4>
                            <tr> Haz click para conocer con qué cumple este hotel</tr>
                            </tbody>
                            </table>
                          </span>
                        }
                      </div>
                      : <div className='col-auto texto-nombre'>
                        {name}
                      </div>
                    }

                    {
                      whatsApp !== '' &&
                      <div className='box-whatsapp'>
                        <a href={'https://wa.me/' + whatsApp + '?text=Hola!%20Quiero%20consultar%20por%20su%20anuncio%20de%20hotel%20en%20Mappcota.'}>
                          <img alt="Chat on WhatsApp" src={wabutton} />
                        </a>
                      </div>
                    }
                  </div>
                  <div className='row' >
                    <div className='col-auto texto-mail'>
                      {email}
                    </div>
                  </div>
                  <div className='row' style={{ marginTop: '20px' }} >
                    <div className='col-auto' style={{ marginRight: '58px' }}>
                      {canRate && <Rating
                        onClick={handleRating}
                        allowFraction={true}
                        fillIcon={null}
                        showTooltip={true}
                        tooltipDefaultText='Califica al hotel '></Rating>
                      }
                    </div>
                    <div className='spacer12'></div>
                    <div className='col-auto texto-mail'> Calificación: {rating.toFixed() + '/5'} ⭐</div>
                  </div>
                  <div className='row' style={{ marginTop: '5px', marginLeft: '5px' }} >
                    <TextField inputProps={{ maxLength: 90 }} id="standard-basic" label="Agregar comentario" variant="standard" color='warning' onChange={handleComment} maxRows='1' />
                    <div className='col-auto' style={{ padding: '0px' }}>
                      <button className="petsitter-hire-button" style={{ marginTop: '10px' }} onClick={handleCommentSubmit}>Enviar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row' style={{ margin: '20px 0px', padding: '0px' }}>
            <div className='col-auto texto-informacion' style={{ padding: '0px' }}>
              Información del Hotel
            </div>
          </div>
          <div className='row' style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px', padding: '0px' }}>
            <div className='col-auto' style={{ paddingRight: '150px' }}>
              <div className='row texto-campo'>
                Teléfono
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {num}
                </div>
              </div>
              <div className='spacer12'></div>
              <div className='col-auto' >
                <div className='spacer12'></div>
                <div className='row texto-campo'>
                  Región
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {addressRegion}
                  </div>
                </div>
                <div className='spacer12'></div>
                <div className='row texto-campo'>
                  Comuna
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {addressComuna}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-auto' style={{ paddingRight: '20px' }}>
            <div className='row texto-campo'>
                Dirección
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {address}
                </div>
              </div>
              <div className='row texto-campo'>
                Descripción
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {description}
                </div>
              </div>
              <div className='spacer12'></div>
              <div className='row texto-campo'>
                Comentarios
              </div>
              <div className='spacer12'></div>
              {coments.length > 0
                ? <div className='texto-form'>
                  {coments.map((comment) => (
                    <div key={comment.id} className='spacer12'>
                      <Card sx={{ maxHeight: 80 }}>
                        <CardContent>
                          <Typography component="div">
                            {comment.label}
                            {comment.userId === props.action.user.credenciales.id && <button onClick={() => deleteComment(comment.id)} style={{ color: 'white', borderRadius: '10px', backgroundColor: '#00BBC7', marginLeft: '10px', fontWeight: 'normal' }}> Eliminar </button>}
                          </Typography>
                          <Typography color="text.secondary">
                            <small>
                              {comment.name}
                            </small>
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
                </div>
                : <Typography>
                  <h6><WarningAmberRoundedIcon color="warning" style= {{ transform: 'scale(1.75) translateY(-2px) translateX(-6px)' }} />No hay comentarios aún</h6>
                </Typography>}
            </div>
          </div>
        </div>
        {}
        <div style={{ margin: 'auto', width: 'fit-content' }}>
            <button className="map-btn" onClick={() => setOpenMap(!openMap)}>{openMap ? 'Cerrar Mapa' : 'Abrir Mapa'}</button>
          </div>
          {openMap &&
            <div style={{ height: '100vh', width: '90%', margin: 'auto', marginBottom: '3vh' }}>
              <GoogleMaps coords={[{ lat: addressLat || 0, lng: addressLng || 0 }]} services={[hotel]} />
            </div>
          }
        <div style={{ margin: '1rem' }}><Link to='/hoteles' className='link-back-veterinarians-show'>Volver</Link></div>
      </div>
      {toggleVerifiedInfo &&
        <div className='register-form-container'>
        <div className='register-form-content'>
          <VerifiedInfo closePopUpVerification={ShowVerifiedInfo} />
        </div>
      </div>

      }
      <Footer />
    </div>
  );
};
export default connect(mapStateToProps)(HotelShow);
