import React, { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import { VerificationHotelTypes } from '../../Types/HotelTypes';
import CloseIcon from '@mui/icons-material/Close';
import './hotelVerification.css';
import { CircularProgress } from '@mui/material';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function VerificationHotel(props: VerificationHotelTypes) {
  const [loading, setLoading] = useState<boolean>(false);
  const [formChecks, setFormChecks] = useState({
    patente_comercial: false,
    inicio_actividades: false,
    entrega_boleta: false,
    ley_21020: false,
    ley_20380: false,
    contrato_trabajo: false,
    reglamento_copropiedad: false,
    adherido_mutual: false
  });

  async function submitForm(event: SyntheticEvent) {
    event.preventDefault();
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/hotels/verification/', {
      hotelId: props.action.user.credenciales.id,
      ...formChecks
    }, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        swal({
          title: 'Solicitud de verificación creada con éxito',
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 409) {
          swal({
            title: 'Error',
            text: 'El servicio ya tiene una solicitud activa.',
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        } else {
          swal({
            title: 'Error',
            text: String(error.response.data.message),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        }
      })
      .finally(() => {
        setLoading(false); // Desactivar la pantalla de carga
      });
  }

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setFormChecks(prevChecks => {
      const newChecks = {
        ...prevChecks,
        [name]: checked
      };
      return newChecks;
    });
  };

  if (loading) {
    return (<div className="loading-label"><CircularProgress /></div>); // Mostrar el indicador de carga mientras loading es true
  }
  return (
  <>
      <form className='registerForm' onSubmit={async (e) => await submitForm(e)}>
  <ul className='nav nav-pills justify-content-center nav-fill'>
    <button className='closeForm' onClick={props.closePopUpVerification}><CloseIcon/></button>
  </ul>
  <div className='register-title-div'>
    <h1 className='register-title'>¡Solicita tu verificación como hotel!</h1>
  </div>
  <div className='register-subtitle-div'>
    <p>Marca todas las opciones con las que cumpla tu establecimiento.</p>
  </div>
  <div className='fieldsets-hotel'>
    <fieldset className='fieldset-div-checks'>
      <div className='form-checks'>
        <fieldset className='check-label'>
          <input type='checkbox' name='patente_comercial' checked={formChecks.patente_comercial} onChange={handleCheckChange} />
          <p>Patente Comercial</p>
        </fieldset>
        <fieldset className='check-label'>
          <input type='checkbox' name='inicio_actividades' checked={formChecks.inicio_actividades} onChange={handleCheckChange} />
          <p>Inicio de Actividades</p>
        </fieldset>
        <fieldset className='check-label'>
          <input type='checkbox' name='entrega_boleta' checked={formChecks.entrega_boleta} onChange={handleCheckChange} />
          <p>Entrega de Boleta</p>
        </fieldset>
        <fieldset className='check-label'>
          <input type='checkbox' name='ley_21020' checked={formChecks.ley_21020} onChange={handleCheckChange} />
          <p>Cumplimiento de la Ley 21.020</p>
        </fieldset>
        <fieldset className='check-label'>
          <input type='checkbox' name='ley_20380' checked={formChecks.ley_20380} onChange={handleCheckChange} />
          <p>Cumplimiento de la Ley 20.380</p>
        </fieldset>
        <fieldset className='check-label'>
          <input type='checkbox' name='contrato_trabajo' checked={formChecks.contrato_trabajo} onChange={handleCheckChange} />
          <p>Contrato de Trabajo</p>
        </fieldset>
        <fieldset className='check-label'>
          <input type='checkbox' name='reglamento_copropiedad' checked={formChecks.reglamento_copropiedad} onChange={handleCheckChange} />
          <p>Reglamento de Copropiedad</p>
        </fieldset>
        <fieldset className='check-label'>
          <input type='checkbox' name='adherido_mutual' checked={formChecks.adherido_mutual} onChange={handleCheckChange} />
          <p>Adherido a una mutualidad</p>
        </fieldset>
      </div>
    </fieldset>
  </div>
  <div className='register-buttons-hotel'>
      <button id='create-account' className='register-form-button' value="submit">Solicitar Verificación</button>
    </div>
</form>
  </>
  );
}

export default connect(mapStateToProps)(VerificationHotel);
