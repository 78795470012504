import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import Navbar from '../Navbar';
import RegionesYcomunas from '../RegisterForm/regionesComunas';
import { PerfilTypes } from '../../Types/PerfilTypes';
import Footer from '../Footer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function mapStateToProps (state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function EditHotel (props: PerfilTypes) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [region, setRegion] = useState('');
  const [commune, setCommune] = useState('');
  const [address, setAddress] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const [comunas, setComunas] = useState<Array<string>>([]);

  const navigate = useNavigate();

  async function getDefaultData() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/hotels/' + props.action.user.credenciales.id, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setName(response.data.hotel.IsHotel[0].name);
        setDescription(response.data.hotel.IsHotel[0].description);
        setRegion(response.data.hotel.IsHotel[0].addressRegion);
        setAddress(response.data.hotel.IsHotel[0].address);
        Object.values(RegionesYcomunas)[0].forEach(reg => {
          if (reg.NombreRegion === response.data.hotel.IsHotel[0].addressRegion) {
            setComunas(reg.comunas);
          }
        });
        setCommune(response.data.hotel.IsHotel[0].addressComuna);
        setImageUrl(response.data.hotel.IsHotel[0].profileUrlImg);
      });
  }

  function handleRegion(event: SyntheticEvent) {
    event.preventDefault();
    const currentRegion = (event.target as HTMLInputElement).value;
    setRegion(currentRegion);
    Object.values(RegionesYcomunas)[0].forEach(reg => {
      if (reg.NombreRegion === currentRegion) {
        setComunas(reg.comunas);
        setCommune(reg.comunas[0]);
      }
    });
  };

  async function volver () {
    if (props.action.user.credenciales.isvet === 'NO') {
      navigate('/perfil/' + props.action.user.credenciales.id);
    } else {
      navigate('/perfilVet/' + props.action.user.credenciales.id);
    }
  };

  async function deleteHotel(event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.delete(process.env.REACT_APP_URL + '/hotels/' + props.action.user.credenciales.id, { headers: { Authorization: 'Bearer ' + token } });
    if (props.action.user.credenciales.isvet === 'NO') {
      navigate('/perfil/' + props.action.user.credenciales.id);
    } else {
      navigate('/perfilVet/' + props.action.user.credenciales.id);
    }
  }

  async function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.patch(process.env.REACT_APP_URL + '/hotels/' + props.action.user.credenciales.id, { name, description, addressRegion: region, addressComuna: commune, profileUrlImg: imageUrl, address }, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        if (props.action.user.credenciales.isvet === 'NO') {
          navigate('/perfil/' + props.action.user.credenciales.id);
        } else {
          navigate('/perfilVet/' + props.action.user.credenciales.id);
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  function convertToBase64(file: any) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  async function onFileChange(event: any) {
    event.preventDefault();
    const convertedFile = await convertToBase64(event.target.files[0]);
    setImageUrl(String(convertedFile));
  };

  useEffect(() => {
    getDefaultData();
  }, []);

  return (
    <>
      <Navbar logged={true}/>
      <div className='form-container' style={{ height: '90vh' }}>
        <div className='title'>Editar Hotel</div>
        <form onSubmit={handleSubmit}>
          <div className='field-container'>
            <input className='register-form-input' name="name" onChange={(e) => setName(e.target.value)} value={name} required/>
            <label className="edit-profile-label" htmlFor="name">Nombre del Servicio</label>
          </div>
          <div className='field-container'>
            <input className='register-form-input' name="description" onChange={(e) => setDescription(e.target.value)} value={description} />
            <label className="edit-profile-label" htmlFor="description">Descripción</label>
          </div>
          <div className='field-container'>
            <select className="box-campo" id="region" name="region" value={region} onChange={(e) => handleRegion(e)} required>
              <option value="" defaultValue="" disabled hidden></option>
              {Object.values(RegionesYcomunas)[0].map((region) => {
                return <option key={region.NombreRegion}>{region.NombreRegion}</option>;
              })}
            </select>
            <label className="edit-profile-label" htmlFor="region">Región</label>
          </div>
          <div className='field-container'>
            <select className="box-campo" id="commune" name="commune" value={commune} onChange={(e) => setCommune(e.target.value)} required>
              <option value="" defaultValue="" disabled hidden></option>
              {comunas.map((comuna) => (<option key={comuna}>{comuna}</option>))}
            </select>
            <label className="edit-profile-label" htmlFor="commune">Comuna</label>
          </div>
          <div className='field-container'>
            <input className='register-form-input' id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
            <label className="edit-profile-label" htmlFor="address">Dirección</label>
          </div>
          <p style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            <label htmlFor="profileUrlImg" className="upload-label">
                              Cambiar foto
                              <input
                                className="register-form-input"
                                type="file"
                                onChange={onFileChange}
                                name="profileUrlImg"
                                id="profileUrlImg"
                              />
                              <CloudUploadIcon className="upload-icon" />
                            </label>
                          </p>

          <div className='upload-button-container'>
            <button className="save-button" onClick={volver}> Volver </button>
            <button className="save-button" value="submit"> Guardar </button>
            <button className="delete-button" onClick={deleteHotel}> Eliminar </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default connect(mapStateToProps)(EditHotel);
