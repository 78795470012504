import React from 'react';
import { connect } from 'react-redux';
import { PerfilTypes } from '../../Types/PerfilTypes';
import CloseIcon from '@mui/icons-material/Close';
import './index.css';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function Interest(props: PerfilTypes) {
  return ((props.action.user.credenciales && props.action.user.credenciales.rol === 2) ? (
  <>
      <form className='pages-popupvet'>
        <ul className='nav nav-pills justify-content-center nav-fill'>
          <button className='closeForm' onClick={props.closePopUpVerification}><CloseIcon/></button>
        </ul>
        <div className='register-title-div'>
          <h1 className='register-title'>Páginas que podrían interesarte</h1>
        </div>
        <div className='spacer12'></div>
        <div></div>
        <div className='box-interest'>
          <a className='interest-button' href='http://www.registratumascota.cl' style={{ fontSize: '18px' }}>Registro Nacional de Tenencia Responsable de Mascotas y Animales de Compañía</a>
          <a className='interest-button' href='https://colmevet.cl/' style={{ fontSize: '18px' }}>Colegio Médico Veterinario de Chile</a>
          <a className='interest-button' href='https://www.avma.org/' style={{ fontSize: '18px' }}>American Veterinary Medical Association</a>
          <a className='interest-button' href='https://www.sag.gob.cl/' style={{ fontSize: '18px' }}>Servicio Agrícola y Ganadero</a>
          <a className='interest-button' href='https://www.veterinaryradiology.net/' style={{ fontSize: '18px' }}>Veterinary Radiology</a>
          <a className='interest-button' href='https://wsava.org/' style={{ fontSize: '18px' }}>World Small Animal Veterinary Association</a>
          <a className='interest-button' href='https://www.worldanimalprotection.org/' style={{ fontSize: '18px' }}>World Animal Protection</a>
          <a className='interest-button' href='https://www.woah.org/es/inicio/' style={{ fontSize: '18px' }}>Organización Mundial de Sanidad Animal</a>
          <a className='interest-button' href='https://www.aabp.org/' style={{ fontSize: '18px' }}>American Association of Bovine Practitioners</a>
          <a className='interest-button' href='https://www.cdc.gov/spanish/index.html' style={{ fontSize: '18px' }}>Centros para el Control y la Prevención de Enfermedades</a>
        </div>
      </form>
  </>) : (
  <>
  <form className='pages-popup'>
        <ul className='nav nav-pills justify-content-center nav-fill'>
          <button className='closeForm' onClick={props.closePopUpVerification}><CloseIcon/></button>
        </ul>
        <div className='register-title-div'>
          <h1 className='register-title'>Páginas que podrían interesarte</h1>
        </div>
        <div className='spacer12'></div>
        <div></div>
        <div className='box-interest'>
          <a className='interest-button' href='http://www.registratumascota.cl' style={{ fontSize: '18px' }}>Registro Nacional de Tenencia Responsable de Mascotas y Animales de Compañía</a>
          <a className='interest-button' href='https://www.mestizos.cl/' style={{ fontSize: '18px' }}>Mestizos Magazine</a>
          <a className='interest-button' href='https://registratumascota.cl/preguntas_frecuentes.xhtml' style={{ fontSize: '18px' }}>Preguntas frecuentes sobre la Ley 21.020 de Tenencia Responsable</a>
        </div>
      </form>
  </>
  )
  );
}

export default connect(mapStateToProps)(Interest);
