import { useNavigate } from 'react-router-dom';
import Navbar from './Components/Navbar';
import LandingPage from './pages/LandingPage/index';
import Footer from './Components/Footer';
import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App(props) {
  const navigate = useNavigate();
  return (
    <div className="App">
      <div className="wrapper">
        <Navbar {...props} navigate={navigate} />
        <br></br>
        <br></br>
        <LandingPage />
      </div>
      <Footer {...props} />
    </div>
  );
}

export default App;
