import React from 'react';
import { InlineWidget } from 'react-calendly';
import { useLocation } from 'react-router-dom';

function Calendar () {
  const location = useLocation();
  const { urlCalendly } = location.state;
  return (
    <div>
      <InlineWidget url={urlCalendly} />
    </div>
  );
};

export default Calendar;
