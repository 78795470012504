import React, { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import './registerPetsitterForm.css';
import { useNavigate, useParams } from 'react-router-dom';
import RegionesYcomunas from '../RegisterForm/regionesComunas';
import swal from 'sweetalert';
import { dataRegisterServType } from '../../Types/RequestServType';
import UploadButton from '../Common/UploadButton';
import CloseIcon from '@mui/icons-material/Close';

type propFunctionTypes = {
  togglePetsitterRegister: React.MouseEventHandler,
  closePetsitterOpenRegister: React.MouseEventHandler,
  closePetsitterOpenLogIn: React.MouseEventHandler,
  toggleSignUpUser: React.MouseEventHandler
}

function RegisterPetsitterForm(props: propFunctionTypes) {
  const [comunas, setComunas] = useState<Array<string>>([]);
  const [datos, setDatos] = useState<dataRegisterServType>({
    name: '',
    description: '',
    address: '',
    addressComuna: '',
    addressRegion: '',
    platformCat: '',
    platformSubCat: [],
    phone: '',
    siiGiro: '',
    link: '',
    profileUrlImg: ''
  });
  const navigate = useNavigate();

  function redirect() {
    navigate(0);
  }

  const { id } = useParams();

  // Ahora puedes utilizar el ID del servicio en tu componente
  console.log('El ID del servicio es:', id);

  async function submitForm(e: SyntheticEvent) {
    e.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/create/petsitter', datos, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        redirect();
      })
      .catch((error) => {
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      }
      );
  };

  function handleComunas(event: SyntheticEvent) {
    event.preventDefault();
    const currentRegion = (event.target as HTMLInputElement).value;
    setDatos({
      ...datos,
      addressRegion: currentRegion
    });
    Object.values(RegionesYcomunas)[0].forEach(region => {
      if (region.NombreRegion === currentRegion) {
        setComunas(region.comunas);
      }
    });
  };

  function handleInputChange(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    if (target.name === 'siiGiro') {
      setDatos({
        ...datos
      });
    } else {
      setDatos({
        ...datos,
        [target.name]: target.value
      });
    }
  }

  const handleFileUpload = (base64: string) => {
    setDatos({
      ...datos,
      profileUrlImg: base64
    });
  };

  return (
    <div>
      <form className='registerForm' onSubmit={(e: SyntheticEvent) => { submitForm(e); }}>
        <ul className="nav nav-pills justify-content-center nav-fill">
          <button className='closeForm' onClick={props.toggleSignUpUser}><CloseIcon /></button>
        </ul>
        <div className='register-title-div'>
          <h1 className='register-title-service' >¡Registrate como petsitter!</h1>
        </div>
        <div className='fieldsets'>
          <fieldset className='fieldset-div'>
            <p>
              <input id="name" placeholder=" " type="text" value={datos.name} name="name" onChange={(e) => handleInputChange(e)} className="register-form-input" required />
              <label htmlFor="name">Nombre del Servicio</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <input id="description" placeholder=" " type="text" value={datos.description} name="description" onChange={(e) => handleInputChange(e)} className="register-form-input" />
              <label htmlFor="description">Descripción</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <select placeholder="Región" id="addressRegion" name="addressRegion" className="register-selector form-select" value={datos.addressRegion} onChange={e => handleComunas(e)} required>
                <option value="" defaultValue="" disabled hidden></option>
                {Object.values(RegionesYcomunas)[0].map((region) => {
                  return <option key={region.NombreRegion}>{region.NombreRegion}</option>;
                })}
              </select>
              <label htmlFor="addressRegion">Región</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <select
                id="addressComuna"
                name="addressComuna"
                className="register-selector form-select"
                value={datos.addressComuna}
                onChange={(e) => handleInputChange(e)}
                required
              >
                <option value="" defaultValue="" disabled hidden></option>
                {comunas.map((comuna) => (<option key={comuna}>{comuna}</option>))}
              </select>
              <label htmlFor="addressComuna">Comuna</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <UploadButton
              onFileUpload={handleFileUpload}
              labelName='Imagen'
            />
          </fieldset>
        </div>
        <div className='register-buttons'>
          <button value="submit" id='create-account' className='register-form-button'>Registrarme</button>
        </div>
      </form>
    </div>
  );
};

RegisterPetsitterForm.propTypes = {
  togglePetsitterRegister: PropTypes.func,
  closePetsitterOpenRegister: PropTypes.func,
  closePetsitterOpenLogIn: PropTypes.func
};

export default RegisterPetsitterForm;
