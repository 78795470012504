import React from 'react';
import logo from '../../Assets/Imagenes/logo.png';
import AndroidDownload from '../../Assets/Imagenes/google_play.png';
import AppleDownload from '../../Assets/Imagenes/app_store.png';
import './index.css';

class LandingPage extends React.Component {
  render () {
    return (
      <div style={{ backgroundColor: '#f8f8f8', marginTop: '-40px' }}>
      <div className="landing-page bottom-margin">
        <img src={logo} className="logo-banner"/>
        <div className='who-is-mascotalerta'>
            <h1 style={{ color: '#ED9936', fontSize: '2vw', fontWeight: 'bold' }}>Acerca de Mascotalerta</h1><br/>
            <p> Somos una Fundación dedicada a promover la educación
              sobre la teniencia responsable de mascotas. Queremos
              ser un referente que ayude a crear conciencia en la
              población sobre la importancia del cuidado de estos,
              para así contribuir a generar una mejor calidad de vida
              de los animales y personas que nos rodean.
            </p>
        </div>
      </div>
        <div className='what-is-mappcota'>
          <h1 style={{ color: '#00BBC7', fontSize: '2vw', fontWeight: 'bold', textAlign: 'center' }}>¿Qué es Mappcota?</h1><br/>
          <p>
            Mappcota es una herramienta invaluable tanto para los amantes de las mascotas como para los profesionales del cuidado animal,
            proporcionando una plataforma centralizada para la gestión y búsqueda de servicios relacionados con el cuidado de las mascotas.
          </p>
          <p>
            Para usuarios: se puede buscar y encontrar médicos/as veterinarios/as y petsitters disponibles en tu área, a la vez que
            gestionas las fichas médicas de tus mascotas y dejas comentarios sobre los servicios recibidos.
          </p>
          <p>
            Para médicos/as veterinarios/as: la plataforma ofrece herramientas que facilitan la gestión de pacientes y la compartición de información médica.
            Los/las médicos/as veterinarios/as pueden acceder al historial de sus pacientes y colaborar con colegas a través de la aplicación.
          </p>
          <p>
          Para servicios: Mappcota te brinda la oportunidad de promocionar tus servicios y facilitar el contacto con potenciales clientes.
          Los tutores de mascotas pueden encontrar tus servicios de manera rápida y sencilla, y en el caso de servicios de petsitter,
          pueden contactarte directamente para solicitar tus servicios, especificando fechas y cuidados necesarios para su mascota.
          </p>
        </div>
        <div className='landing-page top-margin'>
          <a href="https://play.google.com">
            <img src={AndroidDownload} alt='play store' className="download-icon" />
          </a>
          <a href="https://www.apple.com/cl/app-store/">
            <img src={AppleDownload} alt='app store' className="download-icon" />
          </a>
        </div>
      </div>
    );
  }
};

export default LandingPage;
