import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import './denuncias.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import { Link } from 'react-router-dom';
import axios from 'axios';
// import swal from 'sweetalert';
import Footer from '../Footer';
import avatar from '../../Assets/Imagenes/blank-profile-picture-.png';
import { Link } from 'react-router-dom';

type userType = {
  id: string,
  name: string,
  profileUrlImg: string
}

type denunciaType = {
  createdbyId: string,
  id: string,
  other: string,
  reason: string,
  reportedId: string,
  user2: userType
}

function IndexDenuncias () {
  const [denuncias, setDenuncias] = useState<Array<denunciaType>>([]);
  const [type, setType] = useState('spam');

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/reportUser/', { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        console.log(response.data);
        setDenuncias(response.data.reports);
      });
  }, []);

  function mapReportstoGroups () {
    const arrayLength = 5;
    const finalArray = [];
    for (let i = 0; i < denuncias.length; i += arrayLength) {
      finalArray.push(denuncias.slice(i, i + arrayLength));
    }
    return finalArray;
  }

  return (
        <div>
            <div className='wrapper'>
                <Navbar logged={true}/>
                <div className='verifications-index-body'>
          <h6 className='navigation-info-post-post ms-3'>Denuncias / Todas</h6>
          <div className='row margin-row'>
          {type === 'spam' &&
          <>
          <button className="den-index-title-color" onClick={() => setType('spam')}><h4>Spam</h4></button>
          <button className="ver-index-title" onClick={() => setType('innapropiate')}><h4>Contenido inapropiado</h4></button>
          <button className="ver-index-title" onClick={() => setType('other')}><h4>Otras</h4></button>
          </>
          }
          {type === 'innapropiate' &&
          <>
          <button className="ver-index-title" onClick={() => setType('spam')}><h4>Spam</h4></button>
          <button className="den-index-title-color" onClick={() => setType('innapropiate')}><h4>Contenido inapropiado</h4></button>
          <button className="ver-index-title" onClick={() => setType('other')}><h4>Otras</h4></button>
          </>
          }
          {type === 'other' &&
          <>
          <button className="ver-index-title" onClick={() => setType('spam')}><h4>Spam</h4></button>
          <button className="ver-index-title" onClick={() => setType('innapropiate')}><h4>Contenido inapropiado</h4></button>
          <button className="den-index-title-color" onClick={() => setType('other')}><h4>Otras</h4></button>
          </>
          }
          </div>
          <hr/>

          {type === 'spam' && (
            mapReportstoGroups().map((reportGroup, index) => (
              <div key={index} className='card-row card-group'>
                  {reportGroup.map((req) => {
                    if (req.reason === 'SPAM') {
                      return (
                          <div key={req.id} className="card border h-100 mb-3" style={{ width: '20rem', borderRadius: '10px', maxWidth: '18%', padding: '10px', borderStyle: 'inset', borderLeftWidth: '1px', minHeight: '40vh' }}>
                              <img src={req.user2.profileUrlImg || avatar} className="card-img-top"/>
                              <div className="card-body">
                                  <h5 className="card-title">{req.user2.name}</h5>
                                  <Link to={{
                                    pathname: '/admindenuncias/' + req.id
                                  }}
                                    state={{ request: req }}>
                                    <button className="ver-button">Ver Información</button>
                                  </Link>
                              </div>
                          </div>
                      );
                    }
                    return null;
                  })}
              </div>
            ))
          )}

          {type === 'innapropiate' && (
            mapReportstoGroups().map((reportGroup, index) => (
            <div key={index} className='card-row card-group'>
                {reportGroup.map((req) => {
                  if (req.reason === 'INAPROPRIATE') {
                    return (
                        <div key={req.id} className="card border h-100 mb-3" style={{ width: '20rem', borderRadius: '10px', maxWidth: '18%', padding: '10px', borderStyle: 'inset', borderLeftWidth: '1px', minHeight: '40vh' }}>
                            <img src={req.user2.profileUrlImg || avatar} className="card-img-top"/>
                            <div className="card-body">
                                <h5 className="card-title">{req.user2.name}</h5>
                                <Link to={{
                                  pathname: '/admindenuncias/' + req.id
                                }}
                                  state={{ request: req }}>
                                  <button className="ver-button">Ver Información</button>
                                </Link>
                            </div>
                        </div>
                    );
                  }
                  return null;
                })}
            </div>
            ))
          )}
          {type === 'other' && (
            mapReportstoGroups().map((reportGroup, index) => (
            <div key={index} className='card-row card-group'>
                {reportGroup.map((req) => {
                  if (req.reason === 'OTHER') {
                    return (
                        <div key={req.id} className="card border h-100 mb-3" style={{ width: '20rem', borderRadius: '10px', maxWidth: '18%', padding: '10px', borderStyle: 'inset', borderLeftWidth: '1px', minHeight: '40vh' }}>
                            <img src={req.user2.profileUrlImg || avatar} className="card-img-top"/>
                            <div className="card-body">
                                <h5 className="card-title">{req.user2.name}</h5>
                                <Link to={{
                                  pathname: '/admindenuncias/' + req.id
                                }}
                                  state={{ request: req }}>
                                  <button className="ver-button">Ver Información</button>
                                </Link>
                            </div>
                        </div>
                    );
                  }
                  return null;
                })}
            </div>
            ))
          )}
        </div>
            </div>
            <Footer/>
        </div>
  );
}

export default IndexDenuncias;
