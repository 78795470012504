import React, { useState, ReactElement, FC, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import './index.css';
import { propsType, petParams, reduxAction } from '../../Types/petType';
import perrito from '../../Assets/Imagenes/perrito.svg';
import { RazasPerros, RazasGatos } from './especiesRazas';
import swal from 'sweetalert';
import { petColors } from '../../const/petsColors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function mapStateToProps (state : reduxAction) {
  return { action: state };
}
const PetEdit : FC <propsType> = (props): ReactElement => {
  const [petParams, setPetParams] = useState <petParams>();
  const [raceList, setRaceList] = useState <Array<string>>([]);
  const [microchipId, setMicrochipId] = useState('');
  const token = props.action.user.credenciales.token;
  const fetchPetParams = async () => {
    await axios.get(process.env.REACT_APP_URL + '/pets/' + props.location.state.state, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        const pet = res.data.pet;
        setPetParams(pet);
        if (pet.microchipId) {
          const microchipArray = pet.microchipId.split('');
          setNumberCode(microchipArray);
          setMicrochipId(pet.microchipId);
        }

        if (pet.species === 'Perro') {
          setRaceList(RazasPerros);
        } else if (pet.species === 'Gato') {
          setRaceList(RazasGatos);
        } else {
          setRaceList([]);
        }
      })
      .catch((error) => {
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      });
  };
  useEffect(() => {
    fetchPetParams();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPetParams((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const dropdownChangedHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setPetParams((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangebirthdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;
    setPetParams((prevState: any) => ({
      ...prevState,
      birthdate: value
    }));
  };

  const handleRace = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const value = e.target.value;

    if (value === 'Perro') {
      setPetParams((prevState: any) => ({
        ...prevState,
        species: value
      }));
      setRaceList(RazasPerros);
    } else if (value === 'Gato') {
      setPetParams((prevState: any) => ({
        ...prevState,
        species: value
      }));
      setRaceList(RazasGatos);
    } else {
      setPetParams((prevState: any) => ({
        ...prevState,
        species: value
      }));
      setRaceList([]);
    }
  };

  const [numberCode, setNumberCode] = useState(Array(15).fill(''));
  const handleMicrochip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.split('-')[2], 10);

    if (value.length > 1) {
      const pastedValues = value.split('').slice(0, 15 - index);
      const newNumberCode = [...numberCode];

      for (let i = 0; i < pastedValues.length; i++) {
        if (parseInt(pastedValues[i]) >= 0 && parseInt(pastedValues[i]) <= 9) {
          newNumberCode[index + i] = pastedValues[i];
        }
      }

      setNumberCode(newNumberCode);
      setMicrochipId(newNumberCode.join(''));

      const nextIndex = index + pastedValues.length;
      if (nextIndex < 15) {
        const nextInput = document.querySelector(`input[name='number-code-${nextIndex}']`) as HTMLInputElement | null;
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else {
      if (parseInt(value) >= 0 && parseInt(value) <= 9) {
        const newNumberCode = [...numberCode];
        newNumberCode[index] = value;
        setNumberCode(newNumberCode);
        setMicrochipId(newNumberCode.join(''));

        if (index < 14) {
          const nextInput = document.querySelector(`input[name='number-code-${index + 1}']`) as HTMLInputElement | null;
          if (nextInput) {
            nextInput.focus();
          }
        }
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      const newNumberCode = [...numberCode];
      newNumberCode[index] = '';
      setNumberCode(newNumberCode);
      setMicrochipId(newNumberCode.join(''));

      if (index > 0) {
        const input = document.querySelector(`input[name='number-code-${index - 1}']`) as HTMLInputElement | null;
        if (input) {
          input.focus();
        }
      }
    } else if (e.key === 'ArrowRight' && index < 14) {
      const input = document.querySelector(`input[name='number-code-${index + 1}']`) as HTMLInputElement | null;
      if (input) {
        input.focus();
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      const input = document.querySelector(`input[name='number-code-${index - 1}']`) as HTMLInputElement | null;
      if (input) {
        input.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const pastedData = e.clipboardData.getData('Text');
    const newNumberCode = [...numberCode];

    for (let i = 0; i < pastedData.length && index + i < 15; i++) {
      if (parseInt(pastedData[i]) >= 0 && parseInt(pastedData[i]) <= 9) {
        newNumberCode[index + i] = pastedData[i];
      }
    }

    setNumberCode(newNumberCode);
    setMicrochipId(newNumberCode.join(''));

    const nextIndex = index + pastedData.length;
    if (nextIndex < 15) {
      const nextInput = document.querySelector(`input[name='number-code-${nextIndex}']`) as HTMLInputElement | null;
      if (nextInput) {
        nextInput.focus();
      }
    }

    e.preventDefault();
  };

  function convertToBase64 (file: any) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  async function onFileChange (event: any) {
    event.preventDefault();
    const convertedFile = await convertToBase64(event.target.files[0]);
    setPetParams((prevState: any) => ({
      ...prevState,
      profileUrlImg: String(convertedFile)
    }));
  };

  function editar (props : propsType, petParams : petParams | undefined) {
    const token = props.action.user.credenciales.token;
    const patchPetParams = async () => {
      if (microchipId.length !== 0 && microchipId.length !== 15) {
        swal({
          title: 'Error',
          text: 'El microchip debe estar completamente rellenado o completamente en blanco.',
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
        return;
      }

      await axios.patch(process.env.REACT_APP_URL + '/pets/' + props.location.state.state, {
        microchipId,
        name: petParams?.name,
        species: petParams?.species,
        gender: petParams?.gender,
        birthdate: petParams?.birthdate,
        race: petParams?.race,
        color: petParams?.color,
        pattern: petParams?.pattern,
        reproductiveState: petParams?.reproductiveState,
        profileUrlImg: petParams?.profileUrlImg
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(() => {
          redirect(props);
        });
    };
    patchPetParams();
  }

  const redirect = (props : propsType) => {
    const path = '/pets/showAll';
    props.navigate(path, { state: { show: false } });
  };

  let options = null;
  if (raceList) {
    options = raceList.map((el) => ({ value: el, label: el }));
  }
  if (petParams !== undefined) {
    return (
<>
  <body>
    <div className='spacer32'></div>
    <div className='container-perfil' >
      <div className='col-auto' style={{ width: '100%' }}>
        <div className='row' style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', margin: '0px' }}>
          <div className='col-auto'>
            <div className='row'>
              <div className='col-auto'>
                <img src={petParams?.profileUrlImg !== '' ? petParams?.profileUrlImg : perrito}
                className="img-thumbnail"
                style={petParams?.profileUrlImg !== '' ? { margin: '40px 20px', height: '54vh', padding: '20px' }
                  : { border: '16px solid black', borderRadius: '20px', margin: '40px 20px', height: '54vh', padding: '20px' }} />
                <div className='spacer-12'></div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <label htmlFor="profileUrlImg" className="upload-label">
                              Cambiar foto
                              <input
                                className="register-form-input"
                                type="file"
                                onChange={onFileChange}
                                name="profileUrlImg"
                                id="profileUrlImg"
                              />
                              <CloudUploadIcon className="upload-icon" />
                            </label>
                          </div>
              </div>
              <div className='col-auto' style={{ marginLeft: '58px', padding: '0px' }}>
                <div className='row texto-campo'>Nombre</div>
                <div className='spacer12'></div>
                  <input className='texto-nombre-perfil'
                  name="name"
                  onChange={handleChange}
                  value={petParams?.name}/>
                  <div className='spacer12'></div>
                <div className='row texto-campo'>Microchip</div>
                <div className='spacer12'></div>
                <fieldset
                  name='number-code'
                  data-number-code-form
                  style={{ margin: 0, marginInlineStart: '0' }}
                >
                  <div className='microchip-container'>
                    {numberCode.map((value, index) => (
                      <input
                        key={index}
                        className='microchip'
                        type='number'
                        min='0'
                        max='9'
                        name={`number-code-${index}`}
                        data-number-code-input={index}
                        value={value}
                        onChange={(e) => handleMicrochip(e)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={(e) => handlePaste(e, index)}
                        maxLength={1}
                      />
                    ))}
                  </div>
                </fieldset>
                <div className='spacer20'></div>
                <div className='row texto-campo'>Especie</div>
                <div className='spacer12'></div>
                <select name="species" className="box-campo" value={petParams?.species} onChange={(inputText) => handleRace(inputText)} required>
                  <option value="" selected disabled hidden>Selecciona aquí</option>
                  <option value="Perro">Perro</option>
                  <option value="Gato">Gato</option>
                  <option value="Otro">Otro</option>
                </select>
                <div className='spacer12'></div>
                {raceList.length !== 0 && options &&
                      <>
                        <div className='row texto-campo'>
                        Raza
                        </div>
                        <div className='spacer12'></div>
                        <select name="race" className='box-campo-race'
                          value={petParams.race}
                          onChange={(inputText) => dropdownChangedHandler(inputText)} required>
                        <option value="" selected disabled hidden>Selecciona aquí</option>
                          {raceList.map((race) => (
                            <option value={race}> {race}</option>
                          ))}
                        </select>
                      </>
                      }
                <div className='spacer12'></div>
                <div className='row texto-campo'>Fecha de Nacimiento</div>
                <div className='spacer12'></div>
                <input type="date" name="birthdate"
                  className="box-campo-date" value={petParams?.birthdate.substring(0, 10)}
                  onChange={handleChangebirthdate} />
                <div className='spacer12'></div>
                <div className='row texto-campo'>Sexo</div>
                <div className='spacer12'></div>
                <select name="gender" className="box-campo"
                  value={petParams?.gender} onChange={dropdownChangedHandler} >
                  <option value="MALE">Macho</option>
                  <option value="FEMALE">Hembra</option>
                </select>
                <div className='spacer12'></div>
                <div className='row texto-campo'>Color</div>
                <div className='spacer12'></div>
                <select name="color" className="box-campo" value={petParams?.color} onChange={dropdownChangedHandler} required>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        {petColors.map((color) => (
                          <option value={color}>{color}</option>
                        ))}
                      </select>
                <div className='spacer12'></div>
                <div className='row texto-campo'>Patrón</div>
                <div className='spacer12'></div>
                <select name="pattern" className="box-campo" value={petParams?.pattern}
                  onChange={(inputText) => dropdownChangedHandler(inputText)} required>
                  <option value="" selected disabled hidden>Selecciona aquí</option>
                  <option value="Ninguno">Ninguno</option>
                  <option value="Bandas o Franjas">Bandas o Franjas</option>
                  <option value="Jaspeado">Jaspeado</option>
                  <option value="Manchas / Parches">Manchas / Parches</option>
                  <option value="Puntas de otro color">Puntas de otro color</option>
                  <option value="Rayas / Atigrado">Rayas / Atigrado</option>
                  <option value="Sombrero / Leonado">Sombrero / Leonado</option>
                </select>
                <div className='spacer12'></div>
                <div className='row texto-campo'>Estado Reproductivo</div>
                <div className='spacer12'></div>
                <select name="reproductiveState" className="box-campo" value={petParams?.reproductiveState} onChange={dropdownChangedHandler} required>
                  <option value="" selected disabled hidden>Selecciona aquí</option>
                  <option value="Entero">Entero/a</option>
                  <option value="Esterilizado">Esterilizado/a</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style={{ display: 'flex', margin: '20px', justifyContent: 'center' }}>
      <button className='save-button'
        onClick={() => redirect(props)}
        style={{ height: '60px', width: '160px', marginRight: '60px' }}>Volver</button>
      <button className='save-button' value="submit"
        onClick={() => editar(props, petParams)}
        style={{ height: '60px', width: '160px', marginLeft: '60px' }}>Guardar</button>
    </div>
  </body>
</>
    );
  } else {
    return (
    <></>
    );
  }
};
export default connect(mapStateToProps)(PetEdit);
