import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import './index.css';
import AddMicrochip from './addMicrochip';
import { connect } from 'react-redux';
import { reduxAction } from '../../Types/petType';
import CloseIcon from '@mui/icons-material/Close';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function PopUpPetShow ({ state, isPetDangerous, petId } : {state:boolean; isPetDangerous:boolean; petId: string}) {
  const [modal, setModal] = useState(state);
  const closeModal = () => {
    setModal(false);
  };

  const bodyPopUp = (
    <div className="Pop-up">
      <div className="title-pop-up">
        <h1 className="alert-text-pop-up">¡ALERTA! </h1>
        <button className='close-pop-up' onClick = { () => closeModal()}><CloseIcon /></button>
      </div>
        {isPetDangerous
          ? <div>
              <div className="subtitle-pop-up">
                <h3>Mascota declarada como Potencialmente Peligrosa. </h3>
                <h3>Tu mascota no tiene microchip</h3>
              </div>
              <h5>De acuerdo con la Ley 21.020: Tenencia Responsable de Mascotas y Animales de Compañía,
              tienes 15 días para inscribir a tu mascota desde su adquisición en el Registro Nacional de Mascotas </h5>
            </div>
          : <div>
              <div className="subtitle-pop-up">
                <h3>Tu mascota no tiene microchip</h3>
              </div>
              <h5>De acuerdo con la Ley 21.020: Tenencia Responsable de Mascotas y Animales de Compañía,
                tienes 90 días para inscribir a tu mascota desde su adquisición en el Registro Nacional de Mascotas </h5>
            </div>
      }
      <AddMicrochip petId = {petId}/>
    </div>
  );

  return (
    <div className="PopUp">
      <Modal open={modal} onClose={closeModal} disableEscapeKeyDown={true} disableBackdropClick={true}>
          {bodyPopUp}
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps)(PopUpPetShow);
