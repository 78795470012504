import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { PerfilTypes } from '../../Types/PerfilTypes';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function ShowVerifiedInfo(props: PerfilTypes) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [patent, setPatent] = useState(false);
  const [start, setStart] = useState(false);
  const [bill, setBill] = useState(false);
  const [law21020, setLaw21020] = useState(false);
  const [law20380, setLaw20380] = useState(false);
  const [contract, setContract] = useState(false);
  const [regulation, setRegulation] = useState(false);
  const [mutual, setMutual] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/hotels/' + id + '/', { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        console.log(response.data);
        setName(response.data.hotel.IsHotel[0].name);
        setDescription(response.data.hotel.IsHotel[0].description);
        setPatent(response.data.verified_hotel.patente_comercial);
        setStart(response.data.verified_hotel.inicio_actividades);
        setBill(response.data.verified_hotel.entrega_boleta);
        setLaw21020(response.data.verified_hotel.ley_21020);
        setLaw20380(response.data.verified_hotel.ley_20380);
        setContract(response.data.verified_hotel.contrato_trabajo);
        setRegulation(response.data.verified_hotel.reglamento_copropiedad);
        setMutual(response.data.verified_hotel.adherido_mutual);
      });
  }, []);

  function isDocumented (document: boolean) {
    if (document) {
      return 'Cumple con el requisito ✔';
    }
    return 'No cumple con el requisito ❌';
  };

  return (
    <>
    <div className='info-container'>
        <ul className='nav nav-pills justify-content-center nav-fill'>
          <button className='closeForm' onClick={props.closePopUpVerification}><CloseIcon/></button>
        </ul>
          <div className='form-hotel-container'>
          <div className='form-ver-hotel'>
            <div className='col-auto'>
              <div className='spacer12'></div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Nombre del Hotel
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {name}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Descripción
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {description}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Patente Comercial
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(patent)}
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Inicio de Actividades
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(start)}
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Entrega de Boleta
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(bill)}
              </div>
            </div>
            <div className='col-auto'>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Ley 21.020
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(law21020)}
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Ley 20.380
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(law20380)}
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Contrato de Trabajo
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(contract)}
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Reglamento de Copropiedad
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(regulation)}
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Adherido a una mutualidad
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                {isDocumented(mutual)}
              </div>
            </div>
          </div>
          </div>
        </div>
    </>
  );
}

export default connect(mapStateToProps)(ShowVerifiedInfo);
