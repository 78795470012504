import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

interface SelectableListProps {
  items: string[];
  checked: string[];
  handleToggle: (value: string) => () => void;
}

const SelectableFilterList: React.FC<SelectableListProps> = ({ items, checked, handleToggle }) => {
  const [showAll, setShowAll] = useState(false);
  const itemsToShow = showAll ? items.length : 5;

  return (
    <div>
      <Box
        sx={{
          maxHeight: '360px',
          overflowY: 'auto'
        }}
      >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {items.slice(0, itemsToShow).map((value, index) => {
            const labelId = `checkbox-list-label-${index}`;
            return (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={handleToggle(value)} dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      color = "secondary"
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
      {items.length > 5 && (
        <Button onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Ver menos' : 'Ver más'}
        </Button>
      )}
    </div>
  );
};

export default React.memo(SelectableFilterList);
