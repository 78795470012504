export const petColors = [
  'AMARILLO',
  'AMARILLO-BLANCO',
  'AMARILLO-CAFÉ',
  'AMARILLO-CAFÉ-ROJO',
  'AMARILLO-ROJO',
  'AZUL',
  'BLANCO',
  'BLANCO-AMARILLO',
  'BLANCO-AMARILLO-CAFÉ',
  'BLANCO-AMARILLO-ROJO',
  'BLANCO-CAFÉ',
  'BLANCO-CAFÉ-ROJO',
  'BLANCO-CREMA',
  'BLANCO-CREMA-AMARILLO',
  'BLANCO-CREMA-CAFÉ',
  'BLANCO-CREMA-ROJO',
  'BLANCO-GRIS',
  'BLANCO-GRIS-AMARILLO',
  'BLANCO-GRIS-CAFÉ',
  'BLANCO-GRIS-CREMA',
  'BLANCO-GRIS-ROJO',
  'BLANCO-NARANJA',
  'BLANCO-NEGRO',
  'BLANCO-NEGRO-AMARILLO',
  'BLANCO-NEGRO-CAFÉ',
  'BLANCO-NEGRO-CREMA',
  'BLANCO-NEGRO-GRIS',
  'BLANCO-NEGRO-ROJO',
  'BLANCO-ROJO',
  'CAFÉ',
  'CAFÉ-BLANCO',
  'CAFÉ-ROJO',
  'CREMA',
  'CREMA-AMARILLO',
  'CREMA-AMARILLO-CAFÉ',
  'CREMA-AMARILLO-ROJO',
  'CREMA-CAFÉ',
  'CREMA-CAFÉ-ROJO',
  'CREMA-ROJO',
  'GRIS',
  'GRIS-AMARILLO',
  'GRIS-AMARILLO-CAFÉ',
  'GRIS-AMARILLO-ROJO',
  'GRIS-CAFÉ',
  'GRIS-CAFÉ-ROJO',
  'GRIS-CREMA',
  'GRIS-CREMA-AMARILLO',
  'GRIS-CREMA-CAFÉ',
  'GRIS-CREMA-ROJO',
  'GRIS-ROJO',
  'NEGRO',
  'NEGRO-AMARILLO',
  'NEGRO-AMARILLO-CAFÉ',
  'NEGRO-AMARILLO-ROJO',
  'NEGRO-CAFÉ',
  'NEGRO-CREMA',
  'NEGRO-CREMA-AMARILLO',
  'NEGRO-CREMA-CAFÉ',
  'NEGRO-CREMA-ROJO',
  'NEGRO-GRIS',
  'NEGRO-GRIS-AMARILLO',
  'NEGRO-GRIS-CAFÉ',
  'NEGRO-GRIS-CREMA',
  'NEGRO-GRIS-ROJO',
  'NEGRO-ROJO',
  'ROJO'
];
