import React, { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import '../RegisterForm/index.css';
import './registerServiceForm.css';
import { useNavigate, useParams } from 'react-router-dom';
import RegionesYcomunas from '../RegisterForm/regionesComunas';
import swal from 'sweetalert';
import { dataRegisterServType } from '../../Types/RequestServType';
import { productsAndServices } from '../../const/productsAndServices';
import UploadButton from '../Common/UploadButton';
import CloseIcon from '@mui/icons-material/Close';

type propFunctionTypes = {
  toggleServiceRegister: React.MouseEventHandler,
  closeServiceOpenRegister: React.MouseEventHandler,
  closeServiceOpenLogIn: React.MouseEventHandler,
  toggleSignUpUser: React.MouseEventHandler
}

const SUBCATEGORIES_NUMBER: number = 20;
function RegisterServiceForm(props: propFunctionTypes) {
  const productsCategories = Object.values(productsAndServices.products).map((product) => product.category);
  const servicesCategories = Object.values(productsAndServices.services).map((service) => service.category);
  const allCategories = [...new Set([...productsCategories, ...servicesCategories])].sort();
  const [comunas, setComunas] = useState<Array<string>>([]);
  const [badPhoneFormat, setBadPhoneFormat] = useState<boolean>();

  const [numSubCats, setNumSubCats] = useState<number>(1);
  const [datos, setDatos] = useState<dataRegisterServType>({
    name: '',
    description: '',
    address: '',
    addressComuna: '',
    addressRegion: '',
    platformCat: '',
    platformSubCat: [],
    phone: '',
    siiGiro: '',
    link: '',
    profileUrlImg: ''
  });
  const [subCategories, setSubCategories] = useState<Array<string>>([]);
  const navigate = useNavigate();

  function redirect() {
    navigate(0);
  }

  const { id } = useParams();

  // Ahora puedes utilizar el ID del servicio en tu componente
  console.log('El ID del servicio es:', id);

  async function submitForm(e: SyntheticEvent) {
    e.preventDefault();
    const phoneRegex = /^(\+56)\d{9}$/;
    if (phoneRegex.test(datos.phone)) {
      const token = JSON.parse(localStorage.getItem('token') || '');
      await axios.post(process.env.REACT_APP_URL + '/create/service', datos, { headers: { Authorization: 'Bearer ' + token } })
        .then(() => {
          redirect();
        })
        .catch((error) => {
          swal({
            title: 'Error',
            text: String(error.response.data.message),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        }
        );
    }
    if (!phoneRegex.test(datos.phone)) {
      setBadPhoneFormat(true);
    } else {
      setBadPhoneFormat(false);
    }
  };

  function handleComunas(event: SyntheticEvent) {
    event.preventDefault();
    const currentRegion = (event.target as HTMLInputElement).value;
    setDatos({
      ...datos,
      addressRegion: currentRegion
    });
    Object.values(RegionesYcomunas)[0].forEach(region => {
      if (region.NombreRegion === currentRegion) {
        setComunas(region.comunas);
      }
    });
  };

  function handleInputChange(e: SyntheticEvent) {
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    if (target.name === 'siiGiro') {
      setDatos({
        ...datos
      });
    } else {
      setDatos({
        ...datos,
        [target.name]: target.value
      });
    }
    if (target.name === 'platformCat') {
      const selectedCategory = productsAndServices.products.find(
        (product) => product.category === target.value
      ) || productsAndServices.services.find(
        (service) => service.category === target.value
      );

      if (selectedCategory) {
        setSubCategories(selectedCategory.subCategories);
      } else {
        setSubCategories([]);
      }
    }
  }

  function handleNumSubCatsChange(e: React.ChangeEvent<{ value: unknown }>) {
    setNumSubCats(Number(e.target.value));
    setDatos({
      ...datos,
      platformSubCat: Array(Number(e.target.value)).fill('')
    });
  }

  function handleSubCatChange(index: number, value: string) {
    setDatos({
      ...datos,
      platformSubCat: datos.platformSubCat.map((v, i) => (i === index ? value : v))
    });
  }

  const handleFileUpload = (base64: string) => {
    setDatos({
      ...datos,
      profileUrlImg: base64
    });
  };

  return (
    <div>
      <form className='registerForm' onSubmit={(e: SyntheticEvent) => { submitForm(e); }}>
        <ul className="nav nav-pills justify-content-center nav-fill">
          <button className='closeForm' onClick={props.toggleSignUpUser}><CloseIcon /></button>
        </ul>
        <div className='register-title-div'>
          <h1 className='register-title-service' >¡Registra tu Servicio!</h1>
        </div>
        <div className='fieldsets'>
          <fieldset className='fieldset-div'>
            <p>
              <input id="name" placeholder=" " type="text" value={datos.name} name="name" onChange={(e) => handleInputChange(e)} className="register-form-input" required />
              <label htmlFor="name">Nombre del Servicio</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <input id="description" placeholder=" " type="text" value={datos.description} name="description" onChange={(e) => handleInputChange(e)} className="register-form-input" />
              <label htmlFor="description">Descripción</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <select placeholder="Región" id="addressRegion" name="addressRegion" className="register-selector form-select" value={datos.addressRegion} onChange={e => handleComunas(e)} required>
                <option value="" defaultValue="" disabled hidden></option>
                {Object.values(RegionesYcomunas)[0].map((region) => {
                  return <option key={region.NombreRegion}>{region.NombreRegion}</option>;
                })}
              </select>
              <label htmlFor="addressRegion">Región</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <select
                id="addressComuna"
                name="addressComuna"
                className="register-selector form-select"
                value={datos.addressComuna}
                onChange={(e) => handleInputChange(e)}
                required
              >
                <option value="" defaultValue="" disabled hidden></option>
                {comunas.map((comuna) => (<option key={comuna}>{comuna}</option>))}
              </select>
              <label htmlFor="addressComuna">Comuna</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <input id="address" name="address" className="register-form-input" required placeholder=" " onChange={(e) => handleInputChange(e)}></input>
              <label htmlFor='address'>Dirección</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <select
                id="platformCat"
                name="platformCat"
                className="register-selector form-select"
                value={datos.platformCat}
                onChange={(e) => handleInputChange(e)}
                required
              >
                <option value="" defaultValue="" disabled hidden></option>
                {allCategories.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </select>
              <label htmlFor='platformCat'>Categoría</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <p>
              <select
                id="numSubCats"
                name="numSubCats"
                className="register-selector form-select"
                value={numSubCats}
                onChange={handleNumSubCatsChange}
                required
              >
                {
                  Array.from({ length: SUBCATEGORIES_NUMBER }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>{num}</option>
                  ))
                }

              </select>
              <label htmlFor='numSubCats'>Número de Subcategorías</label>
            </p>
          </fieldset>
          {Array(numSubCats).fill(null).map((_, index) => (
            <fieldset key={index} className='fieldset-div'>
              <p>
                <select
                  id={`platformSubCat${index}`}
                  name={`platformSubCat${index}`}
                  className="register-selector form-select"
                  value={datos.platformSubCat[index]}
                  onChange={(e) => handleSubCatChange(index, e.target.value)}
                  required
                >
                  <option value="" defaultValue="" disabled hidden></option>
                  {subCategories.map((subCategory, subIndex) => (
                    <option key={subIndex} value={subCategory}>{subCategory}</option>
                  ))}
                </select>
                <label htmlFor={`platformSubCat${index}`}>Subcategoría {index + 1}</label>
              </p>
            </fieldset>
          ))}
          <fieldset className='fieldset-div'>
            {badPhoneFormat === true && <p className='formError'>Formato de teléfono inválido.</p>}
            <p>
              <input id="phone" placeholder=" " type="text" name="phone" className="register-form-input" value={datos.phone} onChange={(e) => handleInputChange(e)} required />
              <label htmlFor="phone">Teléfono (+56 y 9 dígitos)</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
          {badPhoneFormat === true && <p className='formError' style={{ color: '#fff' }}>.</p>}
            <p>
              <input id="link" name="link" className="register-form-input" placeholder=" " onChange={(e) => handleInputChange(e)}></input>
              <label htmlFor='link'>Link</label>
            </p>
          </fieldset>
          <fieldset className='fieldset-div'>
            <UploadButton
              onFileUpload={handleFileUpload}
              labelName='Imagen'
            />
          </fieldset>
        </div>
        <div className='register-buttons'>
          <button value="submit" id='create-account' className='register-form-button'>Registrar Servicio</button>
        </div>
      </form>
    </div>
  );
};

RegisterServiceForm.propTypes = {
  toggleServiceRegister: PropTypes.func,
  closeServiceOpenRegister: PropTypes.func,
  closeServiceOpenLogIn: PropTypes.func
};

export default RegisterServiceForm;
