import React, { Component } from 'react';
import { Navigate, Link } from 'react-router-dom';
import perrito from '../../Assets/Imagenes/perrito.svg';
import axios from 'axios';
import './index.css';
import { connect } from 'react-redux';
import { RazasPerros, RazasGatos } from './especiesRazas';
import { petColors } from '../../const/petsColors';
import { petParams, propsType, reduxAction, microchipType } from '../../Types/petType';
import swal from 'sweetalert';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

class PetRegister extends Component <propsType, petParams, microchipType> {
  racelist : Array<string> = [];
  constructor(props: propsType) {
    super(props);
    this.state = {
      identificationType: '',
      microchipId: '',
      name: '',
      species: '',
      gender: '',
      birthdate: '',
      race: '',
      color: '',
      pattern: '',
      reproductiveState: '',
      profileUrlImg: '',
      razonTenencia: '',
      obtencion: '',
      ownershipType: '',
      redirect: false,
      numberCode: Array(15).fill('')
    };
  };

  inputChangedHandler = (event: { target: { name: any; value: any; }; }) => {
    const newstate = { [event.target.name]: event.target.value } as Pick<petParams, keyof petParams>;
    this.setState(
      newstate
    );
  };

  handleRace = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const value = event.target.value;

    if (value === 'Perro') {
      this.setState({
        species: 'Perro'

      });
      this.racelist = RazasPerros;
    } else if (value === 'Gato') {
      this.setState({
        species: 'Gato'

      });
      this.racelist = RazasGatos;
    } else {
      this.setState({
        species: 'Otro'
      });
      this.racelist = ['Otro'];
    }
  };

  dropdownChangedHandler = (event: { target: { name: any; value: any; }; }) => {
    const newstate = { [event.target.name]: event.target.value } as Pick<petParams, keyof petParams>;
    this.setState(
      newstate
    );
  };

  raceDropDownHandler = (inputText : string | undefined) => {
    if (inputText !== undefined) {
      this.setState({ race: inputText });
    }
  };

  handleChangeGender = (event : React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    this.setState({
      gender: event.target.value
    });
  };

  handleMicrochip = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const index = parseInt(name.split('-')[2], 10);
    if (value.length > 1) {
      return; // Evitar más de un dígito en el input
    }
    if (parseInt(value) >= 0 && parseInt(value) <= 9) {
      const newNumberCode = [...this.state.numberCode];
      newNumberCode[index] = value;
      this.setState({ numberCode: newNumberCode, microchipId: newNumberCode.join('') });

      if (index < 14) {
        const nextInput = document.querySelector(`input[name='number-code-${index + 1}']`) as HTMLInputElement | null;
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      const newNumberCode = [...this.state.numberCode];
      newNumberCode[index] = ''; // Borrar el número actual
      this.setState({ numberCode: newNumberCode, microchipId: newNumberCode.join('') });

      if (index > 0) {
        const input = document.querySelector(`input[name='number-code-${index - 1}']`) as HTMLInputElement | null;
        if (input) {
          input.focus();
        }
      }
    } else if (e.key === 'ArrowRight' && index < 14) {
      const input = document.querySelector(`input[name='number-code-${index + 1}']`) as HTMLInputElement | null;
      if (input) {
        input.focus();
      }
    } else if (e.key === 'ArrowLeft' && index > 0) {
      const input = document.querySelector(`input[name='number-code-${index - 1}']`) as HTMLInputElement | null;
      if (input) {
        input.focus();
      }
    }
  };

  handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const pastedData = e.clipboardData.getData('Text');
    if (pastedData.length <= 15 && /^[0-9]+$/.test(pastedData)) {
      const newNumberCode = [...this.state.numberCode];
      for (let i = 0; i < pastedData.length && index + i < 15; i++) {
        newNumberCode[index + i] = pastedData[i];
      }
      this.setState({ numberCode: newNumberCode, microchipId: newNumberCode.join('') });

      const nextIndex = index + pastedData.length;
      if (nextIndex < 15) {
        const nextInput = document.querySelector(`input[name='number-code-${nextIndex}']`) as HTMLInputElement | null;
        if (nextInput) {
          nextInput.focus();
        }
      }

      e.preventDefault();
    }
  };

  convertToBase64 = (file: any) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  onFileChange = async (event: any) => {
    event.preventDefault();
    const convertedFile = await this.convertToBase64(event.target.files[0]);
    this.setState({ profileUrlImg: String(convertedFile) });
  };

  handleSubmit = () => {
    if (this.state.gender === '' || this.state.birthdate === '' || this.state.name === '' || this.state.obtencion === '' || this.state.race === '' || this.state.razonTenencia === '' || this.state.pattern === '' || this.state.reproductiveState === '' || this.state.color === '' || this.state.species === '') {
      swal('Error', 'Todos los campos deben ser rellenados', 'error');
    } else if (this.state.microchipId.length > 0 && this.state.microchipId.length !== 15) {
      swal('Error', 'El microchip debe tener 15 caracteres', 'error');
    } else {
      const token = JSON.parse(localStorage.getItem('token') || '');
      axios.post(process.env.REACT_APP_URL + '/pets/' + this.props.action.user.credenciales.id, {
        microchipId: this.state.microchipId,
        name: this.state.name,
        species: this.state.species,
        gender: this.state.gender,
        birthdate: this.state.birthdate,
        race: this.state.race,
        color: this.state.color,
        pattern: this.state.pattern,
        reproductiveState: this.state.reproductiveState,
        profileUrlImg: this.state.profileUrlImg,
        razonTenencia: this.state.razonTenencia,
        obtencion: this.state.obtencion
      }, { headers: { Authorization: 'Bearer ' + token } })
        .then(() => {
          this.redirect();
          swal({
            title: 'Mascota ingresada con éxito',
            icon: 'success',
            buttons: { Aceptar: true },
            timer: 2000
          });
        })
        .catch((error) => {
          console.log(error);
          swal({
            title: 'Error',
            text: String(error.response.data),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        }
        );
    }
  };

  redirect = () => {
    this.setState({
      redirect: true
    });
  };

  render () {
    let options = null;
    if (this.state.redirect === true) {
      return <Navigate to='/pets/showAll' />;
    }
    if (this.racelist) {
      options = this.racelist.map((el) => ({ value: el, label: el }));
    }
    return (
          <>
            <body>
            <div className='spacer32'></div>
            <h6 className='navigation-info-post-post ms-3'><Link to={'/pets/showAll'} className='link-perfil'>Mascotas</Link> / Ingresar</h6>
              <div className='container-pets' style={{ marginTop: '20px' }}>
                <div className='card card-crear-pet'>
                  <div className='card-body'>
                    <div className='row'>
                    <div className='col-auto' style={{ margin: '0px 10px', display: 'flex', flexDirection: 'column' }}>
                        <img src={this.state.profileUrlImg !== '' ? this.state.profileUrlImg : perrito}
                            style={this.state.profileUrlImg !== '' ? { margin: '40px 20px', height: '54vh', padding: '20px' }
                              : { border: '16px solid black', borderRadius: '20px', margin: '40px 20px', height: '54vh', padding: '20px' }} />                        <div className='spacer-12'></div>
                          <p style={{ display: 'flex', justifyContent: 'center' }}>
                            <label htmlFor="profileUrlImg" className="upload-label">
                              Cambiar foto
                              <input
                                className="register-form-input"
                                type="file"
                                onChange={this.onFileChange}
                                name="profileUrlImg"
                                id="profileUrlImg"
                              />
                              <CloudUploadIcon className="upload-icon" />
                            </label>
                          </p>
                      <div className='spacer-12'></div>
                    </div>
                    <div className='col-auto' style={{ margin: '0px 10px' }}>
                      <div className='row texto-campo'>
                        Nombre
                      </div>
                      <div className='spacer12'></div>
                      <input className='box-campo' type="text" name="name" placeholder="" value={this.state.name} onChange={(inputText) => this.inputChangedHandler(inputText)} required></input>
                      <div className='row texto-campo'>
                        Microchip
                      </div>
                      <div className='spacer12'></div>
                      <fieldset
                        name='number-code'
                        data-number-code-form
                        style={{ margin: 0, marginInlineStart: '0' }}
                      >
                        <div className='microchip-container'>
                          {this.state.numberCode.map((value, index) => (
                            <input
                              key={index}
                              className='microchip'
                              type='number'
                              min='0'
                              max='9'
                              name={`number-code-${index}`}
                              data-number-code-input={index}
                              value={value}
                              onChange={(e) => this.handleMicrochip(e)}
                              onKeyDown={(e) => this.handleKeyDown(e, index)}
                              onPaste={(e) => this.handlePaste(e, index)}
                              maxLength={1}
                            />
                          ))}
                        </div>
                      </fieldset>
                      <div className='spacer20'></div>
                      <div className='row texto-campo'>
                        Especie
                      </div>
                      <div className='spacer12'></div>
                      <select name="species" className="box-campo" value={this.state.species} onChange={(inputText) => this.handleRace(inputText)} required>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        <option value="Perro">Perro</option>
                        <option value="Gato">Gato</option>
                        <option value="Otro">Otro</option>
                      </select>
                      {this.racelist.length !== 0 && options &&
                      <>
                        <div className='row texto-campo'>
                        Raza
                        </div>
                        <div className='spacer12'></div>
                        <select name="race" className='box-campo-race'
                          value={this.state.race}
                          onChange={(inputText) => this.dropdownChangedHandler(inputText)} required>
                        <option value="" selected disabled hidden>Selecciona aquí</option>
                          {this.racelist.map((race) => (
                            <option value={race}> {race}</option>
                          ))}
                        </select>
                      </>
                      }
                      {this.state.species === 'Otro' && this.racelist.length === 0 &&
                      <>
                      <div className='row texto-campo'>
                      Ingrese la Especie
                      </div>
                      <div className='spacer12'></div>
                      <input name="race" type="text" className="box-campo" value={this.state.race} onChange={(inputText) => this.inputChangedHandler(inputText)} required />
                      </>
                      }
                      <div className='row texto-campo'>
                        Sexo
                      </div>
                      <div className='spacer12'></div>
                      <select name="gender" className="box-campo" value={this.state.gender} onChange={(inputText) => this.handleChangeGender(inputText)} required>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        <option value="MALE">Macho</option>
                        <option value="FEMALE">Hembra</option>
                      </select>
                      <div className='row texto-campo'>
                        Fecha de Nacimiento
                      </div>
                      <div className='spacer12'></div>
                      <input className='box-campo' type="date" name="birthdate" placeholder="" value={this.state.birthdate} onChange={(inputText) => this.inputChangedHandler(inputText)} required></input>
                      <div className='row texto-campo'>
                        Color
                      </div>
                      <div className='spacer12'></div>
                      <select name="color" className="box-campo" value={this.state.color} onChange={(inputText) => this.dropdownChangedHandler(inputText)} required>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        {petColors.map((color) => (
                          <option value={color}>{color}</option>
                        ))}
                      </select>
                      <div className='row texto-campo'>
                        Patrón
                      </div>
                      <div className='spacer12'></div>
                      <select name="pattern" className="box-campo" value={this.state.pattern} onChange={(inputText) => this.dropdownChangedHandler(inputText)} required>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        <option value="Ninguno">Ninguno</option>
                        <option value="Bandas o Franjas">Bandas o Franjas</option>
                        <option value="Jaspeado">Jaspeado</option>
                        <option value="Manchas / Parches">Manchas / Parches</option>
                        <option value="Puntas de otro color">Puntas de otro color</option>
                        <option value="Rayas / Atigrado">Rayas / Atigrado</option>
                        <option value="Sombrero / Leonado">Sombrero / Leonado</option>
                      </select>
                      <div className='row texto-campo'>
                        Estado Reproductivo
                      </div>
                      <div className='spacer12'></div>
                      <select name="reproductiveState" className="box-campo" value={this.state.reproductiveState} onChange={(inputText) => this.dropdownChangedHandler(inputText)} required>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        <option value="Entero">Entero/a</option>
                        <option value="Esterilizado">Esterilizado/a</option>
                      </select>
                      <div className='row texto-campo'>
                        Obtención
                      </div>
                      <div className='spacer12'></div>
                      <select name="obtencion" className="box-campo" value={this.state.obtencion} onChange={(inputText) => this.dropdownChangedHandler(inputText)}>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        <option value="Compra">Compra</option>
                        <option value="Adopción">Adopción</option>
                        <option value="Recogido">Recogido</option>
                        <option value="Regalo">Regalo</option>
                        <option value="Nació en Casa">Nació en Casa</option>
                      </select>
                      <div className='row texto-campo'>
                        Razón de Tenencia
                      </div>
                      <div className='spacer12'></div>
                      <select name="razonTenencia" className="box-campo" value={this.state.razonTenencia} onChange={(inputText) => this.dropdownChangedHandler(inputText)}>
                      <option value="" selected disabled hidden>Selecciona aquí</option>
                        <option value="Compañía">Compañía</option>
                        <option value="Asistencia">Asistencia</option>
                        <option value="Terapia">Terapia</option>
                        <option value="Trabajo">Trabajo</option>
                        <option value="Seguridad">Seguridad</option>
                        <option value="Deporte">Deporte</option>
                        <option value="Exposición">Exposición</option>
                        <option value="Reproducción">Reproducción</option>
                        <option value="Caza">Caza</option>
                      </select>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                <div style={{ display: 'flex', margin: '20px', justifyContent: 'center' }}>
                      <button onClick={() => this.redirect()}
                       className='save-button'
                       style={{ height: '60px', width: '160px', marginRight: '60px' }}> Volver </button>
                      <button
                      onClick={() => this.handleSubmit()}
                      className='save-button'
                      style={{ height: '60px', width: '160px', marginLeft: '60px' }}>Ingresar</button>
                      </div>
            </body>
          </>
    );
  }
}
export default connect(mapStateToProps)(PetRegister);
