import React, { useState, useEffect, FC } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './PetsHealth.css';
import vet from '../../Assets/Imagenes/med_vet_m.png';
import { connect } from 'react-redux';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { petHealthHistory, propsType, reduxAction } from '../../Types/petHealthTypes';
import swal from 'sweetalert';
import moment from 'moment';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}
const PetsHealthShowAll : FC <propsType> = (props) : any => {
  const [historial, setHistorial] = useState <petHealthHistory[]>();
  const { id } = useParams();
  const token = props.action.user.credenciales.token;

  async function fetchHistory () {
    axios.get(process.env.REACT_APP_URL + '/petHealthHistory/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setHistorial(response.data.petHealthHistory);
      });
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  function sendAccessRequest () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/petsHistoryAccess/',
      { vetId: props.action.user.credenciales.id, petId: id }, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: 'Solicitud de acceso enviada con éxito',
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        })
          .catch((error) => {
            swal({
              title: 'Error',
              text: String(error.response.data.message),
              icon: 'error',
              buttons: { Aceptar: true },
              timer: 2000
            });
          }
          );
      });
  };

  if (historial !== undefined) {
    return (
            <>
            <div className='wrapper'>
            <Navbar logged={true}/>
            <div className='spacer32'></div>
            <h6 className='navigation-info-post-post ms-3'><Link to={'/pets/showAll'} className='link-perfil'>Mascotas</Link> / Historial Médico</h6>
            <div className='spacer32'></div>
            <div className='container-perfil'>
                <div>
            {historial.length > 0 && historial.map(historial => historial.entries.map((ficha, index) => (
                <div key={ficha.id}>
                <div className='card-pets'>
                    <div className='col-auto'>
                        <div className='Box-foto-ficha'>
                            <img src={vet} alt="new" style={{ borderRadius: '10px', maxWidth: '100px', maxHeight: '100px' }} />
                        </div>
                    </div>
                    <div className='pet-name'> Ficha N° {index + 1} </div>
                    <div className='col-auto' style={{ margin: '10px', alignContent: 'center' }}>
                        <div className='pet-name'>
                            {ficha.controlDate ? moment.utc(ficha.controlDate).format('DD/MM/YYYY') : ''}
                        </div>
                    </div>
                    <div className='col-auto' style={{ justifyContent: 'center', display: 'flex' }} >
                    <div className='Box-ingreso' style={{ justifyContent: 'center', maxWidth: 'max-content' }}>
                            <Link className='nav-link link-crear-ficha' state={{ petId: id }} style={{ color: 'white', display: 'flex', flexDirection: 'column', padding: '8px', fontSize: '18px' }} to={'/petsHealth/show/' + ficha.id}> Detalles </Link>
                    </div>
                </div>
                </div>

            </div>
            )))}
            <div className='spacer-20'></div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {historial[0].entries.length === 0 &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className='card container-fichas-vacio'>
                    <div className='title-pets'>No hay Fichas Médicas Disponibles</div>
                    <div className='imagen-vet'>
                      <img src={vet} alt="new" style={{ borderRadius: '10px', maxWidth: '100px', maxHeight: '100px' }} />
                      <div className='spacer-20'></div>
                    </div>
                </div>
              </div>
              }
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ margin: '10px 130px' }}>
              { props.action.user.credenciales.rol === 2 &&
                <div className='Box-ingreso' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 0' }}>
                <Link className='nav-link link-crear-ficha' state={{ petId: id }} style={{ color: 'white', fontSize: '14px', padding: '8px 16px', alignItems: 'center', display: 'flex', justifyContent: 'center' }} to={'/petsHealth/create/' + id}>
                  Crear Ficha Médica
                </Link>
                </div>
              }
                <div className='Box-ingreso' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 0' }}>
                <Link className='nav-link link-crear-ficha' state={{ petId: id }} style={{ color: 'white', fontSize: '14px', padding: '8px 16px', alignItems: 'center', display: 'flex', justifyContent: 'center' }} to={'/petsHealth/All/' + id}>
                  Mostrar todo
                </Link>
                </div>
              </div>
              </div>
            </div>
        </div>
        </div>
        <Footer />
        </>
    );
  } else if (props.action.user.credenciales.rol === 2) {
    return (
      <>
        <div className='wrapper'>
          <Navbar logged={true}/>
          <div className='container-perfil'>
            <div className='spacer-20'></div>
            <div className='container-fichas-vacio'>
              <div className='title-pets'>
                Actualmente no estás autorizado para acceder el historial médico de la mascota
              </div>
              <div className='imagen-vet'>
                <img src={vet} alt="new" style={{ borderRadius: '10px', maxWidth: '100px', maxHeight: '100px' }} />
              </div>
              <div className='spacer-20'></div>
              <button onClick={sendAccessRequest} className='solicitar-acceso-boton'>Solicitar Acceso</button>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <div className='wrapper'>
          <Navbar logged={true}/>
          <div className='container-perfil'>
            <div className='spacer-20'></div>
            <div className='container-fichas-vacio'>
              <div className='title-pets'>
                Actualmente no estás autorizado para acceder el historial médico de la mascota
              </div>
              <div className='imagen-vet'>
                <img src={vet} alt="new" style={{ borderRadius: '10px', maxWidth: '100px', maxHeight: '100px' }} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default connect(mapStateToProps)(PetsHealthShowAll);
