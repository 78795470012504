import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes, { string } from 'prop-types';
import './servicesAndRequests.css';

type SolicitudesType = {
  id: string,
  close: React.MouseEventHandler,
}

function Solicitudes (props: SolicitudesType) {
  const [requests, setRequests] = useState<any[]>([]);

  async function fetchData() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/petsitters/requests/' + props.id, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setRequests(response.data.allRequests);
      });
  }

  async function acceptRequest(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.patch(process.env.REACT_APP_URL + '/petsitters/updatePetsitterRequest/' + id, { estado_solicitud: 'APROBADA' }, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        window.location.reload();
      });
  }

  async function rejectRequest(id: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.patch(process.env.REACT_APP_URL + '/petsitters/updatePetsitterRequest/' + id, { estado_solicitud: 'RECHAZADA' }, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        window.location.reload();
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="pop-up">
      <h4 className="pop-up-title"> Solicitudes pendientes </h4>
      <table>
        <tr>
          <th className='celda'>Mascota</th>
          <th className='celda'>Tutor</th>
          <th className='celda'>Fecha</th>
          <th className='celda'>Hora inicio</th>
          <th className='celda'>Hora término</th>
          <th className='celda'>Contacto</th>
          <th className='celda'>Información</th>
          <th className='celda'>Estado</th>
        </tr>
        {requests.map((request) =>
          <tr className='fila-tabla' key={request.id}>
            <td className='celda'>{request.name}</td>
            <td className='celda'>{request.user.name}</td>
            <td className='celda'>{request.dayPetSitter}</td>
            <td className='celda'>{request.startTime}</td>
            <td className='celda'>{request.endTime}</td>
            <td className='celda'>{request.emergency}</td>
            <td className='celda'>{request.vetInfo}</td>
            <td className='celda'>{request.estado_solicitud === 'ENVIADA' ? <div><button className='accept' onClick={() => acceptRequest(request.id)}>Aceptar</button><button className='reject' onClick={() => rejectRequest(request.id)}>Rechazar</button></div> : request.estado_solicitud}</td>
          </tr>
        )}
      </table>
      <div className='center-button'>
        <button className="close-button" onClick={props.close}> Cerrar </button>
      </div>
    </div>
  );
};

Solicitudes.propTypes = {
  id: string,
  close: PropTypes.func
};

export default Solicitudes;
