import React, { useState, SyntheticEvent } from 'react';
import Navbar from '../../Navbar';
import Footer from '../../Footer';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import wabutton from '../../../Assets/Imagenes/wa_esp.png';
import './ShowVeterinarian.css';
import axios from 'axios';
import { MdVerified } from 'react-icons/md';
import { Rating } from 'react-simple-star-rating';
import { PerfilTypesToken } from '../../../Types/PerfilTypes';
import { Card, CardActions, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import vetMaleImg from '../../../Assets/Imagenes/med_vet_m.png';
import vetFemaleImg from '../../../Assets/Imagenes/med_vet_f.png';
import AssignVet from './AssignVet';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function ShowVeterinarian(props: PerfilTypesToken) {
  const { id } = useParams();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [whatsApp, setWhatsApp] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [comune, setComune] = useState<string>('');
  const [area, setArea] = useState<string>('');
  const [verified, setVerified] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [rating, setRating] = useState<number>(0);
  const [canRate, setcanRate] = useState(true);
  const [urlCalendly, setUrlCalendly] = useState<string>('');
  const [hoverVerified, setHoverVerified] = useState(false);
  const token = JSON.parse(localStorage.getItem('token') || '');
  const [assign, setAssign] = useState(false);

  axios.get(process.env.REACT_APP_URL + '/vets/' + id, { headers: { Authorization: 'Bearer ' + token } })
    .then((response) => {
      setRating(response.data.vet.score);
      setcanRate(response.data.vet.rankedByUser);
      setName(response.data.vet.name);
      setArea(response.data.vet.area);
      setEmail(response.data.vet.email);
      setImage(response.data.vet.profileUrlImg);
      setAddress(response.data.vet.address);
      setRegion(response.data.vet.addressRegion);
      setComune(response.data.vet.addressComuna);
      setVerified(response.data.vet.verified_status);
      setGender(response.data.vet.gender);
      setRating(response.data.vet.score);

      const numarray = response.data.vet.phone.split('');
      const num = '(' + numarray.slice(0, 4).join('') + ')' + ' ' + numarray.slice(4, 8).join('') + ' ' + numarray.slice(7, 11).join('');
      if (response.data.vet.phone.slice(0, 4) === '+569') {
        setWhatsApp(response.data.vet.phone.slice(1));
      } else {
        setWhatsApp('');
      }
      setPhone(num);
    });

  axios.get(process.env.REACT_APP_URL + '/vets/calendar/' + id, { headers: { Authorization: 'Bearer ' + token } })
    .then((response) => {
      console.log(response.data);
      setUrlCalendly(response.data.vetCalendar);
    });

  async function rateVet(rate: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/vetReviews/new/' + id + '/' + props.action.user.credenciales.id, {
      score: rate
    }, { headers: { Authorization: 'Bearer ' + token } });
  }

  function handleRating(rate: number) {
    setRating(rate);
    setcanRate(false);
    rateVet(rate);
  }

  function toggleAssignVet(event: SyntheticEvent) {
    event.preventDefault();
    setAssign(!assign);
  }

  return (
    <div>
      <div className='wrapper'>
        <Navbar logged={true} />
        <div className='spacer32'></div>
        <h6 className='navigation-info-post-post ms-3'>M. Veterinarios/as / {name}</h6>
        <div className='spacer32'></div>
        <div className='container-perfil'>
          <div className='col-auto' style={{ width: '100%' }}>
            <Card
              elevation={0}
              sx={{
                display: 'flex',
                padding: '1rem',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
              }}
            >
            {gender === 'FEMALE'
              ? <CardMedia
                  sx={{
                    borderRadius: '100%',
                    width: '20rem',
                    height: '20rem',
                    marginRight: '3rem'
                  }}
                  component="img"
                  alt="veterinarian profile image"
                  image={image || vetFemaleImg}
                />
              : <CardMedia
                  sx={{
                    borderRadius: '100%',
                    width: '20rem',
                    height: '20rem',
                    marginRight: '3rem'
                  }}
                  component="img"
                  alt="veterinarian profile image"
                  image={image || vetMaleImg}
                />
            }
              <CardContent style={{ padding: '3rem' }}>
                {verified === 'verified'
                  ? <Typography style={{ position: 'relative' }} gutterBottom variant="h3" component="div">{name}
                   <MdVerified onMouseEnter={() => setHoverVerified(true)} onMouseLeave={() => setHoverVerified(false)}
                    style={{ fill: '#C75100', cursor: 'pointer' }} />
                    {hoverVerified &&
                      <span style={{
                        position: 'absolute',
                        backgroundColor: '#C75100',
                        color: 'white',
                        padding: '10px',
                        fontSize: '24px',
                        borderRadius: '5px',
                        bottom: '65px',
                        minWidth: '375px',
                        right: '63px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                      }}>
                        Médico/a veterinario/a verificado
                      </span>
                    }
                  </Typography>
                  : <Typography gutterBottom variant="h3" component="div">{name}  </Typography>}
                <Typography gutterBottom variant="h4" component="div">
                  {email}
                </Typography>
                {!canRate && <Rating onClick={handleRating} allowFraction={true} fillIcon={null} showTooltip={true} tooltipDefaultText="Califica el servicio"></Rating>}
                <Typography variant="h5" style={{ flexGrow: 1 }}>
                  Calificación: {rating.toFixed() + '/5'} ⭐
                </Typography>
              </CardContent>
              <CardActions sx={{ placeContent: 'flex-end', alignItems: 'baseline', placeSelf: 'flex-end', flex: 'auto' }}>
                {urlCalendly !== null &&
                  <Link to={'/calendar'} state={{ urlCalendly }}>
                    <button className="petsitter-hire-button" style={{ width: '200px', height: '45px', marginRight: '1rem' }}>
                      Reservar Hora
                    </button>
                  </Link>
                }
                {
                  whatsApp !== '' &&
                  <div className='box-whatsapp'>
                    <a href={'https://wa.me/' + whatsApp + '?text=Hola!%20Quiero%20consultar%20por%20los%20servicios%20que%20ofrece%20en%20Mappcota.'}>
                      <img alt="Chat on WhatsApp" src={wabutton} />
                    </a>
                  </div>
                }
              </CardActions>
            </Card>
            <Divider variant="middle" />
            <div className='row' style={{ margin: '20px 0px', padding: '0px' }}>
              <div className='col-auto texto-informacion' style={{ padding: '0px' }}>
                Información del Médico/a Veterinario/a
              </div>
            </div>
            <div className='row' style={{ display: 'flex', justifyContent: 'center', margin: '20px 0px', padding: '0px' }}>
              <div className='col-auto' style={{ paddingRight: '150px' }}>
                <div className='spacer20'></div>
                <div className='row texto-campo'>
                  Teléfono
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {phone}
                  </div>
                </div>
                <div className='spacer20'></div>
                <div className='row texto-campo'>
                  Dirección
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {address}
                  </div>
                </div>
                <div className='spacer20'></div>
                <div className='spacer20'></div>
                <div className='spacer20'></div>
              </div>
              <div className='col-auto' style={{ paddingRight: '20px' }}>
                <div className='row texto-campo'>
                  Región
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {region}
                  </div>
                </div>
                <div className='spacer20'></div>
                <div className='row texto-campo'>
                  Comuna
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {comune}
                  </div>
                </div>
                <div className='spacer20'></div>
                <div className='row texto-campo'>
                  Especialidad
                </div>
                <div className='spacer12'></div>
                <div className='box-campo'>
                  <div className='texto-form'>
                    {area}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {assign === true && (
        <div className="register-form-container">
          <div className="register-form-content">
            <AssignVet
              toggleAssignVet={toggleAssignVet} />
          </div>
        </div>
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Link to='/veterinarios' className='link-back-veterinarians-show' style={{ maxWidth: '180px ', textAlign: 'center' }}>Volver</Link>
        <button onClick={toggleAssignVet} className="solicitar-acceso-boton" style={{ padding: '14px' }}>Asignar a mi mascota</button>
      </div>
      <Footer />
    </div>
  );
}

export default connect(mapStateToProps)(ShowVeterinarian);
