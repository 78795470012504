import React, { SyntheticEvent, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/index';
import Footer from '../Footer';
import './showDenuncia.css';
import DeletePerfilForm from '../Perfil/DeletePerfil';
import axios from 'axios';
import DeleteDenunciaForm from './DeleteDenunciaForm';

// function mapStateToProps (state: any) {
//     return { action: state }; // this will be available in HomeScreen as props.action
//   }

function ShowDenuncia () {
  const location = useLocation();
  const { request } = location.state;
  const [openForm, setOpenForm] = useState(false);
  const [openFormReport, setOpenFormReport] = useState(false);
  const navigate = useNavigate();

  function deleteUserForm (event: SyntheticEvent) {
    event.preventDefault();
    setOpenForm(!openForm);
  };

  function deleteUser (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + '/users/' + request.user2.id, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setOpenForm(!openForm);
        navigate('/admindenuncias');
      });
  }
  function deleteReportForm (event: SyntheticEvent) {
    event.preventDefault();
    setOpenFormReport(!openFormReport);
  };

  function deleteReport (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + '/reportUser/' + request.id, { headers: { Authorization: `Bearer ${token}` } })
      .then(() => {
        setOpenFormReport(!openFormReport);
        navigate('/admindenuncias');
      });
  }

  //   console.log("reason: " + request.reason);
  //   console.log("other: " + request.other);
  return (
    <div>
        <div className='wrapper'>
            <Navbar logged={true} />
            <div className='spacer32'></div>
            <h6 className='navigation-info-post-post'><Link to='/admindenuncias' className='link-perfil'>Denuncias</Link> / {request.user2.name}</h6>
            <div className='spacer32'></div>
            <div className='container-show-ver'>
            <div className='card-denuncia'>
            <div className='spacer32'></div>
                <div className='col-auto' style={{ width: '100%' }}>
                <div className='row' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignContent: 'center', margin: '0px' }}>
                    <div className='col-auto'>
                    <div className='row'>
                        <div className='col-auto'>
                        <div className='Box-foto'>
                            <img src={request.user2.profileUrlImg} alt='Image of a service' style={{ borderRadius: '10px', backgroundImage: 'none', width: '10rem' }} />
                        </div>
                        </div>
                        <div className='col-auto' style={{ marginLeft: '58px', padding: '0px' }}>
                        <div className='row'>
                            <div className='col-auto texto-nombre-den'>{request.user2.name}</div>
                            <div className='col-auto' style={{ padding: '0px' }}>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='row'>
                                <div className='col-auto'>
                                    <div className='spacer12'></div>
                                    <div className='spacer20'></div>
                                    <div className='row texto-campo-den'>
                                        Razón
                                    </div>
                                    <div className='spacer12'></div>
                                    <div className='row box-campo-den'>
                                            {request.reason === 'SPAM' &&
                                            <div className='texto-form'>
                                            El usuario manda spam
                                            </div>}
                                            {request.reason === 'INAPROPRIATE' &&
                                            <div className='texto-form'>
                                            El usuario manda contenido inapropiado
                                            </div>}

                                            {request.reason === 'OTHER' &&
                                            <div className='texto-form'>
                                            {request.other}
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                    <div className='row' style={{ display: 'flex-direction', justifyContent: 'start', margin: '0px 0px', padding: '0px', marginTop: '15px' }}>
                        <div className='col-auto' >
                        <Link to={'/users/' + request.user2.id} className='link-mascotas'><button className='button-den'>Ver perfil</button></Link>
                        </div>
                        <div className='col-auto' >
                        <button className='button-den' onClick={deleteUserForm}>Eliminar usuario</button>
                        </div>
                        <div className='col-auto' >
                        <button className='button-den' onClick={deleteReportForm}>Eliminar denuncia</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        {openForm === true &&
          <div className='delete-perfil-form-container'>
            <DeletePerfilForm deletePerfilForm={deleteUserForm} deletePerfil={deleteUser}/>
          </div>
        }

        {openFormReport === true &&
          <div className='delete-perfil-form-container'>
            <DeleteDenunciaForm deleteDenunciaForm={deleteReportForm} deleteDenuncia={deleteReport}/>
          </div>
        }
        <Footer/>
    </div>
  );
}

export default ShowDenuncia;
