export const speciesAndRaces = {
  species: [
    {
      nameSpecies: 'Perro',
      races: [
        'AFFENPINSCHER',
        'AKITA (AKITA INU)',
        'AKITA AMERICANO (AMERICAN AKITA)',
        'ALANO ESPAÑOL',
        'AMERICAN BULLY',
        'AMERICAN ESKIMO',
        'AMERICAN LEOPARD HOUND (CATAHOULA)',
        'AMERICAN STAFFORDSHIRE TERRIER',
        'AZAWAKH (TUAREG SLOUGHI)',
        'BASENJI',
        'BASSET HOUND',
        'BEAGLE',
        'BICHON DE PELO RIZADO (BICHON FRISE)',
        'BICHON HABANERO (HAVANESE)',
        'BLOODHOUND (CHIEN DE SAN HUMBERTO)',
        'BORDER COLLIE',
        'BORZOI (LEBREL RUSO PARA LA CAZA)',
        'BOSTON TERRIER',
        'BOXER',
        'BOYERO AUSTRALIANO (AUSTRALIAN CATTLE DOG)',
        'BOYERO DE BERNA (BERNESE MOUNTAIN DOG)',
        'BOYERO DE FLANDES (BOUVIER DES FLANDRES)',
        'BRACO ALEMAN DE PELO CORTO (KURZHAAR)',
        'BRACO ALEMAN DE PELO DURO (DRAHTHAAR)',
        'BRACO FRANCÉS (BRACO DE LOS PIRINEOS)',
        'BRACO HUNGARO/VIZSLA (HUNGARIAN POINTER)',
        'BRIQUET GRIFFON VENDEEN',
        'BROHOLMER',
        'BULL TERRIER',
        'BULLDOG AMERICANO',
        'BULLDOG FRANCES (FRENCH BULLDOG)',
        'BULLDOG INGLES',
        'BULLMASTIFF',
        'CAIRN TERRIER',
        'CAN DE PALLEIRO (PASTOR GALEGO)',
        'CANE CORSO (ITALIAN CORSO DOG)',
        'CAVALIER KING CHARLES SPANIEL',
        'CHESAPEAKE BAY RETRIEVER',
        'CHIHUAHUA (CHIHUAHUEÑO)',
        'CHOW CHOW',
        'CIMARRÓN URUGUAYO',
        'CLUMBER SPANIEL',
        'COCKER SPANIEL AMERICANO',
        'COCKER SPANIEL INGLES',
        'COLLIE BARBUDO (BEARDED COLLIE)',
        'COLLIE DE PELO CORTO (COLLIE SMOOTH)',
        'COLLIE DE PELO LARGO (COLLIE ROUGH)',
        'CONTINENTAL TOY SPANIEL/PAPILLON',
        'CORGI GALES DE CARDIGAN (WELSH CORGI CARDIGAN)',
        'CORGI GALES DE PEMBROKE (WELSH CORGI PEMBROKE)',
        'COTON DE TULEAR',
        'CRESTADO CHINO (CHINESE CRESTED DOG)',
        'CRESTADO RODESIANO (RHODESIAN RIDGEBACK)',
        'DACHSHUND (TECKEL)',
        'DALMATA',
        'DOBERMANN',
        'DOGO ARGENTINO',
        'DOGO DE BURDEOS (DOUGE DE BORDEAUX)',
        'EURASIER (EURASIAN DOG)',
        'FILA BRASILEIRO',
        'FLAT COATED RETRIEVER (COBRADOR DE PELO LISO)',
        'FOX TERRIER DE PELO ALAMBRE (WIRE)',
        'FOX TERRIER DE PELO LISO (SMOOTH)',
        'FOXHOUND AMERICANO',
        'FOXHOUND INGLES',
        'GALGO (SPANISH GREYHOUND)',
        'GALGO INGLES (GREYHOUND)',
        'GALGO ITALIANO (ITALIAN GREYHOUND)',
        'GOLDEN RETRIEVER (COBRADOR DORADO)',
        'GORDON SETTER',
        'GRAN DANES/DOGO ALEMAN (GREAT DANE)',
        'GRAN PIRINEO/PERRO DE MONTAÑA DE LOS PIRINEOS',
        'GRIFON DE BRUSELAS (GRIFFON BRUXELLOIS)',
        'GRIFON PELO DURO (WIRE-HAIRED POINTING KORTHALS)',
        'HOVAWART',
        'HUSKY SIBERIANO',
        'JACK RUSSELL TERRIER',
        'KELPIE AUSTRALIANO',
        'KERRY BLUE TERRIER',
        'KOMONDOR',
        'KUVASZ',
        'LABRADOR RETRIEVER',
        'LEBREL AFGANO (AFGHAN HOUND)',
        'LEBREL POLACO (CHART POLSKI)',
        'LEONBERGER',
        'LHASA APSO',
        'LOBERO IRLANDÉS (IRISH WOLFHOUND)',
        'LÖWCHEN (PEQUEÑO PERRO LEÓN)',
        'MALAMUTE DE ALASKA',
        'MALTESE (BICHON MALTES/MALTES)',
        'MASTIN INGLES (MASTIFF)',
        'MASTIN NAPOLITANO (NEAPOLITAN MASTIFF)',
        'MASTIN TIBETANO/DOGO DEL TIBET',
        'MASTÍN ESPAÑOL (MASTÍN LEONÉS)',
        'MESTIZO',
        'MUDI',
        'NORFOLK TERRIER',
        'OLDE ENGLISH BULLDOGGE',
        'OVEJERO MAGALLANICO',
        'PARSON RUSSELL TERRIER',
        'PASTOR ALEMAN/OVEJERO ALEMAN',
        'PASTOR AUSTRALIANO (AUSTRALIAN SHEPHERD)',
        'PASTOR BELGA (BELGIAN SHEPHERD DOG)',
        'PASTOR BLANCO SUIZO (WHITE SWISS SHEPHERD DOG)',
        'PASTOR CATALAN (CATALAN SHEEPDOG)',
        'PASTOR CROATA (CROATIAN SHEPHERD DOG)',
        'PASTOR DE ASIA CENTRAL (ALABAY)',
        'PASTOR DE BEAUCE (BERGER DE BEAUCE/BEAUCERON)',
        'PASTOR DE BRIE (BRIARD)',
        'PASTOR DE LOS PIRINEOS DE PELO LARGO',
        'PASTOR DE MAREMMA (MAREMANNO-ABRUCENSE)',
        'PASTOR DE PICARDIE (PICARDY SHEEPDOG)',
        'PASTOR DE SHETLAND (SHETLAND SHEEPDOG)',
        'PASTOR DEL CAUCASO (CAUCASIAN SHEPHERD DOG)',
        'PASTOR HOLANDES (DUTCH SHEPHERD DOG)',
        'PASTOR INGLES (OLD ENGLISH SHEEPDOG)',
        'PASTOR MALLORQUIN (CA DE BESTIAR)',
        'PASTOR POLACO DE PODHALE (TRATA SHEPHERD DOG)',
        'PASTOR PORTUGUÉS (PORTUGUESE SHEEPDOG)',
        'PEKINES (PEKINGESE)',
        'PERDIGUERO DE BURGOS (BURGOS POINTING DOG)',
        'PERRO DE AGUA ESPAÑOL (SPANISH WATER DOG)',
        'PERRO DE AGUA FRISON (FRISIAN WATER DOG)',
        'PERRO LOBO CHECOSLOVACO (CZECHOSLOVAKIAN WOLFDOG)',
        'PERRO SIN PELO DEL PERU (PERUVIAN HAIRLESS DOG)',
        'PINSCHER ALEMÁN (GERMAN PINSCHER)',
        'PINSCHER AUSTRIACO (AUSTRIAN PINSCHER)',
        'PINSCHER MINIATURA (MINIATURE PINSCHER)',
        'PIT BULL TERRIER AMERICANO',
        'PODENCO',
        'POINTER ALEMAN PELO LARGO (DEUTSCH LANGHAAR)',
        'POINTER INGLES (ENGLISH POINTER)',
        'POMERANIA/SPITZ ALEMAN ENANO',
        'POODLE (CANICHE)',
        'PRESA CANARIO/DOGO CANARIO',
        'PRESA MALLORQUÍN (CA DE BOU)',
        'PUG (DOGUILLO)',
        'PULI',
        'PUMI',
        'RETRIEVER DE NUEVA ESCOCIA (DUCK TOLLING)',
        'ROTTWEILER',
        'SALUKI',
        'SAMOYEDO',
        'SAN BERNARDO (ST. BERNARD)',
        'SCHNAUZER',
        'SCOTTISH TERRIER',
        'SEALYHAM TERRIER',
        'SETTER INGLES (ENGLISH SETTER)',
        'SETTER IRLANDES (IRISH RED SETTER)',
        'SETTER IRLANDES ROJO Y BLANCO (IRISH RED AND WHITE SETTER)',
        'SHAR PEI',
        'SHIBA (SHIBA INU)',
        'SHIH TZU',
        'SHIPPERKE',
        'SILKY TERRIER AUSTRALIANO (AUSTRALIAN SILKY TERRIER)',
        'SKYE TERRIER',
        'SPANIEL BRETON (BRITTANY SPANIEL)',
        'SPITZ ALEMAN',
        'SPITZ JAPONES',
        'SPRINGER SPANIEL INGLES',
        'STAFFORDSHIRE BULL TERRIER',
        'TCHUVATCH ESLOVACO (SLOVAKIAN CHUVACH)',
        'TERRANOVA (NEWFOUNDLAND)',
        'TERRIER ALEMAN (GERMAN HUNTING TERRIER)',
        'TERRIER AUSTRALIANO (AUSTRALIAN SILKY TERRIER)',
        'TERRIER CHILENO',
        'TERRIER IRLANDÉS (IRISH TERRIER)',
        'TERRIER RUSO NEGRO (RUSSIAN BLACK TERRIER)',
        'TERRIER TIBETANO',
        'TOSA INU',
        'VOLPINO ITALIANO',
        'WEIMARANER',
        'WELSH TERRIER',
        'WEST HIGHLAND WHITE TERRIER',
        'WHIPPET',
        'XOLOITZCUINTLE',
        'YORKSHIRE TERRIER'
      ]
    },
    {
      nameSpecies: 'Gato',
      races: [
        'ABISINIO',
        'AMERICANO DE PELO CORTO',
        'ANGORA TURCO',
        'AZUL RUSO',
        'BALINES',
        'BAMBINO',
        'BENGALA',
        'BIRMANO/SAGRADO DE BIRMANIA',
        'BOBTAIL AMERICANO',
        'BOBTAIL JAPONES',
        'BOMBAY',
        'BRITÁNICO DE PELO CORTO',
        'BRITÁNICO DE PELO LARGO',
        'BURMES',
        'BURMILLA',
        'CHARTREUX',
        'CHAUSIE',
        'CORNISH REX',
        'CURL AMERICANO PELO CORTO',
        'CURL AMERICANO PELO LARGO',
        'CYMRIC',
        'DEVON REX',
        'DOMESTICO PELO CORTO',
        'DOMESTICO PELO LARGO',
        'DONSKOY/DON SPHYNX',
        'ESFINGE',
        'EUROPEO',
        'EXOTICO',
        'FOLD ESCOCES',
        'GATO DEL BOSQUE NORUEGO',
        'HABANA',
        'HIMALAYA',
        'KHAOMANEE',
        'KORAT',
        'KURILEAN BOBTAIL PELO CORTO',
        'KURILEAN BOBTAIL PELO LARGO',
        'LAPERM PELO CORTO',
        'LAPERM PELO LARGO',
        'LYKOI',
        'MAINE COON',
        'MAINE COON POLYDACTYL',
        'MANX',
        'MAU EGIPCIO',
        'MIST AUSTRALIANO',
        'MUNCHKIN',
        'NAPOLEON/MINUET',
        'NEBELUNG',
        'NEVA MASQUERADE',
        'OCICAT',
        'ORIENTAL DE PELO CORTO',
        'ORIENTAL DE PELO LARGO',
        'PELO DE PUNTA AMERICANO',
        'PERSA',
        'PERSA CHINCHILLA',
        'PETERBALD',
        'PIXIEBOB',
        'RAGAMUFFIN',
        'RAGDOLL',
        'REX ALEMAN',
        'SAVANNAH',
        'SELKIRK REX',
        'SEYCHELLOIS PELO CORTO',
        'SEYCHELLOIS PELO LARGO',
        'SIAMES',
        'SIBERIANO',
        'SINGAPURA',
        'SNOWSHOE',
        'SOKOKE',
        'SOMALI',
        'STRAIGHT ESCOCES',
        'THAI',
        'TONKINES',
        'TOYGER',
        'VAN TURCO'
      ]
    }
  ]
};
