import { createSlice } from '@reduxjs/toolkit';
const initialCredentials = localStorage.getItem('credenciales')
  ? JSON.parse(localStorage.getItem('credenciales') || '')
  : null;

// Slice
const slice = createSlice({
  name: 'auth',
  initialState: {
    credenciales: initialCredentials
  },
  reducers: {
    loginSuccess: (state: any, action: any) => {
      state.credenciales = action.payload;
      localStorage.setItem('credenciales', JSON.stringify(action.payload));
    },
    logoutSuccess: (state: any) => {
      state.credenciales = null;
      localStorage.removeItem('credenciales');
    }
  }
});
export default slice.reducer;
// Actions
const { loginSuccess, logoutSuccess } = slice.actions;
export const login = (state: any) => async (dispatch: any) => {
  try {
    // const res = await api.post('/api/auth/login/', { username, password })
    dispatch(loginSuccess(state));
  } catch (e) {
    // return console.error(e.message);
  }
};
export const logout = () => async (dispatch: any) => {
  try {
    // const res = await api.post('/api/auth/logout/')
    return dispatch(logoutSuccess());
  } catch (e) {
    // return console.error(e.message);
  }
};
