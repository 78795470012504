import React, { useState, SyntheticEvent } from 'react';
import { Modal } from '@material-ui/core';
import './index.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { reduxAction } from '../../Types/petType';
import CloseIcon from '@mui/icons-material/Close';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function AddMicrochip ({ petId }: { petId: string }) {
  const [modal, setModal] = useState(false);
  const [microchipId, setMicrochipId] = useState('');

  const closeModal = () => {
    setModal(!modal);
  };

  async function addMicrochipDB (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.patch(process.env.REACT_APP_URL + '/pets/petMicrochip/' + petId,
      { microchipId },
      { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        window.location.reload();
      });
  }

  const bodyPopUp = (
    <form className="add-microchip-form" onSubmit={addMicrochipDB}>
      <div className="title-pop-up">
        <h1>Agregar Microchip</h1>
        <button className='close-pop-up' onClick = { () => closeModal()}><CloseIcon /></button>
      </div>
      <div className='spacer12'>
        <input className='box-campo' onChange={(e) => setMicrochipId(e.target.value)} type="text" name="microchipId" placeholder='Debe ingresar 15 dígitos' required></input>
      </div>
      <button value="submit" className="add-microchip-button">Agregar Microchip</button>
    </form>
  );

  return (
    <div>
      <button className="add-microchip-button" onClick={() => closeModal()}>Agregar microchip</button>
      <Modal open={modal} onClose={closeModal}>
          {bodyPopUp}
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps)(AddMicrochip);
