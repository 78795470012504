import React, { useState, useEffect, FC } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './PetsHealth.css';
import vet from '../../Assets/Imagenes/med_vet_m.png';
import { connect } from 'react-redux';
import axios from 'axios';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { petHealthHistory, propsType, reduxAction } from '../../Types/petHealthTypes';
import moment from 'moment';
import { Pet } from '../../Types/petType';
import perrito from '../../Assets/Imagenes/perrito.svg';
import dermogramaCanino from '../../Assets/Imagenes/Dermograma/Canino/Dermograma.png';
import dermogramaFelino from '../../Assets/Imagenes/Dermograma/Felino/Dermograma.png';
import { prettifyRut } from 'react-rut-formatter';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}
const PetHealthAll : FC <propsType> = (props) : any => {
  const [historial, setHistorial] = useState <petHealthHistory[]>();
  const [pet, setPet] = useState<Pet>();
  const location : any = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const token = props.action.user.credenciales.token;
  const fetchPetInfo = async () => {
    await axios.get(process.env.REACT_APP_URL + '/pets/' + location?.state?.petId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setPet(res.data.pet);
      });
  };

  async function fetchHistory () {
    await axios.get(process.env.REACT_APP_URL + '/petHealthHistory/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        if (response.data.petHealthHistory[0].entries.length > 0) {
          axios.get(process.env.REACT_APP_URL + '/healthEntry/history/' + response.data.petHealthHistory[0].entries[0].historyId, { headers: { Authorization: `Bearer ${token}` } })
            .then(response2 => {
              for (let i = 0; i < response.data.petHealthHistory[0].entries.length; i++) {
                response.data.petHealthHistory[0].entries[i].rut = prettifyRut(response.data.petHealthHistory[0].entries[i].rut);
                response.data.petHealthHistory[0].entries[i].documentNumber = prettifyRut(response.data.petHealthHistory[0].entries[i].documentNumber);
                response.data.petHealthHistory[0].entries[i].phoneVet = prettifyPhoneNumber(response.data.petHealthHistory[0].entries[i].phoneVet);
                response.data.petHealthHistory[0].entries[i].phoneNumber1 = prettifyPhoneNumber(response.data.petHealthHistory[0].entries[i].phoneNumber1);
                try {
                  response.data.petHealthHistory[0].entries[i].phoneNumber2 = prettifyPhoneNumber(response.data.petHealthHistory[0].entries[i].phoneNumber2);
                } catch (error) {
                }
                response.data.petHealthHistory[0].entries[i].petChartEntries = response2.data.healthEntry[i].chartPlaces[0];
              }
              setHistorial(response.data.petHealthHistory);
            });
        } else {
          setHistorial(response.data.petHealthHistory);
        }
      });
  };

  useEffect(() => {
    fetchHistory();
    fetchPetInfo();
  }, []);

  function prettifyPhoneNumber(phoneNumber:string) {
    const formattedNumber = phoneNumber.replace(/\s/g, '');
    if (formattedNumber.length > 4) {
      const countryCode = formattedNumber.slice(0, 4); // +569
      const firstPart = formattedNumber.slice(4, 8);
      const secondPart = formattedNumber.slice(8);
      return `${countryCode} ${firstPart} ${secondPart}`;
    }
    return phoneNumber;
  }

  function volver () {
    navigate(-1);
  };

  function fetchPdf (file: string | undefined) {
    if (file === undefined) {
      return;
    }
    fetch(file).then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement('a');
        alink.download = 'Terminos y condiciones.pdf';
        alink.href = fileURL;
        alink.click();
      });
    });
  }

  if (historial !== undefined) {
    return (
    <>
      <div className='wrapper'>
        <Navbar logged={true}/>
        <div className='spacer32'></div>
        <h6 className='navigation-info-post-post ms-3'><Link to={'/pets/showAll'} className='link-perfil'>Mascotas</Link> / Historial Médico Completo</h6>
        <div className='container-fichas' style={{ marginTop: '30px', flexDirection: 'column' }}>
            <div className='col-auto' style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="card" style={{
                width: '900px',
                marginBottom: '50px',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
              }}>
              <div className="card-body form-title-container">
                <div className = "texto-titulo-card"> Datos del paciente</div>
                <img src={pet?.profileUrlImg !== '' ? pet?.profileUrlImg : perrito}
                className="img-thumbnail-med" alt="Foto de perfil de la mascota" />
                <div>
                  <h4 className="texto-campo" style={{ fontSize: '26px', margin: '4%', color: '#000' }}>{pet?.name}</h4>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="pet-info">
                      <h6 className="texto-campo-card">
                        Microchip: <span>{pet?.microchipId ? pet.microchipId : 'No hay microchip ingresado'}</span>
                      </h6>
                      <h6 className="texto-campo-card">Especie: <span>{pet?.species}</span></h6>
                      <h6 className="texto-campo-card" style={{ textTransform: 'capitalize' }}>Raza: <span>{pet?.race.toLocaleLowerCase()}</span></h6>
                      <h6 className="texto-campo-card">
                        Nacimiento: <span>{pet?.birthdate ? moment.utc(pet.birthdate).format('DD/MM/YYYY') : ''}</span>
                      </h6>
                      {pet?.gender === 'MALE' &&
                        <h6 className="texto-campo-card">Género: <span>Macho</span></h6>
                      }
                      {pet?.gender === 'FEMALE' &&
                        <h6 className="texto-campo-card">Género: <span>Hembra</span></h6>
                      }
                      {pet?.gender === 'OTHER' &&
                        <h6 className="texto-campo-card">Género: <span>Otro </span></h6>
                      }
                      <h6 className="texto-campo-card">Patrón: <span>{pet?.pattern}</span></h6>
                      <h6 className="texto-campo-card">Color: <span>{pet?.color}</span></h6>
                      <h6 className="texto-campo-card">Estado Reproductivo: <span>{pet?.reproductiveState}</span></h6>
                      <h6 className="texto-campo-card">Método de Obtención: <span>{pet?.obtencion}</span></h6>
                      <h6 className="texto-campo-card">Razón de Tenencia: <span>{pet?.razonTenencia}</span></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div>
          {historial.length > 0 && historial.map(historial => historial.entries.map(ficha => (
            <div key={ficha.id} style={{ display: 'flex', justifyContent: 'center', marginBottom: '40px' }}>
            <div className='card-pets' style={{ display: 'inline-block', border: '4px solid #000', marginTop: '20px' }}>
            <div className='form-title-container'>
              <h3 className='form-title'>Ficha Médica Veterinaria</h3>
            </div>
            <div className='col-auto'>
                <div className='container-form'>
                <div className='row g-3'>
                <div className='col-auto mx-auto'>
                  <div className='form-label texto-campo'>
                    Datos Médico/a Veterinario/a
                  </div>
                </div>
              </div>
              <div className='spacer-12'></div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Nombre Completo
                  </div>
                  <div className='box-campo'>
                    {ficha.nameVet}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    RUT
                  </div>
                  <div className='box-campo'>
                    {ficha.rut}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Correo
                  </div>
                  <div className='box-campo'>
                    {ficha.mailVet}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Teléfono de Contacto
                  </div>
                  <div className='box-campo'>
                    {ficha.phoneVet}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Lugar
                  </div>
                  <div className='box-campo'>
                    {ficha.place}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Profesión
                  </div>
                  <div className='box-campo'>
                    {ficha.profession}
                  </div>
                </div>
              </div>
              </div>
              <div className='container-form'>
                <div className='row g-3'>
                <div className='col-auto mx-auto'>
                  <div className='form-label texto-campo'>
                    Datos del Responsable
                  </div>
                </div>
              </div>
              <div className='spacer-12'></div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Nombre Completo
                  </div>
                  <div className='box-campo long-info'>
                    {ficha.fullName}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Nacionalidad
                  </div>
                  <div className='box-campo'>
                    {ficha.nationality}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Tipo de Documento
                  </div>
                  <div className='box-campo'>
                    {ficha.documentation}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Número de Documento
                  </div>
                  <div className='box-campo'>
                    {ficha.documentNumber}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Fecha de Nacimiento
                  </div>
                  <div className='box-campo'>
                    {ficha.birthdayDate ? moment.utc(ficha.birthdayDate).format('DD/MM/YYYY') : ''}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Región
                  </div>
                  <div className='box-campo'>
                    {ficha.region}
                  </div>
                </div>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Comuna
                  </div>
                  <div className='box-campo'>
                    {ficha.comuna}
                  </div>
                </div>
              </div>
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Domicilio
                  </div>
                  <div className='box-campo long-info'>
                    {ficha.homeAddress}
                  </div>
                </div>
              </div>
              {ficha.phoneNumber2 !== null
                ? (
                    <div className='row g-3'>
                      <div className='col'>
                        <div className='form-label texto-campo'>
                          Teléfono de Contacto 1
                        </div>
                        <div className='box-campo'>
                          {ficha.phoneNumber1}
                        </div>
                      </div>
                      <div className='col'>
                        <div className='form-label texto-campo'>
                          Teléfono de Contacto 2
                        </div>
                        <div className='box-campo'>
                          {ficha.phoneNumber2}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='row g-3 long-info'>
                      <div className='form-label texto-campo' style={{ paddingLeft: '0px' }}>
                        Teléfono de Contacto 1
                      </div>
                      <div className='box-campo'>
                        {ficha.phoneNumber1}
                      </div>
                    </div>
                  )
              }
              <div className='row g-3'>
                <div className='col'>
                  <div className='form-label texto-campo'>
                    Correo
                  </div>
                  <div className='box-campo long-info'>
                    {ficha.mail}
                  </div>
                </div>
              </div>
              </div>
              <div className='spacer-12'></div>
              <div className='container-form'>
              <div className='spacer-12'></div>
                <div className='row g-3'>
                <div className='col-auto mx-auto'>
                  <div className='form-label texto-campo'>
                    Datos Mascota
                  </div>
                </div>
              </div>
              <div className='spacer-12'></div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Temperatura (°C)
                    </div>
                      <div className='box-campo'>
                        {ficha.temperature}
                      </div>
                  </div>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Peso (Kg)
                    </div>
                      <div className='box-campo'>
                        {ficha.weight}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Condición Corporal
                    </div>
                      <div className='box-campo'>
                        {ficha.corporalCondition}
                      </div>
                  </div>
                  <div className="col">
                    <div className='form-label texto-campo'>
                      Deshidratación (%)
                    </div>
                      <div className='box-campo'>
                          {ficha.dehydration}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Color Mucosa
                    </div>
                    <div className='box-campo'>
                      {ficha.mucousColour}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Genitales
                    </div>
                      <div className='box-campo'>
                        {ficha.genitals}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Piel
                    </div>
                    <div className='box-campo'>
                          {ficha.skin}
                      </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Linfonodos
                    </div>
                    <div className='box-campo'>
                          {ficha.lymphoNodes}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Pulso Femoral
                    </div>
                      <div className='box-campo'>
                          {ficha.femoralPulse}
                      </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Riesgo de Anestesia
                    </div>
                    <div className='box-campo'>
                          {ficha.anesthesiaRisk}
                      </div>
                  </div>
                </div>
                <div className='spacer-12'></div>
                <div className='spacer-12'></div>
                <div className='row g-3'>
                </div>
                <div className='row g-3'>
                </div>
                <div className='spacer-12'></div>
                <div className='spacer-12'></div>
                <div className='row g-3'>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Vacuna
                    </div>
                    <div className='box-campo'>
                          {ficha.vaccine}
                      </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Vacuna(s) Vigentes
                    </div>
                    <div className='box-campo'>
                          {ficha.currentVaccine}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Vacuna Antirrábica Vigente
                    </div>
                      {ficha.arVaccine && ficha.arVaccine.toString() === 'true' &&
                      <div className='box-campo'>
                        Sí
                      </div>
                      }
                      {ficha.arVaccine && ficha.arVaccine.toString() === 'false' &&
                        <div className='box-campo'>
                        No
                      </div>
                      }
                      {!ficha.arVaccine &&
                      <div className='box-campo'>
                      No responde
                    </div>
                    }
                    </div>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Antiparasitario Externo
                    </div>
                    {ficha.apExternal && ficha.apExternal.toString() === 'true' &&
                    <div className='box-campo'>
                      Sí
                    </div>
                    }
                    {ficha.apExternal && ficha.apExternal.toString() === 'false' &&
                      <div className='box-campo'>
                      No
                    </div>
                    }{!ficha.apExternal &&
                      <div className='box-campo'>
                      No responde
                    </div>
                    }
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Antiparasitario Interno
                    </div>
                      {ficha.apInternal && ficha.apInternal.toString() === 'true' &&
                      <div className='box-campo'>
                        Sí
                      </div>
                      }
                      {ficha.apInternal && ficha.apInternal.toString() === 'false' &&
                        <div className='box-campo'>
                        No
                      </div>
                      }{!ficha.apInternal &&
                        <div className='box-campo'>
                        No responde
                      </div>
                      }
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Palpación Abdominal
                    </div>
                    <div className='box-campo'>
                          {ficha.abdominalPalpation}
                      </div>
                  </div>
                </div>
                <div className='row g-3'>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Tiempo Llenado Capilar
                    </div>
                      <div className='box-campo'>
                            {ficha.capillaryRefillTime}
                        </div>
                  </div>
                  <div className='col'>
                  <div className='form-label texto-campo'>
                      Celo Actual
                    </div>
                      <div className='box-campo'>
                            {ficha.inHeat}
                        </div>
                  </div>
                </div>
                {ficha.inHeat.toString() !== 'NA'
                  ? (<>
                      <div className='row g-3'>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Último Celo
                          </div>
                          <div className='box-campo'>
                            {ficha.uInHeat ? moment.utc(ficha.uInHeat).format('DD/MM/YYYY') : ''}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Gestación Actual
                          </div>
                          <div className='box-campo'>
                            {ficha.currentGestation}
                          </div>
                        </div>
                      </div>
                      <div className='row g-3'>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Partos Previos
                          </div>
                          <div className='box-campo'>
                            {ficha.pastBirths}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-label texto-campo'>
                            Anticonceptivos
                          </div>
                          <div className='box-campo'>
                            {ficha.contraceptives}
                          </div>
                        </div>
                      </div>
                      </>
                    ) : (
                      <div className='row g-3 long-info'>
                        <div className='form-label texto-campo' style={{ paddingLeft: '0px' }}>
                          Anticonceptivos
                        </div>
                        <div className='box-campo'>
                          {ficha.contraceptives}
                        </div>
                      </div>
                    )
                }
                <div className='row g-3'>
                  <div className='col'>
                    <div className='form-label texto-campo'>
                      Enfermedades Previas
                    </div>
                    <div className='box-campo long-info'>
                      <ul>
                        {ficha.previousDeseases.length > 0 ? (
                          ficha.previousDeseases.map((item, index) => (
                            <li key={index}>{item !== 'Otra' ? item : ficha.otherPreviousDeseases}</li>
                          ))
                        ) : (
                          <p>No hay elementos que mostrar.</p>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='spacer-12'></div>
                <div className='row g-3'>
                  {((pet?.species === 'Gato') || (pet?.species === 'Perro')) && (
                    <div className='row g-3'>
                      <label className='form-label texto-campo'>Dermograma</label>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {pet.species === 'Gato' && (
                          <div className='imagen-contenedor'>
                          <img src={dermogramaFelino} alt="Dermograma de un gato" className="img-thumbnail-med long-info imagen-base"/>
                          {ficha.petChartEntries.PetChartPlaces.length > 0 && (
                            ficha.petChartEntries.PetChartPlaces.map((item, index) => (
                              <img src={require(`../../Assets/Imagenes/Dermograma/Felino/${item}.png`)} alt={`Gato ${index + 1}`} className='img-thumbnail-med long-info imagen-overlay'/>
                            ))
                          )}
                        </div>
                        )}
                        {pet.species === 'Perro' && (
                          <div className='imagen-contenedor'>
                          <img src={dermogramaCanino} alt="Dermograma de un gato" className="img-thumbnail-med long-info imagen-base"/>
                          {ficha.petChartEntries.PetChartPlaces.length > 0 && (
                            ficha.petChartEntries.PetChartPlaces.map((item, index) => (
                              <img src={require(`../../Assets/Imagenes/Dermograma/Canino/${item}.png`)} alt={`Perro ${index + 1}`} className='img-thumbnail-med long-info imagen-overlay'/>
                            ))
                          )}
                        </div>
                        )}
                      </div>
                      <div className='spacer-12'></div>
                      <div className='col'>
                        <label className='form-label texto-campo'>¿Dónde se encuentra la lesión?</label>
                        <div className='box-campo long-info' style={{ marginLeft: '23px' }}>
                          <ul>
                            {ficha.petChartEntries.PetChartPlaces.length > 0 ? (
                              ficha.petChartEntries.PetChartPlaces.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))
                            ) : (
                              <p>No hay elementos que mostrar.</p>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', padding: '20px', flexDirection: 'column', marginTop: '0px' }}>
                    <div className='row g-3'>
                      <div className='col'>
                      <div className='form-label texto-campo'>
                        Primera vez que asiste
                      </div>
                        {ficha.firstTime && ficha.firstTime.toString() === 'true' &&
                            <div className='box-campo'>
                              Sí
                            </div>
                        }
                        {ficha.firstTime && ficha.firstTime.toString() === 'false' &&
                            <div className='box-campo'>
                              No
                            </div>
                        }
                        {!ficha.firstTime &&
                          <div className='box-campo'>
                          No responde
                        </div>
                        }
                      </div>
                      <div className='col'>
                        <div className='form-label texto-campo'>
                          Fecha Control:
                        </div>
                        <div className='box-campo'>
                        {moment.utc(ficha.controlDate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='spacer-20'></div>
                <div className='med-record'>
                  <div className='row g-3'>
                        <div className='form-label texto-campo'>
                          Motivo de Consulta
                        </div>
                        <div className='box-campo-final'>
                          {ficha.visitReason}
                        </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Anamnesis
                    </div>
                    <div className='box-campo-final'>
                      {ficha.anamnesis}
                    </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Antecedentes Clínicos
                    </div>
                    <div className='box-campo-final'>
                      {ficha.clinicalBackground}
                    </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Observaciones
                    </div>
                    <div className='box-campo-final'>
                      {ficha.observations}
                    </div>
                  </div>
                  {props.action.user.credenciales.id === ficha.vetId &&
                  <>
                    <div className='spacer-20'></div>
                    <div className='row g-3'>
                      <div className='form-label texto-campo'>
                        Prediagnóstico
                      </div>
                      <div className='box-campo-final'>
                        {ficha.initialEv}
                      </div>
                    </div>
                  </>
                  }
                  <div className='spacer-20'></div>
                  <div className='row g-3' style={{ width: '35vw' }}>
                    <div className='form-label texto-campo'>
                      Solicitud de Exámenes Complementarios
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='hemograma' checked={ficha.hemograma} className='exam-check'/>
                      <h6>Hemograma completo</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='perfil_bioquimico' checked={ficha.perfil_bioquimico} className='exam-check'/>
                      <h6>Perfil bioquímico completo</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='hem_pb' checked={ficha.hem_pb} className='exam-check'/>
                      <h6>Hemograma + Perfil bioquímico</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='hem_pb_t4' checked={ficha.hem_pb_t4} className='exam-check'/>
                      <h6>Hemograma + Perfil bioquímico + T4</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='hem_pb_t4_tsh' checked={ficha.hem_pb_t4_tsh} className='exam-check'/>
                      <h6>Hemograma + Perfil bioquímico + T4 + TSH</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='prequirurgico_basico' checked={ficha.prequirurgico_basico} className='exam-check'/>
                      <h6>Pre quirúrgico básico (Hemograma + Nus + Crea + Alt + Ast)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='prequirurgico_completo' checked={ficha.prequirurgico_completo} className='exam-check'/>
                      <h6>Pre quirúrgico completo (Perfil + Hemograma + TP + TTPA + PLQ)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='protombina' checked={ficha.protombina} className='exam-check'/>
                      <h6>Tiempo de protrombina (PT)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='tromboplastina' checked={ficha.tromboplastina} className='exam-check'/>
                      <h6>Tiempo de tromboplastina parcial act. (TTPA)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='panel_coagulacion' checked={ficha.panel_coagulacion} className='exam-check'/>
                      <h6>Panel coagulación (TP + TTPA + Plaquetas)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='coagulacion_infecc' checked={ficha.coagulacion_infecc} className='exam-check'/>
                      <h6>Coagulación infecc (TP + TTPA + Plaquetas + Erlichia)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='perfil_renal' checked={ficha.perfil_renal} className='exam-check'/>
                      <h6>Perfil renal</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='perfil_hepatico' checked={ficha.perfil_hepatico} className='exam-check'/>
                      <h6>Perfil hepático</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='perfil_lipidico' checked={ficha.perfil_lipidico} className='exam-check'/>
                      <h6>Perfil lipídico</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='perfil_lipidico_t4_tsh' checked={ficha.perfil_lipidico_t4_tsh} className='exam-check'/>
                      <h6>Perfil lipídico + T4 + TSH</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='t4_tsh' checked={ficha.t4_tsh} className='exam-check'/>
                      <h6>T4 + TSH</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='felv_fiv' checked={ficha.felv_fiv} className='exam-check'/>
                      <h6>FELV + FIV (Elisa o Inmunocromatográfica)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='parvovirus_distemper' checked={ficha.parvovirus_distemper} className='exam-check'/>
                      <h6>Parvovirus ag + Distemper AG (Inmunocromatográfica)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='brucelosis' checked={ficha.brucelosis} className='exam-check'/>
                      <h6>Brucelosis canina AC (Inmunocromatográfica)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='distemper' checked={ficha.distemper} className='exam-check'/>
                      <h6>Distemper AG (Inmunocromatografía)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='leucemia_felina' checked={ficha.leucemia_felina} className='exam-check'/>
                      <h6>Leucemia felina (FELV) AG (Elisa)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='inmunodeficiencia_felina' checked={ficha.inmunodeficiencia_felina} className='exam-check'/>
                      <h6>Inmunodeficiencia felina (FIV) AC (Inmunocromatografía)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='ehrlichia_anaplasma' checked={ficha.ehrlichia_anaplasma} className='exam-check'/>
                      <h6>Ehrlichia-anaplasma (Inmunocromatografía o Elisa)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='parvovirus' checked={ficha.parvovirus} className='exam-check'/>
                      <h6>Parvovirus AG (Inmunocromatografía)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='toxoplasma' checked={ficha.toxoplasma} className='exam-check'/>
                      <h6>Toxoplasma (Inmunocromatografía)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='cultivo_antibiograma_citologico_oidos' checked={ficha.cultivo_antibiograma_citologico_oidos} className='exam-check'/>
                      <h6>Cultivo y antibiograma + Citológico oídos</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='directo_piel_cultivo_dermatofitos' checked={ficha.directo_piel_cultivo_dermatofitos} className='exam-check'/>
                      <h6>Directo de piel más cultivo dermatofitos</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='directo_piel_frotis_malassesia_cultivo_dermatofitos' checked={ficha.directo_piel_frotis_malassesia_cultivo_dermatofitos} className='exam-check'/>
                      <h6>Directo piel + Frotis malassesia + Cultivo dermatofitos</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='urianalisis' checked={ficha.urianalisis} className='exam-check'/>
                      <h6>Urianálisis</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='urocultivo_antibiograma' checked={ficha.urocultivo_antibiograma} className='exam-check'/>
                      <h6>Urocultivo + Antibiograma</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='coproparasitario' checked={ficha.coproparasitario} className='exam-check'/>
                      <h6>Coproparasitario</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='coprocultivo' checked={ficha.coprocultivo} className='exam-check'/>
                      <h6>Coprocultivo</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='cultivo_antibiograma' checked={ficha.cultivo_antibiograma} className='exam-check'/>
                      <h6>Cultivo + Antibiograma (Torula Stuart)</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='directo_piel_pelos' checked={ficha.directo_piel_pelos} className='exam-check'/>
                      <h6>Directo de piel y pelos</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='radiografia' checked={ficha.radiografia} className='exam-check'/>
                      <h6>Radiografía</h6>
                    </div>
                    <div className='check-label'>
                      <input type='checkbox' name='ecotomografia' checked={ficha.ecotomografia} className='exam-check'/>
                      <h6>Ecotomografía</h6>
                    </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Indicaciones
                    </div>
                    <div className='box-campo-final'>
                      {ficha.indications}
                    </div>
                  </div>
                  <div className='spacer-20'></div>
                  <div className='row g-3'>
                    <div className='form-label texto-campo'>
                      Archivos
                    </div>
                    {ficha.files !== undefined &&
                      ficha.files.map((file, index) => {
                        return (
                          <a key={index} onClick={() => fetchPdf(file.fileUrl)} className='link-terms'> Descargar {file.name} </a>
                        );
                      })
                    }
                  </div>
                </div>
                  <div className='Box-ingreso' style={{ justifyContent: 'center', maxWidth: 'max-content', marginTop: '20px' }}>
                    <Link className='nav-link link-crear-ficha' state={{ petId: id }} style={{ color: 'white', display: 'flex', flexDirection: 'column', padding: '8px', fontSize: '18px' }} to={'/petsHealth/show/' + ficha.id}> Detalles </Link>
                </div>
                  {/* <a onClick={fetchPdf} className='link-terms'> Descargar terminos </a> */}
                </div>
                </div>
            </div>
        </div>
          )))}
        <div className='spacer-20'></div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        {historial[0].entries.length === 0 &&
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='card container-fichas-vacio' style={{ width: '900px' }}>
                <div className='title-pets'>No hay Fichas Médicas Disponibles</div>
                <div className='imagen-vet'>
                  <img src={vet} alt="new" style={{ borderRadius: '10px', maxWidth: '100px', maxHeight: '100px' }} />
                  <div className='spacer-20'></div>
                </div>
            </div>
          </div>
        }
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ margin: '10px 130px' }}>
            { props.action.user.credenciales.rol === 2 &&
              <div className='Box-ingreso' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 0' }}>
              <Link className='nav-link link-crear-ficha' state={{ petId: id }} style={{ color: 'white', fontSize: '14px', padding: '8px 16px', alignItems: 'center', display: 'flex', justifyContent: 'center' }} to={'/petsHealth/create/' + id}>
                Crear Ficha Médica
              </Link>
              </div>
            }
            <button onClick={() => volver()} className='button-crear-ficha' style={{ fontSize: '14px', padding: '8px 16px', width: '200px' }}>
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
    <Footer />
    </>
    );
  }
};
export default connect(mapStateToProps)(PetHealthAll);
