import React, { useState, ReactElement, FC, useEffect } from 'react';
import './petShow.css';
import { connect } from 'react-redux';
import axios from 'axios';
import { Pet, propsType, reduxAction } from '../../Types/petType';
import { userDataTypePetShow } from '../../Types/UserTypes';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import huella from '../../Assets/Imagenes/huella.png';
import moment from 'moment';
import AddMedForm from './AddMedForm';
import AddCotutorForm from './AddCotutorForm';
import { HiOutlinePlusSm } from 'react-icons/hi';
import { TfiCheck, TfiClose } from 'react-icons/tfi';
import PopUpPetShow from '../Microchip/popUpPetShow';
import AddMicrochip from '../Microchip/addMicrochip';

function mapStateToProps (state: reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

const PetShow : FC <propsType> = (props): ReactElement => {
  const [pet, setPet] = useState<Pet>();
  const [vets, setvets] = useState<Array<any>>([]);
  const [vetsOptions, setVetsOptions] = useState<Array<any>>([]);
  const [headvets, setheadvets] = useState<Array<any>>([]);
  const [users, setUsers] = useState<Array<any>>([]);
  const [usersOptions, setUsersOptions] = useState<Array<any>>([]);
  const [cotutors, setCotutors] = useState<Array<any>>([]);
  const [accessRequests, setAccessRequests] = useState<Array<any>>([]);
  const [historyAccess, setHistoryAccess] = useState<Array<any>>([]);
  const [petId, setPetId] = useState<string>('');
  const [medForm, setMedForm] = useState<boolean>(false);
  const [cotutorForm, setCotutorForm] = useState<boolean>(false);
  const [isDangerous, setIsDangerous] = useState<boolean>(false);
  const userId = props.location.state.userId;
  const token = props.action.user.credenciales.token;

  const fetchPet = async () => {
    await axios.get(process.env.REACT_APP_URL + '/pets/' + props.location.state.state, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setPet(
          res.data.pet
        );
        fetchHeadvets(res.data.pet.id);
        fetchCotutors(res.data.pet.id);
        setPetId(res.data.pet.id);
      });
  };
  async function fetchAccessRequests () {
    axios.get(process.env.REACT_APP_URL + '/petsHistoryAccess/petRequest/' + props.location.state.state, { headers: { Authorization: `Bearer ${token}` } })
      .then(response => {
        setAccessRequests(response.data);
      });
  }

  useEffect(() => {
    fetchPet();
    fetchVets();
    fetchUsers();
    fetchAccessRequests();
    fetchHistoryAccess();
  }, []);

  async function fetchHeadvets (id : string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/headVets/' + id, { headers: { Authorization: 'Bearer ' + token } })
      .then(res => {
        setheadvets(res.data.headVets.map((headvet : any) => ({ value: headvet.vetId, label: headvet.vet.user.name })));
      });
  }

  function redirectFichas (props : propsType) {
    const path = '/petsHealth/showAll/' + props.location.state.state;
    props.navigate(path);
  };

  function fetchVets () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/vets/', { headers: { Authorization: 'Bearer ' + token } })
      .then(res => {
        setvets(res.data.vets.map((vet: any) => ({ value: vet.id, label: vet.name })));
      });
  }

  function handleVetChange (selectedOption: any) {
    setVetsOptions(selectedOption);
  }

  function sendVets () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    for (let i = 0; i < vets.length; i++) {
      axios.post(process.env.REACT_APP_URL + '/headVets/' + pet?.id + '/' + vetsOptions[i].value,
        { create: true }, { headers: { Authorization: 'Bearer ' + token } })
        .then(() => {
          window.location.reload();
        });
    }
  }

  function deleteHeadvet (id : string, username : string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + '/headVets/' + pet?.id + '/' + id, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: `${username} ya no es médico/a veterinario/a tratante`,
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        fetchHeadvets(petId);
      });
  }

  function fetchUsers () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/users/', { headers: { Authorization: 'Bearer ' + token } })
      .then(res => {
        setUsers(res.data.users.map((user: userDataTypePetShow) => ({ value: user.id, label: user.name })));
      });
  }

  async function fetchCotutors (idPet: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + `/userPets/subtutor?petId=${idPet}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((res: any) => {
        setCotutors(res.data.subtutores);
      });
  };

  function isCotutor (id : string) {
    for (let i = 0; i < cotutors.length; i++) {
      console.log(cotutors[i]);
      if (cotutors[i].user.id === id) {
        return true;
      }
    }
    return false;
  }

  function handleUserChange (selectedOption: any) {
    setUsersOptions(selectedOption);
  }

  function sendUsers () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    for (let i = 0; i < users.length; i++) {
      axios.post(process.env.REACT_APP_URL + '/userPets/subtutor',
        {
          ownerUserId: props.action.user.credenciales.id,
          petId,
          newSubtutorUserId: usersOptions[i].value
        },
        { headers: { Authorization: 'Bearer ' + token } }
      )
        .then(() => {
          swal({
            title: `${usersOptions[i].label} asignado/a con éxito`,
            icon: 'success',
            buttons: { Aceptar: true },
            timer: 2500
          });
          window.location.reload();
        })
        .catch((error) => {
          swal({
            title: 'Error',
            text: String(error.response.data.message),
            icon: 'error',
            buttons: { Aceptar: true },
            timer: 2000
          });
        }
        );
    };
  };

  function deleteCotutor (id : string, idPet: string, username: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + `/userPets/subtutor?ownerUserId=${props.action.user.credenciales.id}&petId=${idPet}&toDeleteUserId=${id}`,
      { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: `${username} ya no es cotutor/a`,
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        fetchCotutors(idPet);
      });
  };

  function handleAccessRequest (vetId : string, petId: string, userId : string, accepted: boolean, mensaje: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/petsHistoryAccess/answerRequest',
      { vetId, petId, userId, accepted }, { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: mensaje,
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        window.location.reload();
      });
  };

  async function fetchHistoryAccess () {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + `/petsHistoryAccess/${props.location.state.state}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((res: any) => {
        setHistoryAccess(res.data);
      });
  };

  function deleteAccess (id : string, username : string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.delete(process.env.REACT_APP_URL + `/petsHistoryAccess/${id}`,
      { headers: { Authorization: 'Bearer ' + token } })
      .then(() => {
        swal({
          title: `${username} ya no puede acceder al historial`,
          icon: 'success',
          buttons: { Aceptar: true },
          timer: 2000
        });
        fetchHistoryAccess();
      });
  };

  function AddMed () {
    setMedForm(!medForm);
  }

  function AddCotutor () {
    setCotutorForm(!cotutorForm);
  }

  function isPetDangerous (petId: string) {
    axios.get(process.env.REACT_APP_URL + '/pets/isPetDangerous/' + petId, { headers: { Authorization: 'Bearer ' + token } })
      .then(res => {
        setIsDangerous(res.data.isPetDangerous);
      });
  }

  if (pet !== undefined && headvets !== undefined && cotutors !== undefined) {
    isPetDangerous(pet.id);
    return (
    <>
      <div className='wrapper'>
          <div className='spacer32'></div>
          <h6 className='navigation-info-post-post ms-3'><Link to={'/pets/showAll'} className='link-perfil'>Mascotas</Link> / {pet.name}</h6>
          <div className='container-perfil-masc'>
            <div className='main-body'>
              <div className='row gutters-sm'>
                <div className='col-md-3 mb-3'>
                  <div className='card-perfil-masc h-100'>
                    <div className='card-body-perfil-vet'>
                      <div className='d-flex flex-column align-items-center text-center'>
                        <img src={pet.profileUrlImg !== '' ? pet.profileUrlImg : huella} alt="new" className='rounded-circle' width='118' height='118' />
                        <div className='mt-2'>
                          <p className='text-secondary mb-1 text-especialidad'>{pet.name}</p>
                          <p className='text-secondary mb-1 text-especialidad'>{pet.species}: {pet.race}</p>
                          {(props.action.user.credenciales.rol === 2 || props.action.user.credenciales.id === userId) &&
                            <button onClick={() => redirectFichas(props)} className='historial-btn'> Ver Historial Médico </button>
                            }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 mb-3'>
                  <div className='card-perfil-masc h-100'>
                    <div className='card-body-perfil-vet'>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <h6 className='mb-1'>Microchip</h6>
                        </div>
                        {pet.microchipId === '' && props.action.user.credenciales.id === userId
                          ? <div className='col-sm-8 text-secondary'>
                              <AddMicrochip petId = {pet.id}/>
                             </div>
                          : <div className='col-sm-8 text-secondary'>
                              {pet.microchipId}
                            </div>
                        }
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-sm-4'>
                          <h6 className='mb-1'>Fecha de nacimiento</h6>
                        </div>
                        <div className='col-sm-8 text-secondary'>
                        {moment.utc(pet.birthdate).format('DD/MM/YYYY')}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                        <div className='col-sm-4'>
                          <h6 className='mb-1'>Color</h6>
                        </div>
                        <div className='col-sm-8 text-secondary'>
                          {pet.color}
                        </div>
                      </div>
                      <hr />
                      <div className='row'>
                          <div className='col-sm-4'>
                            <h6 className='mb-1'>Patrón</h6>
                          </div>
                          <div className='col-sm-8 text-secondary'>
                            {pet.pattern}
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 mb-3'>
                  <div className='card-perfil-masc h-100'>
                    <div className='card-body-perfil-vet'>
                      {(userId === props.action.user.credenciales.id && !isCotutor(props.action.user.credenciales.id))
                        ? <>
                        <div className='row-first'>
                          <h6 className='mb-3'>Solicitudes Historial Médico</h6>
                        </div>
                        <div className='row-over'>
                        {accessRequests.length === 0
                          ? <p className='text-secondary'>No hay solicitudes </p>
                          : accessRequests.map((request: any, index: number) => (
                        <div key={request} className="row row-med">
                        {index !== 0 ? <hr /> : undefined}
                        <div className="col-sm-4">
                          <h6 className="mb-0"> {request.vet.user.name}</h6>
                        </div>
                            <div className="col-sm-4 text-secondary"><Link to={ { pathname: '/veterinario/' + request.vet.user.id } }>
                                    <button className='button-ver-perfil'>
                                      Ve su perfil
                                    </button>
                                </Link> </div>
                                    <div className="col-sm-2 text-secondary">
                                            <button onClick={() => handleAccessRequest(request.vet.user.id, pet.id, userId, true, 'Solicitud aceptada')} className='button-sol-history'>
                                              <TfiCheck size={20}/>
                                            </button>
                                    </div>
                                    <div className="col-sm-2 text-secondary">
                                    <button onClick={() => handleAccessRequest(request.vet.user.id, pet.id, userId, false, 'Solicitud rechazada')} className='button-sol-history'>
                                              <TfiClose size={20}/>
                                            </button>
                                    </div>
                            </div>
                          ))}
                          </div>
                          <hr className='sep-line'/>
                          </>
                        : undefined}
                      <div className='row-first'>
                      <h6 className='mb-3'>Acceso Historial Médico</h6>
                      </div>
                      <div className='row-over'>
                      {historyAccess.length === 0
                        ? <p className='text-secondary'>No hay médicos/as veterinarios/as con acceso </p>
                        : historyAccess.map((access: any, index: number) => (
                        <div key={access} className="row row-med">
                        {index !== 0 &&
                         <hr />}
                        <div className="col-sm-4">
                          <h6 className="mb-0"> {access.vet.user.name}</h6>
                        </div>
                            <div className="col-sm-3 text-secondary"><Link to={ { pathname: '/veterinario/' + access.vet.user.id } }>
                                    <button className='button-ver-perfil'>
                                      Ve su perfil
                                    </button>
                                </Link> </div>
                        {props.action.user.credenciales.id === userId && !isCotutor(props.action.user.credenciales.id)
                          ? <>
                            <div className="col-sm-3 text-secondary">
                              <button onClick={() => deleteAccess(access.id, access.vet.user.name)} className='button-ver-perfil'>
                                Eliminar
                              </button>
                            </div>
                            </>
                          : undefined
                        }
                      </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row gutters-sm'>
                <div className='col-sm-6 mb-3'>
                  <div className='card-perfil-masc h-100'>
                    <div className='card-body-perfil-vet'>
                      <div className='row-first'>
                      <h6 className='mb-3'>Médicos/as tratantes</h6>
                      {props.action.user.credenciales.id === userId
                        ? <button className='add-med mb-2' onClick={AddMed} data-bs-toggle="tooltip" data-bs-placement="right" title='Agregar médico/a tratante'> <HiOutlinePlusSm size={25}/></button>
                        : undefined}
                      </div>
                    {headvets.length === 0
                      ? <>
                        <div className='row row-med'>
                          <p className='text-secondary'>No hay médicos/as tratantes</p>
                        </div>
                        </>
                      : undefined}
                    <div className='row-over'>
                    {headvets.map((vet, index) => (
                      <div key={vet} className="row row-med">
                      {index !== 0 ? <hr /> : undefined}
                      <div className="col-sm-4">
                        <h6 className="mb-0"> {vet.label}</h6>
                      </div>
                      <div className="col-sm-3 text-secondary"><Link to={ { pathname: '/veterinario/' + vet.value } }>
                        <button className='button-ver-perfil'>
                          Ve su perfil
                        </button>
                        </Link> </div>
                        {props.action.user.credenciales.id === userId && !isCotutor(props.action.user.credenciales.id)
                          ? <>
                            <div className="col-sm-3 text-secondary">
                              <button onClick={() => deleteHeadvet(vet.value, vet.label)} className='button-ver-perfil'>
                                Desasignar
                              </button>
                            </div>
                            </>
                          : undefined
                        }
                      </div>
                    ))}
                    </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>
                  <div className='card-perfil-masc h-100'>
                    <div className='card-body-perfil-vet'>
                    <div className='row-first'>
                      <h6 className='mb-3'>Cotutores/as</h6>
                      {props.action.user.credenciales.id === userId
                        ? <button className='add-med mb-2' onClick={AddCotutor} data-bs-toggle="tooltip" data-bs-placement="right" title='Agregar cotutor/a'> <HiOutlinePlusSm size={25}/></button>
                        : undefined}
                      </div>
                    {cotutors.length === 0
                      ? <>
                        <div className='row row-med'>
                          <p className='text-secondary'>No hay cotutores/as</p>
                        </div>
                          </>
                      : undefined}
                      <div className='row-over'>
                      {cotutors.map((cotutor, index) => (
                        <div key={cotutor} className="row row-med">
                        {index !== 0 ? <hr /> : undefined}
                        <div className="col-sm-4">
                          <h6 className="mb-0"> {cotutor.user.name}</h6>
                        </div>
                            <div className="col-sm-3 text-secondary"><Link to={ { pathname: '/users/' + cotutor.user.id } }>
                                    <button className='button-ver-perfil'>
                                      Ve su perfil
                                    </button>
                                </Link> </div>
                                {props.action.user.credenciales.id === userId && !isCotutor(props.action.user.credenciales.id)
                                  ? <>
                                    <div className="col-sm-3 text-secondary">
                                      <button onClick={() => deleteCotutor(cotutor.user.id, pet.id, cotutor.user.name)} className='button-ver-perfil'>
                                        Desasignar
                                      </button>
                                    </div>
                                    </>
                                  : undefined
                                }
                      </div>))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {medForm &&
            <div className='delete-perfil-form-container'>
          <AddMedForm AddMed={AddMed} handleVetChange={handleVetChange} sendVets={sendVets} vets={vets}/>
          </div>
          }
          {cotutorForm &&
            <div className='delete-perfil-form-container'>
              <AddCotutorForm AddCotutor={AddCotutor} handleUserChange={handleUserChange} sendUsers={sendUsers} users={users}/>
            </div>
          }

          {(!pet.microchipId && props.action.user.credenciales.id === userId) &&
            <PopUpPetShow state={true} isPetDangerous={isDangerous} petId={pet.id}/>}
        </div>
      </>
    );
  } else {
    return (
    <></>
    );
  }
};

export default connect(mapStateToProps)(PetShow);
