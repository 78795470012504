import React, { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import '../SignInForm/index.css';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

type PasswordRecoveryFormProps = {
  toggleRecoveryForm: React.MouseEventHandler
}

function PasswordRecoveryForm (props: PasswordRecoveryFormProps) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [sent, setSent] = useState<boolean>(false);

  function submitSignInForm () {
    return function (event: SyntheticEvent) {
      event.preventDefault();
      const token = JSON.parse(localStorage.getItem('token') || '');
      axios.post(process.env.REACT_APP_URL + '/create/recoverPassword', {
        email
      }, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          setSent(true);
        })
        .catch((error) => {
          console.log('Bypass error');
          setSent(true);
          handleError(error.data.error);
        }
        );
    };
  };

  function handleError (err:any) {
    setError(err);
  }

  return (
    <>
    {sent
      ? <form className="sign-in-form" onSubmit={submitSignInForm()}>
            <div className='sign-in-title-div'>
                <h1 className='sign-in-title'>Correo Enviado</h1>
                <button className='closeForm' onClick={props.toggleRecoveryForm}><CloseIcon /></button>
            </div>
            <div>
                    <p>Hemos enviado un correo electrónico a {email} con las información necesaria para que recuperes tu contraseña</p>
                    <p>Cierra este formulario para iniciar sesión</p>
            </div>
        </form>
      : <form className="password-recovery-form" onSubmit={submitSignInForm()}>
            <div className='sign-in-title-div'>
                <h1 className='sign-in-title'>Recupera tu Contraseña</h1>
                <button className='close-password-recovery' onClick={props.toggleRecoveryForm}><CloseIcon /></button>
            </div>
            {error !== '' &&
                <p className="error-message">{error}</p>
            }
            <div className="fieldset-area">
                <fieldset className="fieldset">
                  <p>
                    <input className='password-recovery-input' placeholder="" id="email" type="text" value={email} name="email" onChange={e => setEmail(e.target.value)} required/>
                    <label htmlFor="email">Email</label>
                  </p>
                </fieldset>
            </div>
            <div className="sign-in-buttons">
                <button className="sign-in-form-button" id="sign-in" type="submit">Enviar Correo Electrónico</button>
                <p>Te enviaremos un mail con las instrucciones para recuperar tu contraseña</p>
            </div>
        </form>
    }
    </>
  );
};

PasswordRecoveryForm.propTypes = {
  closeSignInForm: PropTypes.func,
  closeSignInFormOpenSignUp: PropTypes.func

};

export default PasswordRecoveryForm;
