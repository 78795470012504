import React, { FC, ReactElement } from 'react';
import axios from 'axios';
import './index.css';
import { connect } from 'react-redux';
import { propsType, reduxAction } from '../../Types/petType';

function mapStateToProps (state : reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}
const PetDelete : FC <propsType> = (props): ReactElement => {
  const handleSubmit = () => {
    const token = props.action.user.credenciales.token;
    axios.delete(process.env.REACT_APP_URL + '/pets/' + props.location.state.state,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: { ownerUserId: props.action.user.credenciales.id }
      }
    )
      .then(() => {
        redirect();
      });
  };

  const redirect = () => {
    const path = '/pets/showAll';
    props.navigate(path, { state: { show: '' } });
  };

  return (
    <div className='container-perfil' style={{ height: '75vh' }}>
      <br/>
      <h1>Eliminar Mascota</h1>
        <br/>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1> ¿Quieres eliminar tu mascota? </h1>
          <br/>
          <button className="save-button" onClick = {() => redirect()}>Volver</button>
          <button className="delete-button" onClick = {handleSubmit}>Eliminar mascota</button>
      </div>
    </div>
  );
};
export default connect(mapStateToProps)(PetDelete);
