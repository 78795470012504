import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import App from './App';
import PerfilShow from './Components/Perfil/PerfilShow';
import PerfilShowVet from './Components/Perfil/PerfilShowVet';
import VerificationForm from './Components/Perfil/VerificationForm';
import PetRegister from './Components/RegisterPet/index';
import PetEdit from './Components/RegisterPet/petEdit';
import PetDelete from './Components/RegisterPet/petDelete';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PerfilEdit from './Components/Perfil/PerfilEdit';
import ShowVeterinarian from './Components/Veterinarian/Show/ShowVeterinarian';
import IndexVeterinarians from './Components/Veterinarian/Index/IndexVeterinarians';
import Calendar from './Components/Veterinarian/calendar';
import IndexUsers from './Components/Perfil/allUsers';
import ShowUser from './Components/Perfil/showUser';
import IndexUserPets from './Components/RegisterPet/indexUserPets';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; // Archivo CSS de Bootstrap 4
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'; // Archivo Javascript de Bootstrap 4 \
import { Provider } from 'react-redux';
import store from './Components/Store/store';
import Mascotas from './pages/Mascotas';
import PetsHealthCreate from './Components/PetsHealth/createInform';
import PetsHealthShowAll from './Components/PetsHealth/petHealthShowAll';
import PetsHealthEdit from './Components/PetsHealth/editInform';
import PetsHealthShow from './Components/PetsHealth/petHealthShow';
import PetsHealthAll from './Components/PetsHealth/petHealthAll';
import IndexPatients from './Components/Patients/IndexPatients';
import ServicesShowAll from './Components/Servicios/showAll.tsx';
import ServiceShow from './Components/Servicios/show.tsx';
import VerificacionMail from './Components/VerificacionMail/index.tsx';
import WelcomePage from './Components/WelcomePage';
import PetsittersShow from './Components/Petsitters/indexPetsitters.tsx';
import IndexVerifications from './Components/Admin/IndexVerifications';
import ShowVerificationVet from './Components/Admin/ShowVerVet';
import ShowVerificationPetsitter from './Components/Admin/ShowVerPetsitter';
import ShowVerificationHotel from './Components/Admin/ShowVerHotel';
// import EmptyChat from './Components/Chat/EmptyChat';
// import PrivateChatShow from './Components/Chat/Private/PrivateChatShow';
// import GroupChatShow from './Components/Chat/Group/GroupChatShow';
import ShowVerificationServ from './Components/Admin/ShowVerServ';
import ShowVerificationPost from './Components/Admin/ShowVerPost';
import IndexDenuncias from './Components/Admin/denuncias.tsx';
import ShowDenuncia from './Components/Admin/showDenuncia.tsx';
import PostShow from './Components/InstagramPost/PostShow.tsx';
import PetsitterShow from './Components/Petsitters/showProfile.tsx';
import EditService from './Components/Servicios/editService';
import EditPetsitter from './Components/Petsitters/editPetsitter';
import HotelesShow from './Components/Hotels/indexHoteles';
import HotelShow from './Components/Hotels/ShowHotelProfile';
import EditHotel from './Components/Hotels/editHotel';
import IndexAllUsers from './Components/Perfil/allUsersAdmin';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Router>
      <Routes>
            <Route exact path='/' element={< App />}></Route>
            <Route exact path='/landing' element={< WelcomePage />}></Route>
            <Route exact path='/users/validate/:id/:token' element={< VerificacionMail />}></Route>
            <Route path='/perfil/:id' element={< PerfilShow />}></Route>
            <Route path='/perfilVet/:id' element={< PerfilShowVet />}></Route>
            <Route exact path='/perfil/edit/:id' element={< PerfilEdit />}></Route>
            <Route path='/verificationform' element={<VerificationForm/>}></Route>
            <Route exact path='/veterinario/:id' element={< ShowVeterinarian />}></Route>
            <Route path='/veterinarios' element={< IndexVeterinarians />}></Route>
            <Route exact path='/users/:id' element={< ShowUser />}></Route>
            <Route path='/users' element={< IndexUsers />}></Route>
            <Route path='/allUsers' element={< IndexAllUsers />}></Route>
            <Route exact path='users/pets/:id' element={< IndexUserPets />}></Route>
            <Route exact path='/patients' element={< IndexPatients />}></Route>
            <Route exact path='/pets' element={< PetRegister />}></Route>
            <Route exact path='/pets/edit/:id' element={< PetEdit />}></Route>
            <Route exact path='/pets/showAll' element={< Mascotas />}></Route>
            <Route exact path='/pets/delete/:id' element={< PetDelete />}></Route>
            <Route exact path='/blogs' element={< PostShow />}></Route>
            <Route path='/petsHealth/create/:id' element={ <PetsHealthCreate />}></Route>
            <Route path='/petsHealth/edit/:id' element={ <PetsHealthEdit />}></Route>
            <Route path='/petsHealth/showAll/:id' element={ <PetsHealthShowAll />}></Route>
            <Route path='/petsHealth/show/:id' element={ <PetsHealthShow />}></Route>
            <Route path='/petsHealth/show/:id' element={ <PetsHealthShow />}></Route>
            <Route path='/petsHealth/All/:id' element={ <PetsHealthAll />}></Route>
            <Route path='/servicios' element={<ServicesShowAll />}></Route>
            <Route path='/servicios/:id' element={<ServiceShow />}></Route>
            <Route path='/servicios/edit/:id' element={<EditService />}></Route>
            <Route path='/petsitters/' element={<PetsittersShow />}></Route>
            <Route path='/petsitters/edit/:id' element={<EditPetsitter />}></Route>
            <Route path='/petsitters/:id' element={<PetsitterShow />}></Route>
            <Route path='/adminverification' element={<IndexVerifications/>}></Route>
            {/* <Route path='/chat' element={<EmptyChat/>}></Route>
            <Route path='/chat/:id' element={<PrivateChatShow/>}></Route>
            <Route path='/groupChat/:id' element={<GroupChatShow/>}></Route> */}
            <Route path='/adminverification/:id' element={<ShowVerificationVet/>}></Route>
            <Route path='/adminverificationServ/:id' element={<ShowVerificationServ/>}></Route>
            <Route path='/adminverificationPetsitter/:id' element={<ShowVerificationPetsitter/>}></Route>
            <Route path='/adminverificationHotel/:id' element={<ShowVerificationHotel/>}></Route>
            <Route path='/adminverificationPost/:id' element={<ShowVerificationPost/>}></Route>
            <Route path='/calendar' element={<Calendar/>}></Route>
            <Route path='/admindenuncias' element={<IndexDenuncias/>}></Route>
            <Route path='/admindenuncias/:id' element={<ShowDenuncia/>}></Route>
            <Route path='/hoteles/' element={<HotelesShow/>}></Route>
            <Route path='/hotels/:id' element={<HotelShow/>}></Route>
            <Route path='/hotels/edit/:id' element={<EditHotel />}></Route>

      </Routes>
    </Router>
  </Provider>
);
