import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes, { string } from 'prop-types';
import './servicesAndRequests.css';

type ServicesType = {
  id: string,
  close: React.MouseEventHandler,
}

function Services (props: ServicesType) {
  const [requests, setRequests] = useState<any[]>([]);

  async function fetchData() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/petsitters/requests/' + props.id, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setRequests(response.data.allRequests);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="pop-up">
      <h4 className="pop-up-title"> Mis próximos servicios </h4>
      <table>
        <tr>
          <th>Mascota</th>
          <th>Tutor</th>
          <th>Fecha</th>
          <th>Hora inicio</th>
          <th>Hora término</th>
          <th>Contacto</th>
          <th>Información</th>
        </tr>
        {requests.map((request) =>
          <>
          {request.estado_solicitud === 'APROBADA' &&
            <tr className='fila-tabla' key={request.id}>
              <td>{request.name}</td>
              <td>{request.user.name}</td>
              <td>{request.dayPetSitter}</td>
              <td>{request.startTime}</td>
              <td>{request.endTime}</td>
              <td>{request.emergency}</td>
              <td>{request.vetInfo}</td>
            </tr>
          }
          </>
        )}
      </table>
      <div className='center-button'>
        <button className="close-button" onClick={props.close}> Cerrar </button>
      </div>
    </div>
  );
};

Services.propTypes = {
  id: string,
  close: PropTypes.func
};

export default Services;
