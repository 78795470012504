import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { logout } from '../Store/user';
import { SyntheticEvent } from 'react';

function useSignOut(dispatch: any) {
  const navigate = useNavigate();
  return function (event: SyntheticEvent) {
    event.preventDefault();
    // const token = JSON.parse(localStorage.getItem('token') || '');
    axios.get(process.env.REACT_APP_URL + '/session/logout')
      .then(() => {
        dispatch(logout());
        navigate('/');
      });
  };
};

export default useSignOut;
