import React, { useState, SyntheticEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import './ShowVerification.css';
import Navbar from '../Navbar/index';
import Footer from '../Footer';
import swal from 'sweetalert';

function mapStateToProps (state: any) {
  return { action: state };
}

type adminUserProps = {
  action: {
    user: {
      credenciales: {
        rol: number
        id: string
      }
    }
  }
}

function ShowVerificationHotel (props: adminUserProps) {
  const location = useLocation();
  const { request } = location.state;
  const [decline, setDecline] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const navigate = useNavigate();

  function aceptVerification (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/hotels/updateverification/' + request.hotelId, {
      userId: request.userId,
      adminId: props.action.user.credenciales.id,
      accepted: true,
      patente_comercial: request.patente_comercial,
      inicio_actividades: request.inicio_actividades,
      entrega_boleta: request.entrega_boleta,
      ley_21020: request.ley_21020,
      ley_20380: request.ley_20380,
      contrato_trabajo: request.contrato_trabajo,
      reglamento_copropiedad: request.reglamento_copropiedad,
      adherido_mutual: request.adherido_mutual
    }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
      swal({
        title: 'Verificación aceptada',
        icon: 'success',
        buttons: { Aceptar: true },
        timer: 2000
      });
      navigate('/adminverification');
    })
      .catch((error) => {
        console.log(error);
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 5000
        });
      });
  };

  function isDocumented (document: boolean) {
    if (document) {
      return 'Cumple con el requisito ✔';
    }
    return 'No cumple con el requisito ❌';
  };

  function declineVerification (event: SyntheticEvent) {
    event.preventDefault();
    setDecline(!decline);
  };

  function submitResponse (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    axios.post(process.env.REACT_APP_URL + '/hotels/updateverification/' + request.userId, {
      userId: request.userId,
      adminId: props.action.user.credenciales.id,
      accepted: false,
      deniedReason: reason,
      patente_comercial: request.patente_comercial,
      inicio_actividades: request.inicio_actividades,
      entrega_boleta: request.entrega_boleta,
      ley_21020: request.ley_21020,
      ley_20380: request.ley_20380,
      contrato_trabajo: request.contrato_trabajo,
      reglamento_copropiedad: request.reglamento_copropiedad,
      adherido_mutual: request.adherido_mutual
    }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
      swal({
        title: 'Verificación rechazada',
        icon: 'success',
        buttons: { Aceptar: true },
        timer: 2000
      });
      navigate('/adminverification');
    })
      .catch((error) => {
        console.log(error);
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      });
  }

  return (
    <div>
      <Navbar logged={true} />
      <div className='spacer32'></div>
      <h6 className='navigation-info-post-post ms-3'>Verificaciones / {request.name}</h6>
      <div className='spacer32'></div>
      <div className='container-show-ver'>
        <div className='admin-ver-hotel'>
          <div className='row' style={{ margin: '20px 0px', padding: '0px' }}>
            <div className='col-auto texto-informacion' style={{ padding: '0px' }}>
              Información
            </div>
          </div>
          <div className='form-ver-hotel'>
            <div className='col-auto'>
              <div className='spacer12'></div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Nombre del Hotel
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {request.hotelName}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Descripción
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {request.hotelDescription}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Patente Comercial
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.patente_comercial)}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Inicio de Actividades
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.inicio_actividades)}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Entrega de Boleta
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.entrega_boleta)}
                </div>
              </div>
            </div>
            <div className='col-auto'>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Ley 21.020
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.ley_21020)}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Ley 20.380
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.ley_20380)}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Contrato de Trabajo
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.contrato_trabajo)}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Reglamento de Copropiedad
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.reglamento_copropiedad)}
                </div>
              </div>
              <div className='spacer20'></div>
              <div className='row texto-campo'>
                Adherido a una mutualidad
              </div>
              <div className='spacer12'></div>
              <div className='box-campo'>
                <div className='texto-form'>
                  {isDocumented(request.adherido_mutual)}
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0px', padding: '0px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '1vh' }}>
              <button className='acccept-button' onClick={aceptVerification}>Aceptar verificación</button>
              <button className='decline-button' id={'red-button'} onClick={declineVerification}>Rechazar verificación</button>
            </div>
          </div>
          {decline
            ? <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <form onSubmit={(e) => submitResponse(e)} style={{ width: '45%' }}>
                <h3 className='ver-title'>Razón de rechazo</h3>
                <input type='text' minLength={10} maxLength={300} value={reason} onChange={e => setReason(e.target.value)} className='reason-input' />
                <div className='button-verif-petsitter'>
                  <button className="acccept-button" value="submit">Enviar</button>
                </div>
              </form>
            </div>
            : undefined
          }
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default connect(mapStateToProps)(ShowVerificationHotel);
