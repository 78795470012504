import React, { useState, SyntheticEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import './ShowVerification.css';
import Navbar from '../Navbar/index';
import Footer from '../Footer';
import swal from 'sweetalert';
import ReactPlayer from 'react-player';

function mapStateToProps (state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function ShowVerificationPost () {
  const location = useLocation();
  const { request } = location.state;
  const [decline, setDecline] = useState<boolean>(false);
  const [reasonDecline, setReasonDecline] = useState<string>('');
  const navigate = useNavigate();

  console.log(request.post.urlVid);

  async function aceptVerification (event: SyntheticEvent) {
    event.preventDefault();
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/postsAcceptance/' + request.id, { accepted: true }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
      swal({
        title: 'Verificación aceptada',
        icon: 'success',
        buttons: { Aceptar: true },
        timer: 2000
      });
      navigate('/adminverification');
    })
      .catch((error) => {
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 5000
        });
      });
  };

  function declineVerification (event: SyntheticEvent) {
    event.preventDefault();
    setDecline(!decline);
  };

  async function submitResponse (event: SyntheticEvent) {
    event.preventDefault();
    console.log('enviado');
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.post(process.env.REACT_APP_URL + '/postsAcceptance/' + request.id, {
      accepted: false,
      deniedReason: reasonDecline
    }, { headers: { Authorization: `Bearer ${token}` } }).then(() => {
      swal({
        title: 'Verificación rechazada',
        icon: 'success',
        buttons: { Aceptar: true },
        timer: 2000
      });
      navigate('/adminverification');
    })
      .catch((error) => {
        swal({
          title: 'Error',
          text: String(error.response.data.message),
          icon: 'error',
          buttons: { Aceptar: true },
          timer: 2000
        });
      });
  }

  return (
        <div>
            <div className='wrapper'>
            <Navbar logged={true} />
            <div className='spacer32'></div>
            <h6 className='navigation-info-post-post'>Verificaciones / {request.post.title}</h6>
            <div className='spacer32'></div>
            <div className='container-show-ver'>
                <div className='col-auto' style={{ width: '100%' }}>
                <div className='row' style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignContent: 'center', margin: '0px' }}>
                    <div className='col-auto'>
                    <div className='row'>
                        <div className='col-auto'>
                        <div className='Box-foto'>
                            <img src={request.post.urlImg} alt='Image of a post' style={{ borderRadius: '10px', backgroundImage: 'none', width: '10rem' }} />
                        </div>
                        </div>
                        <div className='col-auto' style={{ marginLeft: '58px', padding: '0px' }}>
                        <div className='row'>
                            <div className='col-auto texto-nombre'>{request.post.title}</div>
                            <div className='col-auto' style={{ padding: '0px' }}>
                            </div>
                        </div>
                        <div className='row' >
                            <div className='col-auto texto-mail'>
                            {request.post.category}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                    <div className='row' style={{ margin: '20px 0px', padding: '0px' }}>
                        <div className='col-auto texto-informacion' style={{ padding: '0px' }}>
                            Información
                        </div>
                    </div>
                    <div className='row' style={{ display: 'flex-direction', justifyContent: 'space-between', margin: '0px 0px', padding: '0px' }}>
                        <div className='col-auto' >
                            <div className='row'>
                                <div className='col-auto'>
                                    <div className='spacer12'></div>
                                    <div className='spacer20'></div>
                                    <div className='row texto-campo'>
                                        Categoría
                                    </div>
                                    <div className='spacer12'></div>
                                    <div className='box-campo'>
                                        <div className='texto-form'>
                                            {request.post.category}
                                        </div>
                                    </div>
                                    <div className='spacer20'></div>
                                    <div className='row texto-campo'>
                                        Cuerpo
                                    </div>
                                    <div className='spacer12'></div>
                                    <div className='box-campo'>
                                        <div className='texto-form'>
                                            {request.post.body}
                                        </div>
                                    </div>
                                    <div className='spacer20'></div>
                                    <div className='row texto-campo'>
                                        Video
                                    </div>
                                    <div className='spacer12'></div>
                                    <div className='Box-video-post'>
                                      {request.post.urlVid !== 'None' ? <ReactPlayer url={request.post.urlVid} width='100%' height='100%' controls={true} /> : <div className='texto-form'>No hay video</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', padding: '0px' }}>
                        <div className='col-auto'>
                            <button className='acept-button' onClick={aceptVerification}>Aceptar Verificación</button>
                            <button className='acept-button' onClick={declineVerification}>Rechazar Verificación</button>
                        </div>
                        {decline
                          ? <div>
                            <form onSubmit={(e) => submitResponse(e)} >
                                <h3 className='ver-title'>Razón de rechazo</h3>
                                <input type='text' minLength={10} maxLength={300} value={reasonDecline} onChange={e => setReasonDecline(e.target.value)} className='reason-input'/>
                                <div className="button-parent">
                                    <button className="send-button" value="submit">Enviar</button>
                                </div>
                            </form>
                            </div>
                          : undefined
                        }
                    </div>
                </div>
            </div>
            </div>
        <Footer/>
        </div>
  );
}
export default connect(mapStateToProps)(ShowVerificationPost);
