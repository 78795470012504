import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Perfil.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import RegionesYcomunas from '../RegisterForm/regionesComunas';
import axios from 'axios';
import Navbar from '../Navbar';
import { PerfilTypes } from '../../Types/PerfilTypes';
import user from '../../Assets/Imagenes/blank-profile-picture-.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Footer from '../Footer';
// import perfil from '../../Assets/Imagenes/perfil.png';
// import * as AWS from 'aws-sdk';

import { connect } from 'react-redux';
import { vetsAreas } from '../../const/vetsAreas';
function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

// AWS.config.update({
//   region: 'sa-east-1',
//   accessKeyId: 'AKIA32WNBZ2W67JECHVK',
//   secretAccessKey: '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'
// });

// const s3 = new AWS.S3();

function PerfilEdit(props: PerfilTypes) {
  const [id, setId] = useState<string>('');
  const [rut, setRut] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [addressComuna, setAddressComuna] = useState<string>('');
  const [addressRegion, setAddressRegion] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [gender, setGender] = useState<string>('');
  const [profileUrlImg, setProfileUrlImg] = useState<string>('');
  const [platformCat, setPlatformCat] = useState<string>('');
  const [siiGiro, setSiiGiro] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [comunas, setComunas] = useState<Array<string>>([]);
  const [respuesta, setRespuesta] = useState<boolean>(false);
  const [userType, setUserType] = useState<string>('');
  const [password, setPassword] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [passwordNotEqual, setPasswordNotEqual] = useState<boolean>();
  const [area, setArea] = useState<string>('');
  const [verifiedStatus, setVerifiedStatus] = useState<string>('');
  const navigate = useNavigate();

  async function fetchUserData(userType: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + `/${userType}s/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setId(response.data[userType].id);
        setRut(response.data[userType].rut);
        setAddress(response.data[userType].address);
        setAddressComuna(response.data[userType].addressComuna);
        setAddressRegion(response.data[userType].addressRegion);
        setName(response.data[userType].name);
        setPhone(prettifyPhoneNumber(response.data[userType].phone));
        setEmail(response.data[userType].email);
        setGender(response.data[userType].gender);
        setProfileUrlImg(response.data[userType].profileUrlImg);
        if (response.data[userType].birthdate) {
          setBirthdate(response.data[userType].birthdate.substring(0, 10));
        }
        Object.values(RegionesYcomunas)[0].forEach(region => {
          if (region.NombreRegion === response.data[userType].addressRegion) {
            setComunas(region.comunas);
          }
        });
        setRespuesta(true);
      });
  }

  async function fetchVetData(userType: string) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + `/${userType}s/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setId(response.data[userType].id);
        setRut(response.data[userType].rut);
        setAddress(response.data[userType].address);
        setAddressComuna(response.data[userType].addressComuna);
        setAddressRegion(response.data[userType].addressRegion);
        setName(response.data[userType].name);
        setArea(response.data[userType].area);
        setPhone(prettifyPhoneNumber(response.data[userType].phone));
        setEmail(response.data[userType].email);
        setGender(response.data[userType].gender);
        setProfileUrlImg(response.data[userType].profileUrlImg);
        setVerifiedStatus(response.data[userType].verified_status);
        if (response.data[userType].birthdate) {
          setBirthdate(response.data[userType].birthdate.substring(0, 10));
        }
        Object.values(RegionesYcomunas)[0].forEach(region => {
          if (region.NombreRegion === response.data[userType].addressRegion) {
            setComunas(region.comunas);
          }
        });
        setRespuesta(true);
      });
  }

  async function fetchServiceData() {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + `/services/${props.action.user.credenciales.id}`, { headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        setId(response.data.service.id);
        setAddress(response.data.service.address);
        setAddressComuna(response.data.service.addressComuna);
        setAddressRegion(response.data.service.addressRegion);
        setName(response.data.service.name);
        setPhone(prettifyPhoneNumber(response.data.service.phone));
        setEmail(response.data.service.email);
        setProfileUrlImg(response.data.service.profileUrlImg);
        setSiiGiro(response.data.service.siiGriro);
        setLink(response.data.service.link);
        setDescription(response.data.service.description);
        setPlatformCat(response.data.service.platformCat);
        Object.values(RegionesYcomunas)[0].forEach(region => {
          if (region.NombreRegion === response.data.service.addressRegion) {
            setComunas(region.comunas);
          }
        });
        setRespuesta(true);
      });
  }

  useEffect(() => {
    if (props.action.user.credenciales.isService) {
      fetchServiceData();
      setUserType('service');
    } else {
      if (props.action.user.credenciales.rol === 1) {
        fetchUserData('user');
        setUserType('user');
      } else {
        fetchVetData('vet');
        setUserType('vet');
      }
    }
  }, []);

  async function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (password === passwordConfirmation) {
      if (password !== '') {
        setPhone(formatPhoneNumber(phone));
        if (userType === 'user') {
          const token = JSON.parse(localStorage.getItem('token') || '');
          axios.patch(process.env.REACT_APP_URL + `/${userType}s/` + id, {
            id,
            rut,
            address,
            addressComuna,
            addressRegion,
            name,
            phone: formatPhoneNumber(phone),
            email,
            birthdate,
            gender,
            profileUrlImg,
            password
          }, { headers: { Authorization: 'Bearer ' + token } }
          );
          navigate('/perfil/' + id);
        } else if (userType === 'vet') {
          const token = JSON.parse(localStorage.getItem('token') || '');
          axios.patch(process.env.REACT_APP_URL + `/${userType}s/` + id, {
            id,
            rut,
            address,
            addressComuna,
            addressRegion,
            name,
            phone: formatPhoneNumber(phone),
            email,
            gender,
            profileUrlImg,
            password,
            birthdate,
            area
          }, { headers: { Authorization: 'Bearer ' + token } }
          );
          navigate('/perfilVet/' + id);
        } else {
          const token = JSON.parse(localStorage.getItem('token') || '');
          axios.patch(process.env.REACT_APP_URL + `/${userType}s/` + id, {
            id,
            siiGiro,
            address,
            addressComuna,
            addressRegion,
            name,
            phone: formatPhoneNumber(phone),
            email,
            link,
            profileUrlImg,
            description,
            platformCat,
            password
          }, { headers: { Authorization: 'Bearer ' + token } }
          );
          navigate('/perfil/' + id);
        }
      } else {
        if (userType === 'user') {
          const token = JSON.parse(localStorage.getItem('token') || '');
          axios.patch(process.env.REACT_APP_URL + `/${userType}s/` + id, {
            id,
            rut,
            address,
            addressComuna,
            addressRegion,
            name,
            phone: formatPhoneNumber(phone),
            email,
            gender,
            profileUrlImg
          }, { headers: { Authorization: 'Bearer ' + token } }
          );
          navigate('/perfil/' + id);
        } else if (userType === 'vet') {
          const token = JSON.parse(localStorage.getItem('token') || '');
          axios.patch(process.env.REACT_APP_URL + `/${userType}s/` + id, {
            id,
            rut,
            address,
            addressComuna,
            addressRegion,
            name,
            phone: formatPhoneNumber(phone),
            email,
            gender,
            profileUrlImg,
            area
          }, { headers: { Authorization: 'Bearer ' + token } }
          );
          navigate('/perfilVet/' + id);
        } else {
          const token = JSON.parse(localStorage.getItem('token') || '');
          axios.patch(process.env.REACT_APP_URL + `/${userType}s/` + id, {
            id,
            siiGiro,
            address,
            addressComuna,
            addressRegion,
            name,
            phone: formatPhoneNumber(phone),
            email,
            link,
            profileUrlImg,
            description,
            platformCat
          }, { headers: { Authorization: 'Bearer ' + token } }
          );
          navigate('/perfil/' + id);
        }
      }
    } else {
      setPasswordNotEqual(true);
    }
  }

  const handlePhoneChange = (e: any) => {
    const formattedNumber = prettifyPhoneNumber(e.target.value);
    setPhone(formattedNumber);
  };

  function handleComunas(event: SyntheticEvent) {
    event.preventDefault();
    const currentRegion = (event.target as HTMLInputElement).value;
    setAddressRegion(currentRegion);
    Object.values(RegionesYcomunas)[0].forEach(region => {
      if (region.NombreRegion === currentRegion) {
        setComunas(region.comunas);
      }
    });
  };

  function prettifyPhoneNumber(phoneNumber:string) {
    const formattedNumber = phoneNumber.replace(/\s/g, '');
    if (formattedNumber.length > 4) {
      const countryCode = formattedNumber.slice(0, 4); // +569
      const firstPart = formattedNumber.slice(4, 8);
      const secondPart = formattedNumber.slice(8);
      return `${countryCode} ${firstPart} ${secondPart}`;
    }
    return phoneNumber;
  }

  function formatPhoneNumber(phoneNumber:string) {
    return phoneNumber.replace(/\s/g, '');
  }

  function convertToBase64(file: any) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };
  async function onFileChange(event: any) {
    event.preventDefault();
    const convertedFile = await convertToBase64(event.target.files[0]);
    setProfileUrlImg(String(convertedFile));
  };

  return (
    <>
      <Navbar logged={true} />
      {!respuesta
        ? <div>Cargando</div>
        : <div>
          <div className='spacer32'></div>
          {props.action.user.credenciales.rol === 1
            ? <h6 className='navigation-info-post-post ms-3'><Link to={'/perfil/' + props.action.user.credenciales.id} className='link-perfil'>Perfil</Link> / Editar / {name}</h6>
            : props.action.user.credenciales.rol === 2 && verifiedStatus === 'verified'
              ? <h6 className='navigation-info-post-post ms-3'><Link to={'/perfilVet/' + props.action.user.credenciales.id} className='link-perfil'>Perfil</Link> / Editar / {name}</h6>
              : <h6 className='navigation-info-post-post ms-3'><Link to={'/perfil/' + props.action.user.credenciales.id} className='link-perfil'>Perfil</Link> / Editar / {name}</h6>}
          <div className='spacer32'></div>
          <div className='container-perfil' >
            <div className='col-auto' style={{ width: '100%' }}>
              <div className='row' style={{ display: 'flex', width: '100%', justifyContent: 'center', alignContent: 'center', margin: '0px' }}>
                <div className='col-auto'>
                  <div className='row'>
                    <div className='col-auto'>
                        <img src={profileUrlImg !== null ? profileUrlImg : user} className="img-thumbnail"
                          style={{ backgroundColor: '#00BBC7', margin: '40px 20px', height: '44vh' }} />
                        <div className='spacer-12'></div>
                        <div className="box-image-upload">
                        <fieldset>
                          <p style={{ display: 'flex', justifyContent: 'center' }}>
                            <label htmlFor="profileUrlImg" className="upload-label">
                              Foto de Perfil
                              <input
                                className="register-form-input"
                                type="file"
                                onChange={onFileChange}
                                name="profileUrlImg"
                                id="profileUrlImg"
                              />
                              <CloudUploadIcon className="upload-icon" />
                            </label>
                          </p>
                        </fieldset>
                        </div>
                    </div>
                    <div className='col-auto' style={{ marginLeft: '58px', padding: '0px' }}>
                      <fieldset className='fieldsets' style={{ flexDirection: 'column' }}>
                        <form onSubmit={handleSubmit}>
                          <fieldset>
                            <p>
                              <input className='texto-nombre-perfil' name="name" onChange={(e) => setName(e.target.value)} value={name} required/>
                              <label htmlFor='phone'>Nombre</label>
                            </p>
                          </fieldset>
                          <fieldset>
                            <p>
                              <input className='texto-nombre-perfil' name="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                              <label htmlFor='phone'>Mail</label>
                            </p>
                          </fieldset>
                            {passwordNotEqual === true &&
                              <p className='password-error'>Las contraseñas no coinciden</p>
                            }
                            <fieldset>
                              <p>
                                <input className='register-form-input' type="text" placeholder=' ' value={phone} onChange={(e) => handlePhoneChange(e)} name="phone" />
                                <label htmlFor='phone'>Teléfono</label>
                              </p>
                            </fieldset>
                            <fieldset>
                              <p>
                                {props.action.user.credenciales.isService
                                  ? <select
                                    id="platformCat"
                                    name="platformCat"
                                    className="register-selector"
                                    value={platformCat}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                  >
                                    <option value="ALIMENTOS Y SNACKS">ALIMENTOS Y SNACKS</option>
                                    <option value="ACCESORIOS Y JUGUETES">ACCESORIOS Y JUGUETES</option>
                                    <option value="CUIDADO E HIGIENE">CUIDADO E HIGIENE</option>
                                    <option value="FARMACIA">FARMACIA</option>
                                    <option value="OTRO">OTRO</option>
                                    <option value="SERVICIOS VETERINARIOS">SERVICIOS VETERINARIOS</option>
                                    <option value="PELUQUERIAS Y ESTÉTICA ">PELUQUERÍAS Y ESTÉTICA</option>
                                    <option value="HOTELES  Y GUARDERIA PARA MASCOTAS">HOTELES Y GUARDERIA PARA MASCOTAS</option>
                                    <option value="ADIESTRAMIENTO">ADIESTRAMIENTO</option>
                                    <option value="PET SITTER">PETSITTER</option>
                                    <option value="TRANSPORTE MASCOTAS">TRANSPORTE MASCOTAS</option>
                                    <option value="CAFETERIAS PET FRIENDLY">CAFETERÍAS PET FRIENDLY</option>
                                    <option value="CREMATORIOS Y CEMENTERIOS">CREMATORIOS Y CEMENTERIOS</option>
                                    <option value="FOTOGRAFIA Y RETRATOS">FOTOGRAFÍA Y RETRATOS</option>
                                    <option value="ORGANIZADOR DE EVENTOS">ORGANIZADOR DE EVENTOS</option>
                                    <option value="SPA PARA MASCOTAS">SPA PARA MASCOTAS</option>
                                    <option value="SEGUROS PARA MASCOTAS">SEGUROS PARA MASCOTAS</option>
                                    <option value="MEDICOS VETERINARIOS A DOMICILIO">MÉDICOS/AS VETERINARIOS/AS A DOMICILIO</option>
                                    <option value="CONSULTAS MÉDICAS VETERINARIAS DE ESPECIALIDAD">CONSULTAS MÉDICAS VETERINARIAS DE ESPECIALIDAD</option>
                                    <option value="PASEADOR DE MASCOTAS">PASEADOR DE MASCOTAS</option>
                                  </select>
                                  : <input type="date" name="birthdate" className="register-form-input" value={birthdate ? birthdate.substring(0, 10) : ''} onChange={(e) => setBirthdate(e.target.value)} />
                                }
                                <label htmlFor={props.action.user.credenciales.isService ? 'category' : 'birthdate'}>{props.action.user.credenciales.isService ? 'Categoría' : 'Fecha de Nacimiento'}</label>
                              </p>
                            </fieldset>
                            <fieldset>
                              <p>
                                <input className='register-form-input' placeholder=' ' type="text" value={address} onChange={(e) => setAddress(e.target.value)} name="address" />
                                <label htmlFor='address'>Dirección</label>
                              </p>
                            </fieldset>
                            <fieldset>
                              <p>
                                {!props.action.user.credenciales.isService &&
                                  <>
                                    <select
                                      id="gender"
                                      name="gender"
                                      className="register-selector form-select"
                                      value={gender}
                                      onChange={(e) => setGender(e.target.value)}
                                      required
                                      placeholder='genero'
                                    >
                                      <option value="MALE">Masculino</option>
                                      <option value="FEMALE">Femenino</option>
                                      <option value="OTHER">Otro</option>
                                    </select>
                                  </>
                                }
                                {props.action.user.credenciales.isService &&
                                  <input name='link' className="box-campo" value={link} onChange={(e) => setLink(e.target.value)} />
                                }
                                <label className='gender-input' htmlFor={props.action.user.credenciales.isService ? 'link' : 'gender'}>{props.action.user.credenciales.isService ? 'Link' : 'Género'}</label>
                              </p>
                            </fieldset>
                            <fieldset>
                              <p>
                                <select
                                  id="addressRegion"
                                  name="addressRegion"
                                  className="register-selector form-select"
                                  value={addressRegion}
                                  onChange={(e) => handleComunas(e)}
                                  required
                                >
                                  <option value="" selected disabled hidden></option>
                                  {Object.values(RegionesYcomunas)[0].map((region) => {
                                    return <option key={region.NombreRegion}>{region.NombreRegion}</option>;
                                  })}
                                </select>
                                <label htmlFor='addressRegion'>Región</label>
                              </p>
                            </fieldset>
                            <fieldset>
                              <p>
                                <select
                                  id="addressComuna"
                                  name="addressComuna"
                                  className="register-selector form-select"
                                  value={addressComuna}
                                  onChange={(e) => setAddressComuna(e.target.value)}
                                  required
                                >
                                  <option value="" selected disabled hidden></option>
                                  {comunas.map((comuna) => (
                                    <option key={comuna}>{comuna}</option>
                                  ))}
                                </select>
                                <label htmlFor='addressComuna'>Comuna</label>
                              </p>
                            </fieldset>
                            {((props.action.user.credenciales.rol === 2 && verifiedStatus === 'verified') || props.action.user.credenciales.rol === 3) &&
                              <fieldset>
                                <p>
                                  <select placeholder="Área de desempeño" id="area" name="area" className="register-selector form-select" value={area} onChange={(e) => setArea(e.target.value)} required>
                                    <option value="" defaultValue="" disabled hidden>Área de desempeño</option>
                                    {vetsAreas.map((area) => {
                                      return <option key={area}>{area}</option>;
                                    })}
                                  </select>
                                  <label htmlFor="addressRegion">Área de desempeño</label>
                                </p>
                              </fieldset>
                            }

                            {props.action.user.credenciales.isService &&
                              <fieldset>
                                <p>
                                  <input className='register-form-input' type="text" placeholder=' ' value={description} onChange={(e) => setDescription(e.target.value)} name="description" />
                                  <label htmlFor='description'>Descripción</label>
                                </p>
                              </fieldset>
                            }
                            <fieldset>
                              <p>
                              <p className='password-text'>
                              Rellena estos campos sólo si deseas cambiar de contraseña:
                              </p>
                                <input id='password' type="password" className='register-form-input' placeholder=' ' value={password} onChange={(e) => setPassword(e.target.value)} name="password" />
                                <label htmlFor='password'>Nueva Contraseña</label>
                              </p>
                            </fieldset>
                            <fieldset>
                              <p>
                                <input id='passwordConfirmation' type="password" className='register-form-input' placeholder=' ' value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} name="passwordConfirmation" />
                                <label htmlFor='passwordConfirmation'>Confirmar Nueva Contraseña</label>
                              </p>
                            </fieldset>
                          <button value="submit" id='create-account' className='save-button' style={{ height: '60px', width: '160px' }} >
                            Guardar
                          </button>
                        </form>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Footer/>
    </>

  );
}

export default connect(mapStateToProps)(PerfilEdit);
