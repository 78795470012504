import React, { useState, useEffect, FC, ReactElement } from 'react';
import perrito from '../../Assets/Imagenes/perrito.svg';
import axios from 'axios';
import { connect } from 'react-redux';
import './PetsHealth.css';
import moment from 'moment';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { petEntry, propsType, reduxAction } from '../../Types/petHealthTypes';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Pet } from '../../Types/petType';
import swal from 'sweetalert';
import RegionesYcomunas from '../RegisterForm/regionesComunas';
import dermogramaCanino from '../../Assets/Imagenes/Dermograma/Canino/Dermograma.png';
import dermogramaFelino from '../../Assets/Imagenes/Dermograma/Felino/Dermograma.png';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { prettifyRut, formatRut } from 'react-rut-formatter';

function mapStateToProps (state: reduxAction) {
  return { action: state }; // this will be available in HomeScreen as props.action
}
const PetHealthEdit : FC <propsType> = (props): ReactElement => {
  const [petHealthEntry, setPetHealthEntry] = useState<petEntry>();
  const [files, setFiles] = useState<Array<string>>([]);
  const [filesNames, setFilesNames] = useState<Array<string>>([]);
  const [pet, setPet] = useState<Pet>();
  const navigate = useNavigate();
  const { id } = useParams();
  const location : any = useLocation();
  const [rutVet, setRutVet] = useState<string>('');
  const [phoneVet, setPhoneVet] = useState<string>('');
  const [phoneNumber1, setPhoneNumber1] = useState<string>('');
  const [phoneNumber2, setPhoneNumber2] = useState<string>('');
  const [rutTutor, setRutTutor] = useState<string>('');
  const [show, setShow] = useState<Array<boolean>>([]);
  const [examChecks, setExamChecks] = useState({
    hemograma: false,
    perfil_bioquimico: false,
    hem_pb: false,
    hem_pb_t4: false,
    hem_pb_t4_tsh: false,
    prequirurgico_basico: false,
    prequirurgico_completo: false,
    protombina: false,
    tromboplastina: false,
    panel_coagulacion: false,
    coagulacion_infecc: false,
    perfil_renal: false,
    perfil_hepatico: false,
    perfil_lipidico: false,
    t4_tsh: false,
    perfil_lipidico_t4_tsh: false,
    felv_fiv: false,
    parvovirus_distemper: false,
    brucelosis: false,
    distemper: false,
    leucemia_felina: false,
    inmunodeficiencia_felina: false,
    ehrlichia_anaplasma: false,
    parvovirus: false,
    toxoplasma: false,
    cultivo_antibiograma_citologico_oidos: false,
    directo_piel_cultivo_dermatofitos: false,
    directo_piel_frotis_malassesia_cultivo_dermatofitos: false,
    urianalisis: false,
    urocultivo_antibiograma: false,
    coproparasitario: false,
    coprocultivo: false,
    cultivo_antibiograma: false,
    directo_piel_pelos: false,
    radiografia: false,
    ecotomografia: false
  });

  const [injuryZones, setInjuryZones] = useState<Array<string>>([]);

  const fetchPetEntry = async () => {
    const token = JSON.parse(localStorage.getItem('token') || '');
    await axios.get(process.env.REACT_APP_URL + '/healthEntry/' + id, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setRutVet(prettifyRut(response.data.healthEntry.rut));
        setPhoneVet(prettifyPhoneNumber(response.data.healthEntry.phoneVet));
        setPhoneNumber1(prettifyPhoneNumber(response.data.healthEntry.phoneNumber1));
        try {
          setPhoneNumber2(prettifyPhoneNumber(response.data.healthEntry.phoneNumber2));
        } catch (error) {
          setPhoneNumber2('');
        }
        setRutTutor(prettifyRut(response.data.healthEntry.documentNumber));
        setPetHealthEntry(response.data.healthEntry);
        setPetHealthEntry((prevState: any) => ({
          ...prevState,
          filesNumber: 0
        }));
        setExamChecks({
          hemograma: response.data.healthEntry.hemograma,
          perfil_bioquimico: response.data.healthEntry.perfil_bioquimico,
          hem_pb: response.data.healthEntry.hem_pb,
          hem_pb_t4: response.data.healthEntry.hem_pb_t4,
          hem_pb_t4_tsh: response.data.healthEntry.hem_pb_t4_tsh,
          prequirurgico_basico: response.data.healthEntry.prequirurgico_basico,
          prequirurgico_completo: response.data.healthEntry.prequirurgico_completo,
          protombina: response.data.healthEntry.protombina,
          tromboplastina: response.data.healthEntry.tromboplastina,
          panel_coagulacion: response.data.healthEntry.panel_coagulacion,
          coagulacion_infecc: response.data.healthEntry.coagulacion_infecc,
          perfil_renal: response.data.healthEntry.perfil_renal,
          perfil_hepatico: response.data.healthEntry.perfil_hepatico,
          perfil_lipidico: response.data.healthEntry.perfil_lipidico,
          t4_tsh: response.data.healthEntry.t4_tsh,
          perfil_lipidico_t4_tsh: response.data.healthEntry.perfil_lipidico_t4_tsh,
          felv_fiv: response.data.healthEntry.felv_fiv,
          parvovirus_distemper: response.data.healthEntry.parvovirus_distemper,
          brucelosis: response.data.healthEntry.brucelosis,
          distemper: response.data.healthEntry.distemper,
          leucemia_felina: response.data.healthEntry.leucemia_felina,
          inmunodeficiencia_felina: response.data.healthEntry.inmunodeficiencia_felina,
          ehrlichia_anaplasma: response.data.healthEntry.ehrlichia_anaplasma,
          parvovirus: response.data.healthEntry.parvovirus,
          toxoplasma: response.data.healthEntry.toxoplasma,
          cultivo_antibiograma_citologico_oidos: response.data.healthEntry.cultivo_antibiograma_citologico_oidos,
          directo_piel_cultivo_dermatofitos: response.data.healthEntry.directo_piel_cultivo_dermatofitos,
          directo_piel_frotis_malassesia_cultivo_dermatofitos: response.data.healthEntry.directo_piel_frotis_malassesia_cultivo_dermatofitos,
          urianalisis: response.data.healthEntry.urianalisis,
          urocultivo_antibiograma: response.data.healthEntry.urocultivo_antibiograma,
          coproparasitario: response.data.healthEntry.coproparasitario,
          coprocultivo: response.data.healthEntry.coprocultivo,
          cultivo_antibiograma: response.data.healthEntry.cultivo_antibiograma,
          directo_piel_pelos: response.data.healthEntry.directo_piel_pelos,
          radiografia: response.data.healthEntry.radiografia,
          ecotomografia: response.data.healthEntry.ecotomografia
        });
        const lesiones = [
          'Cabeza, vista ventral',
          'Cuello, vista ventral',
          'Miembro anterior izquierdo, vista ventral',
          'Miembro anterior derecho, vista ventral',
          'Miembro posterior izquierdo, vista ventral',
          'Miembro posterior derecho, vista ventral',
          'Región inguinal izquierda',
          'Región lateral - Flanco izquierdo',
          'Hipocondrio izquierdo',
          'Región inguinal derecha',
          'Región lateral - Flanco derecho',
          'Hipocondrio derecho',
          'Región púbica',
          'Región umbilical',
          'Región xifoidea',
          'Cabeza, vista dorsal',
          'Cuello, vista dorsal',
          'Miembro anterior izquierdo, vista dorsal',
          'Miembro anterior derecho, vista dorsal',
          'Miembro posterior izquierdo, vista dorsal',
          'Miembro posterior derecho, vista dorsal',
          'Cabeza, vista lateral derecha',
          'Cabeza, vista lateral izquierda'
        ];
        setInjuryZones(lesiones);
        console.log(response.data.healthEntry.petChartPlaces);
        let found = false;
        for (let i = 0; i < 23; i++) {
          found = false;
          for (let j = 0; j < response.data.healthEntry.petChartPlaces.length; j++) {
            if (lesiones[i] === response.data.healthEntry.petChartPlaces[j]) {
              setShow(current => [...current, true]);
              found = true;
            }
          }
          if (!found) {
            setShow(current => [...current, false]);
          }
        }
        console.log(show);
      });
  };

  delete petHealthEntry?.files;

  const token = props.action.user.credenciales.token;
  const fetchPetInfo = async () => {
    await axios.get(process.env.REACT_APP_URL + '/pets/' + location?.state?.petId, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setPet(res.data.pet);
      });
  };

  useEffect(() => {
    fetchPetEntry();
    fetchPetInfo();
  }, []);

  function convertToBase64 (file: any) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  async function onFileChange (event: any) {
    console.log('entra a onFileChange');
    event.preventDefault();
    if (sizeVerification(event.target.files[0])) {
      setFilesNames(current => [...current, event.target.files[0].name]);
      console.log('tiene el tamaño');
      const convertedFile = await convertToBase64(event.target.files[0]);
      setFiles(current => [...current, String(convertedFile)]);
      swal({
        title: 'Archivo ingresado correctamente',
        text: 'Puede ingresar hasta 5 archivos si lo desea',
        icon: 'success',
        buttons: { Aceptar: true },
        timer: 3000
      });
    } else {
      swal({
        title: 'Error',
        text: 'El archivo supera los 10MB',
        icon: 'error',
        buttons: { Aceptar: true },
        timer: 4000
      });
    }
  };

  function sizeVerification (file: any) {
    const size = file.size;
    if (size < 10000000) {
      return true;
    } else {
      return false;
    }
  }

  function updateFiles () {
    console.log('files length ', files.length);
    setPetHealthEntry((prevState: any) => ({
      ...prevState,
      filesNumber: files.length
    }));
    if (files.length === 0) {
      console.log('entra aca');
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        filesNumber: files.length
      }));
    } else if (files.length === 1) {
      console.log('1 archivo');
      console.log(files.length);
      console.log('files[0] ', files[0]);
      if (petHealthEntry !== undefined) {
        petHealthEntry.filesNumber = files.length;
        petHealthEntry.file0 = files[0];
        petHealthEntry.file0_name = filesNames[0];
      }
      console.log(petHealthEntry);
    } else if (files.length === 2) {
      if (petHealthEntry !== undefined) {
        petHealthEntry.filesNumber = files.length;
        petHealthEntry.file0 = files[0];
        petHealthEntry.file0_name = filesNames[0];
        petHealthEntry.file1 = files[1];
        petHealthEntry.file1_name = filesNames[1];
      }
    } else if (files.length === 3) {
      if (petHealthEntry !== undefined) {
        petHealthEntry.filesNumber = files.length;
        petHealthEntry.file0 = files[0];
        petHealthEntry.file0_name = filesNames[0];
        petHealthEntry.file1 = files[1];
        petHealthEntry.file1_name = filesNames[1];
        petHealthEntry.file2 = files[2];
        petHealthEntry.file2_name = filesNames[2];
      }
    } else if (files.length === 4) {
      if (petHealthEntry !== undefined) {
        petHealthEntry.filesNumber = files.length;
        petHealthEntry.file0 = files[0];
        petHealthEntry.file0_name = filesNames[0];
        petHealthEntry.file1 = files[1];
        petHealthEntry.file1_name = filesNames[1];
        petHealthEntry.file2 = files[2];
        petHealthEntry.file2_name = filesNames[2];
        petHealthEntry.file3 = files[3];
        petHealthEntry.file3_name = filesNames[3];
      }
    } else if (files.length === 5) {
      if (petHealthEntry !== undefined) {
        petHealthEntry.filesNumber = files.length;
        petHealthEntry.file0 = files[0];
        petHealthEntry.file0_name = filesNames[0];
        petHealthEntry.file1 = files[1];
        petHealthEntry.file1_name = filesNames[1];
        petHealthEntry.file2 = files[2];
        petHealthEntry.file2_name = filesNames[2];
        petHealthEntry.file3 = files[3];
        petHealthEntry.file3_name = filesNames[3];
        petHealthEntry.file4 = files[4];
        petHealthEntry.file4_name = filesNames[4];
      }
    }
  }

  const handleChangeCorporal = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    let floatValue = parseFloat(value);

    if (floatValue > 5) {
      floatValue = 5;
    } else if (floatValue < 1) {
      floatValue = 1;
    }
    value = floatValue.toString();
    setPetHealthEntry((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorEmpty(false);
  };

  const [errorNameVet, setErrorNameVet] = useState<boolean>(false);
  const [errorRut, setErrorRut] = useState<boolean>(false);
  const [errorPlace, setErrorPlace] = useState<boolean>(false);
  const [errorFullName, setErrorFullName] = useState<boolean>(false);
  const [errorNationality, setErrorNationality] = useState<boolean>(false);
  const [errorDocumentNumber, setErrorDocumentNumber] = useState<boolean>(false);
  const [errorBirthday, setErrorBirthday] = useState<boolean>(false);
  const [errorRegion, setErrorRegion] = useState<boolean>(false);
  const [errorComuna, setErrorComuna] = useState<boolean>(false);
  const [errorHomeAddress, setErrorHomeAddress] = useState<boolean>(false);
  const [errorPhone1, setErrorPhone1] = useState<boolean>(false);
  const [errorWeight, setErrorWeight] = useState<boolean>(false);
  const [errorTemperature, setErrorTemperature] = useState<boolean>(false);
  const [errorMail, setErrorMail] = useState<boolean>(false);
  const [errorMailVet, setErrorMailVet] = useState<boolean>(false);
  const [errorPhoneVet, setErrorPhoneVet] = useState<boolean>(false);
  const [errorAr, setErrorAr] = useState<boolean>(false);
  const [errorApInternal, setErrorApInternal] = useState<boolean>(false);
  const [errorApExternal, setErrorApExternal] = useState<boolean>(false);
  const [errorEmpty, setErrorEmpty] = useState<boolean>(false);

  function handleSubmit () {
    if (!petHealthEntry?.birthdayDate ||
      !petHealthEntry?.place ||
      !petHealthEntry?.weight ||
      !petHealthEntry?.temperature ||
      !petHealthEntry?.documentNumber ||
      !petHealthEntry?.region ||
      !petHealthEntry?.comuna ||
      !petHealthEntry?.fullName ||
      !petHealthEntry?.nationality ||
      !petHealthEntry?.phoneNumber1 ||
      !petHealthEntry?.homeAddress ||
      !petHealthEntry?.nameVet ||
      !petHealthEntry?.rut ||
      !petHealthEntry?.mail ||
      !petHealthEntry?.mailVet ||
      petHealthEntry?.arVaccine === undefined ||
      (petHealthEntry?.apInternal === undefined || (petHealthEntry?.apInternal && !petHealthEntry?.apInternalDescription)) ||
      (petHealthEntry?.apExternal === undefined || (petHealthEntry?.apExternal && !petHealthEntry?.apExternalDescription)) ||
      !petHealthEntry?.phoneVet) {
      setErrorEmpty(true);
      if (!petHealthEntry?.nameVet) { setErrorNameVet(true); }
      if (!petHealthEntry?.place) { setErrorPlace(true); }
      if (!petHealthEntry?.rut) { setErrorRut(true); }
      if (!petHealthEntry?.fullName) { setErrorFullName(true); }
      if (!petHealthEntry?.nationality) { setErrorNationality(true); }
      if (!petHealthEntry?.documentNumber) { setErrorDocumentNumber(true); }
      if (!petHealthEntry?.region) { setErrorRegion(true); }
      if (!petHealthEntry?.comuna) { setErrorComuna(true); }
      if (!petHealthEntry?.homeAddress) { setErrorHomeAddress(true); }
      if (!petHealthEntry?.birthdayDate) { setErrorBirthday(true); }
      if (!petHealthEntry?.phoneNumber1) { setErrorPhone1(true); }
      if (!petHealthEntry?.weight) { setErrorWeight(true); }
      if (!petHealthEntry?.temperature) { setErrorTemperature(true); }
      if (!petHealthEntry?.mail) { setErrorMail(true); }
      if (!petHealthEntry?.mailVet) { setErrorMailVet(true); }
      if (!petHealthEntry?.phoneVet) { setErrorPhoneVet(true); }
      if (petHealthEntry?.arVaccine === undefined) { setErrorAr(true); }
      if (petHealthEntry?.apInternal === undefined || (petHealthEntry?.apInternal && !petHealthEntry?.apInternalDescription)) { setErrorApInternal(true); }
      if (petHealthEntry?.apExternal === undefined || (petHealthEntry?.apExternal && !petHealthEntry?.apExternalDescription)) { setErrorApExternal(true); }
    } else {
      updateFiles();
      const token = props.action.user.credenciales.token;
      axios.patch(process.env.REACT_APP_URL + '/healthEntry/' + id, { ...petHealthEntry, ...examChecks }, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          navigate(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function volver () {
    navigate(-1);
  };

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setExamChecks(prevChecks => {
      const newChecks = {
        ...prevChecks,
        [name]: checked
      };
      return newChecks;
    });
  };

  const handleChangeControlDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const correctDateFormat = e.target.value + 'T00:00:00.000Z';
    setPetHealthEntry((prevState: any) => ({
      ...prevState,
      controlDate: correctDateFormat
    }));
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'rut') {
      setRutVet(prettifyRut(value));
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: formatRut(value)
      }));
    } else if (name === 'phoneVet') {
      setPhoneVet(prettifyPhoneNumber(value));
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: formatPhoneNumber(value)
      }));
    } else if (name === 'phoneNumber1') {
      setPhoneNumber1(prettifyPhoneNumber(value));
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: formatPhoneNumber(value)
      }));
    } else if (name === 'phoneNumber2') {
      setPhoneNumber2(prettifyPhoneNumber(value));
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: formatPhoneNumber(value)
      }));
    } else if (name === 'documentNumber') {
      setRutTutor(prettifyRut(value));
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: formatRut(value)
      }));
    } else {
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: value
      }));
    }
  };
  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setPetHealthEntry((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    const valor = parseInt(value);
    setPetHealthEntry((prevState: any) => ({
      ...prevState,
      [name]: valor
    }));
  };
  const handleChangeBoolean = (e: React.MouseEvent<HTMLElement>) => {
    const value = e.currentTarget.getAttribute('value');
    const name = e.currentTarget.getAttribute('name');
    if (value === 'false' && name) {
      const valor : boolean = false;
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: valor
      }));
    } else if (name) {
      const valor : boolean = true;
      setPetHealthEntry((prevState: any) => ({
        ...prevState,
        [name]: valor
      }));
    }
  };
  const dropdownChangedHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setPetHealthEntry((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const multipleDropdownChangedHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, options } = e.target;
    const selectedValues = Array.from(options)
      .filter((option: HTMLOptionElement) => option.selected)
      .map((option: HTMLOptionElement) => option.value);
    setPetHealthEntry((prevState: any) => {
      const existingSelection = prevState[name] || [];
      const updatedSelection = existingSelection.includes(selectedValues[0])
        ? existingSelection.filter((value: string) => value !== selectedValues[0]) // Anotación de tipo explícita
        : [...existingSelection, ...selectedValues];
      return {
        ...prevState,
        [name]: updatedSelection
      };
    });

    if (name === 'petChartPlaces') {
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          const newShow = show.map((valor, index) => {
            if (i === index) {
              return !valor;
            } else {
              return valor;
            }
          });
          setShow(newShow);
        }
      }
    }
  };

  const clearSelection = () => {
    setPetHealthEntry((prevState: any) => ({
      ...prevState,
      petChartPlaces: []
    }));
    setShow([]);
    clearArray();
  };

  const clearArray = async () => {
    try {
      for (let i = 0; i < 23; i++) {
        setShow(current => [...current, false]);
      }
    } catch (error : any) {
      console.log(error);
    }
  };

  function prettifyPhoneNumber(phoneNumber:string) {
    const formattedNumber = phoneNumber.replace(/\s/g, '');
    if (formattedNumber.length > 4) {
      const countryCode = formattedNumber.slice(0, 4); // +569
      const firstPart = formattedNumber.slice(4, 8);
      const secondPart = formattedNumber.slice(8);
      return `${countryCode} ${firstPart} ${secondPart}`;
    }
    return phoneNumber;
  }

  function formatPhoneNumber(phoneNumber:string) {
    return phoneNumber.replace(/\s/g, '');
  }

  return (
    <>
    <div className='wrapper'>
      <Navbar logged={true} />
      <body>
        <div className='spacer32'></div>
        <h6 className='navigation-info-post-post ms-3'><Link to={'/pets/showAll'} className='link-perfil'>Mascotas</Link> / Crear Ficha Médica</h6>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='title-pets'>Ficha Médica Veterinaria</div>
        </div>
        <div className='container-fichas' style={{ marginTop: '30px', flexDirection: 'column' }}>
          <div className='col-auto' style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="card" style={{
              width: '900px',
              marginBottom: '50px',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }}>
            <div className="card-body form-title-container">
              <div className = "texto-titulo-card"> Datos del paciente</div>
              <img src={pet?.profileUrlImg !== '' ? pet?.profileUrlImg : perrito}
              className="img-thumbnail-med" alt="Foto de perfil de la mascota" />
              <div>
                <h4 className="texto-campo" style={{ fontSize: '26px', margin: '4%', color: '#000' }}>{pet?.name}</h4>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className="pet-info">
                    <h6 className="texto-campo-card">
                      Microchip: <span>{pet?.microchipId ? pet.microchipId : 'No hay microchip ingresado'}</span>
                    </h6>
                    <h6 className="texto-campo-card">Especie: <span>{pet?.species}</span></h6>
                    <h6 className="texto-campo-card" style={{ textTransform: 'capitalize' }}>Raza: <span>{pet?.race.toLocaleLowerCase()}</span></h6>
                    <h6 className="texto-campo-card">
                      Nacimiento: <span>{pet?.birthdate ? moment.utc(pet.birthdate).format('DD/MM/YYYY') : ''}</span>
                    </h6>
                    {pet?.gender === 'MALE' &&
                      <h6 className="texto-campo-card">Género: <span>Macho</span></h6>
                    }
                    {pet?.gender === 'FEMALE' &&
                      <h6 className="texto-campo-card">Género: <span>Hembra</span></h6>
                    }
                    {pet?.gender === 'OTHER' &&
                      <h6 className="texto-campo-card">Género: <span>Otro </span></h6>
                    }
                    <h6 className="texto-campo-card">Patrón: <span>{pet?.pattern}</span></h6>
                    <h6 className="texto-campo-card">Color: <span>{pet?.color}</span></h6>
                    <h6 className="texto-campo-card">Estado Reproductivo: <span>{pet?.reproductiveState}</span></h6>
                    <h6 className="texto-campo-card">Método de Obtención: <span>{pet?.obtencion}</span></h6>
                    <h6 className="texto-campo-card">Razón de Tenencia: <span>{pet?.razonTenencia}</span></h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-auto' style={{ margin: '0px 10px' }}>
            <div className='spacer-12'></div>
            <div className='container-form'>
              <div className='spacer-12'></div>
              <div className='row g-3'>
                <div className='col-auto mx-auto'>
                  <div className='form-label texto-campo'>
                    Datos Médico/a Veterinario/a
                  </div>
                </div>
              </div>
        <div className='spacer-12'></div>
          <div className='row g-3'>
              <div className='col'>
                {errorNameVet &&
                  <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo'>
                  Nombre Completo*
                </div>
                <input className='box-campo' type="text" name="nameVet" placeholder="" value={petHealthEntry?.nameVet} onChange={handleChange} required></input>
              </div>
              <div className='col'>
              {errorRut && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo'>
                  RUT*
                </div>
                <input className='box-campo' type="text" name="rut" placeholder="" value={rutVet} onChange={handleChange} required></input>
              </div>
          </div>
          <div className='row g-3'>
            <div className='col'>
              {errorMailVet &&
                <p className='formError'>Campo obligatorio.</p>}
              <div className='form-label texto-campo'>
                Correo*
              </div>
              <input className='box-campo' type="text" name="mailVet" placeholder="" value={petHealthEntry?.mailVet} onChange={handleChange} required></input>
            </div>
            <div className='col'>
              {errorPhoneVet && <p className='formError'>Campo obligatorio.</p>}
              <div className='form-label texto-campo'>
                Teléfono de Contacto*
              </div>
              <input className='box-campo' type="text" name="phoneVet" placeholder="" value={phoneVet} onChange={handleChange} required></input>
            </div>
          </div>
          <div className='row g-3'>
              <div className='col'>
                {errorPlace && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo'>
                  Lugar*
                </div>
                <input className='box-campo' type="text" name="place" placeholder="" value={petHealthEntry?.place} onChange={handleChange} required></input>
              </div>
              <div className='col'>
              {errorPlace && <p className='formError' style={{ color: 'white' }}>.</p>}
            <div className='form-label texto-campo'>
              Profesión
            </div>
              <select autoComplete="on" name="profession" className="box-campo" value={petHealthEntry?.profession} onChange={dropdownChangedHandler}>
                <option value="Médico/a Veterinario/a">Médico/a Veterinario/a</option>
              </select>
            </div>
          </div>
          </div>
        <div className='spacer-12'></div>
        <div className='container-form'>
          <div className='spacer-12'></div>
          <div className='row g-3'>
          <div className='col-auto mx-auto'>
            <div className='form-label texto-campo'>
              Datos del Responsable
            </div>
          </div>
        </div>
        <div className='spacer-12'/>
        <div className='row g-3 long-info'>
          {errorFullName && <p className='formError'>Campo obligatorio.</p>}
            <div className='form-label texto-campo'>
              Nombre Completo*
            </div>
            <input className='box-campo' type="text" name="fullName" placeholder="" value={petHealthEntry?.fullName} onChange={handleChange} required />
          </div>
          <div className='row g-3'>
              <div className='col'>
              {errorNationality && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo'>
                  Nacionalidad*
                </div>
                <input className='box-campo' type="text" name="nationality" placeholder="" value={petHealthEntry?.nationality} onChange={handleChange} required />
              </div>
              <div className='col'>
              {errorNationality && <p className='formError' style={{ color: 'white' }}>.</p>}
            <div className='form-label texto-campo'>
              Tipo de Documento
            </div>
              <select autoComplete="on" name="documentation" className="box-campo" value={petHealthEntry?.documentation} onChange={dropdownChangedHandler}>
                <option value="Cédula">Cédula de identidad chilena</option>
                <option value="Pasaporte">Pasaporte</option>
              </select>
            </div>
          </div>
          <div className='row g-3'>
              <div className='col'>
              {errorDocumentNumber && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo'>
                  Número de Documento*
                </div>
                <input className='box-campo' type="string" name="documentNumber" placeholder="" value={rutTutor} onChange={handleChange} required />
              </div>
              <div className='col'>
                {errorBirthday && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo'>
                  Fecha de Nacimiento*
                </div>
                <input className='box-campo-date-med' type="date" name="birthdayDate" placeholder="" value={petHealthEntry?.birthdayDate} onChange={handleChange} required />
              </div>
            </div>
        <div className='row g-3'>
          <div className='col'>
            {errorRegion && <p className='formError'>Campo obligatorio.</p>}
            <div className="form-label texto-campo">
              Región*
            </div>
            <select autoComplete= "on" name="region" className="box-campo" value={petHealthEntry?.region} onChange={dropdownChangedHandler} required>
            <option value=""defaultValue="" disabled hidden>Selecciona una region</option>
            {RegionesYcomunas.regiones.map((region, index) => (
              <option key={index} value={region.NombreRegion}>
                {region.NombreRegion}
              </option>
            ))}
            </select>
          </div>
          <div className='col'>
          {errorComuna && <p className='formError'>Campo obligatorio.</p>}
          <div className='form-label texto-campo'>
            Comuna*
          </div>
            <select
              autoComplete="on"
              name="comuna"
              className="box-campo"
              value={petHealthEntry?.comuna}
              onChange={dropdownChangedHandler}
              required>
              <option value=""defaultValue="" disabled hidden>Selecciona una comuna</option>
              {petHealthEntry?.region &&
                RegionesYcomunas.regiones
                  .find((region) => region.NombreRegion === petHealthEntry?.region)
                  ?.comunas.map((comuna, index) => (
                    <option key={index} value={comuna}>
                      {comuna}
                    </option>
                  ))}
            </select>
          </div>
          </div>
          <div className='row g-3 long-info'>
            {errorHomeAddress && <p className='formError'>Campo obligatorio.</p>}
            <div className='form-label texto-campo'>Domicilio*</div>
            <input className='box-campo' type="text" name="homeAddress" placeholder="" value={petHealthEntry?.homeAddress} onChange={handleChange} required></input>
          </div>
          <div className='row g-3 long-info'>
            {errorPhone1 && <p className='formError'>Campo obligatorio.</p>}
            <div className='form-label texto-campo'>Teléfono de Contacto 1*</div>
            <input className='box-campo' name="phoneNumber1" placeholder="+56 y 9 dígitos" value={phoneNumber1} onChange={handleChange} required></input>
          </div>
          <div className='row g-3 long-info'>
            <div className='form-label texto-campo'>Teléfono de Contacto 2</div>
            <input className='box-campo' name="phoneNumber2" placeholder="+56 y 9 dígitos" value={phoneNumber2} onChange={handleChange}/>
          </div>
          <div className='row g-3 long-info'>
            {errorMail && <p className='formError'>Campo obligatorio.</p>}
            <div className='form-label texto-campo'>Correo*</div>
              <input className='box-campo' type="text" name="mail" placeholder="" value={petHealthEntry?.mail} onChange={handleChange} required></input>
            </div>
          </div>
        <div className='spacer-12'></div>
        <div className='container-form'>
          <div className='spacer-12'></div>
          <div className='row g-3'>
          <div className='col-auto mx-auto'>
            <div className='form-label texto-campo'>Datos Mascota</div>
          </div>
        </div>
        <div className='register-subtitle-div' style={{ fontSize: '13px' }}><p>Utilice comas para los decimales.</p></div>
        <div className='spacer-12'></div>
          <div className='row g-3'>
            <div className='col'>
              {errorTemperature && <p className='formError'>Campo obligatorio.</p>}
              <div className='form-label texto-campo'>
                Temperatura (°C)*
              </div>
              <input className='box-campo' type="number" step="0.01" name="temperature" placeholder="" value={petHealthEntry?.temperature} onInput={handleChangeNumber} required></input>
            </div>
            <div className='col'>
              {errorWeight && <p className='formError'>Campo obligatorio.</p>}
              <div className='form-label texto-campo'>
                Peso (Kg)*
              </div>
              <input className='box-campo' type="number" step="0.01" name="weight" placeholder="" value={petHealthEntry?.weight} onInput={handleChangeNumber} required></input>
            </div>
          </div>
          <div className='row g-3'>
            <div className="col">
              <div className='form-label texto-campo'>
                Condición Corporal*
              </div>
              <input
                className='box-campo'
                type="number"
                step="0.01"
                autoComplete='on'
                name="corporalCondition"
                placeholder=""
                value={petHealthEntry?.corporalCondition}
                onInput={handleChangeCorporal}
                required
                min='1'
                max='5'
              />
            </div>
            <div className="col">
              <div className='form-label texto-campo'>
                Deshidratación (%)*
              </div>
              <input className='box-campo' type="number" name="dehydration" placeholder="" value={petHealthEntry?.dehydration} onChange={handleChangeNumber} required></input>
            </div>
          </div>
          <div className='row g-3'>
            <div className='col'>
            <div className='form-label texto-campo'>
              Color Mucosa*
            </div>
              <select autoComplete="on" name="mucousColour" className="box-campo" value={petHealthEntry?.mucousColour} onChange={dropdownChangedHandler} required>
                <option value="No Medibles">No medibles</option>
                <option value="Palidas">Pálidas</option>
                <option value="Rosadas">Rosadas</option>
                <option value="Congestivas">Congestivas</option>
                <option value="Ictericas">Ictéricas</option>
                <option value="Cianoticas">Cianóticas</option>
              </select>
            </div>
            <div className='col'>
            <div className='form-label texto-campo'>
              Genitales*
            </div>
              <select autoComplete="on" name="genitals" className="box-campo" value={petHealthEntry?.genitals} onChange={dropdownChangedHandler} required>
                <option value="Normal">Normal</option>
                <option value="Presencia de Sangre">Presencia de sangre</option>
                <option value="Presencia de pus">Presencia de pus</option>
                <option value="Criptorquideo unilateral">Criptorquideo unilateral</option>
                <option value="Criptorquideo bilateral">Criptorquideo bilateral</option>
                <option value="Tumor">Tumor</option>
              </select>
            </div>
          </div>
          <div className='row g-3'>
            <div className='col'>
              <div className='form-label texto-campo'>
                Piel*
              </div>
              <select autoComplete="on" name="skin" className="box-campo" value={petHealthEntry?.skin} onChange={dropdownChangedHandler} required>
                <option value="Integra">Íntegra</option>
                <option value="Eritema">Eritema</option>
                <option value="Petequias">Petequias</option>
                <option value="Papulas">Pápulas</option>
                <option value="Pustulas">Pústulas</option>
                <option value="Inflamacion">Inflamación</option>
                <option value="Necrosis">Necrosis</option>
                <option value="Alopecia">Alopecia</option>
                <option value="Otros">Otros</option>
              </select>
            </div>
            <div className='col'>
            <div className='form-label texto-campo'>
                Linfonodos*
              </div>
              <select autoComplete="on" name="lymphoNodes" className="box-campo" value={petHealthEntry?.lymphoNodes} onChange={dropdownChangedHandler} required>
                <option value="Normales">Todos Normales</option>
                <option value="1 Aumentado">1 Aumentado</option>
                <option value="2 Aumentado">2 Aumentado</option>
                <option value="3 Aumentados">3 o más Aumentados</option>
              </select>
            </div>
          </div>
          <div className='row g-3'>
            <div className="col">
              <div className='form-label texto-campo'>
                Pulso Femoral*
              </div>
                <select autoComplete="on" name="femoralPulse" className="box-campo" value={petHealthEntry?.femoralPulse} onChange={dropdownChangedHandler} required>
                  <option value="Palpable y Fuerte">Palpable y fuerte</option>
                  <option value="Palpable y Lleno">Palpable y lleno</option>
                  <option value="Palpable Debil">Palpable débil</option>
                  <option value="No Palpable">No palpable</option>
                  <option value="Ausente">Ausente</option>
                </select>
            </div>
            <div className='col'>
            <div className='form-label texto-campo'>
                Riesgo de Anestesia*
              </div>
                <select autoComplete="on" name="anesthesiaRisk" className="box-campo" value={petHealthEntry?.anesthesiaRisk} onChange={dropdownChangedHandler} required>
                  <option value="ASA I">ASA I</option>
                  <option value="ASA II">ASA II</option>
                  <option value="ASA III">ASA III</option>
                  <option value="ASA IV">ASA IV</option>
                  <option value="ASA V">ASA V</option>
                </select>
            </div>
          </div>
          <div className='row g-3'>
            <div className='col'>
            <div className='form-label texto-campo'>
                Vacuna
              </div>
              <select autoComplete="on" name="vaccine" className="box-campo" defaultValue={'SEXTUPLE'} value={petHealthEntry?.vaccine} onChange={dropdownChangedHandler} >
                <option value="SEXTUPLE">Séxtuple</option>
                <option value="OCTUPLE">Óctuple</option>
                <option value="TRIPLEFELINA">TripleFelina</option>
                <option value="LEUCEMIA">Leucemia</option>
                <option value="NINGUNA">Ninguna</option>
              </select>
            </div>
            <div className='col'>
            <div className='form-label texto-campo'>
                Vacuna(s) Vigentes
              </div>
              <select autoComplete="on" name="currentVaccine" className="box-campo" value={petHealthEntry?.currentVaccine} onChange={dropdownChangedHandler} >
                <option value="SEXTUPLE">Séxtuple</option>
                <option value="OCTUPLE">Óctuple</option>
                <option value="TRIPLEFELINA">TripleFelina</option>
                <option value="LEUCEMIA">Leucemia</option>
                <option value="NINGUNA">Ninguna</option>
              </select>
            </div>
          </div>
          <div className='spacer-20'></div>
          <div className='spacer-12'></div>
          {errorAr && <p className='formError'>Campo obligatorio.</p>}
          <div className='row g-3 long-info'>
            <label className='form-label texto-campo'>Antirrábica Vigente</label>
            <div className='spacer-20'></div>
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ boxShadow: 'none', margin: '0px' }}>
              <input type="radio" className="btn-check" value="true" onClick={handleChangeBoolean} name="arVaccine" id="btnradio5" ></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio5" > Si </label>
              <input type="radio" className="btn-check" value="false" onClick={handleChangeBoolean} name="arVaccine" id="btnradio6"></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio6" > No</label>
            </div>
          </div>
          {errorApInternal && <p className='formError'>Campo obligatorio.</p>}
          <div className='row g-3 long-info'>
            <label className='form-label texto-campo'>Antiparasitario Interno</label>
            <div className='spacer-20'></div>
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ boxShadow: 'none', margin: '0px' }}>
              <input type="radio" className="btn-check" value="true" onClick={handleChangeBoolean} name="apInternal" id="btnradio3" ></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio3"> Si </label>
              <input type="radio" className="btn-check" value="false" onClick={handleChangeBoolean} name="apInternal" id="btnradio4" ></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio4"> No</label>
            </div>
          </div>
          {errorApExternal && <p className='formError'>Campo obligatorio.</p>}
          <div className='row g-3 long-info'>
            <label className='form-label texto-campo'>Antiparasitario Externo</label>
            <div className='spacer-20'></div>
            <div className="btn-group" role="group" aria-label="Basic radio toggle button group" style={{ boxShadow: 'none', margin: '0px', marginBottom: '-40px' }}>
              <input type="radio" className="btn-check" value="true" onClick={handleChangeBoolean} name="apExternal" id="btnradio1" ></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio1"> Si </label>
              <input type="radio" className="btn-check" value="false" onClick={handleChangeBoolean} name="apExternal" id="btnradio2" ></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio2"> No</label>
            </div>
          </div>
          {petHealthEntry?.apInternal === true ? (
            <div>
              <div className='col'>
                {errorApInternal && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo long-info'>Antiparasitaria interna</div>
                <input className='box-campo' type="text" name="apInternalDescription" placeholder="" value={petHealthEntry?.apInternalDescription} onChange={handleChange} />
              </div>
            </div>
          ) : null }
          {petHealthEntry?.apExternal === true ? (
            <div>
              <div className='col'>
                {errorApExternal && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo long-info'>Antiparasitaria externa</div>
                <input className='box-campo' type="text" name="apExternalDescription" placeholder="" value={petHealthEntry?.apExternalDescription} onChange={handleChange} />
              </div>
            </div>
          ) : null }
          <div className='row g-3'>
            <div className='col'>
            <div className='form-label texto-campo'>Palpación Abdominal*</div>
                <select autoComplete="on" name="abdominalPalpation" className="box-campo" value={petHealthEntry?.abdominalPalpation} onChange={dropdownChangedHandler} required>
                  <option value="SinMolestia">Sin dolor ni molestia</option>
                  <option value="Dolor Leve">Dolor leve</option>
                  <option value="Dolor Moderado">Dolor moderado</option>
                  <option value="Dolor Agudo">Dolor agudo</option>
                  <option value="No Medible">No medible</option>
                </select>
            </div>
            <div className='col'>
            <div className='form-label texto-campo'>
                Tiempo Llenado Capilar*
              </div>
              <select autoComplete="on" name="capillaryRefillTime" className="box-campo" value={petHealthEntry?.capillaryRefillTime} onChange={dropdownChangedHandler} required>
                <option value="Menor a 1 segundo">Menor a 1 segundo</option>
                <option value="1 segundo">1 segundo</option>
                <option value="2 segundos">2 segundos</option>
                <option value="Mayor a 2 segundos">Mayor a 2 segundos</option>
                <option value="No medible">No medible</option>
              </select>
            </div>
          </div>
          <div className='row g-3'>
            <div className='col'>
            <div className='form-label texto-campo'>
                Celo Actual
              </div>
              <select autoComplete="on" name="inHeat" className="box-campo" value={petHealthEntry?.inHeat} onChange={dropdownChangedHandler} >
                <option value='SI'>Sí</option>
                <option value='NO'>No</option>
                <option value='NA'>No Aplica</option>
              </select>
            </div>
            {petHealthEntry?.inHeat.toString() !== 'NA' ? (
            <div className='col'>
              <div className='form-label texto-campo'>Último Celo</div>
              <input className='box-campo-date-med' type="date" name="uInHeat" placeholder="" value={petHealthEntry?.uInHeat} onChange={handleChange} />
              </div>
            ) : null }
          </div>
          <div className='spacer-12'></div>
          {petHealthEntry?.inHeat.toString() !== 'NA' ? (
          <div className='row g-3'>
            <div className='col'>
              <div className='form-label texto-campo'>
                Gestación Actual
              </div>
              <select autoComplete="on" name="currentGestation" className="box-campo" value={petHealthEntry?.currentGestation} onChange={dropdownChangedHandler} >
                <option value="SI">Sí</option>
                <option value="NO">No</option>
              </select>
            </div>
            <div className='col'>
            <div className='form-label texto-campo'>
                Partos Previos
              </div>
              <select autoComplete="on" name="pastBirths" className="box-campo" value={petHealthEntry?.pastBirths} onChange={dropdownChangedHandler} >
                <option value="SI">Sí</option>
                <option value="NO">No</option>
              </select>
            </div>
          </div>
          ) : null }
          <div className='spacer-12'></div>
          <div className='row g-3 long-info'>
            <div className='form-label texto-campo'>Anticonceptivos</div>
            <select autoComplete="on" name="contraceptives" className="box-campo" value={petHealthEntry?.contraceptives} onChange={dropdownChangedHandler} >
              <option value="SI">Sí</option>
              <option value="NO">No</option>
              <option value="NS">N/S</option>
            </select>
          </div>
          <div className='row g-3 long-info'>
          <div className='form-label texto-campo'>Enfermedades Previas</div>
            <select autoComplete="on" name="previousDeseases" className="box-campo" value={petHealthEntry?.previousDeseases} onChange={multipleDropdownChangedHandler} multiple>
                <option className='option-lession' value="Digestiva">Digestiva</option>
                <option className='option-lession' value="Respiratoria">Respiratoria</option>
                <option className='option-lession' value="Neurologica">Neurológica</option>
                <option className='option-lession' value="Endocrina">Endocrina</option>
                <option className='option-lession' value="Ocular">Ocular</option>
                <option className='option-lession' value="Articular">Articular</option>
                <option className='option-lession' value="Cardiovascular">Cardiovascular</option>
                <option className='option-lession' value="Traumatica">Traumática</option>
                <option className='option-lession' value="Reproductiva">Reproductiva</option>
                <option className='option-lession' value="Oncologica">Oncológica</option>
                <option className='option-lession' value="Inmunologica">Inmunológica</option>
                <option className='option-lession' value="Ninguna">Ninguna</option>
                <option className='option-lession' value="Otra">Otra</option>
            </select>
          </div>
          {petHealthEntry?.previousDeseases.includes('Otra') && (
            <div>
              <div className='row g-3 long-info'>
                {errorApInternal && <p className='formError'>Campo obligatorio.</p>}
                <div className='form-label texto-campo long-info'>Especifique la enfermedad previa</div>
                <input className='box-campo' type="text" name="otherPreviousDeseases" placeholder="" value={petHealthEntry?.otherPreviousDeseases} onChange={handleChange} />
              </div>
            </div>
          )}
          <div className='spacer-12'></div>
          <div className='spacer-20'></div>
          {((pet?.species === 'Gato') || (pet?.species === 'Perro')) && (
            <div>
              <div className='long-info' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <label className='form-label texto-campo' style={{ marginTop: '0px', marginBottom: '-30px' }}>Dermograma</label>
                {pet.species === 'Perro' && (
                  <div className='imagen-contenedor'>
                    <img src={dermogramaCanino} alt="Dermograma de un perro" className="img-thumbnail-med long-info imagen-base"/>
                    {show.map((value, index) => (
                      value && <img src={require(`../../Assets/Imagenes/Dermograma/Canino/${injuryZones[index]}.png`)} alt={`Perro ${index + 1}`} className='img-thumbnail-med long-info imagen-overlay'/>
                    ))}
                  </div>
                )}
                {pet.species === 'Gato' && (
                  <div className='imagen-contenedor'>
                    <img src={dermogramaFelino} alt="Dermograma de un gato" className="img-thumbnail-med long-info imagen-base"/>
                    {show.map((value, index) => (
                      value && <img src={require(`../../Assets/Imagenes/Dermograma/Felino/${injuryZones[index]}.png`)} alt={`Gato ${index + 1}`} className='img-thumbnail-med long-info imagen-overlay'/>
                    ))}
                  </div>
                )}
              </div>
              <div className='spacer-20'></div>
              <div className='col' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <label className='form-label texto-campo' style={{ marginTop: '20px', marginBottom: '0px' }}>
                  ¿Dónde se encuentra la lesión?</label>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '815px', marginTop: '-20px' }}>
                  <select autoComplete="on" name="petChartPlaces" className="box-campo-injury" value={petHealthEntry?.petChartPlaces} onChange={multipleDropdownChangedHandler} multiple>
                  <option className='option-lession' value="Cabeza, vista ventral">1. Cabeza, vista ventral</option>
                  <option className='option-lession' value="Cuello, vista ventral">2. Cuello, vista ventral</option>
                  <option className='option-lession' value="Miembro anterior izquierdo, vista ventral">3. Miembro anterior izquierdo, vista ventral</option>
                  <option className='option-lession' value="Miembro anterior derecho, vista ventral">4. Miembro anterior derecho, vista ventral</option>
                  <option className='option-lession' value="Miembro posterior izquierdo, vista ventral">5. Miembro posterior izquierdo, vista ventral</option>
                  <option className='option-lession' value="Miembro posterior derecho, vista ventral">6. Miembro posterior derecho, vista ventral</option>
                  <option className='option-lession' value="Región inguinal izquierda">7. Región inguinal izquierda</option>
                  <option className='option-lession' value="Región lateral - Flanco izquierdo">8. Región lateral - Flanco izquierdo</option>
                  <option className='option-lession' value="Hipocondrio izquierdo">9. Hipocondrio izquierdo</option>
                  <option className='option-lession' value="Región inguinal derecha">10. Región inguinal derecha</option>
                  <option className='option-lession' value="Región lateral - Flanco derecho">11. Región lateral - Flanco derecho</option>
                  <option className='option-lession' value="Hipocondrio derecho">12. Hipocondrio derecho</option>
                  <option className='option-lession' value="Región púbica">13. Región púbica</option>
                  <option className='option-lession' value="Región umbilical">14. Región umbilical</option>
                  <option className='option-lession' value="Región xifoidea">15. Región xifoidea</option>
                  <option className='option-lession' value="Cabeza, vista dorsal">16. Cabeza, vista dorsal</option>
                  <option className='option-lession' value="Cuello, vista dorsal">17. Cuello, vista dorsal</option>
                  <option className='option-lession' value="Miembro anterior izquierdo, vista dorsal">18. Miembro anterior izquierdo, vista dorsal</option>
                  <option className='option-lession' value="Miembro anterior derecho, vista dorsal">19. Miembro anterior derecho, vista dorsal</option>
                  <option className='option-lession' value="Miembro posterior izquierdo, vista dorsal">20. Miembro posterior izquierdo, vista dorsal</option>
                  <option className='option-lession' value="Miembro posterior derecho, vista dorsal">21. Miembro posterior derecho, vista dorsal</option>
                  <option className='option-lession' value="Cabeza, vista lateral derecha">22. Cabeza, vista lateral derecha</option>
                  <option className='option-lession' value="Cabeza, vista lateral izquierda">23. Cabeza, vista lateral izquierda</option>
                  </select>
                  <div className='clear-container'>
                    <button className='clear-selection' onClick={clearSelection}>Borrar</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='spacer-12'></div>
          <div className='spacer-12'></div>
          <div className='spacer-20'></div>
          <div className='row g-3 long-info'>
            <label className='form-label texto-campo'>Primera vez que asiste al médico/a veterinario/a</label>
            <div className='spacer-20'></div>
            <div className="btn-group long-info" role="group" aria-label="Basic radio toggle button group" style={{ boxShadow: 'none', margin: '0px', marginBottom: '-30px' }}>
              <input type="radio" className="btn-check" value="true" onClick={handleChangeBoolean} name="firstTime" id="btnradio9" ></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio9" >Si</label>
              <input type="radio" className="btn-check" value="false" onClick={handleChangeBoolean} name="firstTime" id="btnradio10"></input>
              <label className="btn btn-outline-secondary" htmlFor="btnradio10" >No</label>
            </div>
          </div>
          <div className='row g-3 long-info'>
            <div className='form-label texto-campo' style={{ marginTop: '0px' }}>
              Fecha Control:*
            </div>
            <input className='box-campo-date-med long-info' type="date" name="controlDate" placeholder="" value={petHealthEntry?.controlDate.substring(0, 10)} onChange={handleChangeControlDate} required></input>
          </div>
          <div className='spacer-20'></div>
          <div className='med-record'>
            <div className='row g-3 long-info'>
              <div className='form-label texto-campo'>
                Motivo de Consulta*
              </div>
              <textarea className='box-campo-final' name="visitReason" placeholder="" value={petHealthEntry?.visitReason} onChange={handleChangeTextArea} required></textarea>
            </div>
            <div className='spacer-12'></div>
            <div className='spacer-12'></div>
            <div className='row g-3 long-info'>
                  <div className='form-label texto-campo'>
                    Anamnesis*
                  </div>
                  <textarea className='box-campo-final' name="anamnesis" placeholder="" value={petHealthEntry?.anamnesis} onChange={handleChangeTextArea} required></textarea>
            </div>
            <div className='spacer-12'></div>
            <div className='row g-3 long-info'>
                  <div className='form-label texto-campo'>
                    Antecedentes Clínicos*
                  </div>
                  <textarea className='box-campo-final' name="clinicalBackground" placeholder="" value={petHealthEntry?.clinicalBackground} onChange={handleChangeTextArea} required></textarea>
                  </div>
                  <div className='spacer-12'></div>
                  <div className='row g-3'>
                  <div className='form-label texto-campo'>
                    Observaciones
                  </div>
                  <textarea className='box-campo-final' name="observations" placeholder="" value={petHealthEntry?.observations} onChange={handleChangeTextArea} ></textarea>
            </div>
            <div className='spacer-12'></div>
            <div className='row g-3 long-info'>
                  <div className='form-label texto-campo'>
                  Prediagnóstico*
                  </div>
                  <textarea className='box-campo-final' name="initialEv" placeholder="" value={petHealthEntry?.initialEv} onChange={handleChangeTextArea} required></textarea>
            </div>
            <div className='spacer-12'></div>
            <div className='spacer-12'></div>
            <div className='form-label texto-campo'>
                    Solicitud de Exámenes Complementarios*
              </div>
              <div className='check-label'>
                <input type='checkbox' name='hemograma' checked={examChecks.hemograma} onChange={handleCheckChange} className='exam-check'/>
                <h6>Hemograma completo</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='perfil_bioquimico' checked={examChecks.perfil_bioquimico} onChange={handleCheckChange} className='exam-check'/>
                <h6>Perfil bioquímico completo</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='hem_pb' checked={examChecks.hem_pb} onChange={handleCheckChange} className='exam-check'/>
                <h6>Hemograma + Perfil bioquímico</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='hem_pb_t4' checked={examChecks.hem_pb_t4} onChange={handleCheckChange} className='exam-check'/>
                <h6>Hemograma + Perfil bioquímico + T4</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='hem_pb_t4_tsh' checked={examChecks.hem_pb_t4_tsh} onChange={handleCheckChange} className='exam-check'/>
                <h6>Hemograma + Perfil bioquímico + T4 + TSH</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='prequirurgico_basico' checked={examChecks.prequirurgico_basico} onChange={handleCheckChange} className='exam-check'/>
                <h6>Pre quirúrgico básico (Hemograma + Nus + Crea + Alt + Ast)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='prequirurgico_completo' checked={examChecks.prequirurgico_completo} onChange={handleCheckChange} className='exam-check'/>
                <h6>Pre quirúrgico completo (Perfil + Hemograma + TP + TTPA + PLQ)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='protombina' checked={examChecks.protombina} onChange={handleCheckChange} className='exam-check'/>
                <h6>Tiempo de protrombina (PT)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='tromboplastina' checked={examChecks.tromboplastina} onChange={handleCheckChange} className='exam-check'/>
                <h6>Tiempo de tromboplastina parcial act. (TTPA)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='panel_coagulacion' checked={examChecks.panel_coagulacion} onChange={handleCheckChange} className='exam-check'/>
                <h6>Panel coagulación (TP + TTPA + Plaquetas)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='coagulacion_infecc' checked={examChecks.coagulacion_infecc} onChange={handleCheckChange} className='exam-check'/>
                <h6>Coagulación infecc (TP + TTPA + Plaquetas + Erlichia)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='perfil_renal' checked={examChecks.perfil_renal} onChange={handleCheckChange} className='exam-check'/>
                <h6>Perfil renal</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='perfil_hepatico' checked={examChecks.perfil_hepatico} onChange={handleCheckChange} className='exam-check'/>
                <h6>Perfil hepático</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='perfil_lipidico' checked={examChecks.perfil_lipidico} onChange={handleCheckChange} className='exam-check'/>
                <h6>Perfil lipídico</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='perfil_lipidico_t4_tsh' checked={examChecks.perfil_lipidico_t4_tsh} onChange={handleCheckChange} className='exam-check'/>
                <h6>Perfil lipídico + T4 + TSH</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='t4_tsh' checked={examChecks.t4_tsh} onChange={handleCheckChange} className='exam-check'/>
                <h6>T4 + TSH</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='felv_fiv' checked={examChecks.felv_fiv} onChange={handleCheckChange} className='exam-check'/>
                <h6>FELV + FIV (Elisa o Inmunocromatográfica)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='parvovirus_distemper' checked={examChecks.parvovirus_distemper} onChange={handleCheckChange} className='exam-check'/>
                <h6>Parvovirus ag + Distemper AG (Inmunocromatográfica)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='brucelosis' checked={examChecks.brucelosis} onChange={handleCheckChange} className='exam-check'/>
                <h6>Brucelosis canina AC (Inmunocromatográfica)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='distemper' checked={examChecks.distemper} onChange={handleCheckChange} className='exam-check'/>
                <h6>Distemper AG (Inmunocromatografía)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='leucemia_felina' checked={examChecks.leucemia_felina} onChange={handleCheckChange} className='exam-check'/>
                <h6>Leucemia felina (FELV) AG (Elisa)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='inmunodeficiencia_felina' checked={examChecks.inmunodeficiencia_felina} onChange={handleCheckChange} className='exam-check'/>
                <h6>Inmunodeficiencia felina (FIV) AC (Inmunocromatografía)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='ehrlichia_anaplasma' checked={examChecks.ehrlichia_anaplasma} onChange={handleCheckChange} className='exam-check'/>
                <h6>Ehrlichia-anaplasma (Inmunocromatografía o Elisa)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='parvovirus' checked={examChecks.parvovirus} onChange={handleCheckChange} className='exam-check'/>
                <h6>Parvovirus AG (Inmunocromatografía)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='toxoplasma' checked={examChecks.toxoplasma} onChange={handleCheckChange} className='exam-check'/>
                <h6>Toxoplasma (Inmunocromatografía)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='cultivo_antibiograma_citologico_oidos' checked={examChecks.cultivo_antibiograma_citologico_oidos} onChange={handleCheckChange} className='exam-check'/>
                <h6>Cultivo y antibiograma + Citológico oídos</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='directo_piel_cultivo_dermatofitos' checked={examChecks.directo_piel_cultivo_dermatofitos} onChange={handleCheckChange} className='exam-check'/>
                <h6>Directo de piel más cultivo dermatofitos</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='directo_piel_frotis_malassesia_cultivo_dermatofitos' checked={examChecks.directo_piel_frotis_malassesia_cultivo_dermatofitos} onChange={handleCheckChange} className='exam-check'/>
                <h6>Directo piel + Frotis malassesia + Cultivo dermatofitos</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='urianalisis' checked={examChecks.urianalisis} onChange={handleCheckChange} className='exam-check'/>
                <h6>Urianálisis</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='urocultivo_antibiograma' checked={examChecks.urocultivo_antibiograma} onChange={handleCheckChange} className='exam-check'/>
                <h6>Urocultivo + Antibiograma</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='coproparasitario' checked={examChecks.coproparasitario} onChange={handleCheckChange} className='exam-check'/>
                <h6>Coproparasitario</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='coprocultivo' checked={examChecks.coprocultivo} onChange={handleCheckChange} className='exam-check'/>
                <h6>Coprocultivo</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='cultivo_antibiograma' checked={examChecks.cultivo_antibiograma} onChange={handleCheckChange} className='exam-check'/>
                <h6>Cultivo + Antibiograma (Torula Stuart)</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='directo_piel_pelos' checked={examChecks.directo_piel_pelos} onChange={handleCheckChange} className='exam-check'/>
                <h6>Directo de piel y pelos</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='radiografia' checked={examChecks.radiografia} onChange={handleCheckChange} className='exam-check'/>
                <h6>Radiografía</h6>
              </div>
              <div className='check-label'>
                <input type='checkbox' name='ecotomografia' checked={examChecks.ecotomografia} onChange={handleCheckChange} className='exam-check'/>
                <h6>Ecotomografía</h6>
              </div>
                  <div className='spacer-20'></div>
            <div className='row g-3 long-info'>
                  <div className='form-label texto-campo'>
                    Indicaciones*
                  </div>
                  <textarea className='box-campo-final' name="indications" placeholder="" value={petHealthEntry?.indications} onChange={handleChangeTextArea} required></textarea>
            </div>
            <div className='spacer-12'></div>
            {files.length < 5 &&
            <div className='row g-3 long-info'>
            <div className='spacer-20'></div>
            <div className='spacer-12'></div>
              {files.length > 0 &&
              <p>Archivos seleccionados: {files.length}</p>
              }
              <p style={{ display: 'flex', justifyContent: 'center', margin: '0' }}>
                <label htmlFor="profileUrlImg" className="upload-label">
                  Ingresar archivos
                  <input
                    className="register-form-input"
                    type="file"
                    onChange={onFileChange}
                    name="profileUrlImg"
                    id="profileUrlImg"
                  />
                  <CloudUploadIcon className="upload-icon" />
                </label>
              </p>
            </div>
            }
            {files.length >= 5 &&
            <div className='row g-3 long-info'>
              <div className='form-label texto-campo'>Ya ingresó 5 achivos</div>
            </div>
            }
          </div>
          </div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='buttons-med-create'>
            <button onClick={() => volver()} className='button-crear-ficha'>Volver</button>
            <button onClick={() => handleSubmit()} className='button-crear-ficha'>Confirmar</button>
            <Snackbar anchorOrigin= { { vertical: 'top', horizontal: 'right' } }
              open={errorEmpty} autoHideDuration={5000} onClose={handleCloseAlert}>
              <Alert severity="error"> ¡Hay campos obligatorios sin rellenar!</Alert>
            </Snackbar>
          </div>
        </div>
      </body>
      <Footer/>
      </div>
    </>
  );
};

export default connect(mapStateToProps)(PetHealthEdit);
