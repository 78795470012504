import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Grid, TextField, Pagination } from '@mui/material';
import axios from 'axios';
import Navbar from '../Navbar';
import { PerfilTypesToken } from '../../Types/PerfilTypes';
import { Pet } from '../../Types/petType';
import { speciesAndRaces } from '../../const/speciesAndRaces';
import SelectableFilterList from '../Common/selectableFilterList';
import patientImg from '../../Assets/Imagenes/paciente gris.png';
import Footer from '../Footer';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

function mapStateToProps(state: any) {
  return { action: state }; // this will be available in HomeScreen as props.action
}

function IndexPatients(props: PerfilTypesToken) {
  const patientsInPage: number = 10;
  const speciesNames = Object.values(speciesAndRaces.species).map((species) => species.nameSpecies);
  const [racesOptions, setRacesOptions] = useState<Array<string>>([]);
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [checkedFilter, setCheckedFilter] = useState<{
    species: string[];
    races: string[];
  }>({
    species: [],
    races: []
  });
  const [nameFilter, setNameFilter] = useState<string>('');
  const [microchipFilter, setMicrochipFilter] = useState<string>('');

  useEffect(() => {
    fetchPatients(currentPage);
  }, [currentPage, nameFilter, microchipFilter, checkedFilter]);

  async function fetchPatients(page: number) {
    const token = JSON.parse(localStorage.getItem('token') || '');
    const queryParams = new URLSearchParams();
    queryParams.append('page', page.toString());
    queryParams.append('limit', patientsInPage.toString());

    const addFilterParam = (filterArray: string[], paramName: string) => {
      const joinedFilter = filterArray.join(',');
      if (joinedFilter) {
        queryParams.append(paramName, joinedFilter);
      }
    };

    addFilterParam(checkedFilter.species, 'species');
    addFilterParam(checkedFilter.races, 'breed');
    if (nameFilter) {
      queryParams.append('name', nameFilter);
    }
    if (microchipFilter) {
      queryParams.append('microchip', microchipFilter);
    }

    const url = `${process.env.REACT_APP_URL}/headVets/byVet/${props.action.user.credenciales.id}?${queryParams.toString()}`;
    await axios.get(url,
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .then((response) => {
        console.log(response.data);
        const petPatients = response.data.headVets.map((patient: { pet: Pet; }) => patient.pet);
        setPatients(petPatients);
        setTotalPages(response.data.pagination.totalPages);
      });
  }

  const handleToggle = (
    listName: 'species' | 'races',
    value: string
  ) => () => {
    const currentIndex = checkedFilter[listName].indexOf(value);
    const newChecked = [...checkedFilter[listName]];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedFilter((prev) => ({ ...prev, [listName]: newChecked }));

    if (listName === 'species') {
      const selectedSpecies = speciesAndRaces.species.find((species) => species.nameSpecies === value);
      setRacesOptions((prev) => {
        if (currentIndex === -1 && selectedSpecies) {
          return [...prev, ...selectedSpecies.races].sort();
        } else if (currentIndex !== -1 && selectedSpecies) {
          return prev.filter(race => !selectedSpecies.races.includes(race)).sort();
        }
        return prev.sort();
      });
    }
  };

  return (
    <>
      <Navbar logged={true} />
      <div style={{ minHeight: '80vh' }}>
        <Grid container justifyContent={'center'} style={{ padding: '30px' }}>
          <Grid item xs={3} className='filters-container'>
            <h6 className='navigation-info-post-post pt-3 ms-3'>Pacientes / Todos</h6>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Nombre</h2>
              </div>
              <TextField id="outlined-basic" label="Nombre" variant="outlined" value={nameFilter} onChange={e => setNameFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Microchip</h2>
              </div>
              <TextField id="outlined-basic" label="Microchip" variant="outlined" value={microchipFilter} onChange={e => setMicrochipFilter(e.target.value)} />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Especie</h2>
              </div>
              <SelectableFilterList
                items={speciesNames} checked={checkedFilter.species}
                handleToggle={(value) => handleToggle('species', value)}
              />
            </div>
            <div className='category-container'>
              <div className='category-title'>
                <h2>Raza</h2>
              </div>
              <SelectableFilterList
                items={racesOptions} checked={checkedFilter.races}
                handleToggle={(value) => handleToggle('races', value)}
              />
            </div>
          </Grid>
          {
            patients.length > 0 ? (
              <Grid
                item
                container
                justifyContent={'space-evenly'}
                alignContent={'start'}
                xs={9}
                className='card-group'
              >
                <Grid container item xs={12} justifyContent={'center'} style={{ marginBottom: '15px' }}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="secondary"
                    size='large'
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'gray', // Cambiar el color del texto
                        '&.Mui-selected': {
                          backgroundColor: '#287685', // Color de fondo cuando está seleccionado
                          color: 'white' // Color del texto cuando está seleccionado
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: '#287685' // Color de fondo cuando está seleccionado
                        },
                        '&:hover': {
                          backgroundColor: 'lightblue' // Color de fondo al pasar el ratón
                        }
                      }
                    }}
                  />
                </Grid>
                {patients.map((patient: Pet) => (
                    <Grid item key={patient.id}>
                      <div className="card card-vet" style={{ padding: '20px' }}>
                        <img src={patient.profileUrlImg || patientImg} className="card-img-top img-card-vet" alt={`${patient.name} logo`} />
                        <div className="card-body">
                          <h5 className="card-title">{patient.name}</h5>
                          <p className="card-text">{patient.species}: {patient.race}</p>
                          <p className="card-text">Numero de microchip: {patient.microchipId}</p>
                        </div>
                        <Link to={{ pathname: '/petsHealth/showAll/' + patient.id }}>
                          <button className="profile-button">Ver sus fichas clínicas</button>
                        </Link>
                      </div>
                    </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item container justifyContent={'center'} alignItems={'baseline'} xs={9}>
                <Alert icon={<WarningAmberOutlinedIcon fontSize="inherit" />} severity="info">No hay pacientes registrados</Alert>
              </Grid>
            )
          }
        </Grid>
      </div>
      <Footer/>
    </>
  );
}

export default connect(mapStateToProps)(IndexPatients);
